import React, { useState } from "react";

// ** history
import { history } from "../history";

// ** routes list
import { authRoutes } from "../router/routesList";

import { Link, useLocation } from "react-router-dom";

import Logo from "../assets/img/Logo.png";
import UserIcon from "../assets/img/icons/UserIcon";
import LogOutIcon from "../assets/img/icons/LogOutIcon";
import { IoIosArrowBack } from "react-icons/io";
import { HiMenuAlt2 } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";

import { motion } from "framer-motion";

//**dev extreme */
import Drawer from "devextreme-react/drawer";
import ScrollView from "devextreme-react/scroll-view";

// ** navigation list
import { navigationConfig } from "../configs/navigationConfig";

import { isMobile } from "react-device-detect";
import classnames from "classnames";
import themeConfig from "../configs/themeConfigs";

const VerticalLayout = ({ children }) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const menuVariants = {
    open: { opacity: 1, y: "0%", display: "block" },
    closed: { opacity: 0, y: "-100%", display: "none" },
  };
  const menuItemsVariants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    closed: {
      y: 50,
      opacity: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
  };

  const [openSubMenuItem, setOpenSubMenuItem] = useState(false);

  const [menuConfig, setMenuConfig] = useState({
    opened: isMobile ? false : true,
    openedStateMode: isMobile ? "push" : "shrink",
    revealMode: "slide",
    position: "left",
  });

  const onClickToggleMenu = () => {
    setMenuConfig({
      ...menuConfig,
      opened: !menuConfig.opened,
    });
  };

  const onClickLogOut = () => {
    localStorage.clear();
    history.push(authRoutes.LOGIN_BY_USER_NAME);
  };

  const drawerHeader = () => {
    return (
      <div className="drawer_header">
        <div className="d-flex justify-content-start flex-row   ml-20 align-item-center">
          <img width={32} height={32} src={Logo} alt="Logo" className="mt-2" />
          <h2 className="ml-5 pt-5 text-grey fw-800">Poilabs</h2>
        </div>
        <div>
          <hr className="hr" />
        </div>
      </div>
    );
  };

  const drawerItem = () => {
    return navigationConfig.map((item) => {
      if (item.subMenuList) {
        let isActiveItem;
        return (
          <div key={item.id} style={{ overflow: "hidden" }}>
            <div
              className={"text-decoration-none cursor-pointer"}
              onClick={() => {
                setOpenSubMenuItem(!openSubMenuItem);
              }}
            >
              <div className="d-flex justify-space-between align-item-center flex-row">
                <div className="cursor-pointer list d-flex justify-content-start align-item-center flex-row pl-20 pr-20 pt-20 pb-20  w-100">
                  <div className="d-flex justify-content-center">
                    {item.icon(openSubMenuItem)}
                  </div>
                  <div className="ml-10">{item.title(openSubMenuItem)}</div>
                </div>
                <div className="mr-20">{item.chevronIcon(openSubMenuItem)}</div>
              </div>
            </div>

            <motion.div
              animate={openSubMenuItem ? "open" : "closed"}
              variants={menuVariants}
              className="pl-20 pr-20"
            >
              {item.subMenuList.map((subMenu) => {
                isActiveItem = currentPath === subMenu.navLink ? true : false;
                return (
                  <Link
                    to={subMenu.navLink}
                    className={
                      isActiveItem === true
                        ? "bg-light-grey text-decoration-none d-block mb-10 br-4"
                        : "text-decoration-none d-block mb-10 br-4"
                    }
                    key={subMenu.id}
                  >
                    <motion.div
                      variants={menuItemsVariants}
                      whileHover={{ scale: 1.09 }}
                      whileTap={{ scale: 0.95 }}
                      className="cursor-pointer list d-flex justify-content-start align-item-center flex-row pl-20 pr-20 pt-10 pb-10  w-100"
                    >
                      <div className="d-flex justify-content-center">
                        {subMenu.icon(isActiveItem)}
                      </div>
                      <div className="ml-10">
                        <span className="fs-12">
                          {subMenu.title(isActiveItem)}
                        </span>
                      </div>
                    </motion.div>
                  </Link>
                );
              })}
            </motion.div>
          </div>
        );
      } else {
        const isActiveItem = currentPath === item.navLink ? true : false;
        return (
          <Link to={item.navLink} className={"text-decoration-none "}>
            <div
              className="cursor-pointer list d-flex justify-content-start align-item-center flex-row pl-20 pr-20 pt-20 pb-20  w-100"
              onClick={() => {
                setOpenSubMenuItem(false);
              }}
            >
              <div className="d-flex justify-content-center">
                {item.icon(isActiveItem)}
              </div>
              <div className="ml-10">{item.title(isActiveItem)}</div>
            </div>
          </Link>
        );
      }
    });
  };

  const drawerFooter = () => {
    const userDataLocalStorage = JSON.parse(localStorage.getItem("userData"));

    return (
      <>
        <div>
          <hr className="hr" />
        </div>
        <div className="pt-20 pb-20 pl-20 pr-20 d-flex flex-row justify-content-between align-item-center">
          <div>
            <UserIcon />
          </div>
          <div className="ml-10">
            <span className="mb-2 d-block fs-12">
              {userDataLocalStorage.email}
            </span>
            <span className="text-grey d-block fs-12">Süper Admin</span>
          </div>
          <div className="ml-20 cursor-pointer" onClick={onClickLogOut}>
            <LogOutIcon />
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Drawer
        render={() => {
          return (
            <div
              className="d-flex flex-column drawer justify-space-between "
              style={{ position: "fixed" }}
            >
              <div>
                {drawerHeader()}
                <ScrollView height={window.innerHeight - 200} width={"100%"}>
                  {drawerItem()}
                </ScrollView>
              </div>
              <div>{drawerFooter()}</div>
            </div>
          );
        }}
        opened={menuConfig.opened}
        openedStateMode={menuConfig.openedStateMode}
        position={menuConfig.position}
        revealMode={menuConfig.revealMode}
        className={"vh-100 "}
      >
        <div className="pl-30 pr-30 pt-30 pb-40 drawer-toolbar main-shadow">
          <div
            className="cursor-pointer br-4 drawer-toolbar_toggle d-flex justify-content-center pt-5 pb-5 pl-5 pr-5 main-shadow"
            onClick={onClickToggleMenu}
          >
            {!isMobile ? (
              <motion.div
                animate={{
                  x: 0,
                  y: 0,
                  rotate: menuConfig.opened ? 0 : 180,
                }}
              >
                <IoIosArrowBack color="#f5f4f4" fontSize={17} />
              </motion.div>
            ) : !menuConfig.opened ? (
              <HiMenuAlt2
                color={themeConfig.colors.primaryColor}
                fontSize={30}
              />
            ) : (
              <IoMdClose color={"#605bff"} fontSize={30} />
            )}
          </div>
        </div>
        <div
          className={classnames("bg-grey vh-100  pt-10 pb-20", {
            "pl-10 pr-10": isMobile,
            "pl-30 pr-30": !isMobile,
          })}
        >
          {children}
        </div>
      </Drawer>
    </>
  );
};

export default VerticalLayout;
