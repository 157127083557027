import { all, fork } from "redux-saga/effects";
import authSagas from "./auth/saga";
import usersSagas from "./users/saga";
import customersSagas from "./customers/saga";
import placetypesSagas from "./placetypes/saga";
import menuItemsSagas from "./menuItems/saga";
import settings from "./settings/saga";
import places from "./places/saga";
import brands from "./brands/saga";
import categories from "./categories/saga";
import networktypes from "./networktypes/saga";
import pointypes from "./pointtype/saga";
import zones from "./zones/saga";
import segments from "./segments/saga";
import walkways from "./walkways/saga";
import beacons from "./beacons/saga";
import storeIds from "./storeIds/saga";
import pois from "./pois/saga";

export default function* rootSaga() {
  yield all([
    fork(authSagas),
    fork(usersSagas),
    fork(customersSagas),
    fork(placetypesSagas),
    fork(menuItemsSagas),
    fork(settings),
    fork(places),
    fork(brands),
    fork(categories),
    fork(networktypes),
    fork(pointypes),
    fork(zones),
    fork(segments),
    fork(walkways),
    fork(beacons),
    fork(storeIds),
    fork(pois),
  ]);
}
