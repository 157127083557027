import * as services from "../assets/data/urlConfig";
import axios from "axios";

// ** login async
export const loginPasswordAsync = async (email, password) => {
  const convertToBTOA = btoa(`${email}:${password}`);
  try {
    return await axios.get(services.loginPasswordUrl, {
      headers: {
        Authorization: `Basic ${convertToBTOA}`,
      },
    });
  } catch (error) {
    if (!error.response) {
      //no internet
      return {
        status: null,
      };
    }
    return error.response;
  }
};

// ** forget password
export const forgetPasswordAsync = async (email) => {
  try {
    return await axios.post(services.forgetPasswordUrl, {
      email,
    });
  } catch (error) {
    if (!error.response) {
      //no internet
      return {
        status: null,
      };
    }
    return error.response;
  }
};

// ** reset password
export const resetPasswordAsync = async (token, password) => {
  try {
    return await axios.post(services.restePasswordUrl, {
      password,
      token,
    });
  } catch (error) {
    if (!error.response) {
      //no internet
      return {
        status: null,
      };
    }
    return error.response;
  }
};
