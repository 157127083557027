import { call, takeEvery, put, all, fork } from "redux-saga/effects";
import * as actionTypes from "../actionTypes";

//**  services
import * as services from "../../services/menuItemServices";

//**  actions
import * as actions from "./actions";

import { toast } from "react-toastify";

//** Get user list
function* getMenuItems({ payload }) {
  const customerId = payload.customerId;

  try {
    const response = yield call(services.getMenuItemListAsync, customerId);

    const { data } = response;

    if (response.status === 200) {
      yield put(actions.getMenuItemListSuccess(data));
    } else {
      toast.error(data.message);
      yield put(actions.getMenuItemListFailure());
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.getMenuItemListFailure());
    toast.error(data.message);
    console.log(error);
  }
}

//** post menu  item  */
function* postNewMenuItem({ payload }) {
  let { menuItemInfo } = payload;
  try {
    const response = yield call(services.postMemuItemAsync, menuItemInfo);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.postMenuItemSuccess(data));
      toast.success("Your menu item created successfully");
    } else {
      yield put(actions.postMenuItemFailure());
      toast.error(data.message);
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.postMenuItemFailure());
    toast.error(data.message);
  }
}

// ** delete menu item
function* deleteMenuItem({ payload }) {
  let { menuItemInfo } = payload;
  try {
    const response = yield call(services.deleteMenuItemAsync, menuItemInfo);
    const { data } = response;

    if (response.status === 200) {
      yield put(
        actions.deleteMenuItemSuccess({ menuItemId: menuItemInfo.menuItemId })
      );
      toast.success("Your menu item deleted successfully");
    } else {
      yield put(actions.deleteMenuItemFailure());
      toast.error(data.message);
    }
  } catch (error) {
    yield put(actions.deleteMenuItemFailure());
    toast.error(error.message);
    console.log(error);
  }
}

// ** put menu item
function* putMenuItem({ payload }) {
  let { menuItemInfo } = payload;

  try {
    const response = yield call(services.putMenuItemUrlAsync, menuItemInfo);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.putMenuItemSuccess(data));
      toast.success("Your menu item edited successfully");
    } else {
      yield put(actions.putMenuItemFailure());
      toast.error(data.message);
    }
  } catch (error) {
    yield put(actions.putMenuItemFailure());
    toast.error(error.message);
    console.log(error);
  }
}

// ** put menu item
function* postTranslationMenuItem({ payload }) {
  let { menuItemInfo } = payload;
  try {
    const response = yield call(
      services.postMenuItemTranslationAsync,
      menuItemInfo
    );
    const { data } = response;

    if (response.status === 200) {
      yield put(
        actions.postMenuItemTranslationSuccess({
          menuItemId: menuItemInfo.menuItemId,
          data: data,
        })
      );
      toast.success("Your menu item translation added successfully");
    } else {
      yield put(actions.postMenuItemTranslationFailure());
      toast.error(data.message);
    }
  } catch (error) {
    const { data } = error.response;
    yield put(actions.postMenuItemTranslationFailure());
    toast.error(data.message);
    console.log(error);
  }
}

// ** put menu item
function* deleteTranslationMenuItem({ payload }) {
  let { menuItemInfo } = payload;
  try {
    const response = yield call(
      services.deleteMenuItemTranslationAsync,
      menuItemInfo
    );
    const { data } = response;

    if (response.status === 200) {
      yield put(
        actions.deleteMenuItemTranslationSuccess({
          menuItemId: menuItemInfo.menuItemId,
          translationId: menuItemInfo.translationId,
        })
      );
      yield put(actions.getMenuItemList(menuItemInfo.customerId));
      toast.success("Your menu item translation deleted successfully");
    } else {
      yield put(actions.deleteMenuItemTranslationFailure());
      toast.error(data.message);
    }
  } catch (error) {
    yield put(actions.deleteMenuItemTranslationFailure());
    toast.error(error.message);
    console.log(error);
  }
}

// ** put menu item
function* putMenuItemTranslation({ payload }) {
  let { menuItemInfo } = payload;
  try {
    const response = yield call(
      services.putMenuItemTranslationAsync,
      menuItemInfo
    );
    const { data } = response;

    if (response.status === 200) {
      yield put(
        actions.putMenuItemTranslationSuccess({
          menuItemId: menuItemInfo.menuItemId,
          translationId: menuItemInfo.translationId,
        })
      );
      yield put(actions.getMenuItemList(menuItemInfo.customerId));
      toast.success("Your menu item translation updated successfully");
    } else {
      yield put(actions.putMenuItemTranslationFailure());
      toast.error(data.message);
    }
  } catch (error) {
    yield put(actions.putMenuItemTranslationFailure());
    toast.error(error.message);
    console.log(error);
  }
}

// ** post Exclude MenuItem
function* postExcludeMenuItem({ payload }) {
  let { excludeInfo } = payload;

  try {
    const response = yield call(services.postExcludeMenuItemAsync, excludeInfo);
    const { data } = response;

    if (response.status === 200) {
      console.log(excludeInfo);
      yield put(
        actions.postExcludeMenuItemSuccess({
          data,
          menuitemId: excludeInfo.menuitemId,
          placeId: excludeInfo.placeId,
        })
      );
      toast.success("Your menu item excluded successfully");
    } else {
      yield put(actions.postExcludeMenuItemFailure());
      toast.error(data.message);
    }
  } catch (error) {
    yield put(actions.postExcludeMenuItemFailure());
    toast.error(error.message);
    console.log(error);
  }
}

// ** delete Exclude MenuItem
function* deleteExcludeMenuItem({ payload }) {
  let { excludeInfo } = payload;

  try {
    const response = yield call(
      services.deleteExcludeMenuItemAsync,
      excludeInfo
    );
    const { data } = response;

    if (response.status === 200) {
      yield put(
        actions.deleteExcludeMenuItemSuccess({
          data,
          menuitemId: excludeInfo.menuitemId,
          placeId: excludeInfo.placeId,
        })
      );
      toast.success("Your menu item exclude deleted successfully");
    } else {
      yield put(actions.deleteExcludeMenuItemFailure());
      toast.error(data.message);
    }
  } catch (error) {
    yield put(actions.deleteExcludeMenuItemFailure());
    toast.error(error.message);
    console.log(error);
  }
}

// ** post Attach MenuItem
function* postAttachMenuItem({ payload }) {
  let { attachInfo } = payload;

  try {
    const response = yield call(services.postAttachMenuItemAsync, attachInfo);
    const { data } = response;

    if (response.status === 200) {
      yield put(
        actions.postAttachMenuItemSuccess({
          data,
          menuitemId: attachInfo.menuitemId,
          placetypeId: attachInfo.placetypeId,
        })
      );
      toast.success("Your menu item attach successfully");
    } else {
      yield put(actions.postAttachMenuItemFailure());
      toast.error(data.message);
    }
  } catch (error) {
    yield put(actions.postAttachMenuItemFailure());
    toast.error(error.message);
    console.log(error);
  }
}

// ** delete Attach MenuItem
function* deleteAttachMenuItem({ payload }) {
  let { attachInfo } = payload;

  try {
    const response = yield call(services.deleteAttachMenuItemAsync, attachInfo);
    const { data } = response;

    if (response.status === 200) {
      yield put(
        actions.deleteAttachMenuItemSuccess({
          data,
          menuitemId: attachInfo.menuitemId,
          placetypeId: attachInfo.placetypeId,
        })
      );
      toast.success("Your menu item attach deleted successfully");
    } else {
      yield put(actions.deleteAttachMenuItemFailure());
      toast.error(data.message);
    }
  } catch (error) {
    yield put(actions.deleteAttachMenuItemFailure());
    toast.error(error.message);
    console.log(error);
  }
}

// *************************** watchers ******************************
export function* watchGetMenuItems() {
  yield takeEvery(actionTypes.GET_MENU_ITEM_LIST, getMenuItems);
}
export function* watchPostNewMenuItem() {
  yield takeEvery(actionTypes.POST_MENU_ITEM, postNewMenuItem);
}
export function* watchDeleteMenuItem() {
  yield takeEvery(actionTypes.DELETE_MENU_ITEM, deleteMenuItem);
}
export function* watchPutMenuItem() {
  yield takeEvery(actionTypes.PUT_MENU_ITEM, putMenuItem);
}
export function* watchPostTranslationMenuItem() {
  yield takeEvery(
    actionTypes.POST_MENU_ITEM_TRANSLATION,
    postTranslationMenuItem
  );
}
export function* watchDeleteTranslationMenuItem() {
  yield takeEvery(
    actionTypes.DELETE_MENU_ITEM_TRANSLATION,
    deleteTranslationMenuItem
  );
}
export function* watchPutMenuItemTranslation() {
  yield takeEvery(
    actionTypes.PUT_MENU_ITEM_TRANSLATION,
    putMenuItemTranslation
  );
}
export function* watchPostExcludeMenuItem() {
  yield takeEvery(actionTypes.POST_EXCLUDE_MENU_ITEM, postExcludeMenuItem);
}
export function* watchDeleteExcludeMenuItem() {
  yield takeEvery(actionTypes.DELETE_EXCLUDE_MENU_ITEM, deleteExcludeMenuItem);
}
export function* watchPostAttachMenuItem() {
  yield takeEvery(actionTypes.POST_ATTACH_MENU_ITEM, postAttachMenuItem);
}
export function* watchDeleteAttachMenuItem() {
  yield takeEvery(actionTypes.DELETE_ATTACH_MENU_ITEM, deleteAttachMenuItem);
}

//Fork
export default function* rootSaga() {
  yield all([
    fork(watchGetMenuItems),
    fork(watchPostNewMenuItem),
    fork(watchDeleteMenuItem),
    fork(watchPutMenuItem),
    fork(watchPostTranslationMenuItem),
    fork(watchDeleteTranslationMenuItem),
    fork(watchPutMenuItemTranslation),
    fork(watchPostExcludeMenuItem),
    fork(watchDeleteExcludeMenuItem),
    fork(watchPostAttachMenuItem),
    fork(watchDeleteAttachMenuItem),
  ]);
}
