import * as actionTypes from "../actionTypes";

// ** get language list action
export const getWalkwaysList = (placeInfo) => ({
  type: actionTypes.GET_WALKWAYS_LIST,
  payload: { placeInfo },
});
export const getWalkwaysListSuccess = (walkwaysList) => ({
  type: actionTypes.GET_WALKWAYS_LIST_SUCCESS,
  payload: { walkwaysList },
});
export const getWalkwaysListFailure = () => ({
  type: actionTypes.GET_WALKWAYS_LIST_FAILURE,
});
