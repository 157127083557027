import { call, takeEvery, put, all, fork } from "redux-saga/effects";
import * as actionTypes from "../actionTypes";

//**  services
import * as services from "../../services/beaconsServices";

//**  actions
import * as actions from "./actions";

import { toast } from "react-toastify";

//** Get user list
function* getBeaconsList({ payload }) {
  try {
    const response = yield call(services.getBeaconsAsync, payload);
    const { data } = response;

    console.log(data);
    if (response.status === 200) {
      yield put(actions.getBeaconsSuccess(data));
    } else {
      toast.error(data.message);
      yield put(actions.getBeaconsFailure());
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.getBeaconsFailure());
    toast.error(data.message);
    console.log(error);
  }
}

function* deleteBeaconById({ payload }) {
  try {
    const response = yield call(services.deleteBeaconByIdAsync, payload);
    const { data } = response;

    console.log(data);
    if (response.status === 200) {
      yield put(actions.deleteBeaconByIdSuccess(payload.id));
      toast.success("Beacon deleted successfully");
    } else {
      toast.error(data.message);
      yield put(actions.deleteBeaconByIdFailure());
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.deleteBeaconByIdFailure());
    toast.error(data.message);
    console.log(error);
  }
}

// *************************** watchers ******************************
export function* watchGetBeaconsList() {
  yield takeEvery(actionTypes.GET_BEACONS, getBeaconsList);
}

export function* watchDeleteBeaconById() {
  yield takeEvery(actionTypes.DELETE_BEACON, deleteBeaconById);
}

//Fork
export default function* rootSaga() {
  yield all([fork(watchGetBeaconsList), fork(watchDeleteBeaconById)]);
}
