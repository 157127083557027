import * as actionTypes from "../actionTypes";

// ** login bu password actions
export const postLoginByPassword = (password, email) => ({
  type: actionTypes.POST_LOGIN_WITH_PASSWORD,
  payload: { password, email },
});
export const postLoginByPasswordSuccess = (userData) => ({
  type: actionTypes.POST_LOGIN_WITH_PASSWORD_SUCCESS,
  payload: { userData },
});
export const postLoginByPasswordFailure = () => ({
  type: actionTypes.POST_LOGIN_WITH_PASSWORD_FAILURE,
});

// ** forget password actions
export const postForGetPassword = (email) => ({
  type: actionTypes.POST_FORGET_PASSWORD,
  payload: { email },
});
export const postForGetPasswordSuccess = () => ({
  type: actionTypes.POST_FORGET_PASSWORD_SUCCESS,
});
export const postForGetPasswordFailure = () => ({
  type: actionTypes.POST_FORGET_PASSWORD_FAILURE,
});

//** reset password  action */
export const postResetPassword = (token, password) => ({
  type: actionTypes.POST_RESET_PASSSWORD,
  payload: { token, password },
});
export const postResetPasswordSuccess = () => ({
  type: actionTypes.POST_RESET_PASSSWORD_SUCCESS,
});
export const postResetPasswordFailure = () => ({
  type: actionTypes.POST_RESET_PASSSWORD_FAILURE,
});
