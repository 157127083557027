import * as actionTypes from "../actionTypes";

// ** get customers list
export const getCustomersList = () => ({
  type: actionTypes.GET_CUSTOMERS_LIST,
});
export const getCustomersListSuccess = (customerList) => ({
  type: actionTypes.GET_CUSTOMERS_LIST_SUCCESS,
  payload: { customerList },
});
export const getCustomersListFailure = () => ({
  type: actionTypes.GET_CUSTOMERS_LIST_FAILURE,
});

// ** get deleted customer list
export const getDeletedCustomerList = () => ({
  type: actionTypes.GET_DELETED_CUSTOMER_LIST,
});
export const getDeletedCustomerListSuccess = (deletedCustomerList) => ({
  type: actionTypes.GET_DELETED_CUSTOMER_LIST_SUCCESS,
  payload: { deletedCustomerList },
});
export const getDeletedCustomerListFailure = () => ({
  type: actionTypes.GET_DELETED_CUSTOMER_LIST_FAILURE,
});

// DELETED_CUSTOMER_LIST_MODAL

export const openDeletedCustomerListModal = (openModal) => ({
  type: actionTypes.DELETED_CUSTOMER_LIST_MODAL,
  payload: { openModal },
});

// ** delete customer
export const deleteCustomer = (customerId) => ({
  type: actionTypes.DELETE_CUSTOMER,
  payload: { customerId },
});
export const deleteCustomerSuccess = (customerId) => ({
  type: actionTypes.DELETE_CUSTOMER_SUCCESS,
  payload: { customerId },
});
export const deleteCustomerFailure = () => ({
  type: actionTypes.DELETE_CUSTOMER_FAILURE,
});

// ** restore customer
export const putRestoreCustomer = (customerId) => ({
  type: actionTypes.PUT_RESTORE_CUSTOMER,
  payload: { customerId },
});
export const putRestoreCustomerSuccess = (customerId) => ({
  type: actionTypes.PUT_RESTORE_CUSTOMER_SUCCESS,
  payload: { customerId },
});
export const putRestoreCustomerFailure = () => ({
  type: actionTypes.PUT_RESTORE_CUSTOMER_FAILURE,
});

//** post new customer  */
export const postNewCustomer = (customerInfo) => ({
  type: actionTypes.POST_NEW_CUSTOMER,
  payload: { customerInfo },
});
export const postNewCustomerSuccess = (customerInfo) => ({
  type: actionTypes.POST_NEW_CUSTOMER_SUCCESS,
  payload: { customerInfo },
});
export const postNewCustomerFailure = () => ({
  type: actionTypes.POST_NEW_CUSTOMER_FAILURE,
});

//** put update customer  */
export const putUpdateCustomer = (customerInfo) => ({
  type: actionTypes.PUT_UPDATE_CUSTOMER,
  payload: { customerInfo },
});
export const putUpdateCustomerSuccess = (customerInfo) => ({
  type: actionTypes.PUT_UPDATE_CUSTOMER_SUCCESS,
  payload: { customerInfo },
});
export const putUpdateCustomerFailure = () => ({
  type: actionTypes.PUT_UPDATE_CUSTOMER_FAILURE,
});

// ** create new customer super admin
export const postCreateNewCustomerSuperAdmin = (customerInfo) => ({
  type: actionTypes.POST_CREATE_NEW_CUSTOMER_SUPER_ADMIN,
  payload: { customerInfo },
});
export const postCreateNewCustomerSuperAdminSuccess = (
  customerSuperAdminData
) => ({
  type: actionTypes.POST_CREATE_NEW_CUSTOMER_SUPER_ADMIN_SUCCESS,
  payload: { customerSuperAdminData },
});
export const postCreateNewCustomerSuperAdminFailure = () => ({
  type: actionTypes.POST_CREATE_NEW_CUSTOMER_SUPER_ADMIN_FAILURE,
});

// ** put customer super admin
export const putCustomerSuperAdmin = (params) => ({
  type: actionTypes.PUT_CUSTOMER_SUPER_ADMIN,
  payload: { params },
});
export const putCustomerSuperAdminSuccess = (customerSuperAdminData) => ({
  type: actionTypes.PUT_CUSTOMER_SUPER_ADMIN_SUCCESS,
  payload: { customerSuperAdminData },
});
export const putCustomerSuperAdminFailure = () => ({
  type: actionTypes.PUT_CUSTOMER_SUPER_ADMIN_FAILURE,
});

// ** get customer by id
export const getCustomerById = (customerInfo) => ({
  type: actionTypes.GET_CUSTOMER_BY_ID,
  payload: { customerInfo },
});
export const getCustomerByIdSuccess = (customerDetailInfo) => ({
  type: actionTypes.GET_CUSTOMER_BY_ID_SUCCESS,
  payload: { customerDetailInfo },
});
export const getCustomerByIdFailure = () => ({
  type: actionTypes.GET_CUSTOMER_BY_ID_FAILURE,
});

// ** create new customer super admin
export const postResendCustomerUserInvitation = (invitationInfo) => ({
  type: actionTypes.POST_RESEND_CUSTOMER_USER_INVITATION,
  payload: { invitationInfo },
});
export const postResendCustomerUserInvitationSuccess = (invitationInfo) => ({
  type: actionTypes.POST_RESEND_CUSTOMER_USER_INVITATION_SUCCESS,
  payload: { invitationInfo },
});
export const postResendCustomerUserInvitationFailure = () => ({
  type: actionTypes.POST_RESEND_CUSTOMER_USER_INVITATION_FAILURE,
});

// ** post new customer language
export const postNewCustomerLanguage = (languageInfo) => ({
  type: actionTypes.POST_NEW_CUSTOMER_LANGUAGE,
  payload: { languageInfo },
});
export const postNewCustomerLanguageSuccess = (languageInfo) => ({
  type: actionTypes.POST_NEW_CUSTOMER_LANGUAGE_SUCCESS,
  payload: { languageInfo },
});
export const postNewCustomerLanguageFailure = () => ({
  type: actionTypes.POST_NEW_CUSTOMER_LANGUAGE_FAILURE,
});

// ** delete customer language
export const deleteCustomerLanguage = (languageInfo) => ({
  type: actionTypes.DELETE_CUSTOMER_LANGUAGE,
  payload: { languageInfo },
});
export const deleteCustomerLanguageSuccess = (languageInfo) => ({
  type: actionTypes.DELETE_CUSTOMER_LANGUAGE_SUCCESS,
  payload: { languageInfo },
});
export const deleteCustomerLanguageFailure = () => ({
  type: actionTypes.DELETE_CUSTOMER_LANGUAGE_FAILURE,
});

//** post new customer  */
export const getCustomerNavigationConfig = (customerInfo) => ({
  type: actionTypes.GET_CUSTOMER_NAVIGATION_CONFIG,
  payload: { customerInfo },
});
export const getCustomerNavigationConfigSuccess = (customerConfigInfo) => ({
  type: actionTypes.GET_CUSTOMER_NAVIGATION_CONFIG_SUCCESS,
  payload: { customerConfigInfo },
});
export const getCustomerNavigationConfigFailure = () => ({
  type: actionTypes.GET_CUSTOMER_NAVIGATION_CONFIG_FAILURE,
});

//** post new customer  */
export const putCustomerNavigationConfig = (navigationInfo) => ({
  type: actionTypes.PUT_CUSTOMER_NAVIGATION_CONFIG,
  payload: { navigationInfo },
});
export const putCustomerNavigationConfigSuccess = (navigationInfo) => ({
  type: actionTypes.PUT_CUSTOMER_NAVIGATION_CONFIG_SUCCESS,
  payload: { navigationInfo },
});
export const putCustomerNavigationConfigFailure = () => ({
  type: actionTypes.PUT_CUSTOMER_NAVIGATION_CONFIG_FAILURE,
});

// ** get customer config

export const getCustomerConfig = (data) => ({
  type: actionTypes.GET_CUSTOMER_CONFIG,
  payload: { data },
});
export const getCustomerConfigSuccess = (data) => ({
  type: actionTypes.GET_CUSTOMER_CONFIG_SUCCESS,
  payload: { data },
});
export const getCustomerConfigFailure = () => ({
  type: actionTypes.GET_CUSTOMER_CONFIG_FAILURE,
});

// ** put customer config
export const putCustomerConfig = (data) => ({
  type: actionTypes.PUT_CUSTOMER_CONFIG,
  payload: { data },
});
export const putCustomerConfigSuccess = (data) => ({
  type: actionTypes.PUT_CUSTOMER_CONFIG_SUCCESS,
  payload: { data },
});
export const putCustomerConfigFailure = () => ({
  type: actionTypes.PUT_CUSTOMER_CONFIG_FAILURE,
});

// ** get login as a customer

export const getLoginAsCustomer = (payload) => ({
  type: actionTypes.GET_LOGIN_AS_CUSTOMER,
  payload,
});
export const getLoginAsCustomerSuccess = (payload) => ({
  type: actionTypes.GET_LOGIN_AS_CUSTOMER_SUCCESS,
  payload,
});
export const getLoginAsCustomerFailure = () => ({
  type: actionTypes.GET_LOGIN_AS_CUSTOMER_FAILURE,
});
