import * as actionTypes from "../actionTypes";

export const getPoisList = () => ({
  type: actionTypes.GET_POIS_LIST,
});
export const getPoisListSuccess = (payload) => ({
  type: actionTypes.GET_POIS_LIST_SUCCESS,
  payload: payload,
});

export const getPoisListFailure = () => ({
  type: actionTypes.GET_POIS_LIST_FAILURE,
});
