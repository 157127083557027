import { call, takeEvery, put, all, fork } from "redux-saga/effects";
import * as actionTypes from "../actionTypes";

//**  services
import * as services from "../../services/walkwaysServices";

//**  actions
import * as actions from "./actions";

import { toast } from "react-toastify";

//** Get user list
function* getWalkwaysList({ payload }) {
  const { placeInfo } = payload;
  try {
    const response = yield call(services.getWalkwaysListAsync, placeInfo);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.getWalkwaysListSuccess(data));
    } else {
      toast.error(data.message);
      yield put(actions.getWalkwaysListFailure());
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.getWalkwaysListFailure());
    toast.error(data.message);
    console.log(error);
  }
}

// *************************** watchers ******************************
export function* watchGetWalkwaysList() {
  yield takeEvery(actionTypes.GET_WALKWAYS_LIST, getWalkwaysList);
}

//Fork
export default function* rootSaga() {
  yield all([fork(watchGetWalkwaysList)]);
}
