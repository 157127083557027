import * as actionTypes from "../actionTypes";

// ** get place type list
export const getPlaceTypesList = () => ({
  type: actionTypes.GET_PLACE_TYPES_LIST,
});
export const getPlaceTypesListSuccess = (placeTypesList) => ({
  type: actionTypes.GET_PLACE_TYPES_LIST_SUCCESS,
  payload: { placeTypesList },
});
export const getPlaceTypesListFailure = () => ({
  type: actionTypes.GET_PLACE_TYPES_LIST_FAILURE,
});

//** post new place type  */
export const postNewPlaceType = (placeTypeInfo) => ({
  type: actionTypes.POST_PLACE_TYPE,
  payload: { placeTypeInfo },
});
export const postNewPlaceTypeSuccess = (placeTypeInfo) => ({
  type: actionTypes.POST_PLACE_TYPE_SUCCESS,
  payload: { placeTypeInfo },
});
export const postNewPlaceTypeFailure = () => ({
  type: actionTypes.POST_PLACE_TYPE_FAILURE,
});

// ** delete customer
export const deletePlaceType = (placeTypeId) => ({
  type: actionTypes.DELETE_PLACE_TYPE,
  payload: { placeTypeId },
});
export const deletePlaceTypeSuccess = (placeTypeId) => ({
  type: actionTypes.DELETE_PLACE_TYPE_SUCCESS,
  payload: { placeTypeId },
});
export const deletePlaceTypeFailure = () => ({
  type: actionTypes.DELETE_PLACE_TYPE_FAILURE,
});

// ** get deleted customer list
export const getDeletedPlaceTypeList = () => ({
  type: actionTypes.GET_DELETED_PLACE_TYPE_LIST,
});
export const getDeletedPlaceTypeListSuccess = (deletedPlaceTypeList) => ({
  type: actionTypes.GET_DELETED_PLACE_TYPE_LIST_SUCCESS,
  payload: { deletedPlaceTypeList },
});
export const getDeletedPlaceTypeListFailure = () => ({
  type: actionTypes.GET_DELETED_PLACE_TYPE_LIST_FAILURE,
});

//** DELETED_CUSTOMER_LIST_MODAL

export const openDeletedPlaceTypeListModal = (openModal) => ({
  type: actionTypes.DELETED_PLACE_TYPE_LIST_MODAL,
  payload: { openModal },
});

// ** restore place type
export const putRestorePlaceType = (placeTypeId) => ({
  type: actionTypes.PUT_RESTORE_PLACE_TYPE,
  payload: { placeTypeId },
});
export const putRestorePlaceTypeSuccess = (placeTypeId) => ({
  type: actionTypes.PUT_RESTORE_PLACE_TYPE_SUCCESS,
  payload: { placeTypeId },
});
export const putRestorePlaceTypeFailure = () => ({
  type: actionTypes.PUT_RESTORE_PLACE_TYPE_FAILURE,
});

//** put update customer  */
export const putPlaceType = (placeTypeInfo) => ({
  type: actionTypes.PUT_PLACE_TYPE,
  payload: { placeTypeInfo },
});
export const putPlaceTypeSuccess = (placeTypeInfo) => ({
  type: actionTypes.PUT_PLACE_TYPE_SUCCESS,
  payload: { placeTypeInfo },
});
export const putPlaceTypeFailure = () => ({
  type: actionTypes.PUT_PLACE_TYPE_FAILURE,
});
