import * as actionTypes from "../actionTypes";

// ** get categories list action
export const getCategoriesList = () => ({
  type: actionTypes.GET_CATEGORIES_LIST,
});
export const getCategoriesListSuccess = (categoriesList) => ({
  type: actionTypes.GET_CATEGORIES_LIST_SUCCESS,
  payload: { categoriesList },
});
export const getCategoriesListFailure = () => ({
  type: actionTypes.GET_CATEGORIES_LIST_FAILURE,
});

//** get Category By Id  */
export const getCategoryById = (categoryInfo) => ({
  type: actionTypes.GET_CATEGORY_BY_ID,
  payload: { categoryInfo },
});
export const getCategoryByIdSuccess = (categoryInfo) => ({
  type: actionTypes.GET_CATEGORY_BY_ID_SUCCESS,
  payload: { categoryInfo },
});
export const getCategoryByIdFailure = () => ({
  type: actionTypes.GET_CATEGORY_BY_ID_FAILURE,
});

// ** post category  action
export const postCategory = (categoryInfo) => ({
  type: actionTypes.POST_CATEGORY,
  payload: { categoryInfo },
});
export const postCategorySuccess = (categoryInfo) => ({
  type: actionTypes.POST_CATEGORY_SUCCESS,
  payload: { categoryInfo },
});
export const postCategoryFailure = () => ({
  type: actionTypes.POST_CATEGORY_FAILURE,
});

// ** delete category  action
export const deleteCategory = (categoryInfo) => ({
  type: actionTypes.DELETE_CATEGORY,
  payload: { categoryInfo },
});
export const deleteCategorySuccess = (categoryInfo) => ({
  type: actionTypes.DELETE_CATEGORY_SUCCESS,
  payload: { categoryInfo },
});
export const deleteCategoryFailure = () => ({
  type: actionTypes.DELETE_CATEGORY_FAILURE,
});

// ** putCategoryy  action
export const putCategory = (categoryInfo) => ({
  type: actionTypes.PUT_CATEGORY,
  payload: { categoryInfo },
});
export const putCategorySuccess = (categoryInfo) => ({
  type: actionTypes.PUT_CATEGORY_SUCCESS,
  payload: { categoryInfo },
});
export const putCategoryFailure = () => ({
  type: actionTypes.PUT_CATEGORY_FAILURE,
});

// ** get deleted categoris list
export const getDeletedCategoriesList = () => ({
  type: actionTypes.GET_DELETED_CATEGORIES_LIST,
});
export const getDeletedCategoriesListSuccess = (deletedCategoriesList) => ({
  type: actionTypes.GET_DELETED_CATEGORIES_LIST_SUCCESS,
  payload: { deletedCategoriesList },
});
export const getDeletedCategoriesListFailure = () => ({
  type: actionTypes.GET_DELETED_CATEGORIES_LIST_FAILURE,
});

// ** PUT_RESTORE_CATEGORY
export const putRestoreCategory = (categoryInfo) => ({
  type: actionTypes.PUT_RESTORE_CATEGORY,
  payload: { categoryInfo },
});
export const putRestoreCategorySuccess = (categoryInfo) => ({
  type: actionTypes.PUT_RESTORE_CATEGORY_SUCCESS,
  payload: { categoryInfo },
});
export const putRestoreCategoryFailure = () => ({
  type: actionTypes.PUT_RESTORE_CATEGORY_FAILURE,
});

// ** PUT_RESTORE_CATEGORY
export const postCategoryTranslation = (categoryInfo) => ({
  type: actionTypes.POST_CATEGORY_TRANSLATION,
  payload: { categoryInfo },
});
export const postCategoryTranslationSuccess = (categoryInfo) => ({
  type: actionTypes.POST_CATEGORY_TRANSLATION_SUCCESS,
  payload: { categoryInfo },
});
export const postCategoryTranslationFailure = () => ({
  type: actionTypes.POST_CATEGORY_TRANSLATION_FAILURE,
});

// ** open deleted categories  modal
export const openDeletedCategoriesModal = (openModal) => ({
  type: actionTypes.DELETED_CATEGORIES_LIST_MODAL,
  payload: { openModal },
});

// ** delete category  action
export const deleteCategoryTranslation = (categoryInfo) => ({
  type: actionTypes.DELETE_CATEGORY_TRANSLATION,
  payload: { categoryInfo },
});
export const deleteCategoryTranslationSuccess = (categoryInfo) => ({
  type: actionTypes.DELETE_CATEGORY_TRANSLATION_SUCCESS,
  payload: { categoryInfo },
});
export const deleteCategoryTranslationFailure = () => ({
  type: actionTypes.DELETE_CATEGORY_TRANSLATION_FAILURE,
});

// ** delete category  action
export const putCategoryTranslation = (categoryInfo) => ({
  type: actionTypes.PUT_CATEGORY_TRANSLATION,
  payload: { categoryInfo },
});
export const putCategoryTranslationSuccess = (categoryInfo) => ({
  type: actionTypes.PUT_CATEGORY_TRANSLATION_SUCCESS,
  payload: { categoryInfo },
});
export const putCategoryTranslationFailure = () => ({
  type: actionTypes.PUT_CATEGORY_TRANSLATION_FAILURE,
});

// ******************sub categories list **********************
export const getSubcategoriesList = () => ({
  type: actionTypes.GET_CATEGORIES_LIST,
});
export const getSubcategoriesListSuccess = (categoriesList) => ({
  type: actionTypes.GET_CATEGORIES_LIST_SUCCESS,
  payload: { categoriesList },
});
export const getSubcategoriesListFailure = () => ({
  type: actionTypes.GET_CATEGORIES_LIST_FAILURE,
});

// ** post category  action
export const postSubcategory = (categoryInfo) => ({
  type: actionTypes.POST_SUBCATEGORY,
  payload: { categoryInfo },
});
export const postSubcategorySuccess = (categoryInfo) => ({
  type: actionTypes.POST_SUBCATEGORY_SUCCESS,
  payload: { categoryInfo },
});
export const postSubcategoryFailure = () => ({
  type: actionTypes.POST_SUBCATEGORY_FAILURE,
});

// ** delete category  action
export const deleteSubcategory = (categoryInfo) => ({
  type: actionTypes.DELETE_SUBCATEGORY,
  payload: { categoryInfo },
});
export const deleteSubcategorySuccess = (categoryInfo) => ({
  type: actionTypes.DELETE_SUBCATEGORY_SUCCESS,
  payload: { categoryInfo },
});
export const deleteSubcategoryFailure = () => ({
  type: actionTypes.DELETE_SUBCATEGORY_FAILURE,
});

// ** putSubcategoryy  action
export const putSubcategory = (categoryInfo) => ({
  type: actionTypes.PUT_SUBCATEGORY,
  payload: { categoryInfo },
});
export const putSubcategorySuccess = (categoryInfo) => ({
  type: actionTypes.PUT_SUBCATEGORY_SUCCESS,
  payload: { categoryInfo },
});
export const putSubcategoryFailure = () => ({
  type: actionTypes.PUT_SUBCATEGORY_FAILURE,
});

// ** get deleted categoris list
export const getDeletedSubcategoriesList = () => ({
  type: actionTypes.GET_DELETED_CATEGORIES_LIST,
});
export const getDeletedSubcategoriesListSuccess = (
  deletedSubcategoriesList
) => ({
  type: actionTypes.GET_DELETED_CATEGORIES_LIST_SUCCESS,
  payload: { deletedSubcategoriesList },
});
export const getDeletedSubcategoriesListFailure = () => ({
  type: actionTypes.GET_DELETED_CATEGORIES_LIST_FAILURE,
});

// ** PUT_RESTORE_CATEGORY
export const putRestoreSubcategory = (categoryInfo) => ({
  type: actionTypes.PUT_RESTORE_SUBCATEGORY,
  payload: { categoryInfo },
});
export const putRestoreSubcategorySuccess = (categoryInfo) => ({
  type: actionTypes.PUT_RESTORE_SUBCATEGORY_SUCCESS,
  payload: { categoryInfo },
});
export const putRestoreSubcategoryFailure = () => ({
  type: actionTypes.PUT_RESTORE_SUBCATEGORY_FAILURE,
});

// ** PUT_RESTORE_CATEGORY
export const postSubcategoryTranslation = (categoryInfo) => ({
  type: actionTypes.POST_SUBCATEGORY_TRANSLATION,
  payload: { categoryInfo },
});
export const postSubcategoryTranslationSuccess = (categoryInfo) => ({
  type: actionTypes.POST_SUBCATEGORY_TRANSLATION_SUCCESS,
  payload: { categoryInfo },
});
export const postSubcategoryTranslationFailure = () => ({
  type: actionTypes.POST_SUBCATEGORY_TRANSLATION_FAILURE,
});

// ** open deleted categories  modal
export const openDeletedSubcategoriesModal = (openModal) => ({
  type: actionTypes.DELETED_CATEGORIES_LIST_MODAL,
  payload: { openModal },
});

// ** delete category  action
export const deleteSubcategoryTranslation = (categoryInfo) => ({
  type: actionTypes.DELETE_SUBCATEGORY_TRANSLATION,
  payload: { categoryInfo },
});
export const deleteSubcategoryTranslationSuccess = (categoryInfo) => ({
  type: actionTypes.DELETE_SUBCATEGORY_TRANSLATION_SUCCESS,
  payload: { categoryInfo },
});
export const deleteSubcategoryTranslationFailure = () => ({
  type: actionTypes.DELETE_SUBCATEGORY_TRANSLATION_FAILURE,
});

// ** delete category  action
export const putSubcategoryTranslation = (categoryInfo) => ({
  type: actionTypes.PUT_SUBCATEGORY_TRANSLATION,
  payload: { categoryInfo },
});
export const putSubcategoryTranslationSuccess = (categoryInfo) => ({
  type: actionTypes.PUT_SUBCATEGORY_TRANSLATION_SUCCESS,
  payload: { categoryInfo },
});
export const putSubcategoryTranslationFailure = () => ({
  type: actionTypes.PUT_SUBCATEGORY_TRANSLATION_FAILURE,
});
