import * as actionTypes from "../actionTypes";

// ** getBrandList
export const getBrandList = () => ({
  type: actionTypes.GET_BRANDS_LIST,
});
export const getBrandListSuccess = (brandsList) => ({
  type: actionTypes.GET_BRANDS_LIST_SUCCESS,
  payload: { brandsList },
});
export const getBrandListFailure = () => ({
  type: actionTypes.GET_BRANDS_LIST_FAILURE,
});

//** postNewBrand  */
export const postNewBrand = (brandInfo) => ({
  type: actionTypes.POST_NEW_BRAND,
  payload: { brandInfo },
});
export const postNewBrandSuccess = (brandInfo) => ({
  type: actionTypes.POST_NEW_BRAND_SUCCESS,
  payload: { brandInfo },
});
export const postNewBrandFailure = () => ({
  type: actionTypes.POST_NEW_BRAND_FAILURE,
});

// ** deleteBrand
export const deleteBrand = (brandInfo) => ({
  type: actionTypes.DELETE_BRAND,
  payload: { brandInfo },
});
export const deleteBrandSuccess = (brandInfo) => ({
  type: actionTypes.DELETE_BRAND_SUCCESS,
  payload: { brandInfo },
});
export const deleteBrandFailure = () => ({
  type: actionTypes.DELETE_BRAND_FAILURE,
});

// ** getDeletedBrandsList
export const getDeletedBrandsList = () => ({
  type: actionTypes.GET_DELETED_BRANDS_LIST,
});
export const getDeletedBrandsListSuccess = (deletedBrandsList) => ({
  type: actionTypes.GET_DELETED_BRANDS_LIST_SUCCESS,
  payload: { deletedBrandsList },
});
export const getDeletedBrandsListFailure = () => ({
  type: actionTypes.GET_DELETED_BRANDS_LIST_FAILURE,
});

//** DELETED_CUSTOMER_LIST_MODAL

export const openDeletedBrandsModal = (openModal) => ({
  type: actionTypes.DELETED_PLACE_TYPE_LIST_MODAL,
  payload: { openModal },
});

// ** PUT_RESTORE_BRAND
export const putRestoreBrands = (brandInfo) => ({
  type: actionTypes.PUT_RESTORE_BRAND,
  payload: { brandInfo },
});
export const putRestoreBrandsSuccess = (brandInfo) => ({
  type: actionTypes.PUT_RESTORE_BRAND_SUCCESS,
  payload: { brandInfo },
});
export const putRestoreBrandsFailure = () => ({
  type: actionTypes.PUT_RESTORE_BRAND_FAILURE,
});

//** PUT_BRAND  */
export const putBrand = (brandInfo) => ({
  type: actionTypes.PUT_BRAND,
  payload: { brandInfo },
});
export const putBrandSuccess = (brandInfo) => ({
  type: actionTypes.PUT_BRAND_SUCCESS,
  payload: { brandInfo },
});
export const putBrandFailure = () => ({
  type: actionTypes.PUT_BRAND_FAILURE,
});

export const openDeletedBrandsListModal = (openModal) => ({
  type: actionTypes.DELETED_BRANDS_LIST_MODAL,
  payload: { openModal },
});
