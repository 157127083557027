import * as actionTypes from "../actionTypes";

const initialState = {
  storeIdList: [],

  getStoreIdsLoading: false,
  deleteStoreIdLoading: false,
  postStoreIdLoading: false,
  putStoreIdLoading: false,
};

const storeIdsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_STORE_IDS_LIST:
      return {
        ...state,
        getStoreIdsLoading: true,
      };
    case actionTypes.GET_STORE_IDS_LIST_SUCCESS:
      return {
        ...state,
        getStoreIdsLoading: false,
        storeIdList: action.payload,
      };
    case actionTypes.GET_STORE_IDS_LIST_FAILURE:
      return {
        ...state,
        getStoreIdsLoading: false,
      };

    case actionTypes.DELETE_STORE_ID:
      return {
        ...state,
        deleteStoreIdLoading: true,
      };
    case actionTypes.DELETE_STORE_ID_SUCCESS:
      const storeId = action.payload;
      const storeIdsListArray = [...state.storeIdList];
      const filterStoreIdList = storeIdsListArray.filter(
        (item) => item.id !== storeId
      );

      return {
        ...state,
        deleteStoreIdLoading: false,
        storeIdList: filterStoreIdList,
      };
    case actionTypes.DELETE_STORE_ID_FAILURE:
      return {
        ...state,
        deleteStoreIdLoading: false,
      };

    case actionTypes.POST_STORE_ID:
      return {
        ...state,
        postStoreIdLoading: true,
      };
    case actionTypes.POST_STORE_ID_SUCCESS:
      return {
        ...state,
        storeIdList: [...state.storeIdList, action.payload],
        postStoreIdLoading: false,
      };
    case actionTypes.POST_STORE_ID_FAILURE:
      return {
        ...state,
        postStoreIdLoading: false,
      };

    case actionTypes.PUT_STORE_ID:
      return {
        ...state,
        putStoreIdLoading: true,
      };
    case actionTypes.PUT_STORE_ID_SUCCESS:
      const storeIdIndex = state.storeIdList.findIndex(
        (storeId) => storeId.id == action.payload.id
      );
      const storeIdListArray = [...state.storeIdList];

      // Update object's name property.
      storeIdListArray[storeIdIndex] = {
        ...storeIdListArray[storeIdIndex],
        ...action.payload,
      };

      return {
        ...state,
        storeIdList: storeIdListArray,
        putStoreIdLoading: false,
      };
    case actionTypes.PUT_STORE_ID_FAILURE:
      return {
        ...state,
        putStoreIdLoading: false,
      };

    default:
      return state;
  }
};

export default storeIdsReducer;
