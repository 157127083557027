import * as actionTypes from "../actionTypes";

// ** get customers list actionss
export const getMenuItemList = (customerId) => ({
  type: actionTypes.GET_MENU_ITEM_LIST,
  payload: { customerId },
});
export const getMenuItemListSuccess = (mneuItemList) => ({
  type: actionTypes.GET_MENU_ITEM_LIST_SUCCESS,
  payload: { mneuItemList },
});
export const getMenuItemListFailure = () => ({
  type: actionTypes.GET_MENU_ITEM_LIST_FAILURE,
});

//** post new  menu item */
export const postMenuItem = (menuItemInfo) => ({
  type: actionTypes.POST_MENU_ITEM,
  payload: { menuItemInfo },
});
export const postMenuItemSuccess = (menuItemInfo) => ({
  type: actionTypes.POST_MENU_ITEM_SUCCESS,
  payload: { menuItemInfo },
});
export const postMenuItemFailure = () => ({
  type: actionTypes.POST_MENU_ITEM_FAILURE,
});

//** post new  menu item */
export const deleteMenuItem = (menuItemInfo) => ({
  type: actionTypes.DELETE_MENU_ITEM,
  payload: { menuItemInfo },
});
export const deleteMenuItemSuccess = (menuItemInfo) => ({
  type: actionTypes.DELETE_MENU_ITEM_SUCCESS,
  payload: { menuItemInfo },
});
export const deleteMenuItemFailure = () => ({
  type: actionTypes.DELETE_MENU_ITEM_FAILURE,
});

//** post new  menu item */
export const putMenuItem = (menuItemInfo) => ({
  type: actionTypes.PUT_MENU_ITEM,
  payload: { menuItemInfo },
});
export const putMenuItemSuccess = (menuItemInfo) => ({
  type: actionTypes.PUT_MENU_ITEM_SUCCESS,
  payload: { menuItemInfo },
});
export const putMenuItemFailure = () => ({
  type: actionTypes.PUT_MENU_ITEM_FAILURE,
});

//** post new  menu item translation */
export const postMenuItemTranslation = (menuItemInfo) => ({
  type: actionTypes.POST_MENU_ITEM_TRANSLATION,
  payload: { menuItemInfo },
});
export const postMenuItemTranslationSuccess = (menuItemInfo) => ({
  type: actionTypes.POST_MENU_ITEM_TRANSLATION_SUCCESS,
  payload: { menuItemInfo },
});
export const postMenuItemTranslationFailure = () => ({
  type: actionTypes.POST_MENU_ITEM_TRANSLATION_FAILURE,
});

//** delete menu item translation */
export const deleteMenuItemTranslation = (menuItemInfo) => ({
  type: actionTypes.DELETE_MENU_ITEM_TRANSLATION,
  payload: { menuItemInfo },
});
export const deleteMenuItemTranslationSuccess = (menuItemInfo) => ({
  type: actionTypes.DELETE_MENU_ITEM_TRANSLATION_SUCCESS,
  payload: { menuItemInfo },
});
export const deleteMenuItemTranslationFailure = () => ({
  type: actionTypes.DELETE_MENU_ITEM_TRANSLATION_FAILURE,
});

//** put Menu Item Translation */
export const putMenuItemTranslation = (menuItemInfo) => ({
  type: actionTypes.PUT_MENU_ITEM_TRANSLATION,
  payload: { menuItemInfo },
});
export const putMenuItemTranslationSuccess = (menuItemInfo) => ({
  type: actionTypes.PUT_MENU_ITEM_TRANSLATION_SUCCESS,
  payload: { menuItemInfo },
});
export const putMenuItemTranslationFailure = () => ({
  type: actionTypes.PUT_MENU_ITEM_TRANSLATION_FAILURE,
});

//** post exclude Menu Item  */
export const postExcludeMenuItem = (excludeInfo) => ({
  type: actionTypes.POST_EXCLUDE_MENU_ITEM,
  payload: { excludeInfo },
});
export const postExcludeMenuItemSuccess = (excludeInfo) => ({
  type: actionTypes.POST_EXCLUDE_MENU_ITEM_SUCCESS,
  payload: { excludeInfo },
});
export const postExcludeMenuItemFailure = () => ({
  type: actionTypes.POST_EXCLUDE_MENU_ITEM_FAILURE,
});

//** delete exclude Menu Item  */
export const deleteExcludeMenuItem = (excludeInfo) => ({
  type: actionTypes.DELETE_EXCLUDE_MENU_ITEM,
  payload: { excludeInfo },
});
export const deleteExcludeMenuItemSuccess = (excludeInfo) => ({
  type: actionTypes.DELETE_EXCLUDE_MENU_ITEM_SUCCESS,
  payload: { excludeInfo },
});
export const deleteExcludeMenuItemFailure = () => ({
  type: actionTypes.DELETE_EXCLUDE_MENU_ITEM_FAILURE,
});

//** post attach Menu Item  */
export const postAttachMenuItem = (attachInfo) => ({
  type: actionTypes.POST_ATTACH_MENU_ITEM,
  payload: { attachInfo },
});
export const postAttachMenuItemSuccess = (attachInfo) => ({
  type: actionTypes.POST_ATTACH_MENU_ITEM_SUCCESS,
  payload: { attachInfo },
});
export const postAttachMenuItemFailure = () => ({
  type: actionTypes.POST_ATTACH_MENU_ITEM_FAILURE,
});

//** delete attach Menu Item  */
export const deleteAttachMenuItem = (attachInfo) => ({
  type: actionTypes.DELETE_ATTACH_MENU_ITEM,
  payload: { attachInfo },
});
export const deleteAttachMenuItemSuccess = (attachInfo) => ({
  type: actionTypes.DELETE_ATTACH_MENU_ITEM_SUCCESS,
  payload: { attachInfo },
});
export const deleteAttachMenuItemFailure = () => ({
  type: actionTypes.DELETE_ATTACH_MENU_ITEM_FAILURE,
});
