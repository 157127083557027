import { call, takeEvery, put, all, fork } from "redux-saga/effects";
import * as actionTypes from "../actionTypes";

//**  services
import * as services from "../../services/brandsServices";

//**  actions
import * as actions from "./actions";

import { toast } from "react-toastify";

//** Get user list
function* getBrands() {
  try {
    const response = yield call(services.getBrandsListAsync);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.getBrandListSuccess(data));
    } else {
      toast.error(data.message);
      yield put(actions.getBrandListFailure());
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.getBrandListFailure());
    toast.error(data.message);
    console.log(error);
  }
}

//** post  new place type
function* postNewBrand({ payload }) {
  let { brandInfo } = payload;
  try {
    const response = yield call(services.postNewBrandAsync, brandInfo);
    const { data } = response;

    console.log(data);
    if (response.status === 200) {
      yield put(actions.postNewBrandSuccess(data));
      toast.success("Your brand created successfully");
    } else {
      yield put(actions.postNewBrandFailure());
      toast.error(data.message);
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.postNewBrandFailure());
    toast.error(data.message);
  }
}

//** Get user list
function* getDeletedBrandsList() {
  try {
    const response = yield call(services.getDeletedBrandsListAsync);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.getDeletedBrandsListSuccess(data));
    } else {
      toast.error(data.message);
      yield put(actions.getDeletedBrandsListFailure());
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.getDeletedBrandsListFailure());
    toast.error(data.message);
    console.log(error);
  }
}

// ** delete placeType
function* deleteBrand({ payload }) {
  let { brandInfo } = payload;
  try {
    const response = yield call(services.deleteBrandAsync, brandInfo);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.deleteBrandSuccess(brandInfo));
      toast.success("Your brand deleted successfully");
    } else {
      yield put(actions.deleteBrandFailure());
      toast.error(data.message);
    }
  } catch (error) {
    yield put(actions.deleteBrandFailure());
    toast.error(error.message);
    console.log(error);
  }
}

//** put update  customer
function* putBrand({ payload }) {
  let { brandInfo } = payload;

  try {
    const response = yield call(services.putBrandAsync, brandInfo);
    const { data } = response;

    console.log(response);
    if (response.status === 200) {
      yield put(actions.putBrandSuccess(data[0]));
      toast.success("Your brand  was updated successfully");
    } else {
      yield put(actions.putBrandFailure());
      toast.error(data.message);
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.putBrandFailure());
    toast.error(data.message);
  }
}
// ** restore place type
function* putRestoreBrand({ payload }) {
  let { brandInfo } = payload;
  console.log(brandInfo);
  try {
    const response = yield call(services.putRestorBrandAsync, brandInfo);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.putRestoreBrandsSuccess(brandInfo));
      yield put(actions.openDeletedBrandsListModal(false));
      yield put(actions.getBrandList());

      toast.success("Your brand restored successfully");
    } else {
      yield put(actions.putRestoreBrandsFailure());
      toast.error(data.message);
    }
  } catch (error) {
    yield put(actions.putRestoreBrandsFailure());
    toast.error(error.message);
    console.log(error);
  }
}

// *************************** watchers ******************************
export function* watchGetBrands() {
  yield takeEvery(actionTypes.GET_BRANDS_LIST, getBrands);
}
export function* watchPostNewBrand() {
  yield takeEvery(actionTypes.POST_NEW_BRAND, postNewBrand);
}
export function* watchGetDeletedBrandsList() {
  yield takeEvery(actionTypes.GET_DELETED_BRANDS_LIST, getDeletedBrandsList);
}
export function* watchDeleteBrand() {
  yield takeEvery(actionTypes.DELETE_BRAND, deleteBrand);
}
export function* watchPutBrand() {
  yield takeEvery(actionTypes.PUT_BRAND, putBrand);
}
export function* watchPutRestoreBrand() {
  yield takeEvery(actionTypes.PUT_RESTORE_BRAND, putRestoreBrand);
}

//Fork
export default function* rootSaga() {
  yield all([
    fork(watchGetBrands),
    fork(watchPostNewBrand),
    fork(watchGetDeletedBrandsList),
    fork(watchDeleteBrand),
    fork(watchPutBrand),
    fork(watchPutRestoreBrand),
  ]);
}
