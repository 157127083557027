import * as actionTypes from "../actionTypes";

const intialState = {
  customerList: [],
  deletedCustomerList: [],
  customerSuperAdminData: {},
  customerDetailInfo: {
    superadmin: {},
  },
  selectedLanguageId: null,
  customerConfigInfo: [],
  customerJsonConfig: {},
  loginAsCutomerInfo: {},

  getCustomerListLoader: false,
  deleteCustomerLoader: false,
  getDeletedCustomerListLoader: false,
  putRestoreCustomerLoader: false,
  postNewCustomerLoader: false,
  putUpdateCustomerLoader: false,
  postCreateNewCustomerSuperAdminLoader: false,
  postCreateNewCustomerSuperAdminSuccsessLoader: false,
  getCustomerByIdLoader: true,
  postResendCustomerUserInvitationLoader: false,
  postNewCustomerLanguageLoader: false,
  deleteCustomerLanguageLoader: false,
  putCustomerSuperAdminLoader: false,
  openDeletedCustomerListModal: false,
  getCustomerNavigationConfig: false,
  putCustomerNavigationConfigLoader: false,
  getCustomerJsonConfigLoading: false,
  putCustomerJsonConfigLoading: false,
  getLoginAsCustomerLoader: false,
};

const customers = (state = intialState, action) => {
  switch (action.type) {
    //**GET_CUSTOMERS_LIST */
    case actionTypes.GET_CUSTOMERS_LIST: {
      return {
        ...state,
        getCustomerListLoader: true,
      };
    }
    case actionTypes.GET_CUSTOMERS_LIST_SUCCESS: {
      return {
        ...state,
        customerList: action.payload.customerList,
        getCustomerListLoader: false,
      };
    }
    case actionTypes.GET_CUSTOMERS_LIST_FAILURE: {
      return {
        ...state,
        getCustomerListLoader: false,
      };
    }

    //**GET_DELETED_CUSTOMER_LIST */
    case actionTypes.GET_DELETED_CUSTOMER_LIST: {
      return {
        ...state,
        getDeletedCustomerListLoader: true,
      };
    }
    case actionTypes.GET_DELETED_CUSTOMER_LIST_SUCCESS: {
      return {
        ...state,
        deletedCustomerList: action.payload.deletedCustomerList,
        getDeletedCustomerListLoader: false,
      };
    }
    case actionTypes.GET_DELETED_CUSTOMER_LIST_FAILURE: {
      return {
        ...state,
        getDeletedCustomerListLoader: false,
      };
    }

    // ** delete customer
    case actionTypes.DELETE_CUSTOMER: {
      return {
        ...state,
        deleteCustomerLoader: true,
      };
    }
    case actionTypes.DELETE_CUSTOMER_SUCCESS: {
      const customerId = action.payload.customerId;

      // ** remove deleted item from array
      const updateCustomerList = state.customerList.filter(
        (item) => item.id !== customerId
      );
      return {
        ...state,
        customerList: updateCustomerList,
        deleteCustomerLoader: false,
      };
    }
    case actionTypes.DELETE_CUSTOMER_FAILURE: {
      return {
        ...state,
        deleteCustomerLoader: false,
      };
    }

    // ** PUT_RESTORE_CUSTOMER
    case actionTypes.PUT_RESTORE_CUSTOMER: {
      return {
        ...state,
        putRestoreCustomerLoader: true,
      };
    }
    case actionTypes.PUT_RESTORE_CUSTOMER_SUCCESS: {
      const customerId = action.payload.customerId;

      // ** remove deleted item from array
      const updateDeletedCustomerList = state.deletedCustomerList.filter(
        (item) => item.id !== customerId
      );
      return {
        ...state,
        deletedCustomerList: updateDeletedCustomerList,
        putRestoreCustomerLoader: false,
      };
    }
    case actionTypes.PUT_RESTORE_CUSTOMER_FAILURE: {
      return {
        ...state,
        putRestoreCustomerLoader: false,
      };
    }

    // ** post new customer
    case actionTypes.POST_NEW_CUSTOMER: {
      return {
        ...state,
        postNewCustomerLoader: true,
      };
    }
    case actionTypes.POST_NEW_CUSTOMER_SUCCESS: {
      const {
        id,
        name,
        subscriptionStartDate,
        subscriptionEndDate,
        subscriptionActive,
        navigationActive,
        vdNavigationActive,
        createdAt,
      } = action.payload.customerInfo;

      return {
        ...state,
        customerList: [
          ...state.customerList,
          {
            id,
            name,
            subscriptionStartDate,
            subscriptionEndDate,
            subscriptionActive,
            navigationActive,
            vdNavigationActive,
            createdAt,
          },
        ],
        postNewCustomerLoader: false,
      };
    }
    case actionTypes.POST_NEW_CUSTOMER_FAILURE: {
      return {
        ...state,
        postNewCustomerLoader: false,
      };
    }

    // ** PUT_UPDATE_CUSTOMER
    case actionTypes.PUT_UPDATE_CUSTOMER: {
      return {
        ...state,
        putUpdateCustomerLoader: true,
      };
    }
    case actionTypes.PUT_UPDATE_CUSTOMER_SUCCESS: {
      const {
        id,
        name,
        subscriptionStartDate,
        subscriptionEndDate,
        subscriptionActive,
        navigationActive,
        vdNavigationActive,
        createdAt,
      } = action.payload.customerInfo;

      const customerIndex = state.customerList.findIndex(
        (customer) => customer.id === id
      );

      const customerListUpdate = [...state.customerList];

      customerListUpdate[customerIndex] = {
        id,
        name,
        subscriptionStartDate,
        subscriptionEndDate,
        subscriptionActive,
        navigationActive,
        vdNavigationActive,
        createdAt,
      };

      return {
        ...state,
        customerList: customerListUpdate,
        customerDetailInfo: {
          ...state.customerDetailInfo,
          id,
          name,
          subscriptionStartDate,
          subscriptionEndDate,
          subscriptionActive,
          navigationActive,
          vdNavigationActive,
          createdAt,
        },
        putUpdateCustomerLoader: false,
      };
    }
    case actionTypes.PUT_UPDATE_CUSTOMER_FAILURE: {
      return {
        ...state,
        putUpdateCustomerLoader: false,
      };
    }

    //** POST_CREATE_NEW_CUSTOMER_SUPER_ADMIN */
    case actionTypes.POST_CREATE_NEW_CUSTOMER_SUPER_ADMIN: {
      return {
        ...state,
        postCreateNewCustomerSuperAdminLoader: true,
        postCreateNewCustomerSuperAdminSuccsessLoader: false,
      };
    }
    case actionTypes.POST_CREATE_NEW_CUSTOMER_SUPER_ADMIN_SUCCESS: {
      const { customerSuperAdminData } = action.payload;
      return {
        ...state,
        postCreateNewCustomerSuperAdminLoader: false,
        customerDetailInfo: {
          ...state.customerDetailInfo,
          superadmin: customerSuperAdminData,
        },
        postCreateNewCustomerSuperAdminSuccsessLoader: true,
      };
    }
    case actionTypes.POST_CREATE_NEW_CUSTOMER_SUPER_ADMIN_FAILURE: {
      return {
        ...state,
        postCreateNewCustomerSuperAdminLoader: false,
        postCreateNewCustomerSuperAdminSuccsessLoader: false,
      };
    }

    //** POST_CREATE_NEW_CUSTOMER_SUPER_ADMIN */
    case actionTypes.PUT_CUSTOMER_SUPER_ADMIN: {
      return {
        ...state,
        putCustomerSuperAdminLoader: true,
      };
    }
    case actionTypes.PUT_CUSTOMER_SUPER_ADMIN_SUCCESS: {
      const { customerSuperAdminData } = action.payload;
      return {
        ...state,
        putCustomerSuperAdminLoader: false,
        customerDetailInfo: {
          ...state.customerDetailInfo,
          superadmin: customerSuperAdminData,
        },
      };
    }
    case actionTypes.PUT_CUSTOMER_SUPER_ADMIN_FAILURE: {
      return {
        ...state,
        putCustomerSuperAdminLoader: false,
      };
    }

    //
    case actionTypes.DELETED_CUSTOMER_LIST_MODAL: {
      const { openModal } = action.payload;
      return {
        ...state,
        openDeletedCustomerListModal: openModal,
      };
    }

    //** postResendCustomerUserInvitation*/
    case actionTypes.POST_RESEND_CUSTOMER_USER_INVITATION: {
      return {
        ...state,
        postResendCustomerUserInvitationLoader: true,
      };
    }
    case actionTypes.POST_RESEND_CUSTOMER_USER_INVITATION_SUCCESS: {
      // const { customerSuperAdminData } = action.payload;
      return {
        ...state,
        postResendCustomerUserInvitationLoader: false,
      };
    }
    case actionTypes.POST_RESEND_CUSTOMER_USER_INVITATION_FAILURE: {
      return {
        ...state,
        postResendCustomerUserInvitationLoader: false,
      };
    }

    //** GET_CUSTOMER_BY_ID */
    case actionTypes.GET_CUSTOMER_BY_ID: {
      return {
        ...state,
        customerDetailInfo: {},
        getCustomerByIdLoader: true,
      };
    }
    case actionTypes.GET_CUSTOMER_BY_ID_SUCCESS: {
      const { customerDetailInfo } = action.payload;
      return {
        ...state,
        getCustomerByIdLoader: false,
        customerDetailInfo: customerDetailInfo,
      };
    }
    case actionTypes.GET_CUSTOMER_BY_ID_FAILURE: {
      return {
        ...state,
        getCustomerByIdLoader: false,
      };
    }

    //** POST_NEW_CUSTOMER_LANGUAGE */
    case actionTypes.POST_NEW_CUSTOMER_LANGUAGE: {
      return {
        ...state,
        postNewCustomerLanguageLoader: true,
      };
    }
    case actionTypes.POST_NEW_CUSTOMER_LANGUAGE_SUCCESS: {
      const { languageInfo } = action.payload;
      return {
        ...state,
        customerDetailInfo: {
          ...state.customerDetailInfo,
          languages: [...state.customerDetailInfo.languages, languageInfo],
        },
        postNewCustomerLanguageLoader: false,
      };
    }
    case actionTypes.POST_NEW_CUSTOMER_LANGUAGE_FAILURE: {
      return {
        ...state,
        postNewCustomerLanguageLoader: false,
      };
    }

    // ** delete customer
    case actionTypes.DELETE_CUSTOMER_LANGUAGE: {
      const { languageId } = action.payload.languageInfo;
      return {
        ...state,
        deleteCustomerLanguageLoader: true,
        selectedLanguageId: languageId,
      };
    }
    case actionTypes.DELETE_CUSTOMER_LANGUAGE_SUCCESS: {
      const { languageId } = action.payload.languageInfo;

      // ** remove deleted item from array
      const updateLanguageList = state.customerDetailInfo.languages.filter(
        (item) => item.id !== languageId
      );

      return {
        ...state,
        customerDetailInfo: {
          ...state.customerDetailInfo,
          languages: [...updateLanguageList],
        },
        deleteCustomerLanguageLoader: false,
        selectedLanguageId: languageId,
      };
    }
    case actionTypes.DELETE_CUSTOMER_LANGUAGE_FAILURE: {
      return {
        ...state,
        deleteCustomerLanguageLoader: false,
      };
    }

    //** GET_CUSTOMER_NAVIGATION_CONFIG */
    case actionTypes.GET_CUSTOMER_NAVIGATION_CONFIG: {
      return {
        ...state,
        getCustomerNavigationConfigLoader: true,
      };
    }
    case actionTypes.GET_CUSTOMER_NAVIGATION_CONFIG_SUCCESS: {
      const { customerConfigInfo } = action.payload;
      return {
        ...state,
        getCustomerNavigationConfigLoader: false,
        customerConfigInfo: customerConfigInfo,
      };
    }
    case actionTypes.GET_CUSTOMER_NAVIGATION_CONFIG_FAILURE: {
      return {
        ...state,
        getCustomerNavigationConfigLoader: false,
      };
    }

    //** PUT_CUSTOMER_NAVIGATION_CONFIG */
    case actionTypes.PUT_CUSTOMER_NAVIGATION_CONFIG: {
      return {
        ...state,
        putCustomerNavigationConfigLoader: true,
      };
    }
    case actionTypes.PUT_CUSTOMER_NAVIGATION_CONFIG_SUCCESS: {
      const { navigationInfo } = action.payload;

      const customerConfigInfoUpdate = [...state.customerConfigInfo];

      customerConfigInfoUpdate[0] = {
        ...customerConfigInfoUpdate[0],
        messagingTime: navigationInfo.messagingTime,
        fullInit: navigationInfo.fullInit,
      };
      return {
        ...state,
        putCustomerNavigationConfigLoader: false,
        customerConfigInfo: customerConfigInfoUpdate,
      };
    }
    case actionTypes.PUT_CUSTOMER_NAVIGATION_CONFIG_FAILURE: {
      return {
        ...state,
        putCustomerNavigationConfigLoader: false,
      };
    }

    // **  post customer config
    case actionTypes.GET_CUSTOMER_CONFIG: {
      return {
        ...state,
        getCustomerJsonConfigLoading: true,
      };
    }
    case actionTypes.GET_CUSTOMER_CONFIG_SUCCESS: {
      const data = action.payload.data;

      return {
        ...state,
        customerJsonConfig: data,
        getCustomerJsonConfigLoading: false,
      };
    }
    case actionTypes.GET_CUSTOMER_CONFIG_FAILURE: {
      return {
        ...state,
        customerJsonConfig: {},
        getCustomerJsonConfigLoading: false,
      };
    }

    // ** PUT_CUSTOMER_CONFIG
    case actionTypes.PUT_CUSTOMER_CONFIG: {
      return {
        ...state,
        putCustomerJsonConfigLoading: true,
      };
    }
    case actionTypes.PUT_CUSTOMER_CONFIG_SUCCESS: {
      const data = action.payload.data;

      return {
        ...state,
        customerJsonConfig: data,
        putCustomerJsonConfigLoading: false,
      };
    }
    case actionTypes.PUT_CUSTOMER_CONFIG_FAILURE: {
      return {
        ...state,
        customerJsonConfig: {},
        putCustomerJsonConfigLoading: false,
      };
    }

    // ** GET_LOGIN_AS_CUSTOMER
    case actionTypes.GET_LOGIN_AS_CUSTOMER: {
      return {
        ...state,
        getLoginAsCustomerLoader: true,
      };
    }
    case actionTypes.GET_LOGIN_AS_CUSTOMER_SUCCESS: {
      return {
        ...state,
        loginAsCutomerInfo: action.payload,
        getLoginAsCustomerLoader: false,
      };
    }
    case actionTypes.GET_LOGIN_AS_CUSTOMER_FAILURE: {
      return {
        ...state,
        getLoginAsCustomerLoader: false,
        loginAsCutomerInfo: {},
      };
    }

    default: {
      return state;
    }
  }
};

export default customers;
