import * as services from "../assets/data/urlConfig";

import axiosApiInstance from "./axiosInterceptor";

export const getPoisListAsync = async (data) => {
  const fitlers = data?.fitlers;
  return await axiosApiInstance.get(services.getPoisListUrl(), {
    params: {
      titleFilter: fitlers?.titleFilter,
    },
  });
};
