import { mergedGeoJson } from "../../constants/mergedfile";
import * as actionTypes from "../actionTypes";

const intialState = {
  placesList: [],
  selectedLanguageInfo: {},
  placeGeoJson: {},
  poiGeoJson: [],
  poiContentList: [],
  centerCoordination: {},
  placeSettings: {},
  currentFloor: {
    title: 1,
    id: 1,
  },
  placePublishGISInfo: null,
  placeLiveStatus: null,

  getPlacesListLoader: false,
  getPlaceGeoJsonLoader: false,
  getPoiGeoJsonLoader: false,
  getPlaceCenterCoordinationLoader: false,
  getPlaceSettingsLoader: false,
  putPlaceSettingsLoader: false,
  deletePlaceSettingsLoader: false,
  postPlaceGISPublishLoader: false,
  getPlaceLiveStatusLoader: false,
  postPoiContentLoader: false,
};

const places = (state = intialState, action) => {
  switch (action.type) {
    //**GET_PLACES */
    case actionTypes.GET_PLACES: {
      return {
        ...state,
        getPlacesListLoader: true,
      };
    }
    case actionTypes.GET_PLACES_SUCCESS: {
      return {
        ...state,
        placesList: action.payload.placesList,
        getPlacesListLoader: false,
      };
    }
    case actionTypes.GET_PLACES_FAILURE: {
      return {
        ...state,
        getPlacesListLoader: false,
      };
    }

    //**GET_PLACE_GEO_JSON */
    case actionTypes.GET_PLACE_GEO_JSON: {
      return {
        ...state,
        placeGeoJson: {},
        getPlaceGeoJsonLoader: false,
      };
    }
    case actionTypes.GET_PLACE_GEO_JSON_SUCCESS: {
      const { data } = action.payload;
      // console.log(mergedGeoJson);
      // console.log(data);
      return {
        ...state,
        placeGeoJson: data,
        getPlaceGeoJsonLoader: false,
      };
    }
    case actionTypes.GET_PLACE_GEO_JSON_FAILURE: {
      return {
        ...state,
        placeGeoJson: {},
        getPlaceGeoJsonLoader: false,
      };
    }

    //**GET_POI_GEO_JSON */
    case actionTypes.GET_POI_GEO_JSON: {
      return {
        ...state,
        poiGeoJson: [],
        getPoiGeoJsonLoader: true,
      };
    }
    case actionTypes.GET_POI_GEO_JSON_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        poiGeoJson: data,
        getPoiGeoJsonLoader: false,
      };
    }
    case actionTypes.GET_POI_GEO_JSON_FAILURE: {
      return {
        ...state,
        getPoiGeoJsonLoader: false,
      };
    }

    //**GET_PLACE_CENTER_COORDINATION */
    case actionTypes.GET_PLACE_CENTER_COORDINATION: {
      return {
        ...state,
        centerCoordination: {},
        getPlaceCenterCoordinationLoader: true,
      };
    }
    case actionTypes.GET_PLACE_CENTER_COORDINATION_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        centerCoordination: data,
        getPlaceCenterCoordinationLoader: false,
      };
    }
    case actionTypes.GET_PLACE_CENTER_COORDINATION_FAILURE: {
      return {
        ...state,
        centerCoordination: {},
        getPlaceCenterCoordinationLoader: false,
      };
    }

    //**GET_PLACES_SETTINGS */
    case actionTypes.GET_PLACES_SETTINGS: {
      return {
        ...state,
        placeSettings: {},
        getPlaceSettingsLoader: true,
      };
    }
    case actionTypes.GET_PLACES_SETTINGS_SUCCESS: {
      const { settingInfo } = action.payload;
      return {
        ...state,
        placeSettings: settingInfo,
        getPlaceSettingsLoader: false,
      };
    }
    case actionTypes.GET_PLACES_SETTINGS_FAILURE: {
      return {
        ...state,
        placeSettings: {},
        getPlaceSettingsLoader: false,
      };
    }

    //**PUT_PLACES_SETTINGS */
    case actionTypes.PUT_PLACES_SETTINGS: {
      return {
        ...state,
        placeSettings: {},
        putPlaceSettingsLoader: true,
      };
    }
    case actionTypes.PUT_PLACES_SETTINGS_SUCCESS: {
      const { settingInfo } = action.payload;
      console.log(settingInfo);
      return {
        ...state,
        placeSettings: settingInfo,
        putPlaceSettingsLoader: false,
      };
    }
    case actionTypes.PUT_PLACES_SETTINGS_FAILURE: {
      return {
        ...state,
        placeSettings: {},
        putPlaceSettingsLoader: false,
      };
    }

    //**DELETE_PLACES_SETTINGS */
    case actionTypes.DELETE_PLACES_SETTINGS: {
      return {
        ...state,
        placeSettings: {},
        deletePlaceSettingsLoader: true,
      };
    }
    case actionTypes.DELETE_PLACES_SETTINGS_SUCCESS: {
      const { settingInfo } = action.payload;
      return {
        ...state,
        placeSettings: settingInfo,
        deletePlaceSettingsLoader: false,
      };
    }
    case actionTypes.DELETE_PLACES_SETTINGS_FAILURE: {
      return {
        ...state,
        placeSettings: {},
        deletePlaceSettingsLoader: false,
      };
    }

    //**POST_PLACE_PUBLISH_GIS */
    case actionTypes.POST_PLACE_PUBLISH_GIS: {
      return {
        ...state,
        placePublishGISInfo: null,
        postPlaceGISPublishLoader: true,
      };
    }
    case actionTypes.POST_PLACE_PUBLISH_GIS_SUCCESS: {
      const { placeInfo } = action.payload;
      return {
        ...state,
        placePublishGISInfo: placeInfo,
        postPlaceGISPublishLoader: false,
        placeLiveStatus: {
          result: {
            live: true,
          },
        },
      };
    }
    case actionTypes.POST_PLACE_PUBLISH_GIS_FAILURE: {
      return {
        ...state,
        placePublishGISInfo: null,
        postPlaceGISPublishLoader: false,
      };
    }

    //**POST_PLACE_PUBLISH_GIS */
    case actionTypes.GET_PLACE_LIVE_STATUS: {
      return {
        ...state,
        placeLiveStatus: null,
        getPlaceLiveStatusLoader: true,
      };
    }
    case actionTypes.GET_PLACE_LIVE_STATUS_SUCCESS: {
      const { placeLiveStatus } = action.payload;
      return {
        ...state,
        placeLiveStatus: placeLiveStatus,
        getPlaceLiveStatusLoader: false,
      };
    }
    case actionTypes.GET_PLACE_LIVE_STATUS_FAILURE: {
      return {
        ...state,
        placeLiveStatus: null,
        getPlaceLiveStatusLoader: false,
      };
    }

    //**POST_PLACE_PUBLISH_GIS */
    case actionTypes.POST_POI_CONTENT: {
      return {
        ...state,
        poiContentList: [],
        postPoiContentLoader: true,
      };
    }
    case actionTypes.POST_POI_CONTENT_SUCCESS: {
      // const { data } = action.payload;
      return {
        ...state,
        poiContentList: [],
        postPoiContentLoader: false,
      };
    }
    case actionTypes.POST_POI_CONTENT_FAILURE: {
      return {
        ...state,
        poiContentList: [],
        postPoiContentLoader: false,
      };
    }

    // ** change current floor
    case actionTypes.CHANGE_CURRENT_FLOOR: {
      const { floorInfo } = action.payload;
      return {
        ...state,
        currentFloor: floorInfo,
      };
    }

    default: {
      return state;
    }
  }
};

export default places;
