import * as servicesUrl from "../assets/data/urlConfig";
import axiosApiInctance from "../services/axiosInterceptor";

// ** get Places List Async
export const getBeaconsListAsync = async (placeInfo) => {
  return await axiosApiInctance.get(
    `${servicesUrl.getBeaconsListUrl(placeInfo)}`
  );
};

export const getBeaconsAsync = async (data) => {
  console.log(data);
  const { placeId, withoutPoi, major } = data;
  return await axiosApiInctance.get(`${servicesUrl.getBeaconsUrl()}`, {
    params: {
      placeId,
      ...(withoutPoi ?? { withoutPoi: withoutPoi }),
      ...(major ?? { major: major }),
    },
  });
};

export const deleteBeaconByIdAsync = async (data) => {
  return await axiosApiInctance.delete(
    `${servicesUrl.deleteBeaconByIdUrl(data)}`
  );
};

export const postBeaconAsync = async (data) => {
  const { placeId, major, minor, uuid } = data;
  return await axiosApiInctance.post(`${servicesUrl.postBeaconUrl()}`, {
    placeId,
    major,
    minor,
    uuid,
  });
};

export const putBeaconAsync = async (data) => {
  const { id, placeId, major, minor } = data;
  return await axiosApiInctance.put(`${servicesUrl.putBeaconUrl(id)}`, {
    placeId,
    major,
    minor,
  });
};
