// You can customize the theme with the help of this file

//Template config options
const themeConfig = {
  layout: "vertical", // options[String]: "vertical"(default), "horizontal"
  theme: "light", // options[String]: 'light'(default), 'dark', 'semi-dark'
  sidebarCollapsed: false, // options[Boolean]: true, false(default)
  navbarColor: "default", // options[String]: default / primary / success / danger / info / warning / dark
  navbarType: "floating", // options[String]: floating(default) / static / sticky / hidden
  footerType: "static", // options[String]: static(default) / sticky / hidden
  disableCustomizer: true, // options[Boolean]: true, false(default)
  hideScrollToTop: false, // options[Boolean]: true, false(default)
  menuTheme: "primary", // options[String]: primary / success / danger / info / warning / dark
  direction: "ltr", // options[String] : ltr / rtl

  // ** colors
  colors: {
    primaryColor: "#605bff",
    secondaryColor: "#fffbeb",
    greenColor: "#03A89E",
    dangerColor: "#d32f2f",
  },

  // ** shadows
  shadows: {
    mainShadow: "0 5px 10px 0 rgba(0,0,0, 0.15)",
  },
};

export default themeConfig;
