import * as actionTypes from "../actionTypes";

const initialState = {
  poisList: [],
  filterPoisList: [],

  getPoisListLoading: false,
};

const poisReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_POIS_LIST: {
      return {
        ...state,
        getPoisListLoading: true,
      };
    }
    case actionTypes.GET_POIS_LIST_SUCCESS: {
      const filterPoisArray = action.payload;

      return {
        ...state,
        poisList: action.payload,
        filterPoisList: filterPoisArray,
        getPoisListLoading: false,
      };
    }
    case actionTypes.GET_POIS_LIST_FAILURE: {
      return {
        ...state,
        getPoisListLoading: false,
        poisList: [],
      };
    }
    default:
      return state;
  }
};

export default poisReducer;
