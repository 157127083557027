import * as storeIdActionTypes from "../actionTypes";

// ** Actions
export const getStoreIdsList = (payload) => ({
  type: storeIdActionTypes.GET_STORE_IDS_LIST,
  payload,
});
export const getStoreIdsListSuccess = (payload) => ({
  type: storeIdActionTypes.GET_STORE_IDS_LIST_SUCCESS,
  payload,
});
export const getStoreIdsListFailure = () => ({
  type: storeIdActionTypes.GET_STORE_IDS_LIST_FAILURE,
});

export const deleteStoreId = (payload) => ({
  type: storeIdActionTypes.DELETE_STORE_ID,
  payload,
});
export const deleteStoreIdSuccess = (payload) => ({
  type: storeIdActionTypes.DELETE_STORE_ID_SUCCESS,
  payload,
});

export const deleteStoreIdFailure = () => ({
  type: storeIdActionTypes.DELETE_STORE_ID_FAILURE,
});

// ** post store id
export const postStoreId = (payload) => ({
  type: storeIdActionTypes.POST_STORE_ID,
  payload,
});

export const postStoreIdSuccess = (payload) => ({
  type: storeIdActionTypes.POST_STORE_ID_SUCCESS,
  payload,
});

export const postStoreIdFailure = () => ({
  type: storeIdActionTypes.POST_STORE_ID_FAILURE,
});

// * putStoreId
export const putStoreId = (payload) => ({
  type: storeIdActionTypes.PUT_STORE_ID,
  payload,
});

export const putStoreIdSuccess = (payload) => ({
  type: storeIdActionTypes.PUT_STORE_ID_SUCCESS,
  payload,
});

export const putStoreIdFailure = () => ({
  type: storeIdActionTypes.PUT_STORE_ID_FAILURE,
});
