import { call, takeEvery, put, all, fork } from "redux-saga/effects";
import * as actionTypes from "../actionTypes";

//**  services
import * as services from "../../services/networktypesServices";

//**  actions
import * as actions from "./actions";

import { toast } from "react-toastify";

//** Get user list
function* getNetworktypes() {
  try {
    const response = yield call(services.getNetworktypeListAsync);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.getNetworktypeListSuccess(data));
    } else {
      toast.error(data.message);
      yield put(actions.getNetworktypeListFailure());
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.getNetworktypeListFailure());
    toast.error(data.message);
    console.log(error);
  }
}

//** post  new networktype
function* postNewNetworktype({ payload }) {
  let { networkInfo } = payload;
  try {
    const response = yield call(services.postNewNetworktypeAsync, networkInfo);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.postNewNetworktypeSuccess(data));
      toast.success("Your networktype created successfully");
    } else {
      yield put(actions.postNewNetworktypeFailure());
      toast.error(data.message);
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.postNewNetworktypeFailure());
    toast.error(data.message);
  }
}

// ** delete networktype
function* deleteNetworktype({ payload }) {
  let { networkInfo } = payload;
  try {
    const response = yield call(services.deleteNetworktypeAsync, networkInfo);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.deleteNetworktypeSuccess(networkInfo));
      toast.success("Your netwroktype deleted successfully");
    } else {
      yield put(actions.deleteNetworktypeFailure());
      toast.error(data.message);
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.deleteNetworktypeFailure());
    toast.error(data.message);
    console.log(error);
  }
}

//** get Deleted NetworktypeList
function* getDeletedNetworktypeList() {
  try {
    const response = yield call(services.getDeletedNetworktypeListAsync);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.getDeletedNetworktypeListSuccess(data));
    } else {
      toast.error(data.message);
      yield put(actions.getDeletedNetworktypeListFailure([]));
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.getDeletedNetworktypeListFailure([]));
    toast.error(data.message);
    console.log(error);
  }
}

// ** restore networktype
function* putRestoreNetworktype({ payload }) {
  let { networkInfo } = payload;
  try {
    const response = yield call(
      services.putRestoreNetworktypeUrlAsync,
      networkInfo
    );
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.putRestoreNetworktypeSuccess(networkInfo));
      yield put(actions.openDeletedNetworktypeListModal(false));
      yield put(actions.getNetworktypeList());

      toast.success("Your netwroktype restored successfully");
    } else {
      yield put(actions.putRestoreNetworktypeeFailure());
      toast.error(data.message);
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.putRestoreNetworktypeeFailure());
    toast.error(data.message);
    console.log(error);
  }
}

//** put update  customer
function* putNetworktype({ payload }) {
  let { networkInfo } = payload;

  try {
    const response = yield call(services.putNetworktypeAsync, networkInfo);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.putNetworktypeSuccess(data));
      toast.success("Your networktype was updated successfully");
    } else {
      yield put(actions.putNetworktypeFailure());
      toast.error(data.message);
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.putNetworktypeFailure());
    toast.error(data.message);
  }
}

// *************************** watchers ******************************
export function* watchGetNetworktypes() {
  yield takeEvery(actionTypes.GET_NETWORKTYPE_LIST, getNetworktypes);
}
export function* watchPostNewNetworktype() {
  yield takeEvery(actionTypes.POST_NETWORKTYPE, postNewNetworktype);
}
export function* watchDeleteNetworktype() {
  yield takeEvery(actionTypes.DELETE_NETWORKTYPE, deleteNetworktype);
}
export function* watchGetDeletedNetworktypeList() {
  yield takeEvery(
    actionTypes.GET_DELETED_NETWORKTYPE_LIST,
    getDeletedNetworktypeList
  );
}
export function* watchPutRestoreNetworktype() {
  yield takeEvery(actionTypes.PUT_RESTORE_NETWORKTYPE, putRestoreNetworktype);
}
export function* watchPutNetworktype() {
  yield takeEvery(actionTypes.PUT_NETWROKTYPE, putNetworktype);
}

//Fork
export default function* rootSaga() {
  yield all([
    fork(watchGetNetworktypes),
    fork(watchPostNewNetworktype),
    fork(watchDeleteNetworktype),
    fork(watchGetDeletedNetworktypeList),
    fork(watchPutRestoreNetworktype),
    fork(watchPutNetworktype),
  ]);
}
