import * as servicesUrl from "../assets/data/urlConfig";
import axiosApiInctance from "../services/axiosInterceptor";

// ** get user list async
export const getPlaceTypeListAsync = async () => {
  return await axiosApiInctance.get(`${servicesUrl.getPlaceTypesListUrl}`);
};

// ** get user list async
export const getDeletedPlaceTypeListAsync = async () => {
  return await axiosApiInctance.get(
    `${servicesUrl.getDeletedPlaceTypesListUrl}`
  );
};

// ** get user list async
export const putRestorePlaceTypeUrlAsync = async (placeTypeId) => {
  return await axiosApiInctance.put(
    `${servicesUrl.putRestorePlaceTypeUrl(placeTypeId)}`
  );
};

// ** post new place type
export const postNewPlaceTypeAsync = async (customerInfo) => {
  const { name } = customerInfo;
  console.log(name);
  return await axiosApiInctance.post(`${servicesUrl.postNewPlaceTypeUrl}`, {
    name: name,
  });
};

// ** delete customer
export const deletePlaceTypeAsync = async (placeTypeId) => {
  return await axiosApiInctance.delete(
    `${servicesUrl.deletePlaceTypeUrl(placeTypeId)}`
  );
};

// ** put place type
export const putPlaceTypeAsync = async (placeTypeInfo) => {
  const { name, placeTypeId } = placeTypeInfo;
  return await axiosApiInctance.put(
    `${servicesUrl.putPlaceTypeUrl(placeTypeId)}`,
    {
      name: name,
    }
  );
};
