import * as actionTypes from "../actionTypes";

const intialState = {
  mneuItemList: [],

  getMenuItemListLoader: false,
  postMenuItemLoader: false,
  deleteMenuItemLoader: false,
  putUpdateMenuItemLoader: false,
  postMenuItemTranslationLoader: false,
  deleteMenuItemTranslationLoader: false,
  putMenuItemTranslationLoader: false,

  postExcludeMenuItemLoader: false,
  deleteExcludeMenuItemLoader: false,

  postAttachMenuItemLoader: false,
  deleteAttachMenuItemLoader: false,

  selectedMenuItemForDeleteInfo: {},
  selectedPlaceTypeForAttach: null,
  selectedPlaceForExclude: null,
};

const menuItems = (state = intialState, action) => {
  switch (action.type) {
    //**GET_PLACE_TYPES_LIST */
    case actionTypes.GET_MENU_ITEM_LIST: {
      return {
        ...state,
        getMenuItemListLoader: true,
      };
    }
    case actionTypes.GET_MENU_ITEM_LIST_SUCCESS: {
      return {
        ...state,
        mneuItemList: action.payload.mneuItemList,
        getMenuItemListLoader: false,
      };
    }
    case actionTypes.GET_MENU_ITEM_LIST_FAILURE: {
      return {
        ...state,
        getMenuItemListLoader: false,
      };
    }

    // ** post menu item
    case actionTypes.POST_MENU_ITEM: {
      return {
        ...state,
        postMenuItemLoader: true,
      };
    }
    case actionTypes.POST_MENU_ITEM_SUCCESS: {
      const menuItemInfo = action.payload.menuItemInfo;

      return {
        ...state,
        mneuItemList: [
          ...state.mneuItemList,
          {
            ...menuItemInfo,
          },
        ],
        postMenuItemLoader: false,
      };
    }
    case actionTypes.POST_MENU_ITEM_FAILURE: {
      return {
        ...state,
        postMenuItemLoader: false,
      };
    }

    // ** delete  menu item
    case actionTypes.DELETE_MENU_ITEM: {
      const menuItemInfo = action.payload.menuItemInfo;
      return {
        ...state,
        deleteMenuItemLoader: true,
        selectedMenuItemForDeleteInfo: menuItemInfo,
      };
    }
    case actionTypes.DELETE_MENU_ITEM_SUCCESS: {
      const menuItemInfo = action.payload.menuItemInfo;

      // ** remove deleted item from array
      const updateMenuItems = state.mneuItemList.filter(
        (item) => item.id !== menuItemInfo.menuItemId
      );
      return {
        ...state,
        mneuItemList: updateMenuItems,
        deleteMenuItemLoader: false,
      };
    }
    case actionTypes.DELETE_MENU_ITEM_FAILURE: {
      return {
        ...state,
        deleteMenuItemLoader: false,
      };
    }

    // ** PUT_MENU_ITEM
    case actionTypes.PUT_MENU_ITEM: {
      return {
        ...state,
        putUpdateMenuItemLoader: true,
      };
    }
    case actionTypes.PUT_MENU_ITEM_SUCCESS: {
      const { title, id } = action.payload.menuItemInfo;

      const menuItemIndex = state.mneuItemList.findIndex(
        (menuItem) => menuItem.id === id
      );

      const mneuItemListUpdate = [...state.mneuItemList];

      mneuItemListUpdate[menuItemIndex] = {
        id,
        title,
      };

      return {
        ...state,
        mneuItemList: mneuItemListUpdate,
        putUpdateMenuItemLoader: false,
      };
    }
    case actionTypes.PUT_MENU_ITEM_FAILURE: {
      return {
        ...state,
        putUpdateMenuItemLoader: false,
      };
    }

    // ** POST_MENU_ITEM_TRANSLATION
    case actionTypes.POST_MENU_ITEM_TRANSLATION: {
      return {
        ...state,
        postMenuItemTranslationLoader: true,
      };
    }
    case actionTypes.POST_MENU_ITEM_TRANSLATION_SUCCESS: {
      const { data, menuItemId } = action.payload.menuItemInfo;

      const menuItemIndex = state.mneuItemList.findIndex(
        (menuItem) => menuItem.id === menuItemId
      );

      const mneuItemListUpdate = [...state.mneuItemList];

      mneuItemListUpdate[menuItemIndex] = {
        ...mneuItemListUpdate[menuItemIndex],
        Menuitemtranslations: [
          ...mneuItemListUpdate[menuItemIndex].Menuitemtranslations,
          data,
        ],
      };

      return {
        ...state,
        mneuItemList: mneuItemListUpdate,
        postMenuItemTranslationLoader: false,
      };
    }
    case actionTypes.POST_MENU_ITEM_TRANSLATION_FAILURE: {
      return {
        ...state,
        postMenuItemTranslationLoader: false,
      };
    }

    // ** DELETE_MENU_ITEM_TRANSLATION
    case actionTypes.DELETE_MENU_ITEM_TRANSLATION: {
      return {
        ...state,
        deleteMenuItemTranslationLoader: true,
      };
    }
    case actionTypes.DELETE_MENU_ITEM_TRANSLATION_SUCCESS: {
      const { translationId, menuItemId } = action.payload.menuItemInfo;
      console.log(action.payload.menuItemInfo);

      const menuItemIndex = state.mneuItemList.findIndex(
        (menuItem) => menuItem.id === menuItemId
      );

      const mneuItemListUpdate = [...state.mneuItemList];

      mneuItemListUpdate[menuItemIndex].Menuitemtranslations.filter(
        (item) => item.id !== translationId
      );

      return {
        ...state,
        mneuItemList: mneuItemListUpdate,
        deleteMenuItemTranslationLoader: false,
      };
    }
    case actionTypes.DELETE_MENU_ITEM_TRANSLATION_FAILURE: {
      return {
        ...state,
        deleteMenuItemTranslationLoader: false,
      };
    }

    // ** DELETE_MENU_ITEM_TRANSLATION
    case actionTypes.PUT_MENU_ITEM_TRANSLATION: {
      return {
        ...state,
        putMenuItemTranslationLoader: true,
      };
    }

    case actionTypes.PUT_MENU_ITEM_TRANSLATION_SUCCESS: {
      return {
        ...state,
        putMenuItemTranslationLoader: false,
      };
    }
    case actionTypes.PUT_MENU_ITEM_TRANSLATION_FAILURE: {
      return {
        ...state,
        putMenuItemTranslationLoader: false,
      };
    }

    // *****************  exclude and attach reducers

    // ** POST_EXCLUDE_MENU_ITEM
    case actionTypes.POST_EXCLUDE_MENU_ITEM: {
      const excludeInfo = action.payload.excludeInfo;

      return {
        ...state,
        postExcludeMenuItemLoader: true,
        selectedPlaceForExclude: excludeInfo.placeId,
      };
    }
    case actionTypes.POST_EXCLUDE_MENU_ITEM_SUCCESS: {
      const excludeInfo = action.payload.excludeInfo;

      const index = state.mneuItemList.findIndex(
        (item) => parseInt(item.id) === parseInt(excludeInfo.menuitemId)
      );

      const updateMenuitemList = state.mneuItemList;

      updateMenuitemList[index] = {
        ...updateMenuitemList[index],
        Menuitemexcludes: [
          ...updateMenuitemList[index].Menuitemexcludes,
          {
            id: excludeInfo.data.PlaceId,
            Place: {
              id: excludeInfo.data.PlaceId,
            },
          },
        ],
      };

      return {
        ...state,
        postExcludeMenuItemLoader: false,
        mneuItemList: updateMenuitemList,
        selectedPlaceForExclude: excludeInfo.placeId,
      };
    }
    case actionTypes.POST_EXCLUDE_MENU_ITEM_FAILURE: {
      return {
        ...state,
        postExcludeMenuItemLoader: false,
      };
    }

    // ** DELETE_EXCLUDE_MENU_ITEM
    case actionTypes.DELETE_EXCLUDE_MENU_ITEM: {
      const excludeInfo = action.payload.excludeInfo;
      return {
        ...state,
        deleteExcludeMenuItemLoader: true,
        selectedPlaceForExclude: excludeInfo.placeId,
      };
    }
    case actionTypes.DELETE_EXCLUDE_MENU_ITEM_SUCCESS: {
      const excludeInfo = action.payload.excludeInfo;

      const index = state.mneuItemList.findIndex(
        (item) => parseInt(item.id) === parseInt(excludeInfo.menuitemId)
      );

      const updateMenuitemList = state.mneuItemList;

      const updateMenuitemPlaceTypes = updateMenuitemList[
        index
      ].Menuitemexcludes.filter(
        (item) => parseInt(item.Place.id) !== parseInt(excludeInfo.placeId)
      );

      updateMenuitemList[index] = {
        ...updateMenuitemList[index],
        Menuitemexcludes: updateMenuitemPlaceTypes,
        deleteExcludeMenuItemLoader: false,
        selectedPlaceForExclude: excludeInfo.placeId,
      };

      return {
        ...state,
        mneuItemList: [...updateMenuitemList],
        deleteExcludeMenuItemLoader: false,
      };
    }
    case actionTypes.DELETE_EXCLUDE_MENU_ITEM_FAILURE: {
      return {
        ...state,
        deleteExcludeMenuItemLoader: false,
      };
    }

    // ** POST_ATTACH_MENU_ITEM
    case actionTypes.POST_ATTACH_MENU_ITEM: {
      const attachInfo = action.payload.attachInfo;
      return {
        ...state,
        postAttachMenuItemLoader: true,
        selectedPlaceTypeForAttach: attachInfo.placetypeId,
      };
    }
    case actionTypes.POST_ATTACH_MENU_ITEM_SUCCESS: {
      const attachInfo = action.payload.attachInfo;
      const index = state.mneuItemList.findIndex(
        (item) => parseInt(item.id) === parseInt(attachInfo.menuitemId)
      );

      const updateMenuitemList = state.mneuItemList;

      updateMenuitemList[index] = {
        ...updateMenuitemList[index],
        Menuitemplacetypes: [
          ...updateMenuitemList[index].Menuitemplacetypes,
          {
            id: attachInfo.data.PlacetypeId,
            Placetype: {
              id: attachInfo.data.PlacetypeId,
            },
          },
        ],
      };

      return {
        ...state,
        postAttachMenuItemLoader: false,
        mneuItemList: updateMenuitemList,
        selectedPlaceTypeForAttach: attachInfo.placetypeId,
      };
    }
    case actionTypes.POST_ATTACH_MENU_ITEM_FAILURE: {
      return {
        ...state,
        postAttachMenuItemLoader: false,
      };
    }

    // ** DELETE_ATTACH_MENU_ITEM
    case actionTypes.DELETE_ATTACH_MENU_ITEM: {
      const attachInfo = action.payload.attachInfo;
      return {
        ...state,
        deleteAttachMenuItemLoader: true,
        selectedPlaceTypeForAttach: attachInfo.placetypeId,
      };
    }
    case actionTypes.DELETE_ATTACH_MENU_ITEM_SUCCESS: {
      const attachInfo = action.payload.attachInfo;

      const index = state.mneuItemList.findIndex(
        (item) => parseInt(item.id) === parseInt(attachInfo.menuitemId)
      );

      const updateMenuitemList = state.mneuItemList;

      const updateMenuitemPlaceTypes = updateMenuitemList[
        index
      ].Menuitemplacetypes.filter(
        (item) =>
          parseInt(item.Placetype.id) !== parseInt(attachInfo.placetypeId)
      );

      updateMenuitemList[index] = {
        ...updateMenuitemList[index],
        Menuitemplacetypes: updateMenuitemPlaceTypes,
        selectedPlaceTypeForAttach: attachInfo.placetypeId,
      };

      return {
        ...state,
        deleteAttachMenuItemLoader: false,
        mneuItemList: [...updateMenuitemList],
      };
    }
    case actionTypes.DELETE_ATTACH_MENU_ITEM_FAILURE: {
      return {
        ...state,
        deleteAttachMenuItemLoader: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default menuItems;
