import * as actionTypes from "../actionTypes";

// ** get language list action
export const getBeaconsList = (placeInfo) => ({
  type: actionTypes.GET_BEACONS_LIST,
  payload: { placeInfo },
});
export const getBeaconsListSuccess = (beaconsList) => ({
  type: actionTypes.GET_BEACONS_LIST_SUCCESS,
  payload: { beaconsList },
});
export const getBeaconsListFailure = () => ({
  type: actionTypes.GET_BEACONS_LIST_FAILURE,
});

export const getBeacons = (data) => ({
  type: actionTypes.GET_BEACONS,
  payload: data,
});

export const getBeaconsSuccess = (data) => ({
  type: actionTypes.GET_BEACONS_SUCCESS,
  payload: data,
});

export const getBeaconsFailure = () => ({
  type: actionTypes.GET_BEACONS_FAILURE,
});

// ** deleteBeaconById action
export const deleteBeaconById = (data) => ({
  type: actionTypes.DELETE_BEACON,
  payload: data,
});

export const deleteBeaconByIdSuccess = (payload) => ({
  type: actionTypes.DELETE_BEACON_SUCCESS,
  payload,
});

export const deleteBeaconByIdFailure = () => ({
  type: actionTypes.DELETE_BEACON_FAILURE,
});

// ** postBeacon action
export const postBeacon = (data) => ({
  type: actionTypes.POST_BEACON,
  payload: data,
});

export const postBeaconSuccess = (data) => ({
  type: actionTypes.POST_BEACON_SUCCESS,
  payload: data,
});

export const postBeaconFailure = () => ({
  type: actionTypes.POST_BEACON_FAILURE,
});

// ** putBeacon action

export const putBeacon = (data) => ({
  type: actionTypes.PUT_BEACON,
  payload: data,
});

export const putBeaconSuccess = (data) => ({
  type: actionTypes.PUT_BEACON_SUCCESS,
  payload: data,
});

export const putBeaconFailure = () => ({
  type: actionTypes.PUT_BEACON_FAILURE,
});
