import * as servicesUrl from "../assets/data/urlConfig";
import axiosApiInctance from "../services/axiosInterceptor";

// ** get category list async

export const getCategoriesListAsync = async () => {
  return await axiosApiInctance.get(servicesUrl.getCategoriesUrl);
};

export const getCategoryByIdAsync = async (categoryInfo) => {
  return await axiosApiInctance.get(
    `${servicesUrl.getCategoryByIdUrl(categoryInfo)}`
  );
};

export const postCategoryAsync = async (categoryInfo) => {
  const { name, base64 } = categoryInfo;
  return await axiosApiInctance.post(servicesUrl.postCategoryUrl, {
    name: name,
    ...(base64 && { base64: base64 }),
  });
};

// ** delete category
export const deleteCategoryAsync = async (categoryInfo) => {
  return await axiosApiInctance.delete(
    `${servicesUrl.deleteCategoryUrl(categoryInfo)}`
  );
};

// ** put  category
export const putCategoryAsync = async (categoryInfo) => {
  const { name, base64 } = categoryInfo;
  return await axiosApiInctance.put(
    `${servicesUrl.putCategoryUrl(categoryInfo)}`,
    {
      name: name,
      ...(base64 && { base64: base64 }),
    }
  );
};

// ** get deleted brand list
export const getDeletedCategoriesListAsync = async () => {
  return await axiosApiInctance.get(
    `${servicesUrl.getDeletedCategoriesListUrl}`
  );
};

export const putRestorCategoryAsync = async (categoryInfo) => {
  return await axiosApiInctance.put(
    `${servicesUrl.putRestoreCategoryUrl(categoryInfo)}`
  );
};

export const postCategoryTranslationAsync = async (categoryInfo) => {
  return await axiosApiInctance.post(
    `${servicesUrl.postCategoryTranslationUrl(categoryInfo)}`,
    {
      translation: categoryInfo.translation,
      language: categoryInfo.language,
    }
  );
};

// ** delete category translation
export const deleteCategoryTranslationAsync = async (categoryInfo) => {
  return await axiosApiInctance.delete(
    `${servicesUrl.deleteCategoryTranslationUrl(categoryInfo)}`
  );
};

// ** put  category
export const putCategoryTranslationAsync = async (categoryInfo) => {
  const { translation } = categoryInfo;
  return await axiosApiInctance.put(
    `${servicesUrl.putCategoryTranslationUrl(categoryInfo)}`,
    {
      translation: translation,
    }
  );
};

// ******************************* deleted sub categories services

export const postSubcategoryAsync = async (categoryInfo) => {
  const { name } = categoryInfo;

  return await axiosApiInctance.post(
    servicesUrl.postSubcategoryUrl(categoryInfo),
    {
      name: name,
    }
  );
};

// ** delete subcategory
export const deleteSubcategoryAsync = async (categoryInfo) => {
  return await axiosApiInctance.delete(
    `${servicesUrl.deleteSubcategoryUrl(categoryInfo)}`
  );
};

// ** put  subcategory
export const putSubcategoryAsync = async (categoryInfo) => {
  const { name } = categoryInfo;
  return await axiosApiInctance.put(
    `${servicesUrl.putSubcategoryUrl(categoryInfo)}`,
    {
      name: name,
    }
  );
};

// export const getDeletedSubcategoriesListAsync = async () => {
//   return await axiosApiInctance.get(
//     `${servicesUrl.getDeletedCategoriesListUrl}`
//   );
// };

export const putRestorSubcategoryAsync = async (categoryInfo) => {
  return await axiosApiInctance.put(
    `${servicesUrl.putRestoreSubcategoryUrl(categoryInfo)}`
  );
};

export const postSubcategoryTranslationAsync = async (categoryInfo) => {
  return await axiosApiInctance.post(
    `${servicesUrl.postSubcategoryTranslationUrl(categoryInfo)}`,
    {
      translation: categoryInfo.translation,
      language: categoryInfo.language,
    }
  );
};

// ** delete category translation
export const deleteSubcategoryTranslationAsync = async (categoryInfo) => {
  return await axiosApiInctance.delete(
    `${servicesUrl.deleteSubcategoryTranslationUrl(categoryInfo)}`
  );
};

// ** put  category
export const putSubcategoryTranslationAsync = async (categoryInfo) => {
  const { translation } = categoryInfo;
  return await axiosApiInctance.put(
    `${servicesUrl.putSubcategoryTranslationUrl(categoryInfo)}`,
    {
      translation: translation,
    }
  );
};
