import { call, takeEvery, put, all, fork } from "redux-saga/effects";
import * as actionTypes from "../actionTypes";

//**  services
import * as services from "../../services/settingsServices";

//**  actions
import * as actions from "./actions";

import { toast } from "react-toastify";

//** Get user list
function* getLanguageList() {
  try {
    const response = yield call(services.getLanguageListAsync);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.getLanguageListSuccess(data));
    } else {
      toast.error(data.message);
      yield put(actions.getLanguageListFailure());
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.getLanguageListFailure());
    toast.error(data.message);
    console.log(error);
  }
}

//** Get user list
function* deleteLanguage({ payload }) {
  const { languageInfo } = payload;
  try {
    const response = yield call(services.deleteLanguageAsync, languageInfo);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.deleteLanguageSuccess(languageInfo));
      toast.success("Your language deleted successfully");
    } else {
      toast.error(data.message);
      yield put(actions.deleteLanguageFailure());
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.deleteLanguageFailure());
    toast.error(data.message);
    console.log(error);
  }
}

//** post New Language info
function* postNewLanguage({ payload }) {
  const { languageInfo } = payload;
  try {
    const response = yield call(services.postNewLanguageAsync, languageInfo);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.postNewLanguageSuccess(data));
      toast.success("Your language created successfully");
    } else {
      toast.error(data.message);
      yield put(actions.postNewLanguageFailure());
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.postNewLanguageFailure());
    toast.error(data.message);
    console.log(error);
  }
}

// *************************** watchers ******************************
export function* watchGetLanguageList() {
  yield takeEvery(actionTypes.GET_LANGUAGE_LIST, getLanguageList);
}
export function* watchDeleteLanguage() {
  yield takeEvery(actionTypes.DELETE_LANGUAGE, deleteLanguage);
}
export function* watchPostNewLanguage() {
  yield takeEvery(actionTypes.POST_NEW_LANGUAGE, postNewLanguage);
}

//Fork
export default function* rootSaga() {
  yield all([
    fork(watchGetLanguageList),
    fork(watchDeleteLanguage),
    fork(watchPostNewLanguage),
  ]);
}
