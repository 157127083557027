import * as actionTypes from "../actionTypes";

const intialState = {
  languageList: [],
  selectedLanguageInfo: {},

  getLanguageListLoader: false,
  postNewLanguageLoader: false,
  deleteLanguageLoader: false,
};

const settings = (state = intialState, action) => {
  switch (action.type) {
    //**GET_PLACE_TYPES_LIST */
    case actionTypes.GET_LANGUAGE_LIST: {
      return {
        ...state,
        getLanguageListLoader: true,
      };
    }
    case actionTypes.GET_LANGUAGE_LIST_SUCCESS: {
      return {
        ...state,
        languageList: action.payload.languageList,
        getLanguageListLoader: false,
      };
    }
    case actionTypes.GET_LANGUAGE_LIST_FAILURE: {
      return {
        ...state,
        getLanguageListLoader: false,
      };
    }

    //**DELETE_LANGUAGE */
    case actionTypes.DELETE_LANGUAGE: {
      const languageInfo = action.payload.languageInfo;

      return {
        ...state,
        deleteLanguageLoader: true,
        selectedLanguageInfo: languageInfo,
      };
    }
    case actionTypes.DELETE_LANGUAGE_SUCCESS: {
      const languageInfo = action.payload.languageInfo;

      // ** remove deleted item from array
      const updateLanguageList = state.languageList.filter(
        (item) => item.id !== languageInfo.languageId
      );

      return {
        ...state,
        languageList: updateLanguageList,
        selectedLanguageInfo: languageInfo,
        deleteLanguageLoader: false,
      };
    }
    case actionTypes.DELETE_LANGUAGE_FAILURE: {
      return {
        ...state,
        deleteLanguageLoader: false,
      };
    }

    //**POST_NEW_LANGUAGE */
    case actionTypes.POST_NEW_LANGUAGE: {
      return {
        ...state,
        postNewLanguageLoader: true,
      };
    }
    case actionTypes.POST_NEW_LANGUAGE_SUCCESS: {
      const languageInfo = action.payload.languageInfo;

      return {
        ...state,
        languageList: [
          ...state.languageList,
          {
            ...languageInfo,
          },
        ],
        postNewLanguageLoader: false,
      };
    }
    case actionTypes.POST_NEW_LANGUAGE_FAILURE: {
      return {
        ...state,
        postNewLanguageLoader: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default settings;
