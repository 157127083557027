import * as actionTypes from "../actionTypes";

// ** getNetworktypeList
export const getNetworktypeList = () => ({
  type: actionTypes.GET_NETWORKTYPE_LIST,
});
export const getNetworktypeListSuccess = (networktypeList) => ({
  type: actionTypes.GET_NETWORKTYPE_LIST_SUCCESS,
  payload: { networktypeList },
});
export const getNetworktypeListFailure = () => ({
  type: actionTypes.GET_NETWORKTYPE_LIST_FAILURE,
});

//** post New Networktype  */
export const postNewNetworktype = (networkInfo) => ({
  type: actionTypes.POST_NETWORKTYPE,
  payload: { networkInfo },
});
export const postNewNetworktypeSuccess = (networkInfo) => ({
  type: actionTypes.POST_NETWORKTYPE_SUCCESS,
  payload: { networkInfo },
});
export const postNewNetworktypeFailure = () => ({
  type: actionTypes.POST_NETWORKTYPE_FAILURE,
});

// ** delete networktype
export const deleteNetworktype = (networkInfo) => ({
  type: actionTypes.DELETE_NETWORKTYPE,
  payload: { networkInfo },
});
export const deleteNetworktypeSuccess = (networkInfo) => ({
  type: actionTypes.DELETE_NETWORKTYPE_SUCCESS,
  payload: { networkInfo },
});
export const deleteNetworktypeFailure = () => ({
  type: actionTypes.DELETE_NETWORKTYPE_FAILURE,
});

// ** get Deleted NetworktypeList
export const getDeletedNetworktypeList = () => ({
  type: actionTypes.GET_DELETED_NETWORKTYPE_LIST,
});
export const getDeletedNetworktypeListSuccess = (deletedNetworktypeList) => ({
  type: actionTypes.GET_DELETED_NETWORKTYPE_LIST_SUCCESS,
  payload: { deletedNetworktypeList },
});
export const getDeletedNetworktypeListFailure = () => ({
  type: actionTypes.GET_DELETED_NETWORKTYPE_LIST_FAILURE,
});

//** DELETED_NETWORKTYPE_LIST_MODAL

export const openDeletedNetworktypeListModal = (openModal) => ({
  type: actionTypes.DELETED_NETWORKTYPE_LIST_MODAL,
  payload: { openModal },
});

// ** put Restore Networktype
export const putRestoreNetworktype = (networkInfo) => ({
  type: actionTypes.PUT_RESTORE_NETWORKTYPE,
  payload: { networkInfo },
});
export const putRestoreNetworktypeSuccess = (networkInfo) => ({
  type: actionTypes.PUT_RESTORE_NETWORKTYPE_SUCCESS,
  payload: { networkInfo },
});
export const putRestoreNetworktypeeFailure = () => ({
  type: actionTypes.PUT_RESTORE_NETWORKTYPE_FAILURE,
});

//** put update customer  */
export const putNetworktype = (networkInfo) => ({
  type: actionTypes.PUT_NETWROKTYPE,
  payload: { networkInfo },
});
export const putNetworktypeSuccess = (networkInfo) => ({
  type: actionTypes.PUT_NETWROKTYPE_SUCCESS,
  payload: { networkInfo },
});
export const putNetworktypeFailure = () => ({
  type: actionTypes.PUT_NETWROKTYPE_FAILURE,
});
