import { call, takeEvery, put, all, fork } from "redux-saga/effects";
import * as actionTypes from "../actionTypes";

//**  services
import * as services from "../../services/placeTypesServices";

//**  actions
import * as actions from "./actions";

import { toast } from "react-toastify";

//** Get user list
function* getPlaceTypes() {
  try {
    const response = yield call(services.getPlaceTypeListAsync);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.getPlaceTypesListSuccess(data));
    } else {
      toast.error(data.message);
      yield put(actions.getPlaceTypesListFailure());
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.getPlaceTypesListFailure());
    toast.error(data.message);
    console.log(error);
  }
}

//** post  new place type
function* postNewPlaceType({ payload }) {
  let { placeTypeInfo } = payload;
  try {
    const response = yield call(services.postNewPlaceTypeAsync, placeTypeInfo);
    const { data } = response;

    console.log(data);
    if (response.status === 200) {
      yield put(actions.postNewPlaceTypeSuccess(data));
      toast.success("Your place type created successfully");
    } else {
      yield put(actions.postNewPlaceTypeFailure());
      toast.error(data.message);
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.postNewPlaceTypeFailure());
    toast.error(data.message);
  }
}

// ** delete placeType
function* deletePlaceType({ payload }) {
  let { placeTypeId } = payload;
  try {
    const response = yield call(services.deletePlaceTypeAsync, placeTypeId);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.deletePlaceTypeSuccess(placeTypeId));
      toast.success("Your place type deleted successfully");
    } else {
      yield put(actions.deletePlaceTypeFailure());
      toast.error(data.message);
    }
  } catch (error) {
    yield put(actions.deletePlaceTypeFailure());
    toast.error(error.message);
    console.log(error);
  }
}

//** Get deleted user list
function* getDeletedPlaceTypeList() {
  try {
    const response = yield call(services.getDeletedPlaceTypeListAsync);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.getDeletedPlaceTypeListSuccess(data));
    } else {
      toast.error(data.message);
      yield put(actions.getDeletedPlaceTypeListFailure([]));
    }
  } catch (error) {
    yield put(actions.getDeletedPlaceTypeListFailure([]));
    toast.error(error.message);
    console.log(error);
  }
}

// ** restore place type
function* putRestorePlaceType({ payload }) {
  let { placeTypeId } = payload;
  try {
    const response = yield call(
      services.putRestorePlaceTypeUrlAsync,
      placeTypeId
    );
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.putRestorePlaceTypeSuccess(placeTypeId));
      yield put(actions.openDeletedPlaceTypeListModal(false));
      yield put(actions.getPlaceTypesList());

      toast.success("Your place type restored successfully");
    } else {
      yield put(actions.putRestorePlaceTypeFailure());
      toast.error(data.message);
    }
  } catch (error) {
    yield put(actions.putRestorePlaceTypeFailure());
    toast.error(error.message);
    console.log(error);
  }
}

//** put update  customer
function* putPlaceType({ payload }) {
  let { placeTypeInfo } = payload;

  try {
    const response = yield call(services.putPlaceTypeAsync, placeTypeInfo);
    const { data } = response;

    console.log(response);
    if (response.status === 200) {
      yield put(actions.putPlaceTypeSuccess(data));
      toast.success("Your place type was updated successfully");
    } else {
      yield put(actions.putPlaceTypeFailure());
      toast.error(data.message);
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.putPlaceTypeFailure());
    toast.error(data.message);
  }
}

// *************************** watchers ******************************
export function* watchGetPlaceTypes() {
  yield takeEvery(actionTypes.GET_PLACE_TYPES_LIST, getPlaceTypes);
}
export function* watchPostNewPlaceType() {
  yield takeEvery(actionTypes.POST_PLACE_TYPE, postNewPlaceType);
}
export function* watchDeletePlaceType() {
  yield takeEvery(actionTypes.DELETE_PLACE_TYPE, deletePlaceType);
}
export function* watchGetDeletedPlaceTypeList() {
  yield takeEvery(
    actionTypes.GET_DELETED_PLACE_TYPE_LIST,
    getDeletedPlaceTypeList
  );
}
export function* watchPutRestorePlaceType() {
  yield takeEvery(actionTypes.PUT_RESTORE_PLACE_TYPE, putRestorePlaceType);
}
export function* watchPutPlaceType() {
  yield takeEvery(actionTypes.PUT_PLACE_TYPE, putPlaceType);
}

//Fork
export default function* rootSaga() {
  yield all([
    fork(watchGetPlaceTypes),
    fork(watchPostNewPlaceType),
    fork(watchDeletePlaceType),
    fork(watchGetDeletedPlaceTypeList),
    fork(watchPutRestorePlaceType),
    fork(watchPutPlaceType),
  ]);
}
