import React from "react";

const FullPageMapStudioLayOut = ({ children }) => {
  return (
    <div>
      <div className="app-content">
        <div className="content-wrapper">
          <div className="content-body">
            <main className="main w-100">{children}</main>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullPageMapStudioLayOut;
