import React from "react";

const UserIcon = () => {
  return (
    <svg
      width="45"
      height="43"
      viewBox="0 0 45 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="45" height="43" rx="12" fill="#F7F7F8" />
      <rect width="45" height="43" rx="12" fill="#F1F4FA" />
      <path
        d="M22.5 21.5003C26.6438 21.5003 30 18.2932 30 14.3337C30 10.3741 26.6438 7.16699 22.5 7.16699C18.3562 7.16699 15 10.3741 15 14.3337C15 18.2932 18.3562 21.5003 22.5 21.5003ZM22.5 25.0837C17.4937 25.0837 7.5 27.4845 7.5 32.2503V35.8337H37.5V32.2503C37.5 27.4845 27.5063 25.0837 22.5 25.0837Z"
        fill="#323232"
      />
    </svg>
  );
};

export default UserIcon;
