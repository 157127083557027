import * as servicesUrl from "../assets/data/urlConfig";
import axiosApiInctance from "../services/axiosInterceptor";

// ** get Places List Async
export const getPlacesListAsync = async () => {
  return await axiosApiInctance.get(`${servicesUrl.getPlacesListUrl}`);
};

// ** get Places Geo Json Async
export const getPlacesGeoJsonAsync = async (placeInfo) => {
  return await axiosApiInctance.get(
    `${servicesUrl.getPlaceGeoJsonUrl(placeInfo)}`
  );
};

// ** get Places Geo Json Async
export const getPoiGeoJsonAsync = async (placeInfo) => {
  return await axiosApiInctance.get(
    `${servicesUrl.getPoiGeoJsonUrl(placeInfo)}`
  );
};

// ** get Place Center Coordination Async
export const getPlaceCenterCoordinationAsync = async (placeInfo) => {
  return await axiosApiInctance.get(
    `${servicesUrl.getPlaceCenterCoordinationUrl(placeInfo)}`
  );
};

// ** get Places List Async
export const getPlaceSettingsAsync = async (settingInfo) => {
  return await axiosApiInctance.get(
    `${servicesUrl.getPlaceSettingsUrl(settingInfo)}`
  );
};

// ** putPlaceSettingsAsync
export const putPlaceSettingsAsync = async (settingInfo) => {
  const { zoomLevel } = settingInfo;
  return await axiosApiInctance.put(
    `${servicesUrl.putPlaceSettingsUrl(settingInfo)}`,
    {
      zoomLevel,
    }
  );
};

// ** get Place Center Coordination Async
export const deletePlaceSettingsAsync = async (settingInfo) => {
  return await axiosApiInctance.delete(
    `${servicesUrl.deletePlaceSettingsUrl(settingInfo)}`
  );
};

// ** get Place live status Async
export const getPlaceLiveStatusAsync = async (placeInfo) => {
  return await axiosApiInctance.get(
    `${servicesUrl.getPlaceLiveStatusUrl(placeInfo)}`
  );
};

// ** get Place live status Async
export const postPlacePublishGisAsync = async (placeInfo) => {
  return await axiosApiInctance.post(
    `${servicesUrl.postPlacePublishGisUrl(placeInfo)}`
  );
};

// ************ post poi content
export const postPoiContentAsync = async (poiInfo) => {
  const { language_code, text } = poiInfo;

  return await axiosApiInctance.post(
    `${servicesUrl.postPoiContentUrl(poiInfo)}`,
    {
      language_code,
      text,
    }
  );
};
export const deletePoiContentAsync = async (poiInfo) => {
  const { language_code } = poiInfo;

  return await axiosApiInctance.delete(
    `${servicesUrl.deletePoiContentUrl(poiInfo)}`,
    {
      language_code,
    }
  );
};
