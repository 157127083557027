import React, { useEffect, useState } from "react";

import { history } from "../history";

import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import * as menuItemsActions from "../redux/menuItems/actions";
import * as settingsActions from "../redux/settings/actions";

import ReactTooltip from "react-tooltip";

//**dev extreme */
import Drawer from "devextreme-react/drawer";
import { Button } from "devextreme-react/button";

import { MdDragIndicator } from "react-icons/md";
import { FiTrash, FiEdit2, FiEdit3 } from "react-icons/fi";
import { BsTranslate } from "react-icons/bs";
import { IoIosClose } from "react-icons/io";

import { FaTrash } from "react-icons/fa";
import { CgMenuLeftAlt } from "react-icons/cg";
import { HiMenuAlt2 } from "react-icons/hi";

import themeConfigs from "../configs/themeConfigs";
import ListSkleton from "../components/skeleton/ListSkleton";

// ** loader
import ClipLoader from "react-spinners/ClipLoader";
import { isMobile } from "react-device-detect";
import classnames from "classnames";
// ** country list
import { languageList } from "../constants/countryList";

import ResponsiveBox, {
  Row,
  Col,
  Item,
  Location,
} from "devextreme-react/responsive-box";
import { TextBox } from "devextreme-react/text-box";
import CustomStore from "devextreme/data/custom_store";
import DropDownButton from "devextreme-react/drop-down-button";
import DataGrid, {
  Column,
  FilterRow,
  Scrolling,
} from "devextreme-react/data-grid";

//**  validation and schema */
import { Formik, Field, Form, ErrorMessage } from "formik";
import Popup from "devextreme-react/popup";
import ScrollView from "devextreme-react/scroll-view";
import classNames from "classnames";
import {
  postMenuItemSchema,
  postMenuItemTranslationSchema,
  putMenuItemTranslationSchema,
} from "./schemas/Schemas";

// ** country list
import * as countryList from "../constants/countryList";
import SelectBox from "devextreme-react/select-box";
import themeConfig from "../configs/themeConfigs";

const FullPageMenuItemLayout = ({ children }) => {
  const { id } = useParams();
  const params = useParams();

  const [showPostNewMenuItemModal, setShowPostNewMenuItemModal] =
    useState(false);
  const [showEditMenuItemModal, setShowEditMenuItemModal] = useState(false);
  const [
    showEditMenuItemTransalationModal,
    setShowEditMenuItemTransalationModal,
  ] = useState(false);
  const [selectedMenuItemInfoForEdit, setSelectedMenuItemInfoForEdit] =
    useState({
      title: "",
      id: "",
    });
  const [
    selectedMenuItemInfoForTranslation,
    setSelectedMenuItemInfoForTranslation,
  ] = useState({
    id: "",
    title: "",
    index: "",
  });
  const [
    selectedMenuItemInfoForTranslationEdit,
    setSelectedMenuItemInfoForTranslationEdit,
  ] = useState({
    translationId: "",
    translation: "",
  });
  const [showTranslationModal, setShowTranslationModal] = useState(false);

  // ** distpatch
  const dispatch = useDispatch();

  // ** menu items
  const mneuItemList = useSelector((state) => state.menuItems.mneuItemList);
  const getMenuItemListLoader = useSelector(
    (state) => state.menuItems.getMenuItemListLoader
  );
  const postMenuItemLoader = useSelector(
    (state) => state.menuItems.postMenuItemLoader
  );
  const deleteMenuItemLoader = useSelector(
    (state) => state.menuItems.deleteMenuItemLoader
  );
  const selectedMenuItemForDeleteInfo = useSelector(
    (state) => state.menuItems.selectedMenuItemForDeleteInfo
  );
  const putUpdateMenuItemLoader = useSelector(
    (state) => state.menuItems.putUpdateMenuItemLoader
  );
  const postMenuItemTranslationLoader = useSelector(
    (state) => state.menuItems.postMenuItemTranslationLoader
  );
  const putMenuItemTranslationLoader = useSelector(
    (state) => state.menuItems.putMenuItemTranslationLoader
  );

  // ** settigs language
  const languageListState = useSelector((state) => state.settings.languageList);

  const [menuConfig, setMenuConfig] = useState({
    opened: isMobile ? false : true,
    openedStateMode: isMobile ? "push" : "shrink",
    revealMode: "slide",
    position: "left",
  });

  const handleDeleteMenuItem = (customerId, menuItemId) => {
    dispatch(
      menuItemsActions.deleteMenuItem({
        menuItemId: menuItemId,
        customerId: customerId,
      })
    );
  };
  const handleOnclickEditButton = (editInfo) => {
    setSelectedMenuItemInfoForEdit({
      title: editInfo.title,
      id: editInfo.id,
    });
    setShowEditMenuItemModal(true);
  };

  const drawerHeader = () => {
    return (
      <div className="d-flex main-shadow justify-content-start flex-row pt-5 pb-5 pl-20">
        <h3>Menu Items List</h3>
      </div>
    );
  };

  const drawerItem = () => {
    return (
      <div
        className="pr-10 pl-10 pt-10 pb-50"
        style={{
          minHeight: "85vh",
          maxHeight: "85vh",
          overflowY: "scroll",
          // width: "400px",
        }}
      >
        {mneuItemList.map((item, index) => {
          return (
            <div>
              <ReactTooltip
                place={"top"}
                type={"light"}
                effect={"float"}
                multiline={true}
              />
              <div
                className="cursor-pointer main-shadow br-4  bg-white list d-flex justify-space-between align-item-center flex-row mb-10  pl-20 pr-20 pt-20 pb-20 "
                key={item.id}
                onClick={() => {
                  history.push(`/menu-item/${id}/${item.id}`, {
                    menuitemExcludes: item.Menuitemexcludes,
                    menuitemPlacetypes: item.Menuitemplacetypes,
                  });
                }}
                style={
                  parseInt(params.menuitemId) === parseInt(item.id)
                    ? {
                        borderLeft: `5px solid ${themeConfig.colors.primaryColor}`,
                        backgroundColor: `#eee`,
                        transform: "scale(.97)",
                        transition: "0.1s",
                      }
                    : {
                        borderLeft: `2px solid transparent`,
                      }
                }
              >
                <div className="d-flex justify-content-center">
                  <MdDragIndicator size={20} color={"#000"} />
                </div>
                <div className="ml-10">{item.title}</div>
                <div className="flex-row justify-content-center d-flex align-item-center">
                  <div
                    className="ml-5 mr-5 pr-5 pl-5 cursor-pointer"
                    data-tip="translate"
                    onClick={() => {
                      setShowTranslationModal(true);
                      setSelectedMenuItemInfoForTranslation({
                        id: item.id,
                        title: item.title,
                        index: index,
                      });
                    }}
                  >
                    <BsTranslate
                      size={20}
                      color={themeConfigs.colors.primaryColor}
                    />
                  </div>
                  <div
                    className="ml-5 mr-5 pr-5 pl-5 cursor-pointer"
                    data-tip="Delete"
                    onClick={() => handleDeleteMenuItem(id, item.id)}
                  >
                    {deleteMenuItemLoader === true &&
                    selectedMenuItemForDeleteInfo.menuItemId === item.id ? (
                      <ClipLoader
                        color={themeConfigs.colors.dangerColor}
                        loading={true}
                        size={20}
                        margin={2}
                      />
                    ) : (
                      <FiTrash
                        size={20}
                        color={themeConfigs.colors.dangerColor}
                      />
                    )}
                  </div>
                  <div
                    className="ml-5 mr-5 pr-5 pl-5 cursor-pointer"
                    data-tip="Edit"
                    onClick={() =>
                      handleOnclickEditButton({
                        id: item.id,
                        title: item.title,
                      })
                    }
                  >
                    <FiEdit2
                      size={20}
                      color={themeConfigs.colors.primaryColor}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const drawerFooter = () => {
    return (
      <div className=" d-flex flex-row main-shadow justify-content-center align-item-center drawer-footer bg-white w-100">
        <Button
          className="mt-10 w-100 ml-10 mr-10 mb-5"
          stylingMode="contained"
          type="default"
          height={50}
          icon={"plus"}
          text={"Create New Menu Item"}
          onClick={() => {
            setShowPostNewMenuItemModal(true);
          }}
        />
      </div>
    );
  };

  const languageData = () => {
    return new CustomStore({
      loadMode: "raw",
      key: "id",
      load() {
        const languageListData = languageListState.map((info) => {
          let { language, id } = info;

          let tempObject = [];
          languageList.forEach((item) => {
            if (item.code === language)
              tempObject = {
                language: item.name,
                code: item.code,
                nativeName: item.nativeName,
                id: id,
              };
          });

          return tempObject;
        });

        console.log(languageListData);
        return languageListData;
      },
    });
  };
  languageData();

  const onClickToggleMenu = () => {
    setMenuConfig({
      ...menuConfig,
      opened: !menuConfig.opened,
    });
  };

  useEffect(() => {
    dispatch(menuItemsActions.getMenuItemList(id));
    dispatch(settingsActions.getLanguageList());
  }, [dispatch, id]);

  return (
    <div>
      {/* create menu item  modal */}

      <Popup
        maxWidth={550}
        height={300}
        visible={showPostNewMenuItemModal}
        showTitle={true}
        closeOnOutsideClick={true}
        showCloseButton={true}
        title={"Create New Menu Item"}
        defaultVisible={false}
        onVisibleChange={() => {
          setShowPostNewMenuItemModal(!showPostNewMenuItemModal);
        }}
      >
        <ScrollView width="100%" height="100%">
          <Formik
            initialValues={{
              title: "",
            }}
            validationSchema={postMenuItemSchema}
            onSubmit={(values) => {
              dispatch(
                menuItemsActions.postMenuItem({
                  customerId: id,
                  title: values.title,
                })
              );
            }}
          >
            {({ errors, touched, dirty, isValid, setFieldValue }) => (
              <Form className="w-100">
                <div className="pb-20">
                  <span className="text-left d-block mb-10">
                    Menu Item title
                  </span>
                  <Field
                    name="title"
                    id="title"
                    className={`${
                      errors.title && touched.title && "dx-invalid"
                    }`}
                    mode={"text"}
                    as={TextBox}
                    onValueChange={(value) => {
                      setFieldValue("title", value);
                    }}
                  />

                  <ErrorMessage
                    name="title"
                    component="div"
                    className="text-left text-danger error-field fs-14 mt-5"
                  />
                </div>

                <ResponsiveBox>
                  <Row ratio={2} />

                  <Col screen={"md lg sm xs"} />

                  <Item>
                    <Location row={0} col={0} screen={"md lg sm xs"} />
                    <Button
                      className="mt-50 ml-3"
                      stylingMode="contained"
                      type="default"
                      disabled={!isValid && !dirty}
                      width={"100%"}
                      useSubmitBehavior
                      height={42}
                      render={() => {
                        return (
                          <div className="d-flex flex-row justify-content-center align-item-center">
                            {postMenuItemLoader === true ? (
                              <ClipLoader
                                color={themeConfigs.colors.primaryColor}
                                loading={true}
                                size={20}
                                margin={2}
                              />
                            ) : (
                              ""
                            )}
                            <span
                              className={classNames("dx-button-text d-block ", {
                                "ml-20": postMenuItemLoader === true,
                              })}
                            >
                              {postMenuItemLoader === true
                                ? "Loading..."
                                : "Confirm"}
                            </span>
                          </div>
                        );
                      }}
                    />
                  </Item>
                </ResponsiveBox>
              </Form>
            )}
          </Formik>
        </ScrollView>
      </Popup>

      {/* edit  menu item modal*/}
      <Popup
        maxWidth={550}
        height={300}
        visible={showEditMenuItemModal}
        showTitle={true}
        closeOnOutsideClick={true}
        showCloseButton={true}
        title={"Edit Menu Item"}
        defaultVisible={false}
        onVisibleChange={() => {
          setShowEditMenuItemModal(!showEditMenuItemModal);
        }}
      >
        <ScrollView width="100%" height="100%">
          <Formik
            initialValues={{
              title: selectedMenuItemInfoForEdit.title,
            }}
            validationSchema={postMenuItemSchema}
            enableReinitialize
            onSubmit={(values) => {
              dispatch(
                menuItemsActions.putMenuItem({
                  customerId: id,
                  menuItemId: selectedMenuItemInfoForEdit.id,
                  title: values.title,
                })
              );
            }}
          >
            {({ errors, touched, dirty, isValid, setFieldValue }) => (
              <Form className="w-100">
                <div className="pb-20">
                  <span className="text-left d-block mb-10">
                    Menu Item title
                  </span>
                  <Field
                    name="title"
                    id="title"
                    className={`${
                      errors.title && touched.title && "dx-invalid"
                    }`}
                    mode={"text"}
                    as={TextBox}
                    onValueChange={(value) => {
                      setFieldValue("title", value);
                    }}
                  />

                  <ErrorMessage
                    name="title"
                    component="div"
                    className="text-left text-danger error-field fs-14 mt-5"
                  />
                </div>

                <ResponsiveBox>
                  <Row ratio={2} />

                  <Col screen={"md lg sm xs"} />

                  <Item>
                    <Location row={0} col={0} screen={"md lg sm xs"} />
                    <Button
                      className="mt-50 ml-3"
                      stylingMode="contained"
                      type="default"
                      disabled={!isValid && !dirty}
                      width={"100%"}
                      useSubmitBehavior
                      height={42}
                      render={() => {
                        return (
                          <div className="d-flex flex-row justify-content-center align-item-center">
                            {putUpdateMenuItemLoader === true ? (
                              <ClipLoader
                                color={themeConfigs.colors.primaryColor}
                                loading={true}
                                size={20}
                                margin={2}
                              />
                            ) : (
                              ""
                            )}
                            <span
                              className={classNames("dx-button-text d-block ", {
                                "ml-20": putUpdateMenuItemLoader === true,
                              })}
                            >
                              {putUpdateMenuItemLoader === true
                                ? "Loading..."
                                : "Confirm"}
                            </span>
                          </div>
                        );
                      }}
                    />
                  </Item>
                </ResponsiveBox>
              </Form>
            )}
          </Formik>
        </ScrollView>
      </Popup>

      {/* show translation modal */}
      <Popup
        maxWidth={550}
        height={600}
        visible={showTranslationModal}
        showTitle={true}
        closeOnOutsideClick={true}
        showCloseButton={true}
        title={"Translation Modal"}
        defaultVisible={false}
        onVisibleChange={() => {
          setShowTranslationModal(!showTranslationModal);
        }}
      >
        <ScrollView width="100%" height="100%">
          <div>
            <h4 className="mt-1">
              Set translation for &nbsp;
              <span className="fw-800 text-primary fs-16">
                {selectedMenuItemInfoForTranslation.title}
              </span>
              &nbsp; menu item
            </h4>
          </div>
          <Formik
            initialValues={{
              translation: "",
              language: "",
            }}
            validationSchema={postMenuItemTranslationSchema}
            enableReinitialize
            onSubmit={(values) => {
              const selectedLanguage = countryList.languageList.filter(
                (item) => {
                  return item.name === values.language;
                }
              );

              const { code } = selectedLanguage[0];

              dispatch(
                menuItemsActions.postMenuItemTranslation({
                  language: code,
                  menuItemId: selectedMenuItemInfoForTranslation.id,
                  translation: values.translation,
                })
              );
            }}
          >
            {({ errors, touched, dirty, isValid, setFieldValue }) => (
              <Form className="w-100">
                <ResponsiveBox>
                  <Row ratio={2} />

                  <Col ratio={0.75} screen={"md lg sm xs"} />
                  <Col ratio={0.75} screen={"md lg sm xs"} />
                  <Col ratio={0.5} screen={"md lg sm xs"} />

                  <Item>
                    <Location row={0} col={1} screen={"md lg sm xs"} />
                    <div>
                      <span className="text-left d-block mb-10">
                        translation
                      </span>
                      <Field
                        name="translation"
                        id="translation"
                        className={`${
                          errors.translation &&
                          touched.translation &&
                          "dx-invalid"
                        }`}
                        mode={"text"}
                        as={TextBox}
                        onValueChange={(value) => {
                          setFieldValue("translation", value);
                        }}
                      />

                      <ErrorMessage
                        name="translation"
                        component="div"
                        className="text-left text-danger error-field fs-14 mt-5"
                      />
                    </div>
                  </Item>

                  <Item>
                    <Location row={0} col={0} screen={"md lg sm xs"} />
                    <Location row={0} col={1} screen={"md lg sm xs"} />
                    <div className="mr-15">
                      <span className="text-left d-block mb-10">Language</span>
                      <Field
                        name="language"
                        id="language"
                        dataSource={languageData()}
                        className={`${
                          errors.language && touched.language && "dx-invalid"
                        }`}
                        mode={"text"}
                        as={SelectBox}
                        valueExpr="language"
                        onValueChanged={(e) => {
                          setFieldValue("language", e.value);
                        }}
                        placeholder="Type your language name..."
                        itemRender={(data) => {
                          const { nativeName, language } = data;

                          return (
                            <div className="d-flex flex-row  justify-space-between">
                              <div>
                                <span className="fs-16">{language}</span>
                              </div>
                              <div>
                                <span className="fs-16 fw-800">
                                  {nativeName}
                                </span>
                              </div>
                            </div>
                          );
                        }}
                        displayExpr={"language"}
                      />
                    </div>
                    <ErrorMessage
                      name="language"
                      component="div"
                      className="text-left text-danger error-field fs-14 mt-5"
                    />
                  </Item>

                  <Item>
                    <Location row={0} col={2} screen={"md lg sm xs"} />
                    <Button
                      className="mt-30 ml-5"
                      stylingMode="contained"
                      type="default"
                      disabled={!isValid && !dirty}
                      width={"100%"}
                      useSubmitBehavior
                      height={42}
                      render={() => {
                        return (
                          <div className="d-flex flex-row justify-content-center align-item-center">
                            {postMenuItemTranslationLoader === true ? (
                              <ClipLoader
                                color={themeConfigs.colors.primaryColor}
                                loading={true}
                                size={20}
                                margin={2}
                              />
                            ) : (
                              ""
                            )}
                            <span
                              className={classNames("dx-button-text d-block ", {
                                "ml-20": postMenuItemTranslationLoader === true,
                              })}
                            >
                              {postMenuItemTranslationLoader === true
                                ? "Loading..."
                                : "Confirm"}
                            </span>
                          </div>
                        );
                      }}
                    />
                  </Item>
                </ResponsiveBox>
              </Form>
            )}
          </Formik>
          <hr className="hr mt-20 mb-20" />
          <div>
            <DataGrid
              height={window.innerHeight - 300}
              dataSource={
                mneuItemList[selectedMenuItemInfoForTranslation.index]
                  ?.Menuitemtranslations
              }
              showBorders={false}
              columnAutoWidth={false}
              searchPanel
              hoverStateEnabled={false}
              className="cursor-pointer"
              loadPanel={false}
            >
              <FilterRow visible={true} />
              <Scrolling mode="infinite" />

              <Column
                caption={"Translation"}
                dataField={"translation"}
                cssClass="cursor-pointer"
                cellRender={(cellInfo) => {
                  const { translation } = cellInfo.data;
                  return (
                    <div className="d-flex flex-row align-item-center">
                      <span>{translation}</span>
                    </div>
                  );
                }}
              />
              <Column
                caption={"Language"}
                dataField={"language"}
                cssClass="cursor-pointer"
                cellRender={(cellInfo) => {
                  const { language } = cellInfo.data;
                  return (
                    <div className="d-flex flex-row align-item-center">
                      <span>{language}</span>
                    </div>
                  );
                }}
              />

              <Column
                width={190}
                caption="Actions"
                type="buttons"
                cellRender={(cellInfo) => {
                  const translationId = cellInfo.data.id;
                  const { translation } = cellInfo.data;
                  return (
                    <Button
                      type="text"
                      onClick={(e) => {
                        e.stopPropagination();
                      }}
                    >
                      <DropDownButton
                        useSelectMode={false}
                        width={150}
                        icon={"more"}
                        activeStateEnabled={false}
                        items={[
                          {
                            id: 2,
                            name: "Delete",
                            icon: function () {
                              return (
                                <FaTrash color={this.textColor} size={12} />
                              );
                            },
                            link: `/customers/${id}`,
                            bgColor: "#FFF9FB",
                            textColor: "#FF69B4",
                            onClick: function () {
                              dispatch(
                                menuItemsActions.deleteMenuItemTranslation({
                                  menuItemId:
                                    selectedMenuItemInfoForTranslation.id,
                                  translationId: translationId,
                                  customerId: id,
                                })
                              );
                            },
                          },
                          {
                            id: 3,
                            name: "Edit",
                            icon: function () {
                              return (
                                <FiEdit3 color={this.textColor} size={12} />
                              );
                            },
                            link: `/customers/${id}`,
                            bgColor: "#F5F5FE",
                            textColor: "#3A36DB",
                            onClick: function () {
                              setShowEditMenuItemTransalationModal(true);
                              setSelectedMenuItemInfoForTranslationEdit({
                                translationId: translationId,
                                translation: translation,
                              });
                            },
                          },
                        ]}
                        stylingMode={"text"}
                        dropDownContentRender={(items) => {
                          return items.map((item) => {
                            return (
                              <div
                                key={item.id}
                                className="cursor-pointer d-flex flex-row align-item-center br-4 mt-10 mr-10 mb-10 ml-10 pt-10 pb-10 pl-5 pr-2"
                                style={{
                                  backgroundColor: item.bgColor,
                                }}
                                onClick={() => item.onClick()}
                              >
                                {item.icon()}
                                <span
                                  className="ml-10 d-block fs-12"
                                  style={{ color: item.textColor }}
                                >
                                  {item.name}
                                </span>
                              </div>
                            );
                          });
                        }}
                        keyExpr="id"
                        showArrowIcon={false}
                        hoverStateEnabled={false}
                      />
                    </Button>
                  );
                }}
              />
            </DataGrid>
          </div>
        </ScrollView>
      </Popup>

      {/* edit translation modal */}
      <Popup
        width={550}
        height={300}
        visible={showEditMenuItemTransalationModal}
        showTitle={true}
        closeOnOutsideClick={true}
        showCloseButton={true}
        title={"Edit Menu Item Translation"}
        defaultVisible={false}
        onVisibleChange={() => {
          setShowEditMenuItemTransalationModal(
            !showEditMenuItemTransalationModal
          );
        }}
      >
        <ScrollView width="100%" height="100%">
          <Formik
            initialValues={{
              translation: selectedMenuItemInfoForTranslationEdit.translation,
            }}
            validationSchema={putMenuItemTranslationSchema}
            enableReinitialize
            onSubmit={(values) => {
              dispatch(
                menuItemsActions.putMenuItemTranslation({
                  translationId:
                    selectedMenuItemInfoForTranslationEdit.translationId,
                  translation: values.translation,
                  menuItemId: selectedMenuItemInfoForTranslation.id,
                  customerId: id,
                })
              );
            }}
          >
            {({ errors, touched, dirty, isValid, setFieldValue }) => (
              <Form className="w-100">
                <div className="pb-20">
                  <span className="text-left d-block mb-10">translation</span>
                  <Field
                    name="translation"
                    id="translation"
                    className={`${
                      errors.translation && touched.translation && "dx-invalid"
                    }`}
                    mode={"text"}
                    as={TextBox}
                    onValueChange={(value) => {
                      setFieldValue("translation", value);
                    }}
                  />

                  <ErrorMessage
                    name="translation"
                    component="div"
                    className="text-left text-danger error-field fs-14 mt-5"
                  />
                </div>

                <ResponsiveBox>
                  <Row ratio={2} />

                  <Col screen={"md lg sm xs"} />

                  <Item>
                    <Location row={0} col={0} screen={"md lg sm xs"} />
                    <Button
                      className="mt-50 ml-3"
                      stylingMode="contained"
                      type="default"
                      disabled={!isValid && !dirty}
                      width={"100%"}
                      useSubmitBehavior
                      height={42}
                      render={() => {
                        return (
                          <div className="d-flex flex-row justify-content-center align-item-center">
                            {putMenuItemTranslationLoader === true ? (
                              <ClipLoader
                                color={themeConfigs.colors.primaryColor}
                                loading={true}
                                size={20}
                                margin={2}
                              />
                            ) : (
                              ""
                            )}
                            <span
                              className={classNames("dx-button-text d-block ", {
                                "ml-20": putMenuItemTranslationLoader === true,
                              })}
                            >
                              {putMenuItemTranslationLoader === true
                                ? "Loading..."
                                : "Confirm"}
                            </span>
                          </div>
                        );
                      }}
                    />
                  </Item>
                </ResponsiveBox>
              </Form>
            )}
          </Formik>
        </ScrollView>
      </Popup>

      <div className="menu-item-wrapper">
        <div className="app-bar d-flex flex-row justify-space-between align-item-center mb-10">
          <div
            className={
              "cursor-pointer d-flex flex-row justify-content-center align-item-center "
            }
          >
            <div onClick={onClickToggleMenu} className={"cursor-pointer"}>
              {!menuConfig.opened ? (
                <HiMenuAlt2 color="#f5f4f4" fontSize={30} />
              ) : (
                <CgMenuLeftAlt color="#f5f4f4" fontSize={30} />
              )}
            </div>
            <div onClick={() => history.push(`/customers/${id}`)}>
              <IoIosClose size={50} color={"#fff"} />
            </div>
          </div>

          <div>
            <h3 className="text-white">Manage Menu Items </h3>
          </div>
          <div></div>
        </div>
        <div className="content-wrapper">
          <Drawer
            render={() => {
              return (
                <div className="d-flex flex-column pt-60">
                  {getMenuItemListLoader ? (
                    <ListSkleton />
                  ) : (
                    <>
                      <div>{drawerHeader()}</div>
                      <div>{drawerItem()}</div>
                      <div>{drawerFooter()}</div>
                    </>
                  )}
                </div>
              );
            }}
            opened={menuConfig.opened}
            openedStateMode={menuConfig.openedStateMode}
            position={menuConfig.position}
            revealMode={menuConfig.revealMode}
          >
            <div
              className={classnames("bg-grey vh-85 pt-75 pb-20", {
                "pl-10 pr-10": isMobile,
                "pl-30 pr-30": !isMobile,
              })}
              style={{ minHeight: "100vh" }}
            >
              {children}
            </div>
          </Drawer>
        </div>
      </div>
    </div>
  );
};

export default FullPageMenuItemLayout;
