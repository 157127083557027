import * as actionTypes from "../actionTypes";

const intialState = {
  walkwaysList: [],

  getWalkwaysListLoader: false,
};

const walkways = (state = intialState, action) => {
  switch (action.type) {
    //**GET_WALKWAYS_LIST */
    case actionTypes.GET_WALKWAYS_LIST: {
      return {
        ...state,
        getWalkwaysListLoader: true,
      };
    }
    case actionTypes.GET_WALKWAYS_LIST_SUCCESS: {
      return {
        ...state,
        walkwaysList: action.payload.walkwaysList,
        getWalkwaysListLoader: false,
      };
    }
    case actionTypes.GET_WALKWAYS_LIST_FAILURE: {
      return {
        ...state,
        getWalkwaysListLoader: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default walkways;
