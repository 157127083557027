import { call, takeEvery, put, all, fork } from "redux-saga/effects";
import * as actionTypes from "../actionTypes";

//**  services
import * as services from "../../services/categoriesServices";

//**  actions
import * as actions from "./actions";

import { toast } from "react-toastify";

//** Get category list
function* getCategoriesList() {
  try {
    const response = yield call(services.getCategoriesListAsync);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.getCategoriesListSuccess(data));
    } else {
      toast.error(data.message);
      yield put(actions.getCategoriesListFailure());
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.getCategoriesListFailure());
    toast.error(data.message);
    console.log(error);
  }
}

//** Get category by id
function* getCategoryById({ payload }) {
  let { categoryInfo } = payload;
  try {
    const response = yield call(services.getCategoryByIdAsync, categoryInfo);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.getCategoryByIdSuccess(data));
    } else {
      toast.error(data.message);
      yield put(actions.getCategoryByIdFailure());
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.getCategoryByIdFailure());
    toast.error(data.message);
    console.log(error);
  }
}

//** post  category
function* postCategory({ payload }) {
  let { categoryInfo } = payload;
  try {
    const response = yield call(services.postCategoryAsync, categoryInfo);
    const { data } = response;

    console.log(data);
    if (response.status === 200) {
      yield put(actions.postCategorySuccess(data));
      toast.success("Your category created successfully");
    } else {
      yield put(actions.postCategoryFailure());
      toast.error(data.message);
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.postCategoryFailure());
    toast.error(data.message);
  }
}

//** post  category
function* postSubcategory({ payload }) {
  let { categoryInfo } = payload;
  try {
    const response = yield call(services.postSubcategoryAsync, categoryInfo);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.postSubcategorySuccess(data.data));
      toast.success("Your subcategory created successfully");
    } else {
      yield put(actions.postSubcategoryFailure());
      toast.error(data.message);
    }
  } catch (error) {
    const { message } = error.response.data;
    yield put(actions.postSubcategoryFailure());
    console.log(error);
    toast.error(message);
  }
}

// ** delete category
function* deleteSubcategory({ payload }) {
  let { categoryInfo } = payload;
  try {
    const response = yield call(services.deleteSubcategoryAsync, categoryInfo);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.deleteSubcategorySuccess(categoryInfo));
      toast.success("Your subcategory deleted successfully");
    } else {
      yield put(actions.deleteSubcategoryFailure());
      toast.error(data.message);
    }
  } catch (error) {
    const { message } = error.response.data;
    yield put(actions.deleteSubcategoryFailure());
    toast.error(message);
    console.log(error);
  }
}

// ** delete category
function* deleteCategory({ payload }) {
  let { categoryInfo } = payload;
  try {
    const response = yield call(services.deleteCategoryAsync, categoryInfo);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.deleteCategorySuccess(categoryInfo));
      toast.success("Your category deleted successfully");
    } else {
      yield put(actions.deleteCategoryFailure());
      toast.error(data.message);
    }
  } catch (error) {
    const { data } = error.response;
    yield put(actions.deleteCategoryFailure());
    toast.error(data.message);
    console.log(error);
  }
}

// ** put category
function* putCategory({ payload }) {
  let { categoryInfo } = payload;
  try {
    const response = yield call(services.putCategoryAsync, categoryInfo);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.putCategorySuccess(categoryInfo));
      toast.success("Your category updated successfully");
    } else {
      yield put(actions.putCategoryFailure());
      toast.error(data.message);
    }
  } catch (error) {
    const { data } = error.response;
    yield put(actions.putCategoryFailure());
    toast.error(data.message);
    console.log(error);
  }
}

// ** put sub category
function* putSubcategory({ payload }) {
  let { categoryInfo } = payload;
  try {
    const response = yield call(services.putSubcategoryAsync, categoryInfo);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.putSubcategorySuccess(data.data));
      toast.success("Your sub category updated successfully");
    } else {
      yield put(actions.putSubcategoryFailure());
      toast.error(data.message);
    }
  } catch (error) {
    const { message } = error.response.data;
    yield put(actions.putSubcategoryFailure());
    toast.error(message);
    console.log(error);
  }
}

// ** restore place type
function* putRestoreCategory({ payload }) {
  let { categoryInfo } = payload;

  try {
    const response = yield call(services.putRestorCategoryAsync, categoryInfo);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.putRestoreCategorySuccess(categoryInfo));
      yield put(actions.openDeletedCategoriesModal(false));
      yield put(actions.getCategoriesList());

      toast.success("Your category restored successfully");
    } else {
      yield put(actions.putRestoreCategoryFailure());
      toast.error(data.message);
    }
  } catch (error) {
    yield put(actions.putRestoreCategoryFailure());
    toast.error(error.message);
    console.log(error);
  }
}

// ** delete category
function* putRestoreSubcategory({ payload }) {
  let { categoryInfo } = payload;
  console.log(categoryInfo);
  try {
    const response = yield call(
      services.putRestorSubcategoryAsync,
      categoryInfo
    );
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.putRestoreSubcategorySuccess(categoryInfo));
      toast.success("Your subcategory restored successfully");
    } else {
      yield put(actions.putRestoreSubcategoryFailure());
      toast.error(data.message);
    }
  } catch (error) {
    const { message } = error.response.data;
    yield put(actions.putRestoreSubcategoryFailure());
    toast.error(message);
    console.log(error);
  }
}

//** Get deleted categories list
function* getDeletedCategoriesList() {
  try {
    const response = yield call(services.getDeletedCategoriesListAsync);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.getDeletedCategoriesListSuccess(data));
    } else {
      toast.error(data.message);
      yield put(actions.getDeletedCategoriesListFailure());
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.getDeletedCategoriesListFailure());
    toast.error(data.message);
    console.log(error);
  }
}

// ** post category translation
function* postCategoryTranslation({ payload }) {
  let { categoryInfo } = payload;

  try {
    const response = yield call(
      services.postCategoryTranslationAsync,
      categoryInfo
    );
    const { data } = response;

    if (response.status === 200) {
      yield put(
        actions.postCategoryTranslationSuccess({
          categoryId: categoryInfo.categoryId,
          data: data,
        })
      );
      toast.success("Your category translation added successfully");
    } else {
      yield put(actions.postCategoryTranslationFailure());
      toast.error(data.message);
    }
  } catch (error) {
    const { data } = error.response;
    yield put(actions.postCategoryTranslationFailure());
    toast.error(data?.message);
    console.log(error);
  }
}

// ** post category translation
function* postSubcategoryTranslation({ payload }) {
  let { categoryInfo } = payload;

  try {
    const response = yield call(
      services.postSubcategoryTranslationAsync,
      categoryInfo
    );
    const { data } = response;

    if (response.status === 200) {
      yield put(
        actions.postSubcategoryTranslationSuccess({
          categoryId: categoryInfo.categoryId,
          subcategoryId: categoryInfo.subcategoryId,
          data: data,
        })
      );
      toast.success("Your sub category translation added successfully");
    } else {
      yield put(actions.postSubcategoryTranslationFailure());
      toast.error(data.message);
    }
  } catch (error) {
    const { data } = error.response;
    yield put(actions.postSubcategoryTranslationFailure());
    toast.error(data.message);
    console.log(error);
  }
}

// ** delete category
function* deleteCategoryTranslation({ payload }) {
  let { categoryInfo } = payload;
  try {
    const response = yield call(
      services.deleteCategoryTranslationAsync,
      categoryInfo
    );
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.deleteCategoryTranslationSuccess(categoryInfo));

      toast.success("Your translation deleted successfully");
    } else {
      yield put(actions.deleteCategoryTranslationFailure());
      toast.error(data.message);
    }
  } catch (error) {
    const { data } = error.response;
    yield put(actions.deleteCategoryTranslationFailure());
    toast.error(data.message);
    console.log(error);
  }
}

//
// ** deleteSubcategoryTranslation
function* deleteSubcategoryTranslation({ payload }) {
  let { categoryInfo } = payload;
  try {
    const response = yield call(
      services.deleteSubcategoryTranslationAsync,
      categoryInfo
    );
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.deleteSubcategoryTranslationSuccess(categoryInfo));

      toast.success("Your translation deleted successfully");
    } else {
      yield put(actions.deleteSubcategoryTranslationFailure());
      toast.error(data.message);
    }
  } catch (error) {
    const { data } = error.response;
    yield put(actions.deleteSubcategoryTranslationFailure());
    toast.error(data.message);
    console.log(error);
  }
}

// ** putSubcategoryTranslation
function* putSubcategoryTranslation({ payload }) {
  let { categoryInfo } = payload;
  console.log(categoryInfo);
  try {
    const response = yield call(
      services.putSubcategoryTranslationAsync,
      categoryInfo
    );
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.putSubcategoryTranslationSuccess(categoryInfo));

      toast.success("Your translation updated successfully");
    } else {
      yield put(actions.putSubcategoryTranslationFailure());
      toast.error(data.message);
    }
  } catch (error) {
    const { data } = error.response;
    yield put(actions.putSubcategoryTranslationFailure());
    toast.error(data.message);
    console.log(error);
  }
}

// ** put  category translation
function* putCategoryTranslation({ payload }) {
  let { categoryInfo } = payload;
  try {
    const response = yield call(
      services.putCategoryTranslationAsync,
      categoryInfo
    );
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.putCategoryTranslationSuccess(categoryInfo));

      toast.success("Your translation updated successfully");
    } else {
      yield put(actions.putCategoryTranslationFailure());
      toast.error(data.message);
    }
  } catch (error) {
    const { data } = error.response;
    yield put(actions.putCategoryTranslationFailure());
    toast.error(data.message);
    console.log(error);
  }
}

// *************************** watchers ******************************
export function* watchGetCategoriesList() {
  yield takeEvery(actionTypes.GET_CATEGORIES_LIST, getCategoriesList);
}
export function* watchGetCategoryByIdList() {
  yield takeEvery(actionTypes.GET_CATEGORY_BY_ID, getCategoryById);
}
export function* watchPostCategory() {
  yield takeEvery(actionTypes.POST_CATEGORY, postCategory);
}
export function* watchDeleteCategory() {
  yield takeEvery(actionTypes.DELETE_CATEGORY, deleteCategory);
}
export function* watchPutCategory() {
  yield takeEvery(actionTypes.PUT_CATEGORY, putCategory);
}
export function* watchGetDeletedCategoriesList() {
  yield takeEvery(
    actionTypes.GET_DELETED_CATEGORIES_LIST,
    getDeletedCategoriesList
  );
}
export function* watchPutRestoreCategory() {
  yield takeEvery(actionTypes.PUT_RESTORE_CATEGORY, putRestoreCategory);
}
export function* watchPostCategoryTranslation() {
  yield takeEvery(
    actionTypes.POST_CATEGORY_TRANSLATION,
    postCategoryTranslation
  );
}
export function* watchDeleteCategoryTranslation() {
  yield takeEvery(
    actionTypes.DELETE_CATEGORY_TRANSLATION,
    deleteCategoryTranslation
  );
}
export function* watchPutCategoryTranslation() {
  yield takeEvery(actionTypes.PUT_CATEGORY_TRANSLATION, putCategoryTranslation);
}
export function* watchPostSubcategory() {
  yield takeEvery(actionTypes.POST_SUBCATEGORY, postSubcategory);
}
export function* watchDeleteSubcategory() {
  yield takeEvery(actionTypes.DELETE_SUBCATEGORY, deleteSubcategory);
}
export function* watchPutRestoreSubcategory() {
  yield takeEvery(actionTypes.PUT_RESTORE_SUBCATEGORY, putRestoreSubcategory);
}
export function* watchPutSubcategory() {
  yield takeEvery(actionTypes.PUT_SUBCATEGORY, putSubcategory);
}
export function* watchPostSubcategoryTranslation() {
  yield takeEvery(
    actionTypes.POST_SUBCATEGORY_TRANSLATION,
    postSubcategoryTranslation
  );
}
export function* watchDeleteSubcategoryTranslation() {
  yield takeEvery(
    actionTypes.DELETE_SUBCATEGORY_TRANSLATION,
    deleteSubcategoryTranslation
  );
}
export function* watchPutSubcategoryTranslation() {
  yield takeEvery(
    actionTypes.PUT_SUBCATEGORY_TRANSLATION,
    putSubcategoryTranslation
  );
}

//Fork
export default function* rootSaga() {
  yield all([
    fork(watchGetCategoriesList),
    fork(watchPostCategory),
    fork(watchDeleteCategory),
    fork(watchPutCategory),
    fork(watchGetDeletedCategoriesList),
    fork(watchPutRestoreCategory),
    fork(watchPostCategoryTranslation),
    fork(watchDeleteCategoryTranslation),
    fork(watchPutCategoryTranslation),
    fork(watchGetCategoryByIdList),
    fork(watchPostSubcategory),
    fork(watchDeleteSubcategory),
    fork(watchPutRestoreSubcategory),
    fork(watchPutSubcategory),
    fork(watchPostSubcategoryTranslation),
    fork(watchDeleteSubcategoryTranslation),
    fork(watchPutSubcategoryTranslation),
  ]);
}
