import classnames from "classnames";

//** icons */
import {
  FiGrid,
  FiBriefcase,
  FiMap,
  FiSettings,
  FiUsers,
} from "react-icons/fi";

import { HiOutlineTicket } from "react-icons/hi";
import { IoMdNotificationsOutline } from "react-icons/io";
import { VscSymbolConstant } from "react-icons/vsc";
import { FaCircle } from "react-icons/fa";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";

import { appRoutes } from "../router/routesList";

const iconFontSize = 20;
const subTitleIconFontSize = 8;
const iconColor = (active) => (active ? "#605bff" : "#adadad");

const titleTextStyle = (active) =>
  classnames("fs-16", {
    "text-primary": active === true,
    "text-grey": active === false,
  });

const subtTitleTextStyle = (active) =>
  classnames("fs-14", {
    "text-primary": active === true,
    "text-grey": active === false,
  });

export const navigationConfig = [
  {
    id: "home",
    title: (active) => (
      <span className={titleTextStyle(active)}>Dashboard</span>
    ),
    type: "item",
    icon: (active) => <FiGrid size={iconFontSize} color={iconColor(active)} />,
    permissions: ["admin"],
    navLink: appRoutes.DASHBOARD,
  },
  {
    id: "customers",
    title: (active) => (
      <span className={titleTextStyle(active)}>Customers</span>
    ),
    type: "item",
    icon: (active) => (
      <FiBriefcase size={iconFontSize} color={iconColor(active)} />
    ),
    permissions: ["admin"],
    navLink: appRoutes.CUSTOMERS,
  },
  {
    id: "users",
    title: (active) => (
      <span className={titleTextStyle(active)}>User List</span>
    ),
    type: "item",
    icon: (active) => <FiUsers size={iconFontSize} color={iconColor(active)} />,
    permissions: ["admin"],
    navLink: appRoutes.USER_LIST,
  },
  {
    id: "places",
    title: (active) => <span className={titleTextStyle(active)}>Places</span>,
    type: "item",
    icon: (active) => <FiMap size={iconFontSize} color={iconColor(active)} />,
    permissions: ["admin"],
    navLink: "/places-list",
  },

  {
    id: "constants",
    title: (active) => (
      <span className={titleTextStyle(active)}>Constants</span>
    ),
    type: "item",
    icon: (active) => (
      <VscSymbolConstant size={iconFontSize} color={iconColor(active)} />
    ),
    chevronIcon: (active) => {
      return active ? (
        <HiChevronUp size={18} color={iconColor(false)} />
      ) : (
        <HiChevronDown size={18} color={iconColor(false)} />
      );
    },
    permissions: ["admin"],
    subMenuList: [
      {
        id: "networktypes",
        title: (active) => (
          <span className={subtTitleTextStyle(active)}>Network Types</span>
        ),
        type: "item",
        icon: (active) => (
          <FaCircle size={subTitleIconFontSize} color={iconColor(active)} />
        ),
        permissions: ["admin"],
        category: "constants",
        navLink: appRoutes.NETWORKTYPES_LIST,
      },
      {
        id: "pointtype",
        title: (active) => (
          <span className={subtTitleTextStyle(active)}>Point Types</span>
        ),
        type: "item",
        icon: (active) => (
          <FaCircle size={subTitleIconFontSize} color={iconColor(active)} />
        ),
        permissions: ["admin"],
        category: "constants",
        navLink: appRoutes.POINTTYPE_LIST,
      },
      {
        id: "brands",
        title: (active) => (
          <span className={subtTitleTextStyle(active)}>Brands</span>
        ),
        type: "item",
        icon: (active) => (
          <FaCircle size={subTitleIconFontSize} color={iconColor(active)} />
        ),
        permissions: ["admin"],
        category: "constants",
        navLink: appRoutes.BRANDS_LIST,
      },
      {
        id: "categories",
        title: (active) => (
          <span className={subtTitleTextStyle(active)}>Categories</span>
        ),
        type: "item",
        icon: (active) => (
          <FaCircle size={subTitleIconFontSize} color={iconColor(active)} />
        ),
        permissions: ["admin"],
        category: "constants",
        navLink: appRoutes.CATEGORIES_LIST,
      },
      {
        id: "placesTypes",
        title: (active) => (
          <span className={subtTitleTextStyle(active)}>Place Types</span>
        ),
        type: "item",
        icon: (active) => (
          <FaCircle size={subTitleIconFontSize} color={iconColor(active)} />
        ),
        permissions: ["admin"],
        category: "constants",
        navLink: appRoutes.PLACE_TYPES,
      },
    ],
  },
  {
    id: "notification",
    title: (active) => (
      <span className={titleTextStyle(active)}>Notification</span>
    ),
    type: "item",
    icon: (active) => (
      <IoMdNotificationsOutline size={iconFontSize} color={iconColor(active)} />
    ),
    permissions: ["admin"],
    category: "home",
    navLink: "/notification",
  },
  {
    id: "tickets",
    title: (active) => <span className={titleTextStyle(active)}>Tickets</span>,
    type: "item",
    icon: (active) => (
      <HiOutlineTicket size={iconFontSize} color={iconColor(active)} />
    ),
    permissions: ["admin"],
    navLink: "/tickets",
  },
  {
    id: "settings",
    title: (active) => <span className={titleTextStyle(active)}>Settings</span>,
    type: "item",
    icon: (active) => (
      <FiSettings size={iconFontSize} color={iconColor(active)} />
    ),
    permissions: ["admin"],
    navLink: appRoutes.SETTINGS,
  },
];
