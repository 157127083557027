import * as Yup from "yup";

export const postMenuItemSchema = Yup.object().shape({
  title: Yup.string().required("Please enter your menu item title"),
});

export const putMenuItemTranslationSchema = Yup.object().shape({
  translation: Yup.string().required("Please enter your translation "),
});

export const postMenuItemTranslationSchema = Yup.object().shape({
  translation: Yup.string().min(2).required("Please enter your translation"),
  language: Yup.string().required("Please select your language  "),
});
