import * as actionTypes from "../actionTypes";

const intialState = {
  zoneList: [],

  getZoneListLoader: false,
};

const settings = (state = intialState, action) => {
  switch (action.type) {
    //**GET_ZONE_LIST */
    case actionTypes.GET_ZONE_LIST: {
      return {
        ...state,
        getZoneListLoader: true,
      };
    }
    case actionTypes.GET_ZONE_LIST_SUCCESS: {
      return {
        ...state,
        zoneList: action.payload.zoneList,
        getZoneListLoader: false,
      };
    }
    case actionTypes.GET_ZONE_LIST_FAILURE: {
      return {
        ...state,
        getZoneListLoader: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default settings;
