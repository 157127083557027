// UI:
// dev.operations.poilabs.com
// operations.poilabs.com

// API:
// operations-api-dev.poilabs.com
// operations-api.poilabs.com

export const baseUrl =
  process.env.REACT_APP_ENVIRONMENT === "production"
    ? "https://operations-api.poilabs.com/api"
    : "https://operations-api-dev.poilabs.com/api";

export const customerPanelUrl =
  process.env.REACT_APP_ENVIRONMENT === "production"
    ? "https://venueops.poilabs.com"
    : "https://dev.venueops.poilabs.com";

export const loginAsCustoemerPanelUrl = (token) => {
  return `${customerPanelUrl}/login-as-customer/${token}`;
};

// WEB URL --> ""
export const webUrl = "";

console.log(process.env.REACT_APP_ENVIRONMENT);

// *************************************** auth urls *************************************
export const loginPasswordUrl = `${baseUrl}/auth/login`;
export const forgetPasswordUrl = `${baseUrl}/auth/forgot-password`;
export const restePasswordUrl = `${baseUrl}/auth/reset-password`;

// ** ***********************************users services ** ***********************************
export const getUserListUrl = `${baseUrl}/users`;
export const getDeletedUserListUrl = `${baseUrl}/users/deleted`;
export const postCreateNewUserUrl = `${baseUrl}/users`;
export const deleteUserUrl = (userId) => {
  return `${baseUrl}/users/${userId}`;
};
export const restoreUserUrl = (userId) => {
  return `${baseUrl}/users/${userId}/restore`;
};

export const putUserResendInvitationUrl = (customerId) => {
  return `${baseUrl}/users/${customerId}/resend-invitation`;
};

// ** *********************************** customers url **************************
export const getCustomerListUrl = `${baseUrl}/customers`;

export const getCustomerConfigUrl = (data) => {
  const { customerId } = data;

  return `${baseUrl}/customers/${customerId}/config`;
};

// ** put customer config url
export const putCustomerConfigUrl = (data) => {
  const { customerId } = data;

  return `${baseUrl}/customers/${customerId}/config`;
};

export const postNewCustomerUrl = `${baseUrl}/customers`;

export const putUpdateCustomerUrl = (customerId) => {
  return `${baseUrl}/customers/${customerId}`;
};

export const deleteCustomerUrl = (customerId) => {
  return `${baseUrl}/customers/${customerId}`;
};

export const putRestoreCustomerUrl = (customerId) => {
  return `${baseUrl}/customers/${customerId}/restore`;
};

export const postCreateNewCustomerSuperAdmin = (customerId) => {
  return `${baseUrl}/customers/${customerId}/superadmin`;
};
export const putCustomerSuperAdminUrl = (data) => {
  const { superadminId, customerId } = data;
  return `${baseUrl}/customers/${customerId}/superadmin/${superadminId}`;
};

export const getCustomerByIdUrl = (customerId) => {
  return `${baseUrl}/customers/${customerId}`;
};
export const getDeletedCustomerListUrl = `${baseUrl}/customers/deleted`;

export const postResendCustomerUserInvitationUrl = (invitationInfo) => {
  const { customerId, userId } = invitationInfo;
  return `${baseUrl}/customers/${customerId}/users/${userId}/resend-invitation`;
};
export const postNewCustomerLanguageUrl = (customerId) => {
  return `${baseUrl}/customers/${customerId}/language`;
};
export const deleteCustomerLanguageUrl = (languageInfo) => {
  const { customerId, languageId } = languageInfo;
  return `${baseUrl}/customers/${customerId}/language/${languageId}`;
};
export const getCustomerNavigationConfigUrl = (customerInfo) => {
  const { customerId } = customerInfo;

  return `${baseUrl}/customers/${customerId}/config/navigation`;
};
export const putCustomerNavigationConfigUrl = (navigationInfo) => {
  const { customerId } = navigationInfo;

  return `${baseUrl}/customers/${customerId}/config/navigation`;
};

export const getLoginAsCustomerUrl = (data) => {
  const { id } = data;

  return `${baseUrl}/customers/${id}/login`;
};

// ** *********************************** place types url **************************

export const getPlaceTypesListUrl = `${baseUrl}/placetypes`;
export const getDeletedPlaceTypesListUrl = `${baseUrl}/placetypes/deleted`;
export const postNewPlaceTypeUrl = `${baseUrl}/placetypes`;
export const putRestorePlaceTypeUrl = (placeTypeId) => {
  return `${baseUrl}/placetypes/${placeTypeId}/restore`;
};

export const deletePlaceTypeUrl = (placeTypeId) => {
  return `${baseUrl}/placetypes/${placeTypeId}`;
};

export const putPlaceTypeUrl = (placeTypeId) => {
  return `${baseUrl}/placetypes/${placeTypeId}`;
};

// ***************************** menu item url ****************

export const getMenuItemListUrl = (customerId) => {
  return `${baseUrl}/customers/${customerId}/menuitems`;
};
export const postMenuItemUrl = (customerId) => {
  return `${baseUrl}/customers/${customerId}/menuitems`;
};
export const deleteMenuItemUrl = (menuItemInfo) => {
  const { customerId, menuItemId } = menuItemInfo;
  return `${baseUrl}/customers/${customerId}/menuitems/${menuItemId}`;
};
export const putMenuItemUrl = (menuItemInfo) => {
  const { customerId, menuItemId } = menuItemInfo;

  return `${baseUrl}/customers/${customerId}/menuitems/${menuItemId}`;
};

export const postMenuItemTranslationUrl = (languageInfo) => {
  const { menuItemId } = languageInfo;
  return `${baseUrl}/menuitems/${menuItemId}/translations`;
};
export const deleteMenuItemTranslationUrl = (languageInfo) => {
  const { menuItemId, translationId } = languageInfo;
  return `${baseUrl}/menuitems/${menuItemId}/translations/${translationId}`;
};
export const putMenuItemTranslationUrl = (languageInfo) => {
  const { menuItemId, translationId } = languageInfo;

  return `${baseUrl}/menuitems/${menuItemId}/translations/${translationId}`;
};

export const postExcludeMenuItemUrl = (excludeInfo) => {
  const { menuitemId, placeId } = excludeInfo;

  return `${baseUrl}/menuitems/${menuitemId}/exclude/${placeId}`;
};

export const deleteExcludeMenuItemUrl = (excludeInfo) => {
  const { menuitemId, placeId } = excludeInfo;

  return `${baseUrl}/menuitems/${menuitemId}/exclude/${placeId}`;
};

export const postAttachMenuItemUrl = (attachInfo) => {
  const { menuitemId, placetypeId } = attachInfo;

  return `${baseUrl}/menuitems/${menuitemId}/placetypes/${placetypeId}`;
};

export const deleteAttachMenuItemUrl = (attachInfo) => {
  const { menuitemId, placetypeId } = attachInfo;

  return `${baseUrl}/menuitems/${menuitemId}/placetypes/${placetypeId}`;
};

// ***************************** settings ****************
export const getLanguageListUrl = `${baseUrl}/settings/languages`;

export const deleteLanguageUrl = (languageInfo) => {
  const { languageId } = languageInfo;
  return `${baseUrl}/settings/languages/${languageId}`;
};

export const postNewLanguageUrl = `${baseUrl}/settings/languages`;

// ***************************** Places ****************

export const getPlacesListUrl = `${baseUrl}/places/cosmos`;

export const getPlaceGeoJsonUrl = (placeInfo) => {
  const { buildingId } = placeInfo;

  return `${baseUrl}/places/${buildingId}/geojson`;
};

export const getPoiGeoJsonUrl = (placeInfo) => {
  const { buildingId } = placeInfo;

  return `${baseUrl}/places/${buildingId}/pois/geojson`;
};

export const getPlaceCenterCoordinationUrl = (placeInfo) => {
  const { buildingId } = placeInfo;

  return `${baseUrl}/places/${buildingId}/centerCoordinates`;
};

export const getPlaceSettingsUrl = (settingInfo) => {
  const { placeId } = settingInfo;

  return `${baseUrl}/places/${placeId}/settings`;
};

export const putPlaceSettingsUrl = (settingInfo) => {
  const { placeId } = settingInfo;

  return `${baseUrl}/places/${placeId}/settings`;
};

export const deletePlaceSettingsUrl = (settingInfo) => {
  const { placeId } = settingInfo;

  return `${baseUrl}/places/${placeId}/settings`;
};

export const getPlaceLiveStatusUrl = (placeInfo) => {
  const { placeId } = placeInfo;

  return `${baseUrl}/places/${placeId}/live-status`;
};
export const postPlacePublishGisUrl = (placeInfo) => {
  const { placeId } = placeInfo;

  return `${baseUrl}/places/${placeId}/publish-gis`;
};

// ***************************** Brands ****************

export const getBrandsListUrl = `${baseUrl}/brands`;

export const postNewBrandUrl = `${baseUrl}/brands`;

export const getDeletedBrandsListUrl = `${baseUrl}/brands/deleted`;

export const putBrandUrl = (brandInfo) => {
  const { id } = brandInfo;

  return `${baseUrl}/brands/${id}`;
};

export const deleteBrandUrl = (brandInfo) => {
  const { id } = brandInfo;

  return `${baseUrl}/brands/${id}`;
};

export const putRestoreBrandUrl = (brandInfo) => {
  const { id } = brandInfo;

  return `${baseUrl}/brands/${id}/restore`;
};

// ****************** category  *************************

export const getCategoriesUrl = `${baseUrl}/categories`;
export const postCategoryUrl = `${baseUrl}/categories`;
export const deleteCategoryUrl = (categoryInfo) => {
  const { id } = categoryInfo;

  return `${baseUrl}/categories/${id}`;
};
export const putCategoryUrl = (categoryInfo) => {
  const { id } = categoryInfo;

  return `${baseUrl}/categories/${id}`;
};

export const getCategoryByIdUrl = (categoryInfo) => {
  const { categoryId } = categoryInfo;

  return `${baseUrl}/categories/${categoryId}`;
};

export const getDeletedCategoriesListUrl = `${baseUrl}/categories/deleted`;

export const putRestoreCategoryUrl = (categoryInfo) => {
  const { id } = categoryInfo;

  return `${baseUrl}/categories/${id}/restore`;
};
export const postCategoryTranslationUrl = (categoryInfo) => {
  const { categoryId } = categoryInfo;

  return `${baseUrl}/categories/${categoryId}/translations`;
};

export const deleteCategoryTranslationUrl = (categoryInfo) => {
  const { categoryId, translationId } = categoryInfo;

  return `${baseUrl}/categories/${categoryId}/translations/${translationId}`;
};

export const putCategoryTranslationUrl = (categoryInfo) => {
  const { categoryId, translationId } = categoryInfo;

  return `${baseUrl}/categories/${categoryId}/translations/${translationId}`;
};

// ** sub categories

export const postSubcategoryUrl = (categoryInfo) => {
  const { categoryId } = categoryInfo;

  return `${baseUrl}/categories/${categoryId}/subcategories`;
};
export const deleteSubcategoryUrl = (categoryInfo) => {
  const { categoryId, subcategoryId } = categoryInfo;

  return `${baseUrl}/categories/${categoryId}/subcategories/${subcategoryId}`;
};
export const putSubcategoryUrl = (categoryInfo) => {
  const { categoryId, subcategoryId } = categoryInfo;

  return `${baseUrl}/categories/${categoryId}/subcategories/${subcategoryId}`;
};

export const getDeletedSubCategoriesListUrl = `${baseUrl}/categories/deleted`;

export const putRestoreSubcategoryUrl = (categoryInfo) => {
  console.log(categoryInfo);
  const { categoryId, subcategoryId } = categoryInfo;

  return `${baseUrl}/categories/${categoryId}/subcategories/${subcategoryId}/restore`;
};
export const postSubcategoryTranslationUrl = (categoryInfo) => {
  const { categoryId, subcategoryId } = categoryInfo;

  return `${baseUrl}/categories/${categoryId}/subcategories/${subcategoryId}/translations`;
};

export const deleteSubcategoryTranslationUrl = (categoryInfo) => {
  const { categoryId, subcategoryId, translationId } = categoryInfo;

  return `${baseUrl}/categories/${categoryId}/subcategories/${subcategoryId}/translations/${translationId}`;
};

export const putSubcategoryTranslationUrl = (categoryInfo) => {
  const { categoryId, subcategoryId, translationId } = categoryInfo;
  console.log(categoryInfo);

  return `${baseUrl}/categories/${categoryId}/subcategories/${subcategoryId}/translations/${translationId}`;
};

// ******************************* netwroktype *******************************

export const getNetworktypeListUrl = `${baseUrl}/networktypes`;
export const getDeletedNetworktypeListUrl = `${baseUrl}/networktypes/deleted`;
export const postNewNetworktypeUrl = `${baseUrl}/networktypes`;
export const putRestoreNetworktypeUrl = (networkInfo) => {
  const { id } = networkInfo;

  return `${baseUrl}/networktypes/${id}/restore`;
};

export const deleteNetworktypeUrl = (networkInfo) => {
  const { id } = networkInfo;

  return `${baseUrl}/networktypes/${id}`;
};

export const putNetworkypeUrl = (networkInfo) => {
  const { id } = networkInfo;
  return `${baseUrl}/networktypes/${id}`;
};

// ******************************* pointtype  *******************************

export const getPointtypeListUrl = `${baseUrl}/pointtypes`;
export const getDeletedPointtypeListUrl = `${baseUrl}/pointtypes/deleted`;
export const postNewPointtypeUrl = `${baseUrl}/pointtypes`;
export const putRestorePointtypeUrl = (pointInfo) => {
  const { id } = pointInfo;

  return `${baseUrl}/pointtypes/${id}/restore`;
};

export const deletePointtypeUrl = (pointInfo) => {
  const { id } = pointInfo;

  return `${baseUrl}/pointtypes/${id}`;
};

export const putPointtypeUrl = (pointInfo) => {
  const { id } = pointInfo;
  return `${baseUrl}/pointtypes/${id}`;
};

// ***********************Zones ***********************

export const getZoneListUrl = (placeInfo) => {
  const { buildingId } = placeInfo;

  return `${baseUrl}/places/${buildingId}/zones/geojson`;
};

// ***********************segment ***********************

export const getSegmentListUrl = (placeInfo) => {
  const { buildingId } = placeInfo;

  return `${baseUrl}/places/${buildingId}/segments/geojson`;
};

// ***********************walkways ***********************

export const getWalkwaysListUrl = (placeInfo) => {
  const { buildingId } = placeInfo;

  return `${baseUrl}/places/${buildingId}/walkways/geojson`;
};

// ***********************beacons ***********************

export const getBeaconsListUrl = (placeInfo) => {
  const { buildingId } = placeInfo;

  return `${baseUrl}/places/${buildingId}/beacons/geojson`;
};

// ***  poi content  urls
export const postPoiContentUrl = (poiInfo) => {
  const { poiId } = poiInfo;

  return `${baseUrl}/pois/${poiId}/content`;
};

export const deletePoiContentUrl = (poiInfo) => {
  const { poiId } = poiInfo;

  return `${baseUrl}/pois/${poiId}/content`;
};

// ** store id

// ** store ids
export const getStoreIdsListUrl = () => {
  return `${baseUrl}/stores`;
};

export const deleteStoreIdUrl = (data) => {
  const { id } = data;

  return `${baseUrl}/stores/${id}`;
};

export const postStoreIdUrl = () => {
  return `${baseUrl}/stores`;
};

export const putStoreIdUrl = (data) => {
  const { id } = data;

  return `${baseUrl}/stores/${id}`;
};

// ** pois list
export const getPoisListUrl = () => {
  return `${baseUrl}/points `;
};

// ** beacons urls

export const getBeaconsUrl = () => {
  return `${baseUrl}/beacons`;
};

export const postBeaconUrl = () => {
  return `${baseUrl}/beacons`;
};

export const putBeaconUrl = (data) => {
  const { id } = data;

  return `${baseUrl}/beacons/${id}`;
};

export const deleteBeaconByIdUrl = (data) => {
  const { id } = data;

  return `${baseUrl}/beacons/${id}`;
};
