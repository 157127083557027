import * as servicesUrl from "../assets/data/urlConfig";
import axiosApiInctance from "../services/axiosInterceptor";

// ** get language list async
export const getLanguageListAsync = async () => {
  return await axiosApiInctance.get(`${servicesUrl.getLanguageListUrl}`);
};

// ** get language list async
export const deleteLanguageAsync = async (languageInfo) => {
  return await axiosApiInctance.delete(
    `${servicesUrl.deleteLanguageUrl(languageInfo)}`
  );
};

// ** post new language async
export const postNewLanguageAsync = async (languageInfo) => {
  const { language } = languageInfo;
  return await axiosApiInctance.post(`${servicesUrl.postNewLanguageUrl}`, {
    language: language,
  });
};
