import * as actionTypes from "../actionTypes";

const intialState = {
  networktypeList: [],
  deletedNetworktypeList: [],

  selectedNetworkInfo: null,
  postNetworktypeLoader: false,
  getNetworktypeListLoader: false,
  deleteNetworktypeLoader: false,
  putRestoreNetworktypeLoader: false,
  putNetworktypeLoader: false,

  getDeletedNetworktypeListLoader: false,

  openDeletedNetworktypeListModal: false,
};

const palceTypes = (state = intialState, action) => {
  switch (action.type) {
    //**GET_NETWORKTYPE_LIST */
    case actionTypes.GET_NETWORKTYPE_LIST: {
      return {
        ...state,
        getNetworktypeListLoader: true,
      };
    }
    case actionTypes.GET_NETWORKTYPE_LIST_SUCCESS: {
      return {
        ...state,
        networktypeList: action.payload.networktypeList,
        getNetworktypeListLoader: false,
      };
    }
    case actionTypes.GET_NETWORKTYPE_LIST_FAILURE: {
      return {
        ...state,
        getNetworktypeListLoader: false,
      };
    }

    // ** post new place type
    case actionTypes.POST_NETWORKTYPE: {
      return {
        ...state,
        postNetworktypeLoader: true,
      };
    }
    case actionTypes.POST_NETWORKTYPE_SUCCESS: {
      const { id, name, createdAt } = action.payload.networkInfo;

      return {
        ...state,
        networktypeList: [
          ...state.networktypeList,
          {
            id,
            name,
            createdAt,
          },
        ],
        postNetworktypeLoader: false,
      };
    }
    case actionTypes.POST_NETWORKTYPE_FAILURE: {
      return {
        ...state,
        postNetworktypeLoader: false,
      };
    }

    // ** DELETE NETWORKTYPE
    case actionTypes.DELETE_NETWORKTYPE: {
      return {
        ...state,
        deleteNetworktypeLoader: true,
      };
    }
    case actionTypes.DELETE_NETWORKTYPE_SUCCESS: {
      const networkInfo = action.payload.networkInfo;

      // ** remove deleted item from array
      const updateNetworktypeList = state.networktypeList.filter(
        (item) => item.id !== networkInfo.id
      );
      return {
        ...state,
        networktypeList: updateNetworktypeList,
        deleteNetworktypeLoader: false,
      };
    }
    case actionTypes.DELETE_NETWORKTYPE_FAILURE: {
      return {
        ...state,
        deleteNetworktypeLoader: false,
      };
    }

    //** DELETED_NETWORKTYPE_LIST_MODAL
    case actionTypes.DELETED_NETWORKTYPE_LIST_MODAL: {
      const { openModal } = action.payload;
      return {
        ...state,
        openDeletedNetworktypeListModal: openModal,
      };
    }

    //**GET_DELETED_CUSTOMER_LIST */
    case actionTypes.GET_DELETED_NETWORKTYPE_LIST: {
      return {
        ...state,
        getDeletedNetworktypeListLoader: true,
      };
    }
    case actionTypes.GET_DELETED_NETWORKTYPE_LIST_SUCCESS: {
      return {
        ...state,
        deletedNetworktypeList: action.payload.deletedNetworktypeList,
        getDeletedNetworktypeListLoader: false,
      };
    }
    case actionTypes.GET_DELETED_NETWORKTYPE_LIST_FAILURE: {
      return {
        ...state,
        getDeletedNetworktypeListLoader: false,
      };
    }

    // ** delete customer
    case actionTypes.PUT_RESTORE_NETWORKTYPE: {
      const networkInfo = action.payload.networkInfo;
      return {
        ...state,
        selectedNetworkInfo: networkInfo,
        putRestoreNetworktypeLoader: true,
      };
    }
    case actionTypes.PUT_RESTORE_NETWORKTYPE_SUCCESS: {
      const networkInfo = action.payload.networkInfo;

      // ** remove deleted item from array
      const updateDeletedNetworktypeList = state.deletedNetworktypeList.filter(
        (item) => item.id !== networkInfo.id
      );
      return {
        ...state,
        deletedNetworktypeList: updateDeletedNetworktypeList,
        putRestoreNetworktypeLoader: false,
      };
    }
    case actionTypes.PUT_RESTORE_NETWORKTYPE_FAILURE: {
      return {
        ...state,
        putRestoreNetworktypeLoader: false,
      };
    }

    // ** PUT_NETWROKTYPE
    case actionTypes.PUT_NETWROKTYPE: {
      return {
        ...state,
        putNetworktypeLoader: true,
      };
    }
    case actionTypes.PUT_NETWROKTYPE_SUCCESS: {
      const { id, name, createdAt, updatedAt } = action.payload.networkInfo;
      console.log(action.payload.networkInfo);

      const networkTypeIndex = state.networktypeList.findIndex(
        (networktype) => networktype.id === id
      );

      const networktypeListUpdate = [...state.networktypeList];

      networktypeListUpdate[networkTypeIndex] = {
        id,
        name,
        createdAt,
        updatedAt,
      };

      return {
        ...state,
        networktypeList: networktypeListUpdate,
        putNetworktypeLoader: false,
      };
    }
    case actionTypes.PUT_NETWROKTYPE_FAILURE: {
      return {
        ...state,
        putNetworktypeLoader: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default palceTypes;
