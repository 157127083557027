import * as servicesUrl from "../assets/data/urlConfig";
import axiosApiInctance from "../services/axiosInterceptor";

// ** get user list async
export const getMenuItemListAsync = async (customerId) => {
  return await axiosApiInctance.get(
    `${servicesUrl.getMenuItemListUrl(customerId)}`
  );
};

// ** post new menu item
export const postMemuItemAsync = async (menuItemInfo) => {
  const { customerId, title } = menuItemInfo;
  return await axiosApiInctance.post(
    `${servicesUrl.postMenuItemUrl(customerId)}`,
    {
      title,
    }
  );
};

// ** delete menu item
export const deleteMenuItemAsync = async (menuItemInfo) => {
  return await axiosApiInctance.delete(
    `${servicesUrl.deleteMenuItemUrl(menuItemInfo)}`
  );
};
// ** delete menu item
export const putMenuItemUrlAsync = async (menuItemInfo) => {
  return await axiosApiInctance.put(
    `${servicesUrl.putMenuItemUrl(menuItemInfo)}`,
    {
      title: menuItemInfo.title,
    }
  );
};

// ** post Menu Item Translation
export const postMenuItemTranslationAsync = async (menuItemInfo) => {
  return await axiosApiInctance.post(
    `${servicesUrl.postMenuItemTranslationUrl(menuItemInfo)}`,
    {
      translation: menuItemInfo.translation,
      language: menuItemInfo.language,
    }
  );
};

// ** post Menu Item Translation
export const putMenuItemTranslationAsync = async (menuItemInfo) => {
  return await axiosApiInctance.put(
    `${servicesUrl.putMenuItemTranslationUrl(menuItemInfo)}`,
    {
      translation: menuItemInfo.translation,
    }
  );
};

// ** delete MenuItem Translation
export const deleteMenuItemTranslationAsync = async (menuItemInfo) => {
  return await axiosApiInctance.delete(
    `${servicesUrl.deleteMenuItemTranslationUrl(menuItemInfo)}`
  );
};

// ** post Exclude MenuItem
export const postExcludeMenuItemAsync = async (excludeInfo) => {
  return await axiosApiInctance.post(
    `${servicesUrl.postExcludeMenuItemUrl(excludeInfo)}`
  );
};

// ** delete Exclude MenuItem
export const deleteExcludeMenuItemAsync = async (excludeInfo) => {
  return await axiosApiInctance.delete(
    `${servicesUrl.deleteExcludeMenuItemUrl(excludeInfo)}`
  );
};

// ** post Attach MenuItem Async
export const postAttachMenuItemAsync = async (attachInfo) => {
  return await axiosApiInctance.post(
    `${servicesUrl.postAttachMenuItemUrl(attachInfo)}`
  );
};

// ** delete Exclude MenuItem
export const deleteAttachMenuItemAsync = async (attachInfo) => {
  return await axiosApiInctance.delete(
    `${servicesUrl.deleteAttachMenuItemUrl(attachInfo)}`
  );
};
