import * as servicesUrl from "../assets/data/urlConfig";
import axiosApiInctance from "../services/axiosInterceptor";

// ** get user list async
export const getPointtypeListAsync = async () => {
  return await axiosApiInctance.get(`${servicesUrl.getPointtypeListUrl}`);
};

// ** get user list async
export const getDeletedPointtypeListAsync = async () => {
  return await axiosApiInctance.get(
    `${servicesUrl.getDeletedPointtypeListUrl}`
  );
};

// ** get Pointtype list async
export const putRestorePointtypeUrlAsync = async (pointInfo) => {
  return await axiosApiInctance.put(
    `${servicesUrl.putRestorePointtypeUrl(pointInfo)}`
  );
};

// ** post New Pointtype Async
export const postNewPointtypeAsync = async (pointInfo) => {
  const { name } = pointInfo;

  return await axiosApiInctance.post(`${servicesUrl.postNewPointtypeUrl}`, {
    name: name,
  });
};

// ** delete Pointtype
export const deletePointtypeAsync = async (pointInfo) => {
  return await axiosApiInctance.delete(
    `${servicesUrl.deletePointtypeUrl(pointInfo)}`
  );
};

// ** put Pointtype
export const putPointtypeAsync = async (pointInfo) => {
  const { name } = pointInfo;
  return await axiosApiInctance.put(
    `${servicesUrl.putPointtypeUrl(pointInfo)}`,
    {
      name: name,
    }
  );
};
