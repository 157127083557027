import * as actionTypes from "../actionTypes";
import moment from "moment";

const intialState = {
  categoriesList: [],
  categoryInfo: [],
  deletedCategoriesList: [],
  selectedLanguageInfo: {},
  selectedCategoryId: "",
  selectedSubcategoryId: "",

  getCategoriesListLoader: false,
  getCategoryByIdLoader: false,
  postCategoryLoader: false,
  postSubcategoryLoader: false,
  deleteCategoryLoader: false,
  deleteSubcategoryLoader: false,
  putCategoryLoader: false,
  putSubcategoryLoader: false,
  putRestoreSubcategoryLoader: false,
  getDeletedCategoriesListLoader: false,
  openDeletedCategoriesListModal: false,
  putRestoreCategoryLoader: false,
  postCategoryTranslationLoader: false,
  deleteCategoryTranslationLoader: false,
  putCategoryTranslationLoader: false,
  deleteSubcategoryTranslationLoader: false,
  putSubcategoryTranslationLoader: false,
};

const categories = (state = intialState, action) => {
  switch (action.type) {
    //**GET_CATEGORIES_LIST */
    case actionTypes.GET_CATEGORIES_LIST: {
      return {
        ...state,
        getCategoriesListLoader: true,
      };
    }
    case actionTypes.GET_CATEGORIES_LIST_SUCCESS: {
      return {
        ...state,
        categoriesList: action.payload.categoriesList,
        getCategoriesListLoader: false,
      };
    }
    case actionTypes.GET_CATEGORIES_LIST_FAILURE: {
      return {
        ...state,
        getCategoriesListLoader: false,
      };
    }

    //**POST_CATEGORY*/
    case actionTypes.POST_CATEGORY: {
      return {
        ...state,
        postCategoryLoader: true,
      };
    }
    case actionTypes.POST_CATEGORY_SUCCESS: {
      const { id, name, createdAt, iconUrl } = action.payload.categoryInfo.data;

      console.log(iconUrl);

      return {
        ...state,
        categoriesList: [
          ...state.categoriesList,
          {
            id,
            name,
            createdAt,
            Categorytranslations: [],
            iconUrl,
          },
        ],
        postCategoryLoader: false,
      };
    }
    case actionTypes.POST_CATEGORY_FAILURE: {
      return {
        ...state,
        postCategoryLoader: false,
      };
    }

    case actionTypes.POST_SUBCATEGORY: {
      return {
        ...state,
        postSubcategoryLoader: true,
      };
    }
    case actionTypes.POST_SUBCATEGORY_SUCCESS: {
      const categoryData = action.payload.categoryInfo;

      const categoryInfoUpdate = [...state.categoryInfo];

      categoryInfoUpdate[0] = {
        ...categoryInfoUpdate[0],
        Subcategories: [
          ...categoryInfoUpdate[0].Subcategories,
          { ...categoryData, Subcategorytranslations: [] },
        ],
      };

      return {
        ...state,
        categoryInfo: categoryInfoUpdate,
        postSubcategoryLoader: false,
      };
    }
    case actionTypes.POST_SUBCATEGORY_FAILURE: {
      return {
        ...state,
        postSubcategoryLoader: false,
      };
    }

    case actionTypes.POST_SUBCATEGORY_TRANSLATION: {
      return {
        ...state,
        postSubcategoryTranslationLoader: true,
      };
    }
    case actionTypes.POST_SUBCATEGORY_TRANSLATION_SUCCESS: {
      const categoryData = action.payload.categoryInfo;

      const categoryInfoUpdate = [...state.categoryInfo];

      const tempUpdateSubcategory = categoryInfoUpdate[0]?.Subcategories.map(
        (item) => {
          if (item.id === categoryData.subcategoryId) {
            return {
              ...item,
              Subcategorytranslations: [
                ...item.Subcategorytranslations,
                categoryData.data.data,
              ],
            };
          } else return { ...item };
        }
      );

      categoryInfoUpdate[0] = {
        ...categoryInfoUpdate[0],
        Subcategories: tempUpdateSubcategory,
      };

      return {
        ...state,
        categoryInfo: categoryInfoUpdate,
        postSubcategoryTranslationLoader: false,
      };
    }
    case actionTypes.POST_SUBCATEGORY_TRANSLATION_FAILURE: {
      return {
        ...state,
        postSubcategoryTranslationLoader: false,
      };
    }

    // ** delete category
    case actionTypes.DELETE_CATEGORY: {
      return {
        ...state,
        deleteCategoryLoader: true,
      };
    }
    case actionTypes.DELETE_CATEGORY_SUCCESS: {
      const categoryInfo = action.payload.categoryInfo;

      // ** remove deleted item from array
      const updateCategoryList = state.categoriesList.filter(
        (item) => item.id !== categoryInfo.id
      );
      return {
        ...state,
        categoriesList: updateCategoryList,
        deleteCategoryLoader: false,
      };
    }
    case actionTypes.DELETE_CATEGORY_FAILURE: {
      return {
        ...state,
        deleteCategoryLoader: false,
      };
    }

    // ** delete sub category
    case actionTypes.DELETE_SUBCATEGORY: {
      return {
        ...state,
        deleteSubcategoryLoader: true,
      };
    }
    case actionTypes.DELETE_SUBCATEGORY_SUCCESS: {
      const categoryData = action.payload.categoryInfo;

      const categoryInfoUpdate = [...state.categoryInfo];

      const updateSubcategories = categoryInfoUpdate[0].Subcategories.map(
        (item) => {
          if (item.id === categoryData.subcategoryId) {
            return { ...item, deletedAt: moment().format() + "Z" };
          } else return item;
        }
      );

      categoryInfoUpdate[0] = {
        ...categoryInfoUpdate[0],
        Subcategories: updateSubcategories,
      };

      return {
        ...state,
        categoryInfo: categoryInfoUpdate,
        deleteSubcategoryLoader: false,
      };
    }
    case actionTypes.DELETE_SUBCATEGORY_FAILURE: {
      return {
        ...state,
        deleteSubcategoryLoader: false,
      };
    }

    // ** PUT_CATEGORY
    case actionTypes.PUT_CATEGORY: {
      return {
        ...state,
        putCategoryLoader: true,
      };
    }
    case actionTypes.PUT_CATEGORY_SUCCESS: {
      const { id, name, createdAt, updatedAt, base64 } =
        action.payload.categoryInfo;

      alert(id);
      const categoryIndex = state.categoriesList.findIndex(
        (category) => category.id === id
      );

      const categoriesListUpdate = [...state.categoriesList];

      categoriesListUpdate[categoryIndex] = {
        id,
        name,
        createdAt,
        updatedAt,
        iconUrl: base64,
      };

      return {
        ...state,
        categoriesList: categoriesListUpdate,
        putCategoryLoader: false,
      };
    }
    case actionTypes.PUT_CATEGORY_FAILURE: {
      return {
        ...state,
        putCategoryLoader: false,
      };
    }

    // ** PUT_CATEGORY
    case actionTypes.PUT_SUBCATEGORY: {
      return {
        ...state,
        putSubcategoryLoader: true,
      };
    }
    case actionTypes.PUT_SUBCATEGORY_SUCCESS: {
      const categoryData = action.payload.categoryInfo;
      const categoryInfoUpdate = [...state.categoryInfo];

      const updateSubcategories = categoryInfoUpdate[0].Subcategories.map(
        (item) => {
          if (item.id === categoryData.id) {
            return { ...categoryData };
          } else return item;
        }
      );

      categoryInfoUpdate[0] = {
        ...categoryInfoUpdate[0],
        Subcategories: updateSubcategories,
      };

      return {
        ...state,
        categoryInfo: categoryInfoUpdate,
        putSubcategoryLoader: false,
      };
    }
    case actionTypes.PUT_SUBCATEGORY_FAILURE: {
      return {
        ...state,
        putSubcategoryLoader: false,
      };
    }

    //**GET_DELETED_CATEGORIES_LIST */
    case actionTypes.GET_DELETED_CATEGORIES_LIST: {
      return {
        ...state,
        getDeletedCategoriesListLoader: true,
      };
    }
    case actionTypes.GET_DELETED_CATEGORIES_LIST_SUCCESS: {
      const { deletedCategoriesList } = action.payload;
      return {
        ...state,
        deletedCategoriesList: deletedCategoriesList,
        getDeletedCategoriesListLoader: false,
      };
    }
    case actionTypes.GET_DELETED_CATEGORIES_LIST_FAILURE: {
      return {
        ...state,
        getDeletedCategoriesListLoader: false,
      };
    }

    // ** PUT_RESTORE_CATEGORY
    case actionTypes.PUT_RESTORE_CATEGORY: {
      const categoryInfo = action.payload.categoryInfo;
      return {
        ...state,
        selectedCategoryId: categoryInfo.id,
        putRestoreCategoryLoader: true,
      };
    }
    case actionTypes.PUT_RESTORE_CATEGORY_SUCCESS: {
      const categoryInfo = action.payload.categoryInfo;

      // ** remove deleted item from array
      const updateDeletedCategoriesList = state.deletedCategoriesList.filter(
        (item) => item.id !== categoryInfo.id
      );
      return {
        ...state,
        deletedCategoriesList: updateDeletedCategoriesList,
        putRestoreCategoryLoader: false,
      };
    }
    case actionTypes.PUT_RESTORE_CATEGORY_FAILURE: {
      return {
        ...state,
        putRestoreCategoryLoader: false,
      };
    }

    // ** PUT_RESTORE_CATEGORY
    case actionTypes.PUT_RESTORE_SUBCATEGORY: {
      const categoryInfo = action.payload.categoryInfo;
      return {
        ...state,
        selectedSubcategoryId: categoryInfo.subcategoryId,
        putRestoreSubcategoryLoader: true,
      };
    }
    case actionTypes.PUT_RESTORE_SUBCATEGORY_SUCCESS: {
      const categoryData = action.payload.categoryInfo;

      const categoryInfoUpdate = [...state.categoryInfo];

      const updateSubcategories = categoryInfoUpdate[0].Subcategories.map(
        (item) => {
          if (item.id === categoryData.subcategoryId) {
            return { ...item, deletedAt: null };
          } else return item;
        }
      );

      categoryInfoUpdate[0] = {
        ...categoryInfoUpdate[0],
        Subcategories: updateSubcategories,
      };

      return {
        ...state,
        categoryInfo: categoryInfoUpdate,
        selectedSubcategoryId: "",
        putRestoreSubcategoryLoader: false,
      };
    }
    case actionTypes.PUT_RESTORE_SUBCATEGORY_FAILURE: {
      return {
        ...state,
        putRestoreSubcategoryLoader: false,
      };
    }

    //**POST_CATEGORY_TRANSLATION*/
    case actionTypes.POST_CATEGORY_TRANSLATION: {
      return {
        ...state,
        postCategoryTranslationLoader: true,
      };
    }
    case actionTypes.POST_CATEGORY_TRANSLATION_SUCCESS: {
      const { data, categoryId } = action.payload.categoryInfo;

      const categoryIndex = state.categoriesList.findIndex(
        (menuItem) => menuItem.id === categoryId
      );

      const categorytranslationsUpdate = [...state.categoriesList];

      categorytranslationsUpdate[categoryIndex] = {
        ...categorytranslationsUpdate[categoryIndex],
        Categorytranslations: [
          ...categorytranslationsUpdate[categoryIndex].Categorytranslations,
          data,
        ],
      };

      return {
        ...state,
        categoriesList: categorytranslationsUpdate,
        postCategoryTranslationLoader: false,
      };
    }
    case actionTypes.POST_CATEGORY_TRANSLATION_FAILURE: {
      return {
        ...state,
        postCategoryTranslationLoader: false,
      };
    }

    // ** delete category
    case actionTypes.DELETE_CATEGORY_TRANSLATION: {
      return {
        ...state,
        deleteCategoryTranslationLoader: true,
      };
    }
    case actionTypes.DELETE_CATEGORY_TRANSLATION_SUCCESS: {
      const { categoryId, translationId } = action.payload.categoryInfo;

      const categoryIndex = state.categoriesList.findIndex(
        (category) => category.id === categoryId
      );

      const categoriesListUpdate = [...state.categoriesList];

      const updateTranslation = categoriesListUpdate[
        categoryIndex
      ].Categorytranslations.filter((item) => item.id !== translationId);

      categoriesListUpdate[categoryIndex].Categorytranslations =
        updateTranslation;

      return {
        ...state,
        categoriesList: categoriesListUpdate,
        deleteCategoryTranslationLoader: false,
      };
    }
    case actionTypes.DELETE_CATEGORY_TRANSLATION_FAILURE: {
      return {
        ...state,
        deleteCategoryTranslationLoader: false,
      };
    }

    // ** delete category translation
    case actionTypes.PUT_CATEGORY_TRANSLATION: {
      return {
        ...state,
        putCategoryTranslationLoader: true,
      };
    }
    case actionTypes.PUT_CATEGORY_TRANSLATION_SUCCESS: {
      const { categoryId, translationId, translation } =
        action.payload.categoryInfo;

      const categoryIndex = state.categoriesList.findIndex(
        (category) => category.id === categoryId
      );

      const categoriesListUpdate = [...state.categoriesList];

      const updateTranslation = categoriesListUpdate[
        categoryIndex
      ].Categorytranslations.map((item) => {
        if (item.id === translationId) {
          return { ...item, translation: translation };
        } else return item;
      });

      categoriesListUpdate[categoryIndex].Categorytranslations =
        updateTranslation;

      return {
        ...state,
        categoriesList: categoriesListUpdate,
        putCategoryTranslationLoader: false,
      };
    }
    case actionTypes.PUT_CATEGORY_TRANSLATION_FAILURE: {
      return {
        ...state,
        putCategoryTranslationLoader: false,
      };
    }

    // ** delete sub category translation
    case actionTypes.DELETE_SUBCATEGORY_TRANSLATION: {
      return {
        ...state,
        deleteSubcategoryTranslationLoader: true,
      };
    }
    case actionTypes.DELETE_SUBCATEGORY_TRANSLATION_SUCCESS: {
      // const { categoryId, translationId, subcategoryId } =
      //   action.payload.categoryInfo;

      const categoryData = action.payload.categoryInfo;

      const categoryInfoUpdate = [...state.categoryInfo];

      const tempUpdateSubcategory = categoryInfoUpdate[0]?.Subcategories.map(
        (item) => {
          if (item.id === categoryData.subcategoryId) {
            return {
              ...item,
              Subcategorytranslations: item.Subcategorytranslations.filter(
                (translation) => {
                  return translation.id !== categoryData.translationId;
                }
              ),
            };
          } else return { ...item };
        }
      );

      categoryInfoUpdate[0] = {
        ...categoryInfoUpdate[0],
        Subcategories: tempUpdateSubcategory,
      };

      return {
        ...state,
        categoryInfo: categoryInfoUpdate,
        deleteSubcategoryTranslationLoader: false,
      };
    }
    case actionTypes.DELETE_SUBCATEGORY_TRANSLATION_FAILURE: {
      return {
        ...state,
        deleteSubcategoryTranslationLoader: false,
      };
    }

    // ** DELETED_CATEGORIES_LIST_MODAL
    case actionTypes.DELETED_CATEGORIES_LIST_MODAL: {
      const { openModal } = action.payload;
      return {
        ...state,
        openDeletedCategoriesListModal: openModal,
      };
    }

    //
    //**GET_CATEGORIES_LIST */
    case actionTypes.GET_CATEGORY_BY_ID: {
      return {
        ...state,
        getCategoryByIdLoader: true,
      };
    }
    case actionTypes.GET_CATEGORY_BY_ID_SUCCESS: {
      return {
        ...state,
        categoryInfo: action.payload.categoryInfo,
        getCategoryByIdLoader: false,
      };
    }
    case actionTypes.GET_CATEGORY_BY_ID_FAILURE: {
      return {
        ...state,
        getCategoryByIdLoader: false,
      };
    }

    // ** delete sub category translation
    case actionTypes.PUT_SUBCATEGORY_TRANSLATION: {
      return {
        ...state,
        putSubcategoryTranslationLoader: true,
      };
    }
    case actionTypes.PUT_SUBCATEGORY_TRANSLATION_SUCCESS: {
      const categoryData = action.payload.categoryInfo;

      const categoryInfoUpdate = [...state.categoryInfo];

      const tempUpdateSubcategory = categoryInfoUpdate[0]?.Subcategories.map(
        (item) => {
          if (item.id === categoryData.subcategoryId) {
            return {
              ...item,
              Subcategorytranslations: item.Subcategorytranslations.map(
                (subTranslationItem) => {
                  if (subTranslationItem.id === categoryData.translationId)
                    return {
                      ...subTranslationItem,
                      translation: categoryData.translation,
                    };
                  else return subTranslationItem;
                }
              ),
            };
          } else return { ...item };
        }
      );

      categoryInfoUpdate[0] = {
        ...categoryInfoUpdate[0],
        Subcategories: tempUpdateSubcategory,
      };

      return {
        ...state,
        categoryInfo: categoryInfoUpdate,
        putSubcategoryTranslationLoader: false,
      };
    }
    case actionTypes.PUT_SUBCATEGORY_TRANSLATION_FAILURE: {
      return {
        ...state,
        putSubcategoryTranslationLoader: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default categories;
