import { call, takeEvery, put, all, fork } from "redux-saga/effects";

// services
import * as services from "../../services/storeIdsServices";

// ** Utils
import { toast } from "react-toastify";

// ** Actions
import * as storeIdActionTypes from "../actionTypes";
import * as storeIdAction from "./actions";

// **POIS
function* getStoreIdsList({ payload }) {
  try {
    const response = yield call(services.getStoreIdsListAsync, payload);

    const { data } = response;

    if (response.status === 200) {
      yield put(storeIdAction.getStoreIdsListSuccess(data));
    } else {
      toast.error(data.message);
      yield put(storeIdAction.getStoreIdsListFailure());
    }
  } catch (error) {
    yield put(storeIdAction.getStoreIdsListFailure());
  }
}

function* deleteStoreId({ payload }) {
  try {
    const response = yield call(services.deleteStoreIdAsync, payload);

    const { data } = response;

    if (response.status === 200) {
      toast.success("Your store id has been deleted");
      yield put(storeIdAction.deleteStoreIdSuccess(payload.id));
    } else {
      toast.error(data.message);
      yield put(storeIdAction.deleteStoreIdFailure());
    }
  } catch (error) {
    yield put(storeIdAction.deleteStoreIdFailure());
  }
}

function* postStoreId({ payload }) {
  try {
    const response = yield call(services.postStoreIdAsync, payload);

    const { data } = response;

    if (response.status === 200) {
      yield put(storeIdAction.postStoreIdSuccess(response.data));
      toast.success("Store id created successfully");
    } else {
      toast.error(data.message);
      yield put(storeIdAction.postStoreIdFailure(data));
    }
  } catch (error) {
    yield put(storeIdAction.postStoreIdFailure());
  }
}

function* putStoreId({ payload }) {
  try {
    const response = yield call(services.putStoreIdAsync, payload);

    const { data } = response;

    if (response.status === 200) {
      yield put(storeIdAction.putStoreIdSuccess(response.data));
      toast.success("Store id updated successfully");
    } else {
      toast.error(data.message);
      yield put(storeIdAction.putStoreIdFailure(data));
    }
  } catch (error) {
    yield put(storeIdAction.putStoreIdFailure());
  }
}

function* watchGetStoreIdsList() {
  yield takeEvery(storeIdActionTypes.GET_STORE_IDS_LIST, getStoreIdsList);
}

function* watchDeleteStoreId() {
  yield takeEvery(storeIdActionTypes.DELETE_STORE_ID, deleteStoreId);
}

function* watchPostStoreId() {
  yield takeEvery(storeIdActionTypes.POST_STORE_ID, postStoreId);
}

function* watchPutStoreId() {
  yield takeEvery(storeIdActionTypes.PUT_STORE_ID, putStoreId);
}

export default function* storeIdsSagas() {
  yield all([
    fork(watchGetStoreIdsList),
    fork(watchDeleteStoreId),
    fork(watchPostStoreId),
    fork(watchPutStoreId),
  ]);
}
