import { call, put, all, fork, takeLatest } from "redux-saga/effects";

// services
import * as services from "../../services/poisServices";

import * as poisActions from "./actions";
import * as actionTypes from "../actionTypes";

// **POIS
function* getPoisList({ payload }) {
  try {
    const response = yield call(services.getPoisListAsync, payload);

    const { data } = response;

    if (response.status === 200) {
      yield put(poisActions.getPoisListSuccess(data));
    } else {
      yield put(poisActions.getPoisListFailure());
    }
  } catch (error) {
    yield put(poisActions.getPoisListFailure());
  }
}

// ** WATCHERS
function* watchGetPoisList() {
  yield takeLatest(actionTypes.GET_POIS_LIST, getPoisList);
}

export default function* poisSaga() {
  yield all([fork(watchGetPoisList)]);
}
