import { combineReducers } from "redux";

import auth from "./auth/reducer";
import users from "./users/reducer";
import customers from "./customers/reducer";
import placetypes from "./placetypes/reducer";
import menuItems from "./menuItems/reducer";
import settings from "./settings/reducer";
import places from "./places/reducer";
import brands from "./brands/reducer";
import categories from "./categories/reducer";
import networktypes from "./networktypes/reducer";
import pointtypes from "./pointtype/reducer";
import zones from "./zones/reducer";
import segments from "./segments/reducer";
import beacons from "./beacons/reducer";
import walkways from "./walkways/reducer";
import storeIds from "./storeIds/reducer";
import pois from "./pois/reducer";

const rootReducer = combineReducers({
  auth: auth,
  users: users,
  customers: customers,
  placetypes,
  menuItems,
  settings,
  places,
  brands,
  categories,
  networktypes,
  pointtypes,
  zones,
  segments,
  walkways,
  beacons,
  storeIds,
  pois,
});

export default rootReducer;
