import * as actionTypes from "../actionTypes";

const intialState = {
  beaconsList: [],

  getBeaconsListLoader: false,
};

const beacons = (state = intialState, action) => {
  switch (action.type) {
    // //**GET_ZONE_LIST */
    // case actionTypes.GET_BEACONS_LIST: {
    //   return {
    //     ...state,
    //     getBeaconsListLoader: true,
    //   };
    // }
    // case actionTypes.GET_BEACONS_LIST_SUCCESS: {
    //   return {
    //     ...state,
    //     beaconsList: action.payload.beaconsList,
    //     getBeaconsListLoader: false,
    //   };
    // }
    // case actionTypes.GET_BEACONS_LIST_FAILURE: {
    //   return {
    //     ...state,
    //     getBeaconsListLoader: false,
    //   };
    // }

    case actionTypes.GET_BEACONS: {
      return {
        ...state,
        getBeaconsListLoader: true,
      };
    }

    case actionTypes.GET_BEACONS_SUCCESS: {
      return {
        ...state,
        beaconsList: action.payload,
        getBeaconsListLoader: false,
      };
    }

    case actionTypes.GET_BEACONS_FAILURE: {
      return {
        ...state,
        getBeaconsListLoader: false,
      };
    }

    case actionTypes.DELETE_BEACON: {
      return {
        ...state,
        deleteBeaconLoader: true,
      };
    }
    case actionTypes.DELETE_BEACON_SUCCESS: {
      const beaconId = action.payload;
      const beaconsListArray = [...state.beaconsList];
      const filterBeaconList = beaconsListArray.filter(
        (item) => item.id !== beaconId
      );
      return {
        ...state,
        beaconsList: filterBeaconList,
        deleteBeaconLoader: false,
      };
    }
    case actionTypes.DELETE_BEACON_FAILURE: {
      return {
        ...state,
        deleteBeaconLoader: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default beacons;
