import * as actionTypes from "../actionTypes";

// ** get language list action
export const getLanguageList = () => ({
  type: actionTypes.GET_LANGUAGE_LIST,
});
export const getLanguageListSuccess = (languageList) => ({
  type: actionTypes.GET_LANGUAGE_LIST_SUCCESS,
  payload: { languageList },
});
export const getLanguageListFailure = () => ({
  type: actionTypes.GET_LANGUAGE_LIST_FAILURE,
});

// ** add new language
export const postNewLanguage = (languageInfo) => ({
  type: actionTypes.POST_NEW_LANGUAGE,
  payload: { languageInfo },
});
export const postNewLanguageSuccess = (languageInfo) => ({
  type: actionTypes.POST_NEW_LANGUAGE_SUCCESS,
  payload: { languageInfo },
});
export const postNewLanguageFailure = () => ({
  type: actionTypes.POST_NEW_LANGUAGE_FAILURE,
});

// ** delete  language
export const deleteLanguage = (languageInfo) => ({
  type: actionTypes.DELETE_LANGUAGE,
  payload: { languageInfo },
});
export const deleteLanguageSuccess = (languageInfo) => ({
  type: actionTypes.DELETE_LANGUAGE_SUCCESS,
  payload: { languageInfo },
});
export const deleteLanguageFailure = () => ({
  type: actionTypes.DELETE_LANGUAGE_FAILURE,
});
