import * as servicesUrl from "../assets/data/urlConfig";
import axiosApiInctance from "../services/axiosInterceptor";

// ** get user list async
export const getBrandsListAsync = async () => {
  return await axiosApiInctance.get(`${servicesUrl.getBrandsListUrl}`);
};

// ** post new brand
export const postNewBrandAsync = async (brandInfo) => {
  const { name } = brandInfo;
  return await axiosApiInctance.post(`${servicesUrl.postNewBrandUrl}`, {
    name,
  });
};
// ** get deleted brand list
export const getDeletedBrandsListAsync = async () => {
  return await axiosApiInctance.get(`${servicesUrl.getDeletedBrandsListUrl}`);
};

// ** put brand
export const putBrandAsync = async (brandInfo) => {
  const { name } = brandInfo;
  return await axiosApiInctance.put(`${servicesUrl.putBrandUrl(brandInfo)}`, {
    name: name,
  });
};

// ** delete brand
export const deleteBrandAsync = async (brandInfo) => {
  return await axiosApiInctance.delete(
    `${servicesUrl.deleteBrandUrl(brandInfo)}`
  );
};

// ** put brand
export const putRestorBrandAsync = async (brandInfo) => {
  return await axiosApiInctance.put(
    `${servicesUrl.putRestoreBrandUrl(brandInfo)}`
  );
};
