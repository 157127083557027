import React from "react";
// import Logo from "../../assets/img/Logo.png";
import PropagateLoader from "react-spinners/PropagateLoader";
import themeConfig from "../../configs/themeConfigs";

const GlobalSpinner = () => {
  return (
    <div
      className="d-flex justify-content-center flex-column bg-white vh-100 vw-100 align-item-center"
      style={{ height: "100%", width: "100%" }}
    >
      {/* <img src={Logo} alt="logo" width={100} height={100} className={"mb-5"} /> */}
      <span className="fs-24 text-primary">
        <PropagateLoader
          color={themeConfig.colors.greenColor}
          loading={true}
          size={25}
          margin={10}
        />
      </span>
    </div>
  );
};

export default GlobalSpinner;
