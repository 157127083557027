export const mergedGeoJson = {
  1: {
    type: "FeatureCollection",
    name: "centroids-1wjvux",
    crs: {
      type: "name",
      properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" },
    },
    features: [
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "tuvalet",
          label: "erkek_WC",
          floor: "secondfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "centroids",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "icon-image": "icon_toilets_men",
          "icon-size": 0.33,
          "text-field": null,
          "text-size": null,
        },
        geometry: {
          type: "Point",
          coordinates: [29.040061784469078, 41.108905817951346],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "tuvalet",
          label: "kadın_WC",
          floor: "secondfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "centroids",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "icon-image": "icon_toilets_women",
          "icon-size": 0.33,
          "text-field": null,
          "text-size": null,
        },
        geometry: {
          type: "Point",
          coordinates: [29.040064487484685, 41.10889007794088],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "ofis",
          label: "201-202",
          floor: "secondfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "centroids",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "icon-image": null,
          "icon-size": null,
          "text-field": "label",
          "text-size": 11,
        },
        geometry: {
          type: "Point",
          coordinates: [29.040162549110235, 41.108991684894704],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "ofis",
          label: "203-204",
          floor: "secondfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "centroids",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "icon-image": null,
          "icon-size": null,
          "text-field": "label",
          "text-size": 11,
        },
        geometry: {
          type: "Point",
          coordinates: [29.04030196255842, 41.10900503483032],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "ofis",
          label: "205-206",
          floor: "secondfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "centroids",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "icon-image": null,
          "icon-size": null,
          "text-field": "label",
          "text-size": 11,
        },
        geometry: {
          type: "Point",
          coordinates: [29.04043578979738, 41.10901878530032],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "ofis",
          label: "207",
          floor: "secondfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "centroids",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "icon-image": null,
          "icon-size": null,
          "text-field": "label",
          "text-size": 11,
        },
        geometry: {
          type: "Point",
          coordinates: [29.04053897159281, 41.10902627371452],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "ofis",
          label: "208",
          floor: "secondfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "centroids",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "icon-image": null,
          "icon-size": null,
          "text-field": "label",
          "text-size": 11,
        },
        geometry: {
          type: "Point",
          coordinates: [29.04060487397854, 41.109034966011414],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "ofis",
          label: "209",
          floor: "secondfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "centroids",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "icon-image": null,
          "icon-size": null,
          "text-field": "label",
          "text-size": 11,
        },
        geometry: {
          type: "Point",
          coordinates: [29.040676426533686, 41.10903949698253],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "kafeterya",
          label: "kafeterya",
          floor: "secondfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "centroids",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "icon-image": null,
          "icon-size": null,
          "text-field": "label",
          "text-size": 11,
        },
        geometry: {
          type: "Point",
          coordinates: [29.040197838976052, 41.108913270512815],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "seminer salonu",
          label: "seminer salonu",
          floor: "secondfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "centroids",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "icon-image": null,
          "icon-size": null,
          "text-field": "label",
          "text-size": 11,
        },
        geometry: {
          type: "Point",
          coordinates: [29.040586672067015, 41.108942564353434],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "seminer salonu",
          label: "seminer salonu",
          floor: "secondfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "centroids",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "icon-image": null,
          "icon-size": null,
          "text-field": "label",
          "text-size": 11,
        },
        geometry: {
          type: "Point",
          coordinates: [29.04068969587968, 41.1089635851719],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "tuvalet",
          label: "erkek_WC",
          floor: "secondfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "centroids",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "icon-image": "icon_toilets_men",
          "icon-size": 0.33,
          "text-field": null,
          "text-size": null,
        },
        geometry: {
          type: "Point",
          coordinates: [29.040767154472963, 41.10897105653477],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "tuvalet",
          label: "kadin_WC",
          floor: "secondfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "centroids",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "icon-image": "icon_toilets_women",
          "icon-size": 0.33,
          "text-field": null,
          "text-size": null,
        },
        geometry: {
          type: "Point",
          coordinates: [29.040741127015018, 41.10896847124046],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "tuvalet",
          label: "erkek_WC",
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "centroids",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "icon-image": "icon_toilets_men",
          "icon-size": 0.33,
          "text-field": null,
          "text-size": null,
        },
        geometry: {
          type: "Point",
          coordinates: [29.030394987150974, 41.108344723674485],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "tuvalet",
          label: "kadın_WC",
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "centroids",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "icon-image": "icon_toilets_women",
          "icon-size": 0.33,
          "text-field": null,
          "text-size": null,
        },
        geometry: {
          type: "Point",
          coordinates: [29.030397880296587, 41.10832785751982],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "ofis",
          label: "10-11-12-13-14-15",
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "centroids",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "icon-image": null,
          "icon-size": null,
          "text-field": "label",
          "text-size": 11,
        },
        geometry: {
          type: "Point",
          coordinates: [29.030631869173515, 41.10844476599171],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "ofis",
          label: "Similasyon Alanı",
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "centroids",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "icon-image": null,
          "icon-size": null,
          "text-field": "label",
          "text-size": 11,
        },
        geometry: {
          type: "Point",
          coordinates: [29.030940429424152, 41.10847416603157],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "ofis",
          label: "20-21-22",
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "centroids",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "icon-image": null,
          "icon-size": null,
          "text-field": "label",
          "text-size": 11,
        },
        geometry: {
          type: "Point",
          coordinates: [29.030530498455686, 41.108352973715725],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "ofis",
          label: "19",
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "centroids",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "icon-image": null,
          "icon-size": null,
          "text-field": "label",
          "text-size": 11,
        },
        geometry: {
          type: "Point",
          coordinates: [29.031023511435702, 41.1084027565622],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "tuvalet",
          label: "kadın_WC",
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "centroids",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "icon-image": "icon_toilets_women",
          "icon-size": 0.33,
          "text-field": null,
          "text-size": null,
        },
        geometry: {
          type: "Point",
          coordinates: [29.031074535239593, 41.10840887589818],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "tuvalet",
          label: "erkek_WC",
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "centroids",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "icon-image": "icon_toilets_men",
          "icon-size": 0.33,
          "text-field": null,
          "text-size": null,
        },
        geometry: {
          type: "Point",
          coordinates: [29.031100823520735, 41.10841142519511],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "tuvalet",
          label: "erkek_WC",
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "centroids",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "icon-image": "icon_toilets_men",
          "icon-size": 0.33,
          "text-field": null,
          "text-size": null,
        },
        geometry: {
          type: "Point",
          coordinates: [29.03504390106421, 41.108647367685414],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "tuvalet",
          label: "kadın_WC",
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "centroids",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "icon-image": "icon_toilets_women",
          "icon-size": 0.33,
          "text-field": null,
          "text-size": null,
        },
        geometry: {
          type: "Point",
          coordinates: [29.035046619304474, 41.10863163629981],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "ofis",
          label: "101-102",
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "centroids",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "icon-image": null,
          "icon-size": null,
          "text-field": "label",
          "text-size": 11,
        },
        geometry: {
          type: "Point",
          coordinates: [29.035144780155544, 41.108733329697024],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "ofis",
          label: "103-104",
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "centroids",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "icon-image": null,
          "icon-size": null,
          "text-field": "label",
          "text-size": 11,
        },
        geometry: {
          type: "Point",
          coordinates: [29.03528323590777, 41.108746682306105],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "ofis",
          label: "105-106",
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "centroids",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "icon-image": null,
          "icon-size": null,
          "text-field": "label",
          "text-size": 11,
        },
        geometry: {
          type: "Point",
          coordinates: [29.03541421651575, 41.10876029615828],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "ofis",
          label: "107-108-109",
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "centroids",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "icon-image": null,
          "icon-size": null,
          "text-field": "label",
          "text-size": 11,
        },
        geometry: {
          type: "Point",
          coordinates: [29.03558319029144, 41.10877563812435],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "tuvalet",
          label: "kadın_WC",
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "centroids",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "icon-image": "icon_toilets_women",
          "icon-size": 0.33,
          "text-field": null,
          "text-size": null,
        },
        geometry: {
          type: "Point",
          coordinates: [29.035718570289472, 41.10871245984884],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "tuvalet",
          label: "erkek_WC",
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "centroids",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "icon-image": "icon_toilets_men",
          "icon-size": 0.33,
          "text-field": null,
          "text-size": null,
        },
        geometry: {
          type: "Point",
          coordinates: [29.03574461548581, 41.10871501150812],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "ofis",
          label: "110-111-112",
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "centroids",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "icon-image": null,
          "icon-size": null,
          "text-field": "label",
          "text-size": 11,
        },
        geometry: {
          type: "Point",
          coordinates: [29.035599281625313, 41.108697657303914],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "ofis",
          label: "113",
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "centroids",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "icon-image": null,
          "icon-size": null,
          "text-field": "label",
          "text-size": 11,
        },
        geometry: {
          type: "Point",
          coordinates: [29.03545970420097, 41.10868297144869],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "ofis",
          label: "Toplantı Odası",
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "centroids",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "icon-image": null,
          "icon-size": null,
          "text-field": "label",
          "text-size": 11,
        },
        geometry: {
          type: "Point",
          coordinates: [29.03524643757121, 41.10866482525751],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "ofis",
          label: "114-115",
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "centroids",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "icon-image": null,
          "icon-size": null,
          "text-field": "label",
          "text-size": 11,
        },
        geometry: {
          type: "Point",
          coordinates: [29.035157857517984, 41.108654039359756],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#2d2b2a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.03528008550567, 41.108672079362094],
              [29.03527336826395, 41.10866733128159],
              [29.03527198682162, 41.1086753846296],
              [29.03528008550567, 41.108672079362094],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "secondfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#2d2b2a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.04029665309772, 41.10894422701424],
              [29.040303378546426, 41.10894896005867],
              [29.04030475465209, 41.10894093785507],
              [29.04029665309772, 41.10894422701424],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "secondfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#2d2b2a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.04008594497023, 41.10891735192146],
              [29.040089696505156, 41.10892335245466],
              [29.040095349567533, 41.108918267762014],
              [29.04008594497023, 41.10891735192146],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "secondfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#2d2b2a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.040089696505156, 41.10892335245466],
              [29.040093448040086, 41.10892935298731],
              [29.040095349567533, 41.108918267762014],
              [29.040089696505156, 41.10892335245466],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#2d2b2a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.035277668291073, 41.10868620374319],
              [29.035284393739786, 41.10869093680622],
              [29.035285769845444, 41.1086829145711],
              [29.035277668291073, 41.10868620374319],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#2d2b2a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.035068702927305, 41.108658783977525],
              [29.035072454462238, 41.10866478453435],
              [29.035078107524615, 41.10865969982167],
              [29.035068702927305, 41.108658783977525],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#2d2b2a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.035072454462238, 41.10866478453435],
              [29.035076205997168, 41.10867078509063],
              [29.035078107524615, 41.10865969982167],
              [29.035072454462238, 41.10866478453435],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#2d2b2a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.035286802747397, 41.10867682744227],
              [29.03528008550567, 41.108672079362094],
              [29.03527198682162, 41.1086753846296],
              [29.035286802747397, 41.10867682744227],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#2d2b2a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.035277668291073, 41.10868620374319],
              [29.035270942842356, 41.108681470679834],
              [29.035269566736698, 41.10868949291512],
              [29.035277668291073, 41.10868620374319],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#2d2b2a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.035284393739786, 41.10869093680622],
              [29.035277668291073, 41.10868620374319],
              [29.035269566736698, 41.10868949291512],
              [29.035284393739786, 41.10869093680622],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#2d2b2a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.03528008550567, 41.108672079362094],
              [29.035286802747397, 41.10867682744227],
              [29.035288184189728, 41.108668774094426],
              [29.03528008550567, 41.108672079362094],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: "stairs",
          unti_subty: "stair_step",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#8a8a8a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.0307109108522, 41.108394209169234],
              [29.030713361741363, 41.10839361448094],
              [29.030709573849744, 41.10838475189586],
              [29.030708959980224, 41.10838494683703],
              [29.03070854742805, 41.10838508625912],
              [29.030708138123508, 41.108385231013386],
              [29.030707732188, 41.108385381056905],
              [29.030707329741897, 41.108385536345175],
              [29.030707222507253, 41.10838557949501],
              [29.0307109108522, 41.108394209169234],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: "stairs",
          unti_subty: "stair_step",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#8a8a8a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.0306937318877, 41.10838867637032],
              [29.030695220199128, 41.10839042635611],
              [29.030706528957058, 41.108385219214256],
              [29.030705051875092, 41.10838346405769],
              [29.0306937318877, 41.10838867637032],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: "stairs",
          unti_subty: "stair_step",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#8a8a8a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.030692217892646, 41.10838689618518],
              [29.0306937318877, 41.10838867637032],
              [29.030705051875092, 41.10838346405769],
              [29.030703549303293, 41.108381678612545],
              [29.030692217892646, 41.10838689618518],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: "stairs",
          unti_subty: "stair_step",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#8a8a8a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.030690697447216, 41.10838510841548],
              [29.030692217892646, 41.10838689618518],
              [29.030703549303293, 41.108381678612545],
              [29.030702040329768, 41.10837988556045],
              [29.030690697447216, 41.10838510841548],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: "stairs",
          unti_subty: "stair_step",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#8a8a8a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.03072787363821, 41.10839431640295],
              [29.030730371538205, 41.10839529940964],
              [29.030735875555287, 41.10838735929232],
              [29.030735908359183, 41.108386922417026],
              [29.03073354718543, 41.108386131720636],
              [29.03072787363821, 41.10839431640295],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: "stairs",
          unti_subty: "stair_step",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#8a8a8a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.03071863066684, 41.108392336019065],
              [29.030721972209903, 41.108392330091164],
              [29.030721660529323, 41.10839160084759],
              [29.030718117408373, 41.108383310955716],
              [29.030717698813724, 41.10838332989681],
              [29.030717248010856, 41.10838335617251],
              [29.03071679787536, 41.10838338829345],
              [29.03071634854073, 41.108383426250136],
              [29.030715900140216, 41.1083834700313],
              [29.03071545280681, 41.108383519623956],
              [29.030715006673162, 41.108383575013406],
              [29.030714892879107, 41.10838359066254],
              [29.03071863066684, 41.108392336019065],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: "stairs",
          unti_subty: "stair_step",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#8a8a8a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.030715942448197, 41.10839298829344],
              [29.03071863066684, 41.108392336019065],
              [29.030714892879107, 41.10838359066254],
              [29.030714561871587, 41.108383636183206],
              [29.030714118533986, 41.10838370311523],
              [29.030713676791848, 41.108383775789626],
              [29.03071323677618, 41.108383854184844],
              [29.03071279861745, 41.10838393827761],
              [29.030712362445634, 41.10838402804303],
              [29.03071213425273, 41.10838407820298],
              [29.030715942448197, 41.10839298829344],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: "stairs",
          unti_subty: "stair_step",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#8a8a8a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.030713361741363, 41.10839361448094],
              [29.030715942448197, 41.10839298829344],
              [29.03071213425273, 41.10838407820298],
              [29.03071192839006, 41.10838412345445],
              [29.030711496579457, 41.10838422448358],
              [29.030711067141887, 41.10838433110046],
              [29.030710640204706, 41.10838444327348],
              [29.030710215894526, 41.108384560969355],
              [29.03070979433718, 41.1083846841532],
              [29.030709573849744, 41.10838475189586],
              [29.030713361741363, 41.10839361448094],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "secondfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#2d2b2a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.040305787554036, 41.10893485075016],
              [29.04029907031232, 41.108930102688646],
              [29.040290971628263, 41.10893340794316],
              [29.040305787554036, 41.10893485075016],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "secondfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#2d2b2a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.04029907031232, 41.108930102688646],
              [29.040305787554036, 41.10893485075016],
              [29.040307168996375, 41.108926797433966],
              [29.04029907031232, 41.108930102688646],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "secondfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#2d2b2a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.04029665309772, 41.10894422701424],
              [29.040289927649006, 41.10893949396947],
              [29.04028855154334, 41.108947516173245],
              [29.04029665309772, 41.10894422701424],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "secondfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#2d2b2a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.040303378546426, 41.10894896005867],
              [29.04029665309772, 41.10894422701424],
              [29.04028855154334, 41.108947516173245],
              [29.040303378546426, 41.10894896005867],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: "stairs",
          unti_subty: "stair_step",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#8a8a8a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.030689104915897, 41.108383235885746],
              [29.030690697447216, 41.10838510841548],
              [29.030702040329768, 41.10837988556045],
              [29.030700459814263, 41.108378007497855],
              [29.030689104915897, 41.108383235885746],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "secondfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#2d2b2a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.04029907031232, 41.108930102688646],
              [29.040292353070598, 41.1089253546268],
              [29.040290971628263, 41.10893340794316],
              [29.04029907031232, 41.108930102688646],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: "stairs",
          unti_subty: "stair_step",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#8a8a8a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.03539683781844, 41.108681305220955],
              [29.0354091160901, 41.10868134000003],
              [29.035409127014816, 41.10867915045274],
              [29.035396848743154, 41.10867911567367],
              [29.03539683781844, 41.108681305220955],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: "stairs",
          unti_subty: "stair_step",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#8a8a8a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.03539682798529, 41.10868327599328],
              [29.035409106256953, 41.108683310772356],
              [29.0354091160901, 41.10868134000003],
              [29.03539683781844, 41.108681305220955],
              [29.03539682798529, 41.10868327599328],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: "stairs",
          unti_subty: "stair_step",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#8a8a8a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.03539681515567, 41.108685847324125],
              [29.035409093427326, 41.108685882103195],
              [29.035409106256953, 41.108683310772356],
              [29.03539682798529, 41.10868327599328],
              [29.03539681515567, 41.108685847324125],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "secondfloor",
          unit_type: "stairs",
          unti_subty: "stair_step",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#8a8a8a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.040367047360775, 41.10893642722925],
              [29.040378874159895, 41.10893354450698],
              [29.040378023625625, 41.10893129471765],
              [29.040366101015096, 41.10893420079356],
              [29.040367047360775, 41.10893642722925],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#2d2b2a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.0306331999714, 41.10836960260443],
              [29.03062648272968, 41.10836485450204],
              [29.03062510128735, 41.108372907887166],
              [29.0306331999714, 41.10836960260443],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: "stairs",
          unti_subty: "stair_step",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#8a8a8a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.035396868615624, 41.108675132806354],
              [29.035409146887286, 41.108675167585425],
              [29.035409158069868, 41.108672926356284],
              [29.035396879798213, 41.108672891577214],
              [29.035396868615624, 41.108675132806354],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#2d2b2a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.030638884311173, 41.108380437863346],
              [29.030630782756802, 41.108383727050594],
              [29.030637508205515, 41.10838846013542],
              [29.030638884311173, 41.108380437863346],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: "stairs",
          unti_subty: "stair_step",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#8a8a8a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.035396858547127, 41.10867715074891],
              [29.03540913681878, 41.10867718552799],
              [29.035409146887286, 41.108675167585425],
              [29.035396868615624, 41.108675132806354],
              [29.035396858547127, 41.10867715074891],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#2d2b2a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.030424167937134, 41.10836201017456],
              [29.0304204164022, 41.10835600959006],
              [29.030418514874757, 41.10836709491029],
              [29.030424167937134, 41.10836201017456],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: "stairs",
          unti_subty: "stair_step",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#8a8a8a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.035396848743154, 41.10867911567367],
              [29.035409127014816, 41.10867915045274],
              [29.03540913681878, 41.10867718552799],
              [29.035396858547127, 41.10867715074891],
              [29.035396848743154, 41.10867911567367],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#2d2b2a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.03527336826395, 41.10866733128159],
              [29.03528008550567, 41.108672079362094],
              [29.035288184189728, 41.108668774094426],
              [29.03527336826395, 41.10866733128159],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: "stairs",
          unti_subty: "stair_step",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#8a8a8a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.030732733666973, 41.108396228985825],
              [29.030735138490474, 41.1083971753638],
              [29.030735510323606, 41.1083922233699],
              [29.030732733666973, 41.108396228985825],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#2d2b2a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.035270942842356, 41.108681470679834],
              [29.035277668291073, 41.10868620374319],
              [29.035285769845444, 41.1086829145711],
              [29.035270942842356, 41.108681470679834],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: "stairs",
          unti_subty: "stair_step",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#8a8a8a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.03072787363821, 41.10839431640295],
              [29.030733547184656, 41.10838613172037],
              [29.03073076054935, 41.10838519218867],
              [29.03072517256397, 41.1083932534406],
              [29.03072787363821, 41.10839431640295],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "secondfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#2d2b2a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.040089696505156, 41.10892335245466],
              [29.04008594497023, 41.10891735192146],
              [29.040084043442786, 41.108928437146915],
              [29.040089696505156, 41.10892335245466],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#2d2b2a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.035072454462238, 41.10866478453435],
              [29.035068702927305, 41.108658783977525],
              [29.03506680139986, 41.108669869246626],
              [29.035072454462238, 41.10866478453435],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: "stairs",
          unti_subty: "stair_step",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#8a8a8a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.030730371538205, 41.10839529940964],
              [29.030732733666973, 41.108396228985825],
              [29.030735510323606, 41.1083922233699],
              [29.030735875555287, 41.10838735929232],
              [29.030730371538205, 41.10839529940964],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: "stairs",
          unti_subty: "stair_step",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#8a8a8a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.035344649141383, 41.108672944059],
              [29.035356822397812, 41.10867036440328],
              [29.035356047808783, 41.10866834974655],
              [29.035343797209386, 41.10867094579223],
              [29.035344649141383, 41.108672944059],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: "stairs",
          unti_subty: "stair_step",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#8a8a8a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.035345479492793, 41.108674891706926],
              [29.03535757736546, 41.108672328026],
              [29.035356822397812, 41.10867036440328],
              [29.035344649141383, 41.108672944059],
              [29.035345479492793, 41.108674891706926],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: "stairs",
          unti_subty: "stair_step",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#8a8a8a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.035346389340166, 41.10867702581816],
              [29.03535840461199, 41.10867447964141],
              [29.03535757736546, 41.108672328026],
              [29.035345479492793, 41.108674891706926],
              [29.035346389340166, 41.10867702581816],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: "stairs",
          unti_subty: "stair_step",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#8a8a8a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.03534725086958, 41.10867904659617],
              [29.035359187927135, 41.108676516994024],
              [29.03535840461199, 41.10867447964141],
              [29.035346389340166, 41.10867702581816],
              [29.03534725086958, 41.10867904659617],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#2d2b2a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.030427919472057, 41.10836801075851],
              [29.030424167937134, 41.10836201017456],
              [29.030418514874757, 41.10836709491029],
              [29.030427919472057, 41.10836801075851],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#2d2b2a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.0306331999714, 41.10836960260443],
              [29.03063991721312, 41.10837435070648],
              [29.030641298655457, 41.10836629732153],
              [29.0306331999714, 41.10836960260443],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#2d2b2a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.035076205997168, 41.10867078509063],
              [29.035072454462238, 41.10866478453435],
              [29.03506680139986, 41.108669869246626],
              [29.035076205997168, 41.10867078509063],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#2d2b2a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.0304204164022, 41.10835600959006],
              [29.030424167937134, 41.10836201017456],
              [29.030429820999505, 41.10835692543844],
              [29.0304204164022, 41.10835600959006],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "secondfloor",
          unit_type: "stairs",
          unti_subty: "stair_step",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#8a8a8a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.04036524957943, 41.108932197649395],
              [29.04037725839233, 41.108929270561994],
              [29.040376469405448, 41.1089271835745],
              [29.04036437171436, 41.108930132325604],
              [29.04036524957943, 41.108932197649395],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#2d2b2a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.030424167937134, 41.10836201017456],
              [29.030427919472057, 41.10836801075851],
              [29.030429820999505, 41.10835692543844],
              [29.030424167937134, 41.10836201017456],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "secondfloor",
          unit_type: "stairs",
          unti_subty: "stair_step",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#8a8a8a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.040366101015096, 41.10893420079356],
              [29.040378023625625, 41.10893129471765],
              [29.04037725839233, 41.108929270561994],
              [29.04036524957943, 41.108932197649395],
              [29.040366101015096, 41.10893420079356],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#2d2b2a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.030630782756802, 41.108383727050594],
              [29.030622681202424, 41.108387016237685],
              [29.030637508205515, 41.10838846013542],
              [29.030630782756802, 41.108383727050594],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "secondfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#2d2b2a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.040292353070598, 41.1089253546268],
              [29.04029907031232, 41.108930102688646],
              [29.040307168996375, 41.108926797433966],
              [29.040292353070598, 41.1089253546268],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "secondfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#2d2b2a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.040289927649006, 41.10893949396947],
              [29.04029665309772, 41.10894422701424],
              [29.04030475465209, 41.10894093785507],
              [29.040289927649006, 41.10893949396947],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#2d2b2a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.03062648272968, 41.10836485450204],
              [29.0306331999714, 41.10836960260443],
              [29.030641298655457, 41.10836629732153],
              [29.03062648272968, 41.10836485450204],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#2d2b2a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.03063991721312, 41.10837435070648],
              [29.0306331999714, 41.10836960260443],
              [29.03062510128735, 41.108372907887166],
              [29.03063991721312, 41.10837435070648],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#2d2b2a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.030630782756802, 41.108383727050594],
              [29.030638884311173, 41.108380437863346],
              [29.030624057308085, 41.10837899396543],
              [29.030630782756802, 41.108383727050594],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#2d2b2a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.030622681202424, 41.108387016237685],
              [29.030630782756802, 41.108383727050594],
              [29.030624057308085, 41.10837899396543],
              [29.030622681202424, 41.108387016237685],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "secondfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "line-color": "#2d2b2a",
          type: "line",
          source: "composite",
        },
        geometry: {
          type: "MultiLineString",
          coordinates: [
            [
              [29.040093448040086, 41.10892935298731],
              [29.040089696505156, 41.10892335245466],
              [29.040084043442786, 41.108928437146915],
              [29.040093448040086, 41.10892935298731],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: null,
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "border",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/border.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#ffffff",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030359448714794, 41.108287944533764],
                [29.03035350311168, 41.10828736553221],
                [29.03032132806609, 41.10847493610584],
                [29.031123298330545, 41.10855303422632],
                [29.031131739509973, 41.10855385625162],
                [29.031163914555574, 41.10836628590343],
                [29.03115547337613, 41.10836546387578],
                [29.030359448714794, 41.108287944533764],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: null,
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "border",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/border.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#ffffff",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03501331645894, 41.10857274912198],
                [29.035007370855826, 41.10857217012043],
                [29.034975195810237, 41.10875974069406],
                [29.03577716607469, 41.10883783881454],
                [29.03578560725412, 41.10883866083984],
                [29.03581778229972, 41.10865109049165],
                [29.035809341120277, 41.108650268464],
                [29.03501331645894, 41.10857274912198],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "2",
          poly_type: null,
          label: null,
          floor: null,
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "border",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/border.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#ffffff",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.0400246424331, 41.1088338594324],
                [29.040018696829986, 41.10883328043085],
                [29.039986521784396, 41.10902085100448],
                [29.04078849204885, 41.109098949124956],
                [29.04079693322828, 41.109099771150255],
                [29.04082910827388, 41.10891220080207],
                [29.040820667094437, 41.108911378774415],
                [29.0400246424331, 41.1088338594324],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: null,
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "floor",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/floor.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#ffffff",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030776686655848, 41.108404367189465],
                [29.030777526378074, 41.108399471868786],
                [29.030779769687232, 41.10839969032887],
                [29.03078250629666, 41.108383736739924],
                [29.030780262987495, 41.108383518279894],
                [29.030782482062673, 41.10837058174701],
                [29.030783592915142, 41.10836410119259],
                [29.030784880722756, 41.10835659366425],
                [29.03078790232627, 41.10835688791658],
                [29.03079025885872, 41.108343150053656],
                [29.03077866674217, 41.108342021177805],
                [29.030757918536498, 41.108340000653854],
                [29.030720993278397, 41.108336404758724],
                [29.030718636745885, 41.10835014262302],
                [29.030625377979558, 41.108341102638306],
                [29.03062205073717, 41.10836049944643],
                [29.03064623025337, 41.108362854120685],
                [29.030645453118904, 41.10836738457649],
                [29.030644243185314, 41.108374439153025],
                [29.030643123182653, 41.10838096842419],
                [29.03064174499221, 41.1083890018114],
                [29.03064136970438, 41.10839118962376],
                [29.030646232985482, 41.10839166322453],
                [29.030645933282884, 41.10839341039816],
                [29.03058935031582, 41.10838790017915],
                [29.030589774332228, 41.10838542829542],
                [29.03057906193056, 41.108384385074736],
                [29.0305786379117, 41.1083868569728],
                [29.030568608618566, 41.108385880290285],
                [29.03056921971579, 41.108382317783345],
                [29.030510652230713, 41.10837661430735],
                [29.030510041133454, 41.10838017681453],
                [29.03050001184032, 41.10837920013201],
                [29.030501544251386, 41.1083702666522],
                [29.030505702830947, 41.10837067162705],
                [29.0305055294737, 41.10837168224563],
                [29.030508836776754, 41.10837200432023],
                [29.030510065710068, 41.108364840026425],
                [29.030474344433067, 41.10836136138271],
                [29.03047303514866, 41.10836789027086],
                [29.030477740106356, 41.10836834845389],
                [29.030476275169626, 41.10837688858347],
                [29.030438104168702, 41.10837317137615],
                [29.03043764792673, 41.108375831126146],
                [29.030429984908473, 41.1083750848783],
                [29.030430441150436, 41.10837242512832],
                [29.0304258767511, 41.10837198063329],
                [29.030426149002118, 41.10837039349386],
                [29.03041808377693, 41.10836960807778],
                [29.030417811525922, 41.10837119521722],
                [29.030413368977943, 41.108370762588436],
                [29.030417689187185, 41.10834557709496],
                [29.03041853109774, 41.108340669013636],
                [29.030419075861055, 41.10833749321],
                [29.030419930671474, 41.10833250992529],
                [29.030421804910528, 41.10832158368118],
                [29.030410491178746, 41.108320485999094],
                [29.030408616939695, 41.10833141224317],
                [29.030407762818186, 41.10833639151106],
                [29.030407218054872, 41.108339567314694],
                [29.030406376144125, 41.10834447539658],
                [29.030402055934832, 41.108369660890006],
                [29.03039701463015, 41.108369169952475],
                [29.030397286881158, 41.10836758281299],
                [29.03038888073313, 41.108366764196774],
                [29.03038860848212, 41.10836835133629],
                [29.030381538477393, 41.10836766283778],
                [29.030381010487744, 41.1083707408547],
                [29.03037831702088, 41.108370478556736],
                [29.030376701881835, 41.108379894318986],
                [29.030379395348703, 41.1083801566169],
                [29.030379110808234, 41.108381815399916],
                [29.03038743390558, 41.10838262592818],
                [29.030387143292554, 41.10838432011227],
                [29.030395681954293, 41.108385151632795],
                [29.030395972567316, 41.10838345744873],
                [29.030403929553444, 41.10838423232396],
                [29.030403638940413, 41.10838592650799],
                [29.03041217405962, 41.108386757683554],
                [29.030412464672654, 41.10838506349951],
                [29.030428013481366, 41.10838657768921],
                [29.03042828774704, 41.108384978805205],
                [29.030435950765295, 41.10838572505294],
                [29.03043501955973, 41.108391153693006],
                [29.030473190560677, 41.10839487089931],
                [29.030471831506862, 41.10840279376093],
                [29.030467383276015, 41.10840236057895],
                [29.030466033590102, 41.10840992636696],
                [29.030496962057853, 41.108412938272835],
                [29.030498243322405, 41.108405468899356],
                [29.030495499756203, 41.10840520172272],
                [29.03049685881001, 41.10839727886137],
                [29.030506888103144, 41.10839825554389],
                [29.03050634542721, 41.10840172163668],
                [29.030564912912286, 41.108407425112674],
                [29.030565524009546, 41.108403862605485],
                [29.03057555330268, 41.10840483928801],
                [29.030574020891613, 41.108413772767825],
                [29.030569862312053, 41.10841336779297],
                [29.0305700356693, 41.10841235717439],
                [29.030566659566205, 41.10841185986053],
                [29.030565332476023, 41.108419596384415],
                [29.030600796421254, 41.10842304996852],
                [29.03060195023544, 41.10841632359106],
                [29.03059774865355, 41.108415914428676],
                [29.03059925254988, 41.108407147180124],
                [29.030643823018973, 41.108411487584256],
                [29.030643211921742, 41.1084150500912],
                [29.03070177940682, 41.10842075356719],
                [29.03070239050408, 41.10841719106001],
                [29.03071241979722, 41.108418167742535],
                [29.030710887386153, 41.10842710122235],
                [29.030706728806585, 41.1084266962475],
                [29.030706902163832, 41.10842568562891],
                [29.030703497752558, 41.108425353339896],
                [29.030702198970562, 41.10843292483894],
                [29.030737920247578, 41.10843640348278],
                [29.03073903849599, 41.10842988444291],
                [29.0307346078333, 41.10842945297217],
                [29.030736147296178, 41.1084204783808],
                [29.03081539356297, 41.108428195611864],
                [29.03081403450916, 41.10843611847349],
                [29.030809488401886, 41.10843567576047],
                [29.030808189744224, 41.10844324652933],
                [29.030842932699528, 41.108446629898374],
                [29.030844231361623, 41.10843905912324],
                [29.030842361790775, 41.10843887706615],
                [29.03084218845226, 41.10843988770393],
                [29.03083748855323, 41.108439430014066],
                [29.030839020973954, 41.108430496517414],
                [29.030849050267086, 41.108431473199936],
                [29.03084843916986, 41.10843503570688],
                [29.03090671497072, 41.1084407107778],
                [29.03090732606892, 41.108437148265104],
                [29.030951989658718, 41.10844149772607],
                [29.03095063060491, 41.10844942058769],
                [29.030946182374056, 41.108448987405716],
                [29.03094502817008, 41.10845657224842],
                [29.03098077041776, 41.10846005293078],
                [29.03098206907628, 41.10845248215882],
                [29.030974611991503, 41.10845175596573],
                [29.030975971045315, 41.10844383310438],
                [29.030986000338448, 41.1084448097869],
                [29.030985389241227, 41.108448372293836],
                [29.031043665042077, 41.10845404736476],
                [29.03104427613967, 41.10845048485562],
                [29.031048415467687, 41.10845088795575],
                [29.031049331218775, 41.10844554941138],
                [29.031056994237026, 41.108446295659114],
                [29.031056770562337, 41.10844759961374],
                [29.031072610379347, 41.10844914213946],
                [29.031072269175326, 41.108451131252764],
                [29.03108163154915, 41.108452042989704],
                [29.031081968356958, 41.10845006916822],
                [29.031095669598077, 41.10845138771168],
                [29.031095337390727, 41.1084533243771],
                [29.03113443770499, 41.10845713207912],
                [29.031134826512112, 41.10845486545881],
                [29.03114005054249, 41.10845537419006],
                [29.031142487317272, 41.108441168560745],
                [29.031137263286933, 41.108440659829405],
                [29.031111440553424, 41.10843814513612],
                [29.03111086032614, 41.1084415409546],
                [29.03110703418983, 41.10844115734571],
                [29.031107946093332, 41.108435820421164],
                [29.031098497797313, 41.10843490018523],
                [29.031098150762467, 41.10843692329122],
                [29.03106860123031, 41.10843404567371],
                [29.03106894826515, 41.10843202256763],
                [29.031059598754013, 41.108431112129146],
                [29.031058691398453, 41.10843640173232],
                [29.0310510283802, 41.10843565548447],
                [29.031051525503486, 41.10843275740792],
                [29.03104738617552, 41.10843235430785],
                [29.031047898081514, 41.10842937005511],
                [29.030989622280636, 41.1084236949843],
                [29.030989110374296, 41.108426679238995],
                [29.030979081081167, 41.1084257025566],
                [29.03098044013497, 41.108417779695245],
                [29.030987755905713, 41.10841849212687],
                [29.030989031642314, 41.10841108483394],
                [29.030957722278696, 41.108408078368846],
                [29.0309550744417, 41.10842351547537],
                [29.030944432570593, 41.10842247913883],
                [29.030944732273195, 41.10842073196596],
                [29.030934052222047, 41.1084196951608],
                [29.030933727917425, 41.108421585756076],
                [29.030931484608253, 41.10842136729608],
                [29.030936580440155, 41.10839166017528],
                [29.030921156725316, 41.10839015816717],
                [29.03091992277235, 41.10839731794288],
                [29.030924934173903, 41.108397885120446],
                [29.030924451952643, 41.10840038224218],
                [29.03092258564217, 41.10840019136705],
                [29.030921449980198, 41.10840681192468],
                [29.03092354193683, 41.10840701564558],
                [29.03092157591678, 41.1084184769321],
                [29.030923515759998, 41.10841866583973],
                [29.0309232160574, 41.10842041301265],
                [29.030682112201802, 41.10839693360864],
                [29.030682411904394, 41.1083951864351],
                [29.030776686655848, 41.108404367189465],
              ],
              [
                [29.030735138490474, 41.1083971753638],
                [29.030722788600734, 41.10839231527161],
                [29.03071863066684, 41.108392336019065],
                [29.030709656612174, 41.10839451350036],
                [29.03070892653535, 41.108394499500506],
                [29.030708196871146, 41.10839447630692],
                [29.030707467823486, 41.10839444392607],
                [29.03070673959611, 41.10839440236703],
                [29.030706012392542, 41.10839435164141],
                [29.030705286416005, 41.10839429176337],
                [29.030704561869396, 41.108394222749645],
                [29.030703838955194, 41.10839414461954],
                [29.03070311787544, 41.108394057394875],
                [29.03070239883165, 41.10839396110004],
                [29.03070168202476, 41.108393855761925],
                [29.030700967655118, 41.10839374140998],
                [29.030700255922362, 41.10839361807617],
                [29.030699547025392, 41.10839348579496],
                [29.03069884116232, 41.108393344603314],
                [29.03069813853042, 41.108393194540696],
                [29.030697439326058, 41.10839303564903],
                [29.030689104915897, 41.108383235885746],
                [29.030700459814263, 41.108378007497855],
                [29.030706930904564, 41.10838569683215],
                [29.030707329741897, 41.108385536345175],
                [29.030707732187995, 41.108385381056905],
                [29.030708138123508, 41.108385231013386],
                [29.030708547428045, 41.10838508625912],
                [29.030708959980224, 41.10838494683703],
                [29.03070937565769, 41.10838481278847],
                [29.03070979433718, 41.1083846841532],
                [29.030710215894526, 41.108384560969355],
                [29.030710640204706, 41.10838444327348],
                [29.030711067141883, 41.10838433110046],
                [29.030711496579457, 41.10838422448358],
                [29.03071192839006, 41.10838412345445],
                [29.03071213425273, 41.10838407820298],
                [29.030712362445634, 41.10838402804303],
                [29.03071279861745, 41.10838393827761],
                [29.03071323677618, 41.10838385418484],
                [29.030713676791848, 41.108383775789626],
                [29.030714118533986, 41.10838370311523],
                [29.030714561871587, 41.108383636183206],
                [29.030715006673162, 41.1083835750134],
                [29.030715452806806, 41.108383519623956],
                [29.030715900140216, 41.1083834700313],
                [29.03071634854073, 41.10838342625013],
                [29.03071679787536, 41.10838338829344],
                [29.030717248010856, 41.1083833561725],
                [29.030717698813724, 41.10838332989681],
                [29.030718117408373, 41.108383310955716],
                [29.030718150150268, 41.10838330947417],
                [29.03071860188665, 41.10838329491064],
                [29.030719053888895, 41.10838328621055],
                [29.030719506022955, 41.10838328337645],
                [29.030719958154737, 41.10838328640921],
                [29.03072041015017, 41.10838329530792],
                [29.030720861875196, 41.10838331006993],
                [29.03072131319587, 41.108383330690884],
                [29.03072176397832, 41.108383357164655],
                [29.03072221408888, 41.10838338948339],
                [29.03072266339406, 41.10838342763751],
                [29.030723111760604, 41.108383471615696],
                [29.030723559055552, 41.108383521404896],
                [29.03072400514625, 41.10838357699036],
                [29.030724449900415, 41.108383638355605],
                [29.030724893186125, 41.10838370548242],
                [29.030725334871942, 41.108383778350905],
                [29.030725774826863, 41.10838385693945],
                [29.030726212920424, 41.10838394122474],
                [29.0307266490227, 41.108384031181785],
                [29.030727083004347, 41.10838412678391],
                [29.030727514736682, 41.10838422800276],
                [29.03072794409166, 41.10838433480832],
                [29.030728370941944, 41.108384447168916],
                [29.030728795160964, 41.10838456505122],
                [29.03072921662289, 41.10838468842027],
                [29.03072963520275, 41.108384817239504],
                [29.030730050776395, 41.108384951470676],
                [29.030730463220593, 41.10838509107401],
                [29.030730514185013, 41.10838510912538],
                [29.03073076054935, 41.10838519218867],
                [29.030733547184653, 41.10838613172037],
                [29.030735908359183, 41.108386922417026],
                [29.030735138490474, 41.1083971753638],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: null,
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "floor",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/floor.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#ffffff",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035025377680917, 41.108682276538815],
                [29.0350280707805, 41.10868253880097],
                [29.035027786239883, 41.10868419758403],
                [29.035036109337376, 41.108685008112246],
                [29.035035818724353, 41.10868670229635],
                [29.035044357386088, 41.10868753381687],
                [29.035044647999115, 41.108685839632805],
                [29.035052604985243, 41.10868661450803],
                [29.03505231437221, 41.108688308692074],
                [29.03506084949142, 41.108689139867636],
                [29.03506114010445, 41.10868744568358],
                [29.035076688913165, 41.10868895987328],
                [29.035076963178838, 41.10868736098928],
                [29.03508462619709, 41.10868810723702],
                [29.035083694991524, 41.10869353587708],
                [29.035121865992473, 41.10869725308338],
                [29.035120506938664, 41.108705175945],
                [29.03511553609998, 41.10870469184293],
                [29.035114254830695, 41.10871216124336],
                [29.035145183298447, 41.108715173149236],
                [29.035146464563, 41.10870770377576],
                [29.035143720996796, 41.10870743659911],
                [29.03514508005061, 41.10869951373777],
                [29.03515510934375, 41.10870049042029],
                [29.035154498246516, 41.10870405292726],
                [29.035212206674988, 41.10870967274578],
                [29.035212817772248, 41.10870611023863],
                [29.03522284706538, 41.10870708692115],
                [29.03522131465431, 41.108716020400955],
                [29.035217156074754, 41.108715615426114],
                [29.035217329432, 41.108714604807524],
                [29.03521480478107, 41.10871435894951],
                [29.035213506072715, 41.108721929821506],
                [29.03524774470456, 41.10872526404858],
                [29.035249026106804, 41.108718550005385],
                [29.035244824524913, 41.108718140842996],
                [29.035246328421238, 41.10870937359444],
                [29.035290898890338, 41.108713713998576],
                [29.035290287793103, 41.10871727650557],
                [29.035348367168414, 41.10872293244798],
                [29.035348978265667, 41.10871936994076],
                [29.0353590075588, 41.10872034662328],
                [29.035357475147737, 41.10872928010309],
                [29.03535331656818, 41.10872887512824],
                [29.03535348992542, 41.10872786450966],
                [29.03535096895198, 41.10872761893983],
                [29.035349670273167, 41.108735189761504],
                [29.035384561685593, 41.108738587590665],
                [29.035385679953542, 41.10873206855274],
                [29.03538095682716, 41.10873160860104],
                [29.03538249629004, 41.10872263400967],
                [29.03546174255684, 41.10873035124073],
                [29.035460383503025, 41.10873827410236],
                [29.035455564926306, 41.10873780485548],
                [29.03545400090175, 41.10874692254783],
                [29.035485303125434, 41.1087499708477],
                [29.03548669385598, 41.10874186379877],
                [29.03548321730467, 41.108741525242074],
                [29.035484749725388, 41.108732591745415],
                [29.03549477901852, 41.108733568427944],
                [29.035494167921296, 41.108737130934884],
                [29.035552443722153, 41.1087428060058],
                [29.03555305482035, 41.10873924349311],
                [29.035597718410155, 41.10874359295407],
                [29.035596359356354, 41.10875151581569],
                [29.0355919111255, 41.10875108263372],
                [29.035590332479988, 41.10876028554773],
                [29.035621359013582, 41.10876330700038],
                [29.035622937663597, 41.1087541040897],
                [29.035619256000327, 41.10875374555839],
                [29.03562061505414, 41.10874582269704],
                [29.035630644347272, 41.10874679937955],
                [29.03563003325005, 41.10875036188649],
                [29.0356878365303, 41.10875599094195],
                [29.03568844762788, 41.10875242843287],
                [29.03569258695591, 41.108752831532996],
                [29.035693502706984, 41.10874749298863],
                [29.03570116572523, 41.10874823923637],
                [29.035700855719988, 41.10875004647144],
                [29.035716695536994, 41.10875158899717],
                [29.03571635433297, 41.108753578110466],
                [29.035725717151543, 41.10875448989043],
                [29.03572605659967, 41.10875250059565],
                [29.03573975475919, 41.10875383454904],
                [29.03573939735841, 41.108755822084994],
                [29.035775081118608, 41.10875929707047],
                [29.035778217267524, 41.108741014473665],
                [29.03575530945122, 41.10873878364381],
                [29.035754364017368, 41.10874426236885],
                [29.035750685440057, 41.10874390199316],
                [29.03575160921234, 41.108738548795365],
                [29.035742688331105, 41.108737667257316],
                [29.035742341296267, 41.10873969036331],
                [29.035712791764098, 41.108736812745796],
                [29.03571313879895, 41.10873478963972],
                [29.035703746040447, 41.10873386207675],
                [29.035702831140874, 41.108739163858196],
                [29.035695168118135, 41.10873841760996],
                [29.03569566817417, 41.10873551981895],
                [29.035691576709315, 41.10873512137994],
                [29.0356921888456, 41.10873155281578],
                [29.035634366060673, 41.10872592186102],
                [29.035633753924557, 41.1087294904242],
                [29.035623724631424, 41.1087285137418],
                [29.035625083685236, 41.10872059088046],
                [29.035632747291608, 41.10872133718533],
                [29.035634074734684, 41.10871359870635],
                [29.035598598003634, 41.10871014383682],
                [29.035597443873936, 41.10871687198856],
                [29.03560167819099, 41.10871728433906],
                [29.035600145808203, 41.10872621756603],
                [29.035556320866128, 41.10872194976209],
                [29.035556963785087, 41.10871820178143],
                [29.035498527245558, 41.10871251105758],
                [29.035497904053592, 41.1087161442217],
                [29.03548787476046, 41.10871516753931],
                [29.03548923381427, 41.10870724467795],
                [29.035496855671166, 41.10870798691714],
                [29.035498171095718, 41.10870036411658],
                [29.035462623172958, 41.108696902354616],
                [29.035461460792373, 41.10870367867439],
                [29.035465811150328, 41.10870410232119],
                [29.035464287070436, 41.10871298723491],
                [29.035425445037415, 41.108709204718195],
                [29.035422502207588, 41.10870891813939],
                [29.03543099753791, 41.108659392976634],
                [29.035344479459265, 41.10865091557066],
                [29.035272732524877, 41.10864388679687],
                [29.035269480051184, 41.10866284772626],
                [29.035293659567387, 41.108665202400516],
                [29.03529288243292, 41.10866973285632],
                [29.03529167249933, 41.108676787432856],
                [29.03529055249667, 41.108683316704024],
                [29.035289174306225, 41.108691350091235],
                [29.035288799018396, 41.10869353790359],
                [29.035293662299498, 41.10869401150436],
                [29.0352933625969, 41.10869575867799],
                [29.035236779629837, 41.10869024845898],
                [29.035237203646243, 41.10868777657525],
                [29.0352264546495, 41.10868671246904],
                [29.035226027708156, 41.108689201404296],
                [29.035215998415023, 41.108688224721774],
                [29.035216609512283, 41.108684662214856],
                [29.0351596215547, 41.108679112557944],
                [29.035158441652143, 41.10868261967318],
                [29.03514841235901, 41.10868164299066],
                [29.035149944770076, 41.10867270951085],
                [29.035154103349637, 41.1086731144857],
                [29.035155158933858, 41.108666960810915],
                [29.035122558720968, 41.108663786132425],
                [29.03512143566735, 41.10867033312951],
                [29.035126140625046, 41.108670791312534],
                [29.035124675688316, 41.108679331442126],
                [29.035086509525183, 41.108675614705916],
                [29.035086043492175, 41.108678349062615],
                [29.035078378496912, 41.108677614340785],
                [29.035078817085314, 41.108675016748954],
                [29.035074790653756, 41.10867462464296],
                [29.035075062904774, 41.10867303750353],
                [29.035066997679586, 41.10867225208745],
                [29.035066725428578, 41.10867383922689],
                [29.0350622828806, 41.10867340659811],
                [29.03506660308984, 41.10864822110463],
                [29.035068405593865, 41.10864839663801],
                [29.03506924750442, 41.1086434885567],
                [29.035067445000397, 41.10864331302331],
                [29.03506798976371, 41.10864013721967],
                [29.035069792267734, 41.10864031275307],
                [29.035070647078154, 41.108635329468356],
                [29.03506884457413, 41.10863515393496],
                [29.035070337674117, 41.10862644961593],
                [29.035059021712538, 41.10862536493286],
                [29.03505753084235, 41.10863405625284],
                [29.035055751344697, 41.10863388295988],
                [29.035054910407737, 41.10863886351172],
                [29.035056676720842, 41.10863903552073],
                [29.03505613195753, 41.108642211324366],
                [29.035054329453505, 41.10864203579097],
                [29.035053487542758, 41.10864694387287],
                [29.03505529004678, 41.10864711940625],
                [29.03505096983749, 41.10867230489968],
                [29.035045928532806, 41.10867181396215],
                [29.035046200783814, 41.10867022682266],
                [29.035037794635787, 41.108669408206445],
                [29.035037522384776, 41.10867099534596],
                [29.03503045238005, 41.10867030684745],
                [29.0350299243904, 41.108673384864375],
                [29.035027230923536, 41.108673122566415],
                [29.035025377680917, 41.108682276538815],
              ],
              [
                [29.03535918792713, 41.108676516994024],
                [29.03534725086958, 41.10867904659617],
                [29.03534176773503, 41.1086661855163],
                [29.035396863628684, 41.108671697585095],
                [29.035355753681568, 41.1086675847404],
                [29.03535918792713, 41.108676516994024],
              ],
              [
                [29.035409071903374, 41.1086729189619],
                [29.035409093427326, 41.108685882103195],
                [29.03539681515567, 41.108685847324125],
                [29.035396863628687, 41.108671697585095],
                [29.035409071903374, 41.1086729189619],
              ],
              [
                [29.035328564251156, 41.108699770267975],
                [29.035328863953747, 41.10869802309443],
                [29.035414103394473, 41.108706323886764],
                [29.035413803691874, 41.108708071060086],
                [29.035328564251156, 41.108699770267975],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: null,
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "floor",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/floor.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#ffffff",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040511370111, 41.10894916043809],
                [29.040515849515323, 41.10892304686847],
                [29.040467408813317, 41.108918403660255],
                [29.040356470835125, 41.10890776987153],
                [29.040355831672485, 41.108911555389554],
                [29.040343494443057, 41.108910341862575],
                [29.04034412846142, 41.108906586812225],
                [29.040289189620413, 41.10890131060732],
                [29.040288209785807, 41.108907113796654],
                [29.040285907091857, 41.108921155094784],
                [29.04031154261576, 41.108923652631866],
                [29.040310765481294, 41.10892818308767],
                [29.040309555547704, 41.108935237664205],
                [29.040308435545043, 41.108941766935374],
                [29.0403070573546, 41.108949800322584],
                [29.04030668206677, 41.10895198813494],
                [29.04031254936381, 41.10895255950808],
                [29.04031224966122, 41.10895430668171],
                [29.040254662678212, 41.10894869869033],
                [29.04024391075653, 41.108947651635646],
                [29.040233881463397, 41.108946674953124],
                [29.040176324700518, 41.10894106990453],
                [29.040166295407385, 41.10894009322201],
                [29.04014255873669, 41.108937781673475],
                [29.040104392573557, 41.108934064937266],
                [29.04010392654055, 41.108936799293964],
                [29.040096261545287, 41.108936064572134],
                [29.040096700133688, 41.108933466980304],
                [29.04009267370213, 41.10893307487431],
                [29.04009294595315, 41.10893148773488],
                [29.04008488072796, 41.1089307023188],
                [29.040084608476953, 41.10893228945824],
                [29.040080165928973, 41.10893185682946],
                [29.040084486138216, 41.10890667133598],
                [29.04008532804877, 41.10890176325466],
                [29.040085872812085, 41.10889858745102],
                [29.040086727622505, 41.10889360416631],
                [29.04008822072249, 41.10888489984728],
                [29.040076904760912, 41.10888381516421],
                [29.040075413890726, 41.10889250648419],
                [29.04007363439307, 41.10889233319123],
                [29.040072793456112, 41.10889731374307],
                [29.040074559769216, 41.10889748575208],
                [29.040074015005903, 41.108900661555715],
                [29.04007221250188, 41.10890048602232],
                [29.040071370591132, 41.10890539410422],
                [29.040073173095156, 41.1089055696376],
                [29.040068852885863, 41.10893075513103],
                [29.04006381158118, 41.108930264193496],
                [29.04006408383219, 41.10892867705401],
                [29.040055677684162, 41.108927858437795],
                [29.04005540543315, 41.10892944557731],
                [29.040048335428423, 41.1089287570788],
                [29.040047807438775, 41.108931835095724],
                [29.04004511397191, 41.108931572797765],
                [29.04004326072929, 41.108940726770165],
                [29.040045953828873, 41.10894098903232],
                [29.040045669288258, 41.10894264781538],
                [29.04005399238575, 41.108943458343596],
                [29.040053701772727, 41.1089451525277],
                [29.040062240434462, 41.10894598404822],
                [29.04006253104749, 41.108944289864155],
                [29.040070488033617, 41.10894506473938],
                [29.040070197420583, 41.10894675892342],
                [29.040078732539794, 41.108947590098985],
                [29.040079023152824, 41.10894589591493],
                [29.04009457196154, 41.108947410104626],
                [29.040094846227213, 41.10894581122063],
                [29.040102509245465, 41.10894655746837],
                [29.0401015780399, 41.10895198610843],
                [29.040139749040847, 41.10895570331473],
                [29.04013838998704, 41.10896362617635],
                [29.040133419148354, 41.10896314207428],
                [29.04013213787907, 41.10897061147471],
                [29.04016306634682, 41.108973623380585],
                [29.040164347611373, 41.108966154007106],
                [29.04016160404517, 41.10896588683046],
                [29.040162963098986, 41.10895796396912],
                [29.040172992392126, 41.10895894065164],
                [29.04017238129489, 41.10896250315861],
                [29.040230089723362, 41.10896812297713],
                [29.040230700820622, 41.10896456046998],
                [29.040240730113755, 41.10896581538387],
                [29.040239371427223, 41.10897346004947],
                [29.040235212480376, 41.108973055038874],
                [29.040232500181155, 41.10897279088578],
                [29.0402312245489, 41.10898039418874],
                [29.040266956462023, 41.1089838436738],
                [29.040268110311246, 41.10897711720685],
                [29.040263908729358, 41.10897670804446],
                [29.04026541262569, 41.10896794079591],
                [29.040308781938712, 41.108972164229925],
                [29.040308170841477, 41.10897572673692],
                [29.040367778632678, 41.10898153151836],
                [29.040368389729935, 41.108977969011185],
                [29.040376890607174, 41.10897879685463],
                [29.040378419023067, 41.10897894569371],
                [29.040377059969252, 41.10898686855493],
                [29.040371372973794, 41.10898631474101],
                [29.040370183728747, 41.108986198926175],
                [29.040369170011793, 41.10899379742807],
                [29.040402444733967, 41.108997037822014],
                [29.0404043811481, 41.10899722639291],
                [29.040405442316768, 41.108990701793516],
                [29.040403563001917, 41.10899051878409],
                [29.04040071920241, 41.1089902418437],
                [29.040402258670884, 41.108981267252865],
                [29.040479625605215, 41.10898880147208],
                [29.040482038593172, 41.10898903645216],
                [29.04048067953936, 41.108996959313785],
                [29.0404782665514, 41.10899672433371],
                [29.040475860962637, 41.10899649006689],
                [29.040474424758354, 41.10900477856484],
                [29.040509261513037, 41.10900817106804],
                [29.040510620052157, 41.10899913019762],
                [29.040504573599268, 41.108998532862344],
                [29.040505932653083, 41.108990610000994],
                [29.040515961946216, 41.108991586683516],
                [29.040515410794807, 41.10899479972497],
                [29.04057368659566, 41.109000474795884],
                [29.04057425962138, 41.10899713423388],
                [29.040619137843322, 41.10900150456957],
                [29.040617778789514, 41.10900942743119],
                [29.04061333055866, 41.109008994249216],
                [29.040611758375796, 41.10901779025213],
                [29.040646126044788, 41.10902113701689],
                [29.040647632039565, 41.10901216066079],
                [29.040641340914465, 41.10901154003965],
                [29.040642669276636, 41.10900379610104],
                [29.04065269856977, 41.109004772783564],
                [29.040652140561313, 41.10900802579946],
                [29.04071017116615, 41.109013676992056],
                [29.040710470004285, 41.109011281764346],
                [29.040710782263734, 41.10901011448298],
                [29.040714921591757, 41.1090105175831],
                [29.040715837342834, 41.10900517903873],
                [29.04072350036109, 41.109005925286475],
                [29.040723190355838, 41.109007732521555],
                [29.040739030172844, 41.10900927504728],
                [29.04074839123552, 41.109010186645754],
                [29.04076208939504, 41.10901152059915],
                [29.040801197069978, 41.109015309571056],
                [29.040803149722887, 41.10900387012032],
                [29.04077335264037, 41.109000969646],
                [29.0407742829039, 41.10899563494772],
                [29.040765000056815, 41.10899473097308],
                [29.04076465302197, 41.10899675407907],
                [29.040735103489812, 41.10899387646156],
                [29.040735450524654, 41.108991853355484],
                [29.04072610123071, 41.10899094291032],
                [29.040725193657963, 41.10899623252017],
                [29.040717530639707, 41.10899548627232],
                [29.040718027762992, 41.10899258819577],
                [29.040668619779353, 41.10898792382773],
                [29.040669029731276, 41.10898545146379],
                [29.040660621763017, 41.10898465999768],
                [29.040660211811094, 41.108987132361655],
                [29.040624367494342, 41.1089837582337],
                [29.040624777446236, 41.108981285869596],
                [29.04061625323668, 41.108980483461366],
                [29.04061584328479, 41.108982955825496],
                [29.04051596556093, 41.10897355405017],
                [29.04048336346201, 41.108970485121226],
                [29.04034918437175, 41.10895753650111],
                [29.040349393748055, 41.10895630478686],
                [29.04048356777802, 41.10896925291888],
                [29.040484260518394, 41.10896507509484],
                [29.0404860075052, 41.108965239543735],
                [29.0404855204007, 41.10896817720552],
                [29.040511286908394, 41.1089705877716],
                [29.04051459426989, 41.10895130686061],
                [29.040488721568774, 41.10894887138816],
                [29.040488446589166, 41.108950529753656],
                [29.040486699602365, 41.108950365304715],
                [29.040487275467374, 41.10894689233929],
                [29.040511370111, 41.10894916043809],
              ],
              [
                [29.040378874159895, 41.10893354450698],
                [29.040367047360775, 41.10893642722925],
                [29.040361984615938, 41.10892451627861],
                [29.04037594984616, 41.10892580926297],
                [29.040378874159895, 41.10893354450698],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "deadzone",
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040008752881096, 41.10900086381824],
                [29.04003254759295, 41.10900318101652],
                [29.04004172773835, 41.108949663633254],
                [29.0400179330308, 41.10894734645268],
                [29.040008752881096, 41.10900086381824],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "deadzone",
          label: null,
          floor: "secondfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040035241059822, 41.10900344331416],
                [29.040084143732976, 41.1090082055985],
                [29.040094281715795, 41.10894910432437],
                [29.04004537904266, 41.1089443420352],
                [29.040035241059822, 41.10900344331416],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "deadzone",
          label: null,
          floor: "secondfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 40,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040022338865032, 41.108921631474495],
                [29.040046417416313, 41.108923976295216],
                [29.040053688215316, 41.10888158976002],
                [29.040029603454446, 41.108879281133056],
                [29.040022338865032, 41.108921631474495],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "deadzone",
          label: null,
          floor: "secondfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040085276216434, 41.10891275117867],
                [29.04009988351087, 41.108914173681605],
                [29.040104635126493, 41.108886473238265],
                [29.04009002405886, 41.10888507270527],
                [29.040085276216434, 41.10891275117867],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "tuvalet",
          label: "engelli_WC",
          floor: "secondfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#50b6b9",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040048608046718, 41.10892716997502],
                [29.04006732300016, 41.10892899249399],
                [29.040069618775945, 41.10891560882592],
                [29.04005090382202, 41.10891378630584],
                [29.040048608046718, 41.10892716997502],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "tuvalet",
          label: "erkek_WC",
          floor: "secondfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#61b3cc",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.04005117701695, 41.10891219366301],
                [29.0400698919711, 41.10891401618199],
                [29.040072391921083, 41.1088994422401],
                [29.040053676967634, 41.1088976197202],
                [29.04005117701695, 41.10891219366301],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "tuvalet",
          label: "kadın_WC",
          floor: "secondfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#61b3cc",

          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040053869236573, 41.10889649885097],
                [29.04007258419003, 41.10889832137089],
                [29.040075102159122, 41.1088836423777],
                [29.040056382377127, 41.108881848006895],
                [29.040053869236573, 41.10889649885097],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "ofis",
          label: "201-202",
          floor: "secondfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#82c1c9",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040232500181155, 41.10897279088578],
                [29.04022934213409, 41.10897248336707],
                [29.04022985404042, 41.108969499112355],
                [29.04017214561192, 41.10896387929394],
                [29.040171633705594, 41.10896686354861],
                [29.040169140787935, 41.108966620780876],
                [29.04016738592653, 41.10897685107618],
                [29.040131664649522, 41.10897337243247],
                [29.04013213824636, 41.10897061151046],
                [29.040133942123468, 41.10896319303012],
                [29.040134845995766, 41.1089579237354],
                [29.040104249974178, 41.10895494420339],
                [29.040103346101887, 41.10896021349834],
                [29.040100219353373, 41.108959909006266],
                [29.0400914328232, 41.10901113170828],
                [29.040223950029564, 41.109023753356034],
                [29.0402312245489, 41.10898039418874],
                [29.040232500181155, 41.10897279088578],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "ofis",
          label: "203-204",
          floor: "secondfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#82c1c9",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.04022777780462, 41.10902411793377],
                [29.040361698453253, 41.10903687325034],
                [29.040369170011793, 41.10899379742807],
                [29.040370183728747, 41.108986198926175],
                [29.04036703067612, 41.108985891872535],
                [29.040367542582445, 41.108982907617836],
                [29.040309494929726, 41.108977254764774],
                [29.040308917069748, 41.10898023259678],
                [29.040298887776615, 41.10897925591439],
                [29.040299453449848, 41.10897422141312],
                [29.04026911385578, 41.10897126685408],
                [29.040268110311246, 41.10897711720685],
                [29.04026648385334, 41.10898659878807],
                [29.040234597738596, 41.10898346828579],
                [29.04022777780462, 41.10902411793377],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "ofis",
          label: "205-206",
          floor: "secondfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#82c1c9",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040365527817894, 41.10903723797941],
                [29.040497822983365, 41.109049838473794],
                [29.040504567345547, 41.109010520978174],
                [29.04047395115361, 41.109007539485695],
                [29.040474424758354, 41.10900477856484],
                [29.040475860962637, 41.10899649006689],
                [29.040476733731037, 41.10899140209822],
                [29.04040649551182, 41.10898456209789],
                [29.040405442316768, 41.108990701793516],
                [29.0404043811481, 41.10899722639291],
                [29.04040390755125, 41.10899998731479],
                [29.040372634505108, 41.10899694185298],
                [29.040365527817894, 41.10903723797941],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "ofis",
          label: "207",
          floor: "secondfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#82c1c9",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040502045076632, 41.10905024060835],
                [29.040566585549033, 41.1090563877849],
                [29.040575349846087, 41.109005295613265],
                [29.040572900567568, 41.10900505709523],
                [29.040573450545438, 41.10900185089536],
                [29.04051517787315, 41.10899615758577],
                [29.0405146028924, 41.10899950954474],
                [29.040510620052157, 41.10899913019762],
                [29.040509261513037, 41.10900817106804],
                [29.040502045076632, 41.10905024060835],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "ofis",
          label: "208",
          floor: "secondfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#82c1c9",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040570873193637, 41.10905679616286],
                [29.040635865486113, 41.10906298637253],
                [29.040642475400613, 41.1090235885531],
                [29.04061128477895, 41.109020551173145],
                [29.040611758375796, 41.10901779025213],
                [29.04061333055866, 41.109008994249216],
                [29.040614234430954, 41.10900372495449],
                [29.040583833735138, 41.109000764470665],
                [29.040582929848874, 41.10900603374969],
                [29.04057963594432, 41.109005713006255],
                [29.040570873193637, 41.10905679616286],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "ofis",
          label: "209",
          floor: "secondfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#82c1c9",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.04070993511591, 41.10901505309154],
                [29.040651904511066, 41.10900940189904],
                [29.04065137020094, 41.10901251670329],
                [29.040647632039565, 41.10901216066079],
                [29.04063905387795, 41.10906329005183],
                [29.040704794941874, 41.10906955157782],
                [29.040713373103493, 41.10901842219165],
                [29.040709421742562, 41.10901804584289],
                [29.04070993511591, 41.10901505309154],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "deadzone",
          label: null,
          floor: "secondfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.04071246091189, 41.109070281725245],
                [29.04073776911408, 41.109072427096194],
                [29.04074839123552, 41.109010186645754],
                [29.040739030172844, 41.10900927504728],
                [29.04073868896882, 41.109011264160564],
                [29.040722849151816, 41.109009721634884],
                [29.04071246091189, 41.109070281725245],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "deadzone",
          label: null,
          floor: "secondfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040739869683513, 41.10907262921389],
                [29.040790667594568, 41.10907700886506],
                [29.040801197372648, 41.10901530960038],
                [29.040762071472773, 41.109011518862815],
                [29.040761732968214, 41.10901350242788],
                [29.040750151427787, 41.10901238039708],
                [29.040739869683513, 41.10907262921389],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "deadzone",
          label: null,
          floor: "secondfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.04077704098011, 41.10900134062021],
                [29.040803149722887, 41.10900387012032],
                [29.040812151520957, 41.10895144842952],
                [29.040786309763753, 41.10894896763363],
                [29.04077704098011, 41.10900134062021],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "kafeterya",
          label: "kafeterya",
          floor: "secondfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#e7e39c",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040105155323186, 41.10892959184478],
                [29.040110669669517, 41.10893012884852],
                [29.04011036690312, 41.10893189388336],
                [29.04020276237151, 41.1089408915258],
                [29.040211371707787, 41.10894172991458],
                [29.04027846685747, 41.108948263730284],
                [29.040283143090363, 41.10892088454495],
                [29.04028556895775, 41.10890686066413],
                [29.040286547109822, 41.10890106743976],
                [29.04024781481392, 41.108897354814474],
                [29.040250137191283, 41.10888360024996],
                [29.040181459870336, 41.10887701728795],
                [29.04017911723332, 41.1088908918442],
                [29.040112752191327, 41.10888453052297],
                [29.040105155323186, 41.10892959184478],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "seminer salonu",
          label: "seminer salonu",
          floor: "secondfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#8dc8b5",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.04051938318818, 41.10892338891953],
                [29.040511286908394, 41.1089705877716],
                [29.040515767485758, 41.108971024095645],
                [29.040649111199553, 41.10898357647541],
                [29.04066063708549, 41.10891406538852],
                [29.040527757591857, 41.108901125409126],
                [29.040523863764385, 41.10892382524377],
                [29.04051938318818, 41.10892338891953],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "seminer salonu",
          label: "seminer salonu",
          floor: "secondfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#8dc8b5",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.04065316260782, 41.10898395784606],
                [29.040718453915485, 41.10899010390363],
                [29.040726229152536, 41.108943212500115],
                [29.040660937844873, 41.10893706643816],
                [29.04065316260782, 41.10898395784606],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "tuvalet",
          label: "erkek_WC",
          floor: "secondfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#61b3cc",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.0407517750351, 41.1089934430828],
                [29.0407742829039, 41.10899563494772],
                [29.040782480161152, 41.10894860432871],
                [29.040760089639573, 41.10894645732456],
                [29.0407517750351, 41.1089934430828],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "tuvalet",
          label: "kadin_WC",
          floor: "secondfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#61b3cc",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.04072610123071, 41.10899094291032],
                [29.040747947057422, 41.10899307030343],
                [29.04075626205067, 41.10894608235049],
                [29.040734161334356, 41.10894396615771],
                [29.04072610123071, 41.10899094291032],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "tuvalet",
          label: "engelli_WC",
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#50b6b9",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.0303818107284, 41.10836607569825],
                [29.03040052568184, 41.108367898217224],
                [29.030402821457816, 41.10835451454916],
                [29.03038410650438, 41.10835269202982],
                [29.0303818107284, 41.10836607569825],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "tuvalet",
          label: "erkek_WC",
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#61b3cc",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030384379699527, 41.108351099385885],
                [29.03040309465279, 41.108352921905215],
                [29.030405594602502, 41.10833834796331],
                [29.03038687964924, 41.10833652544357],
                [29.030384379699527, 41.108351099385885],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "tuvalet",
          label: "kadın_WC",
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#61b3cc",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03038707191825, 41.108335404574206],
                [29.030405786871714, 41.108337227094125],
                [29.030408688675006, 41.108320310465686],
                [29.03038997372154, 41.1083184879453],
                [29.03038707191825, 41.108335404574206],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "deadzone",
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03041847889812, 41.10835165690191],
                [29.030433748566573, 41.10835314390885],
                [29.030435808369667, 41.10834113588711],
                [29.030420538701215, 41.108339648879905],
                [29.03041847889812, 41.10835165690191],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "deadzone",
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03043887725043, 41.10832324524716],
                [29.030423607414548, 41.10832175921461],
                [29.03042073285619, 41.108338528194075],
                [29.030436002692074, 41.10834001422624],
                [29.03043887725043, 41.10832324524716],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "ofis",
          label: "10-11-12-13-14-15",
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#82c1c9",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030564676862056, 41.10840880121214],
                [29.030506109376958, 41.10840309773626],
                [29.030505625905725, 41.108405916222985],
                [29.0305030819573, 41.108405668485794],
                [29.030501281270357, 41.108416165932205],
                [29.030465559990787, 41.108412687290304],
                [29.030465559993267, 41.10841268728898],
                [29.030468236123106, 41.108397086267075],
                [29.030437691126718, 41.10839411175222],
                [29.030436787254427, 41.10839938104718],
                [29.03043366050592, 41.10839907655509],
                [29.030431884582985, 41.10840940788133],
                [29.030424765638628, 41.108450909111546],
                [29.030831119587685, 41.10849048101372],
                [29.03083823853204, 41.108448979808514],
                [29.030807716147518, 41.108446007450105],
                [29.030810361404843, 41.10843058642521],
                [29.030740091908196, 41.10842374337734],
                [29.03073744665109, 41.108439164402554],
                [29.03070172537374, 41.10843568576087],
                [29.030703497752558, 41.108425353339896],
                [29.030701031577717, 41.10842511317669],
                [29.030701543356592, 41.108422129666664],
                [29.030642975871498, 41.10841642619079],
                [29.030642464091716, 41.10841940970636],
                [29.030632434798573, 41.10841843302493],
                [29.030633293374038, 41.10841342779734],
                [29.03060295377997, 41.108410473238294],
                [29.030600322825137, 41.108425810886324],
                [29.030564858879533, 41.10842235730435],
                [29.030566659566205, 41.10841185986053],
                [29.03056419339108, 41.10841161969714],
                [29.030564676862056, 41.10840880121214],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "ofis",
          label: "Similasyon Alanı",
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#82c1c9",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.0308353401583, 41.1084908920249],
                [29.031038165218952, 41.10851064370393],
                [29.031047056422278, 41.10845881081786],
                [29.031042917085855, 41.108458407716974],
                [29.03104342899185, 41.108455423464235],
                [29.030985153190972, 41.108449748393426],
                [29.030984641284633, 41.108452732648125],
                [29.030982069076288, 41.10845248215882],
                [29.03098077041776, 41.10846005293078],
                [29.03098029682091, 41.10846281385166],
                [29.03094455457323, 41.10845933316944],
                [29.03094618237406, 41.108448987405716],
                [29.030947086246357, 41.10844371811099],
                [29.03091690018267, 41.1084407785019],
                [29.030915996308234, 41.108446047808854],
                [29.0309059670151, 41.10844507112646],
                [29.03090647892049, 41.108442086877275],
                [29.03084820311961, 41.108436411806466],
                [29.03084769121327, 41.108439396061165],
                [29.030844231361623, 41.10843905912324],
                [29.0308353401583, 41.1084908920249],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "ofis",
          label: "20-21-22",
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#82c1c9",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030444082968764, 41.10837100075762],
                [29.03047203466568, 41.10837372277839],
                [29.030474614620367, 41.10835868243581],
                [29.030506958891973, 41.108361832219444],
                [29.030506976065077, 41.1083617321055],
                [29.03050697606508, 41.10836173210549],
                [29.03050697606508, 41.1083617321055],
                [29.030510539306878, 41.108362079104786],
                [29.030508836776754, 41.10837200432023],
                [29.03051140018424, 41.108372253952886],
                [29.03051088828094, 41.10837523820788],
                [29.03056945576604, 41.10838094168376],
                [29.03056996767238, 41.10837795742906],
                [29.03057993026608, 41.108378927616066],
                [29.03057899522717, 41.108384378578926],
                [29.03061311292036, 41.10838770107784],
                [29.030615750497507, 41.10837232488955],
                [29.03062118347043, 41.10834065232465],
                [29.030512409038018, 41.108330059535476],
                [29.030512409038003, 41.10833005953547],
                [29.03051240903782, 41.10833005953549],
                [29.030513142637705, 41.10832578287244],
                [29.030447332322897, 41.10831937406011],
                [29.030441396243663, 41.108353979590525],
                [29.03043887138884, 41.10836869871903],
                [29.030444385735123, 41.10836923572276],
                [29.030444082968764, 41.10837100075762],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "deadzone",
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030915878421247, 41.108397516192355],
                [29.030916003150995, 41.10839688429956],
                [29.03091992277235, 41.10839731794288],
                [29.030926110128664, 41.108361416985545],
                [29.030897257148872, 41.10835859393503],
                [29.03085829965261, 41.10835481374388],
                [29.030856793061762, 41.1083635967059],
                [29.03080135655598, 41.10835819813043],
                [29.030797726536743, 41.108379360029744],
                [29.030793161706907, 41.108405971578584],
                [29.03088755341366, 41.1084151637209],
                [29.030890996866603, 41.108395089456],
                [29.030890996866265, 41.10839508945876],
                [29.030915878421247, 41.108397516192355],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "danışma",
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#5bc2af",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030791686191556, 41.10840582788854],
                [29.030797481599567, 41.10837204244684],
                [29.03078472537302, 41.1083708002072],
                [29.03077892996501, 41.10840458564954],
                [29.030791686191556, 41.10840582788854],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "deadzone",
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030919483960147, 41.108418273211235],
                [29.03092258564217, 41.10840019136705],
                [29.03091545656722, 41.108399462247704],
                [29.03091556068061, 41.10839888432514],
                [29.030893004033906, 41.10839668455119],
                [29.030889796722825, 41.10841538218092],
                [29.030919483960147, 41.108418273211235],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "deadzone",
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03095427282365, 41.10840554509078],
                [29.0309542343023, 41.10840550097422],
                [29.030954284755435, 41.10840550576149],
                [29.030984587806532, 41.10840845607107],
                [29.030991562676256, 41.1083677913021],
                [29.030928353437822, 41.10836163544569],
                [29.030923656565967, 41.10838900252257],
                [29.030939059832733, 41.1083905025394],
                [29.030936771920153, 41.10840384023034],
                [29.030936765835964, 41.108403843458184],
                [29.03093405189104, 41.108419695128624],
                [29.030951558878723, 41.108421396760804],
                [29.03095427282365, 41.10840554509078],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "ofis",
          label: "19",
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#82c1c9",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030994792077564, 41.10837747342979],
                [29.030987755077476, 41.10841849695525],
                [29.03099046860024, 41.1084187612061],
                [29.03098985833089, 41.10842231888472],
                [29.03104813413174, 41.10842799395563],
                [29.031048744401396, 41.10842443627555],
                [29.03105288417527, 41.108424839418944],
                [29.031059921175352, 41.10838381589744],
                [29.030994792077564, 41.10837747342979],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "deadzone",
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.031071085035887, 41.108513849528414],
                [29.031081686166576, 41.108452048308514],
                [29.031056429368004, 41.10844958872803],
                [29.031045828237314, 41.10851138995025],
                [29.031071085035887, 41.108513849528414],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "deadzone",
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.031123827577098, 41.10851898574528],
                [29.03113443770499, 41.10845713207912],
                [29.03108379480222, 41.10845220032631],
                [29.031073184674327, 41.10851405399711],
                [29.031123827577098, 41.10851898574528],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "tuvalet",
          label: "kadın_WC",
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#61b3cc",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03106303802219, 41.10841106146249],
                [29.03106296668012, 41.10841105451499],
                [29.03105952727558, 41.108431105168364],
                [29.031081702513323, 41.108433264658096],
                [29.031085141917853, 41.10841321400539],
                [29.031073380470882, 41.10841206864081],
                [29.031073655732733, 41.108410464737915],
                [29.031073655472316, 41.10841046445029],
                [29.031085416077005, 41.1084116099972],
                [29.03108968750713, 41.10838671463227],
                [29.031067583824356, 41.10838456161244],
                [29.03106331239423, 41.10840945697818],
                [29.03106331336755, 41.10840945707299],
                [29.03106303802219, 41.10841106146249],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "tuvalet",
          label: "erkek_WC",
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#61b3cc",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03108924500957, 41.10841198295619],
                [29.03110040817018, 41.10841307005784],
                [29.03110013305634, 41.10841467375275],
                [29.031088969918116, 41.10841358665329],
                [29.031085530476393, 41.10843363752267],
                [29.031107946093332, 41.108435820421164],
                [29.0311116299953, 41.108414162872414],
                [29.031115900470486, 41.10838926732927],
                [29.03109351548476, 41.108387087412225],
                [29.03108924500957, 41.10841198295619],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "deadzone",
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.031119728373582, 41.10838964010195],
                [29.03111140855992, 41.108438142020496],
                [29.031137263286933, 41.108440659829405],
                [29.0311455831006, 41.108392157912725],
                [29.031119728373582, 41.10838964010195],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "deadzone",
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.034990869465428, 41.10874241355114],
                [29.035014664177286, 41.10874473074942],
                [29.03502384440486, 41.108691213374165],
                [29.035000049693, 41.108688896174],
                [29.034990869465428, 41.10874241355114],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "deadzone",
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03500445544937, 41.10866318120739],
                [29.03502853400065, 41.10866552602812],
                [29.0350358047989, 41.10862313949284],
                [29.03501172624762, 41.10862079467059],
                [29.03500445544937, 41.10866318120739],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "deadzone",
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03501735764416, 41.10874499304706],
                [29.03506626031732, 41.1087497553314],
                [29.035076398390828, 41.10869065406611],
                [29.03502749571767, 41.108685891777476],
                [29.03501735764416, 41.10874499304706],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "tuvalet",
          label: "engelli_WC",
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#50b6b9",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035030724631056, 41.108668719707914],
                [29.035049439584498, 41.10867054222689],
                [29.035051735381295, 41.10865715856086],
                [29.035033020427854, 41.108655336041515],
                [29.035030724631056, 41.108668719707914],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "tuvalet",
          label: "erkek_WC",
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#61b3cc",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035033293601288, 41.10865374339592],
                [29.03505200855544, 41.108655565914894],
                [29.035054508527214, 41.10864099197513],
                [29.03503579357306, 41.10863916945576],
                [29.035033293601288, 41.10865374339592],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "tuvalet",
          label: "kadın_WC",
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#61b3cc",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035038498961466, 41.10862339773979],
                [29.035036019865576, 41.108638080489236],
                [29.035054739647567, 41.10863987485965],
                [29.035057218743457, 41.10862519211061],
                [29.035038498961466, 41.10862339773979],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "deadzone",
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035072140643198, 41.10862662243818],
                [29.0350696796877, 41.10864119774832],
                [29.035084290755336, 41.10864259828098],
                [29.035086751710832, 41.10862802297115],
                [29.035072140643198, 41.10862662243818],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "deadzone",
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03506945260391, 41.10864229288935],
                [29.03506739277826, 41.10865430093386],
                [29.03508204162865, 41.10865572748344],
                [29.03508410145431, 41.1086437194392],
                [29.03506945260391, 41.10864229288935],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "ofis",
          label: "101-102",
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#82c1c9",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035073549407528, 41.10875268144118],
                [29.03520601817598, 41.10876558163226],
                [29.03521480478107, 41.10871435894951],
                [29.03521145898708, 41.1087140315338],
                [29.035211970624758, 41.10871104884526],
                [29.03515426219626, 41.10870542902684],
                [29.035153750289933, 41.10870841328151],
                [29.035151257372274, 41.10870817051378],
                [29.035149502510873, 41.10871840080909],
                [29.03511378123386, 41.10871492216538],
                [29.0351142548307, 41.10871216124337],
                [29.03511553609998, 41.10870469184294],
                [29.03511644038647, 41.108699420132574],
                [29.035086366558517, 41.10869649393629],
                [29.035085462686226, 41.10870176323125],
                [29.03508233593772, 41.10870145873916],
                [29.035080089121678, 41.10871455730426],
                [29.035073549407528, 41.10875268144118],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "ofis",
          label: "103-104",
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#82c1c9",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035209844671268, 41.10876595426712],
                [29.03534218233366, 41.108778841688355],
                [29.03535096895198, 41.10872761893983],
                [29.03534761921186, 41.10872729280211],
                [29.035348131118177, 41.10872430854746],
                [29.035290051742848, 41.108718652605155],
                [29.035289539836523, 41.108721636859805],
                [29.03527951054339, 41.10872066017741],
                [29.035280369245406, 41.10871565421166],
                [29.035250029651337, 41.108712699652614],
                [29.035247271107718, 41.10872802497102],
                [29.03521685898338, 41.108725063377086],
                [29.035209844671268, 41.10876595426712],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "ofis",
          label: "105-106",
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#82c1c9",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03547386003793, 41.1087916648382],
                [29.03548060902094, 41.10875232076397],
                [29.035453527300685, 41.108749683469],
                [29.035453527292425, 41.108749683468005],
                [29.035456437694705, 41.108732716886806],
                [29.035386733130984, 41.108725928854696],
                [29.035384088084175, 41.108741348512105],
                [29.035353035754166, 41.10873832454429],
                [29.035345827659214, 41.1087791966804],
                [29.03547386003793, 41.1087916648382],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "ofis",
          label: "107-108-109",
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#82c1c9",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035478080608545, 41.10879207584936],
                [29.035680852977215, 41.1088118223951],
                [29.03568963958727, 41.10876059971947],
                [29.03568708857407, 41.10876035129422],
                [29.035687600480067, 41.108757367041434],
                [29.0356297971998, 41.10875173798608],
                [29.03562928529346, 41.10875472224078],
                [29.035622937663604, 41.108754104089705],
                [29.035620885416883, 41.108766067921295],
                [29.035589858878335, 41.108763046468304],
                [29.0355919111255, 41.10875108263372],
                [29.0355928149978, 41.10874581333899],
                [29.035562628934112, 41.1087428737299],
                [29.035561725059676, 41.108748143036856],
                [29.035551695766543, 41.10874716635446],
                [29.03555220767193, 41.108744182105276],
                [29.035493931871052, 41.10873850703447],
                [29.035493419964713, 41.108741491289166],
                [29.03548809056761, 41.10874097231419],
                [29.03548791720371, 41.10874198293194],
                [29.03548669385598, 41.10874186379878],
                [29.035478080608545, 41.10879207584936],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "deadzone",
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035715732654882, 41.10881300280122],
                [29.03572576894816, 41.10875449493454],
                [29.035700514614856, 41.108752035594414],
                [29.035690478321577, 41.108810543463285],
                [29.035715732654882, 41.10881300280122],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "deadzone",
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035765044821055, 41.10881780495821],
                [29.035775081118608, 41.10875929707048],
                [29.035727868590875, 41.10875469937808],
                [29.035717832293322, 41.108813207269904],
                [29.035765044821055, 41.10881780495821],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "deadzone",
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03576303340861, 41.10869402381719],
                [29.03575569135596, 41.10873657053157],
                [29.035778596936336, 41.10873880114377],
                [29.035785893754426, 41.10869626335658],
                [29.03576303340861, 41.10869402381719],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "tuvalet",
          label: "kadın_WC",
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#61b3cc",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03572957488876, 41.10871437399051],
                [29.035733584454, 41.10869113881727],
                [29.035711492040754, 41.10868897450915],
                [29.035707482475512, 41.108712209683155],
                [29.035706841409244, 41.10871589980841],
                [29.0357068456191, 41.10871590022361],
                [29.035703746040447, 41.10873386207675],
                [29.035725838453693, 41.10873602638343],
                [29.035729298159534, 41.10871597761774],
                [29.035717568873977, 41.108714828545494],
                [29.03571784626653, 41.10871323181001],
                [29.035717811845014, 41.108713221611104],
                [29.03572957488876, 41.10871437399051],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "tuvalet",
          label: "erkek_WC",
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#61b3cc",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03575506891793, 41.10871850003208],
                [29.03575506723042, 41.10871849986676],
                [29.035755345647093, 41.108716896404914],
                [29.0357593548313, 41.108693663441244],
                [29.035737412042895, 41.10869151379132],
                [29.035733402858696, 41.10871474675575],
                [29.03574459760447, 41.10871584346121],
                [29.035744319187792, 41.108717446923094],
                [29.035733126129568, 41.108716350382984],
                [29.035729666424007, 41.10873639914692],
                [29.03575160921234, 41.108738548795365],
                [29.03575506891793, 41.10871850003208],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "ofis",
          label: "110-111-112",
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#82c1c9",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03550400726184, 41.10866654389891],
                [29.035496855671173, 41.10870798691714],
                [29.035499263107408, 41.10870822136048],
                [29.035498763295813, 41.10871113495799],
                [29.035557199837605, 41.10871682568217],
                [29.035557681194174, 41.10871401955048],
                [29.035564497450522, 41.108714683338015],
                [29.035563608628358, 41.108719864844005],
                [29.035596382997028, 41.108723056510186],
                [29.035599071598103, 41.108707382955004],
                [29.035634548331533, 41.10871083778447],
                [29.035632747291608, 41.10872133718533],
                [29.035635112978373, 41.10872156756298],
                [29.035634602110928, 41.10872454576145],
                [29.035692424895828, 41.1087301767163],
                [29.03569293576313, 41.108727198518594],
                [29.035697035246578, 41.10872759772636],
                [29.035704186837243, 41.108686154720516],
                [29.03550400726184, 41.10866654389891],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "ofis",
          label: "113",
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#82c1c9",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035425944963738, 41.10870629030252],
                [29.035460436638818, 41.10870964917262],
                [29.035462623172958, 41.10869690235462],
                [29.03546309676955, 41.108694141432586],
                [29.035493532322974, 41.108697105337],
                [29.035498861222827, 41.108666039761225],
                [29.035433940367746, 41.10865967955566],
                [29.035425944963738, 41.10870629030252],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "ofis",
          label: "Toplantı Odası",
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#82c1c9",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035232037513538, 41.10863990006135],
                [29.035224979775926, 41.10868104414348],
                [29.03522738676197, 41.108681278542946],
                [29.0352264546495, 41.10868671246904],
                [29.035237203646243, 41.10868777657525],
                [29.03526049511445, 41.10869004475528],
                [29.03526853135632, 41.108643475224376],
                [29.035232037513538, 41.10863990006135],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "ofis",
          label: "114-115",
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#82c1c9",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03521983323061, 41.1086805429576],
                [29.035226891250286, 41.10863939723086],
                [29.03509479208272, 41.108626533011744],
                [29.035087853685432, 41.10866769039572],
                [29.03508727190753, 41.10867114157768],
                [29.035092786253855, 41.10867167858142],
                [29.03509248348746, 41.10867344361627],
                [29.03512043518437, 41.108676165637036],
                [29.03512143566735, 41.10867033312951],
                [29.035123032321728, 41.108661025210786],
                [29.03515893982561, 41.10866452196319],
                [29.03515846622877, 41.10866728288507],
                [29.035155158933858, 41.108666960810915],
                [29.035154103349637, 41.1086731144857],
                [29.03515392999239, 41.10867412510429],
                [29.03515980070596, 41.10867469681183],
                [29.035159857604956, 41.108677736458475],
                [29.03521684556253, 41.10868328611528],
                [29.035217357468838, 41.10868030186055],
                [29.03521983323061, 41.1086805429576],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "deadzone",
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030368203617105, 41.10844540092279],
                [29.030417106290226, 41.108450163207166],
                [29.030427722867667, 41.10838827187314],
                [29.030378820194546, 41.10838350958427],
                [29.030368203617105, 41.10844540092279],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "deadzone",
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030349139152563, 41.1083830305653],
                [29.03034913915341, 41.108383030565385],
                [29.030338929421028, 41.108442550115846],
                [29.03036551015024, 41.108445138625136],
                [29.030376432958754, 41.10838146206608],
                [29.030376432957556, 41.108381462065964],
                [29.030378907411368, 41.10836703676393],
                [29.03038728025451, 41.10831822564729],
                [29.030360699525115, 41.108315637133295],
                [29.03035232668197, 41.108364448251855],
                [29.030349139152563, 41.1083830305653],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "deadzone",
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03502426801814, 41.108688745524425],
                [29.035025377680917, 41.10868227653882],
                [29.03502537733721, 41.108682276386624],
                [29.035027230923536, 41.108673122566415],
                [29.03502723092363, 41.10867312256642],
                [29.035028171742876, 41.10866763787946],
                [29.035004093191635, 41.10866529305881],
                [29.03500315237239, 41.10867077774597],
                [29.035003153847644, 41.108670777889635],
                [29.035003153847626, 41.108670777889635],
                [29.035001582607325, 41.10867995932227],
                [29.03500158260735, 41.10867995932227],
                [29.035001582607325, 41.108679959322274],
                [29.035000472944542, 41.108686428308104],
                [29.03502426801814, 41.108688745524425],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "deadzone",
          label: null,
          floor: "secondfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.04001835636021, 41.1089448785752],
                [29.040042151433806, 41.10894719579152],
                [29.040043260729288, 41.108940726770165],
                [29.040045114339197, 41.1089315728335],
                [29.04004605515853, 41.10892608814657],
                [29.0400219766073, 41.10892374332591],
                [29.04001835636021, 41.1089448785752],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: "teras",
          label: null,
          floor: "secondfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: null,
          layer: "polygons",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/polygons.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#6bc5c7",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.04034628934495, 41.10889378871632],
                [29.040343494443057, 41.108910341862575],
                [29.040355831672485, 41.108911555389554],
                [29.040358631718675, 41.10889497177585],
                [29.04034628934495, 41.10889378871632],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030424167937134, 41.10836201017456],
                [29.0304204164022, 41.10835600959006],
                [29.030418514874757, 41.10836709491029],
                [29.030424167937134, 41.10836201017456],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030638884311173, 41.108380437863346],
                [29.030630782756802, 41.108383727050594],
                [29.030637508205515, 41.10838846013542],
                [29.030638884311173, 41.108380437863346],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.0306331999714, 41.10836960260443],
                [29.03062648272968, 41.10836485450204],
                [29.03062510128735, 41.108372907887166],
                [29.0306331999714, 41.10836960260443],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: "stair_bord",
          unti_subty: null,
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#999999",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03534176773503, 41.1086661855163],
                [29.03534725086958, 41.10867904659617],
                [29.035359187927135, 41.108676516994024],
                [29.03535575368157, 41.1086675847404],
                [29.03534176773503, 41.1086661855163],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035072454462238, 41.10866478453435],
                [29.035068702927305, 41.108658783977525],
                [29.03506680139986, 41.108669869246626],
                [29.035072454462238, 41.10866478453435],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "secondfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040089696505156, 41.10892335245466],
                [29.04008594497023, 41.10891735192146],
                [29.040084043442786, 41.108928437146915],
                [29.040089696505156, 41.10892335245466],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035270942842356, 41.108681470679834],
                [29.035277668291073, 41.10868620374319],
                [29.035285769845444, 41.1086829145711],
                [29.035270942842356, 41.108681470679834],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03527336826395, 41.10866733128159],
                [29.03528008550567, 41.108672079362094],
                [29.035288184189728, 41.108668774094426],
                [29.03527336826395, 41.10866733128159],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "secondfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040289927649006, 41.10893949396947],
                [29.04029665309772, 41.10894422701424],
                [29.04030475465209, 41.10894093785507],
                [29.040289927649006, 41.10893949396947],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "secondfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040292353070598, 41.1089253546268],
                [29.04029907031232, 41.108930102688646],
                [29.040307168996375, 41.108926797433966],
                [29.040292353070598, 41.1089253546268],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "secondfloor",
          unit_type: "stair_bord",
          unti_subty: null,
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#999999",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040361984615938, 41.10892451627861],
                [29.040367047360775, 41.10893642722925],
                [29.040378874159895, 41.10893354450698],
                [29.04037594984616, 41.10892580926297],
                [29.040361984615938, 41.10892451627861],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030630782756802, 41.108383727050594],
                [29.030622681202424, 41.108387016237685],
                [29.030637508205515, 41.10838846013542],
                [29.030630782756802, 41.108383727050594],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030622681202424, 41.108387016237685],
                [29.030630782756802, 41.108383727050594],
                [29.030624057308085, 41.10837899396543],
                [29.030622681202424, 41.108387016237685],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030630782756802, 41.108383727050594],
                [29.030638884311173, 41.108380437863346],
                [29.030624057308085, 41.10837899396543],
                [29.030630782756802, 41.108383727050594],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03063991721312, 41.10837435070648],
                [29.0306331999714, 41.10836960260443],
                [29.03062510128735, 41.108372907887166],
                [29.03063991721312, 41.10837435070648],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03062648272968, 41.10836485450204],
                [29.0306331999714, 41.10836960260443],
                [29.030641298655457, 41.10836629732153],
                [29.03062648272968, 41.10836485450204],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.0306331999714, 41.10836960260443],
                [29.03063991721312, 41.10837435070648],
                [29.030641298655457, 41.10836629732153],
                [29.0306331999714, 41.10836960260443],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: "elv_border",
          unti_subty: null,
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030617190188778, 41.10838883495057],
                [29.030639099580664, 41.108390968552364],
                [29.030641259549984, 41.108378376596654],
                [29.030619350158098, 41.10837624299445],
                [29.030617190188778, 41.10838883495057],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: "elv_border",
          unti_subty: null,
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03061959298456, 41.10837482738664],
                [29.03064150237694, 41.108376960988984],
                [29.03064365629665, 41.108364404298186],
                [29.030621746904274, 41.10836227069543],
                [29.03061959298456, 41.10837482738664],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030427919472057, 41.10836801075851],
                [29.030424167937134, 41.10836201017456],
                [29.030418514874757, 41.10836709491029],
                [29.030427919472057, 41.10836801075851],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030424167937134, 41.10836201017456],
                [29.030427919472057, 41.10836801075851],
                [29.030429820999505, 41.10835692543844],
                [29.030424167937134, 41.10836201017456],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.0304204164022, 41.10835600959006],
                [29.030424167937134, 41.10836201017456],
                [29.030429820999505, 41.10835692543844],
                [29.0304204164022, 41.10835600959006],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: "elv_border",
          unti_subty: null,
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03041544373294, 41.10836935098229],
                [29.030430713401433, 41.10837083798891],
                [29.03043344483233, 41.10835491458728],
                [29.030418175163838, 41.1083534275803],
                [29.03041544373294, 41.10836935098229],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035076205997168, 41.10867078509063],
                [29.035072454462238, 41.10866478453435],
                [29.03506680139986, 41.108669869246626],
                [29.035076205997168, 41.10867078509063],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035072454462238, 41.10866478453435],
                [29.035076205997168, 41.10867078509063],
                [29.035078107524615, 41.10865969982167],
                [29.035072454462238, 41.10866478453435],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035068702927305, 41.108658783977525],
                [29.035072454462238, 41.10866478453435],
                [29.035078107524615, 41.10865969982167],
                [29.035068702927305, 41.108658783977525],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: "elv_border",
          unti_subty: null,
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.0350643576356, 41.10867199499195],
                [29.03507908513375, 41.10867342920028],
                [29.03508181659465, 41.108657505769344],
                [29.0350670890965, 41.108656071560674],
                [29.0350643576356, 41.10867199499195],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035277668291073, 41.10868620374319],
                [29.035284393739786, 41.10869093680622],
                [29.035285769845444, 41.1086829145711],
                [29.035277668291073, 41.10868620374319],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03528008550567, 41.108672079362094],
                [29.035286802747397, 41.10867682744227],
                [29.035288184189728, 41.108668774094426],
                [29.03528008550567, 41.108672079362094],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035284393739786, 41.10869093680622],
                [29.035277668291073, 41.10868620374319],
                [29.035269566736698, 41.10868949291512],
                [29.035284393739786, 41.10869093680622],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035277668291073, 41.10868620374319],
                [29.035270942842356, 41.108681470679834],
                [29.035269566736698, 41.10868949291512],
                [29.035277668291073, 41.10868620374319],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035286802747397, 41.10867682744227],
                [29.03528008550567, 41.108672079362094],
                [29.03527198682162, 41.1086753846296],
                [29.035286802747397, 41.10867682744227],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03528008550567, 41.108672079362094],
                [29.03527336826395, 41.10866733128159],
                [29.03527198682162, 41.1086753846296],
                [29.03528008550567, 41.108672079362094],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: "elv_border",
          unti_subty: null,
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035264619502794, 41.1086911832304],
                [29.035286528894673, 41.1086933168322],
                [29.035288688883572, 41.10868072487839],
                [29.035266779491685, 41.10867859127618],
                [29.035264619502794, 41.1086911832304],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: "elv_border",
          unti_subty: null,
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03526702229925, 41.10867717566661],
                [29.035288931690957, 41.108679309268815],
                [29.03529108563011, 41.1086667525799],
                [29.035269176238405, 41.1086646189773],
                [29.03526702229925, 41.10867717566661],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "secondfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040093448040086, 41.10892935298731],
                [29.040089696505156, 41.10892335245466],
                [29.040084043442786, 41.108928437146915],
                [29.040093448040086, 41.10892935298731],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "secondfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040089696505156, 41.10892335245466],
                [29.040093448040086, 41.10892935298731],
                [29.040095349567533, 41.108918267762014],
                [29.040089696505156, 41.10892335245466],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "secondfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.04008594497023, 41.10891735192146],
                [29.040089696505156, 41.10892335245466],
                [29.040095349567533, 41.108918267762014],
                [29.04008594497023, 41.10891735192146],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "secondfloor",
          unit_type: "elv_border",
          unti_subty: null,
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040082241051262, 41.10893044525905],
                [29.040096848343012, 41.10893186776143],
                [29.04009957981985, 41.108915944364234],
                [29.0400849725281, 41.10891452186152],
                [29.040082241051262, 41.10893044525905],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "secondfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.04029665309772, 41.10894422701424],
                [29.040303378546426, 41.10894896005867],
                [29.04030475465209, 41.10894093785507],
                [29.04029665309772, 41.10894422701424],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "secondfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040303378546426, 41.10894896005867],
                [29.04029665309772, 41.10894422701424],
                [29.04028855154334, 41.108947516173245],
                [29.040303378546426, 41.10894896005867],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "secondfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.04029665309772, 41.10894422701424],
                [29.040289927649006, 41.10893949396947],
                [29.04028855154334, 41.108947516173245],
                [29.04029665309772, 41.10894422701424],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "secondfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.04029907031232, 41.108930102688646],
                [29.040305787554036, 41.10893485075016],
                [29.040307168996375, 41.108926797433966],
                [29.04029907031232, 41.108930102688646],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "secondfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040305787554036, 41.10893485075016],
                [29.04029907031232, 41.108930102688646],
                [29.040290971628263, 41.10893340794316],
                [29.040305787554036, 41.10893485075016],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "secondfloor",
          unit_type: "elevator",
          unti_subty: "elevator_line",
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.04029907031232, 41.108930102688646],
                [29.040292353070598, 41.1089253546268],
                [29.040290971628263, 41.10893340794316],
                [29.04029907031232, 41.108930102688646],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "secondfloor",
          unit_type: "elv_border",
          unti_subty: null,
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040282502918448, 41.108949633497495],
                [29.04030441231034, 41.108951767099285],
                [29.040306572315952, 41.10893917514711],
                [29.040284662924066, 41.1089370415449],
                [29.040282502918448, 41.108949633497495],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "secondfloor",
          unit_type: "elv_border",
          unti_subty: null,
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040284905714906, 41.108935625933704],
                [29.04030681510662, 41.10893775953591],
                [29.04030896906245, 41.10892520284863],
                [29.040287059670746, 41.10892306924602],
                [29.040284905714906, 41.108935625933704],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: "stair_bord",
          unti_subty: null,
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#999999",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03069884116232, 41.108393344603314],
                [29.030699547025392, 41.10839348579496],
                [29.030700255922362, 41.10839361807617],
                [29.030700967655118, 41.108393741409984],
                [29.030701682024763, 41.108393855761925],
                [29.03070239883165, 41.10839396110004],
                [29.03070311787544, 41.108394057394875],
                [29.030703838955194, 41.10839414461954],
                [29.030704561869396, 41.10839422274965],
                [29.030705286416005, 41.10839429176337],
                [29.030706012392542, 41.10839435164141],
                [29.03070673959611, 41.10839440236703],
                [29.030707467823486, 41.10839444392608],
                [29.030708196871146, 41.10839447630692],
                [29.03070892653535, 41.108394499500506],
                [29.030709656612174, 41.10839451350036],
                [29.03071863066684, 41.108392336019065],
                [29.030722788600734, 41.10839231527161],
                [29.030724933763395, 41.108393159464455],
                [29.030727233894854, 41.108394064642674],
                [29.030735138490474, 41.1083971753638],
                [29.030735908359183, 41.108386922417026],
                [29.030733547184656, 41.10838613172037],
                [29.030730514185013, 41.10838510912538],
                [29.030730463220596, 41.10838509107401],
                [29.0307300507764, 41.108384951470676],
                [29.03072963520275, 41.108384817239504],
                [29.03072921662289, 41.10838468842027],
                [29.030728795160964, 41.10838456505122],
                [29.030728370941944, 41.108384447168916],
                [29.03072794409166, 41.10838433480832],
                [29.030727514736682, 41.10838422800276],
                [29.030727083004347, 41.10838412678391],
                [29.0307266490227, 41.108384031181785],
                [29.030726212920424, 41.10838394122474],
                [29.030725774826866, 41.10838385693945],
                [29.030725334871942, 41.108383778350905],
                [29.030724893186125, 41.108383705482424],
                [29.030724449900415, 41.108383638355605],
                [29.030724005146254, 41.10838357699036],
                [29.030723559055552, 41.108383521404896],
                [29.030723111760604, 41.108383471615696],
                [29.03072266339406, 41.10838342763751],
                [29.03072221408888, 41.10838338948339],
                [29.03072176397832, 41.108383357164655],
                [29.03072131319587, 41.108383330690884],
                [29.0307208618752, 41.10838331006993],
                [29.03072041015017, 41.10838329530792],
                [29.030719958154737, 41.10838328640921],
                [29.030719506022955, 41.10838328337645],
                [29.030719053888895, 41.10838328621055],
                [29.03071860188665, 41.10838329491064],
                [29.030718150150268, 41.10838330947417],
                [29.030717698813724, 41.10838332989681],
                [29.030717248010856, 41.10838335617251],
                [29.03071679787536, 41.10838338829345],
                [29.03071634854073, 41.108383426250136],
                [29.030715900140216, 41.1083834700313],
                [29.03071545280681, 41.108383519623956],
                [29.030715006673162, 41.108383575013406],
                [29.030714561871587, 41.108383636183206],
                [29.030714118533986, 41.10838370311523],
                [29.030713676791848, 41.108383775789626],
                [29.03071323677618, 41.108383854184844],
                [29.03071279861745, 41.10838393827761],
                [29.030712362445634, 41.10838402804303],
                [29.03071192839006, 41.10838412345445],
                [29.030711496579457, 41.10838422448358],
                [29.030711067141887, 41.10838433110046],
                [29.030710640204706, 41.10838444327348],
                [29.030710215894526, 41.108384560969355],
                [29.03070979433718, 41.1083846841532],
                [29.03070937565769, 41.10838481278847],
                [29.030708959980224, 41.10838494683703],
                [29.03070854742805, 41.10838508625912],
                [29.030708138123508, 41.108385231013386],
                [29.030707732188, 41.108385381056905],
                [29.030707329741897, 41.108385536345175],
                [29.030706930904564, 41.10838569683215],
                [29.030700459814263, 41.108378007497855],
                [29.030689104915897, 41.108383235885746],
                [29.030697439326058, 41.10839303564903],
                [29.030698138530422, 41.108393194540696],
                [29.03069884116232, 41.108393344603314],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: "stair_bord",
          unti_subty: null,
          wall_type: null,
          layer: "units",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/units.shp",
          "fill-extrusion-height": 0,
          "fill-extrusion-color": "#999999",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03539681515567, 41.108685847324125],
                [29.035409093427326, 41.108685882103195],
                [29.035409158069868, 41.108672926356284],
                [29.035396863628687, 41.108671697585095],
                [29.03539681515567, 41.108685847324125],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "second_floor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040511774388797, 41.10895104141702],
                [29.040513710394418, 41.10895122994766],
                [29.04051371145603, 41.10895122375878],
                [29.04051459426989, 41.10895130686061],
                [29.04051474464549, 41.10895043021668],
                [29.040514744645492, 41.10895043021668],
                [29.040514765572713, 41.10895030821734],
                [29.040514905373282, 41.1089494932226],
                [29.040514905373257, 41.1089494932226],
                [29.040518173894856, 41.10893043873413],
                [29.04051938318818, 41.10892338891953],
                [29.040515849515323, 41.10892304686847],
                [29.040511370111, 41.108949160438094],
                [29.04048902245418, 41.10894705678823],
                [29.040487275467374, 41.10894689233929],
                [29.040486699602365, 41.108950365304715],
                [29.040488446589166, 41.108950529753656],
                [29.040488721568774, 41.10894887138816],
                [29.040511774388797, 41.10895104141702],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030429984908473, 41.1083750848783],
                [29.03043764792673, 41.108375831126146],
                [29.030446540352624, 41.10832399100627],
                [29.03043887725043, 41.10832324524717],
                [29.030429984908473, 41.1083750848783],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.0304258767511, 41.10837198063329],
                [29.030426149002118, 41.10837039349386],
                [29.030430713401433, 41.10837083798891],
                [29.030430441150436, 41.10837242512832],
                [29.0304258767511, 41.10837198063329],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030419930671474, 41.10833250992529],
                [29.030421733175498, 41.108332685458684],
                [29.030423607414548, 41.10832175921461],
                [29.030421804910528, 41.10832158368118],
                [29.030419930671474, 41.10833250992529],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03038437969863, 41.10835109938625],
                [29.03038687964859, 41.108336525443],
                [29.030386879649317, 41.10833652544344],
                [29.030405594602765, 41.10833834796333],
                [29.03040559460277, 41.10833834796333],
                [29.03040541555085, 41.1083393917813],
                [29.030407218054872, 41.108339567314694],
                [29.030407762818186, 41.10833639151106],
                [29.030405960314162, 41.108336215977666],
                [29.030405786871714, 41.108337227094125],
                [29.030387071918256, 41.108335404574206],
                [29.030387071917442, 41.10833540457442],
                [29.030389973721377, 41.108318487945446],
                [29.03038728025451, 41.10831822564729],
                [29.03037831702088, 41.108370478556736],
                [29.030381010487744, 41.1083707408547],
                [29.030381538477222, 41.10836766283761],
                [29.030381538477393, 41.10836766283778],
                [29.03038860848212, 41.10836835133629],
                [29.03038888073313, 41.108366764196774],
                [29.0303818107284, 41.10836607569825],
                [29.030381810728084, 41.108366075698406],
                [29.030384106503703, 41.10835269202908],
                [29.03038410650438, 41.108352692029854],
                [29.030402821457628, 41.10835451454914],
                [29.03040052568184, 41.108367898217224],
                [29.030397286881158, 41.10836758281299],
                [29.03039701463015, 41.108369169952475],
                [29.030402055934832, 41.108369660890006],
                [29.030402166600524, 41.10836901574312],
                [29.030402166600574, 41.10836901574313],
                [29.030406376144125, 41.10834447539658],
                [29.0304045736401, 41.1083442998632],
                [29.030403094652787, 41.10835292190522],
                [29.030384379699527, 41.10835109938588],
                [29.03038437969863, 41.10835109938625],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03041808377693, 41.10836960807778],
                [29.03041544373294, 41.10836935098229],
                [29.03041817516359, 41.10835342758017],
                [29.030418175163724, 41.10835342758036],
                [29.030433444832177, 41.10835491458726],
                [29.030433748566573, 41.10835314390885],
                [29.03041847889812, 41.10835165690191],
                [29.030418478897964, 41.108351656902016],
                [29.03041949169121, 41.10834575262834],
                [29.030417689187185, 41.10834557709496],
                [29.030413523350422, 41.108369862644466],
                [29.030413368977943, 41.108370762588436],
                [29.030417811525922, 41.10837119521722],
                [29.03041808377693, 41.10836960807778],
              ],
            ],
            [
              [
                [29.03043580836967, 41.108341135887116],
                [29.03043600063861, 41.10834001501788],
                [29.030420730970203, 41.10833852801047],
                [29.030420878365078, 41.108337668743395],
                [29.030419075861055, 41.10833749321],
                [29.03041853109774, 41.108340669013636],
                [29.030420333601764, 41.10834084454703],
                [29.030420538701257, 41.10833964887968],
                [29.03042053870126, 41.108339648879685],
                [29.03043580836967, 41.108341135887116],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030427722868342, 41.1083882718732],
                [29.030417106290226, 41.108450163207166],
                [29.030424769308475, 41.108450909454156],
                [29.030435385886587, 41.10838901812091],
                [29.030435950765295, 41.10838572505295],
                [29.03042828774704, 41.108384978805205],
                [29.030427722868335, 41.1083882718732],
                [29.030427722868342, 41.1083882718732],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030406814435672, 41.10833123670977],
                [29.030408616939695, 41.10833141224317],
                [29.030410491178746, 41.108320485999094],
                [29.030408688674726, 41.108320310465665],
                [29.030406814435672, 41.10833123670977],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "border",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.031134826512112, 41.10845486545881],
                [29.03114005054249, 41.10845537419006],
                [29.031136923674467, 41.10847360284061],
                [29.03113169964409, 41.1084730941095],
                [29.031134826512112, 41.10845486545881],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "border",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.031129722666066, 41.108484619262896],
                [29.03113494669644, 41.10848512799391],
                [29.031134027795122, 41.108490484895825],
                [29.031128803764744, 41.10848997616485],
                [29.031129722666066, 41.108484619262896],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "border",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03112684083614, 41.10850141941178],
                [29.03113206486652, 41.10850192814266],
                [29.031131629114103, 41.108504468439804],
                [29.031126405083725, 41.10850395970893],
                [29.03112684083614, 41.10850141941178],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "border",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03034985222861, 41.10837887355419],
                [29.030343949753743, 41.1083782987534],
                [29.030332068182247, 41.108447564610266],
                [29.03033141730299, 41.10845135903142],
                [29.031122217933497, 41.1085283694495],
                [29.031122217933447, 41.10852836944979],
                [29.031127441964337, 41.10852887818051],
                [29.031129665367274, 41.10851591645393],
                [29.031124441336903, 41.10851540772315],
                [29.0311238275771, 41.10851898574528],
                [29.030338929421028, 41.10844255011584],
                [29.03034985222861, 41.10837887355419],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "border",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030712098830385, 41.108345157838855],
                [29.030521545474116, 41.10832660116663],
                [29.030522390744522, 41.10832167349709],
                [29.030440441703888, 41.108313693045105],
                [29.030439266194737, 41.10831357857037],
                [29.0304383505906, 41.10831891626486],
                [29.030355519627317, 41.10831084992818],
                [29.030346424207423, 41.108363873450976],
                [29.030352326682284, 41.10836444825189],
                [29.030360699525115, 41.108315637133295],
                [29.030446540352624, 41.10832399100627],
                [29.030447332322897, 41.10831937406011],
                [29.030513142637712, 41.10832578287244],
                [29.03051240903801, 41.10833005953548],
                [29.030520811874414, 41.108330877829616],
                [29.030718636745885, 41.10835014262302],
                [29.030719370345587, 41.10834586596128],
                [29.030720993278397, 41.108336404758724],
                [29.030757918536498, 41.108340000653854],
                [29.03075882288841, 41.108334728558134],
                [29.030721897630304, 41.10833113266272],
                [29.030721897630304, 41.10833113266273],
                [29.030714626115103, 41.10833042454014],
                [29.030712098830385, 41.108345157838855],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03089324011924, 41.10839530824643],
                [29.030893240178564, 41.1083953079006],
                [29.0308909968694, 41.1083950894405],
                [29.030890996866265, 41.10839508945876],
                [29.030890989429462, 41.10839513281302],
                [29.03088755341366, 41.1084151637209],
                [29.030889796722825, 41.10841538218093],
                [29.030893004033842, 41.10839668455118],
                [29.03091556068061, 41.10839888432514],
                [29.03091545656722, 41.108399462247704],
                [29.030924451952647, 41.10840038224218],
                [29.030924934173907, 41.108397885120446],
                [29.030916003150995, 41.10839688429956],
                [29.030915878421247, 41.108397516192355],
                [29.03089324011924, 41.10839530824643],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030368203617112, 41.10844540092279],
                [29.030378820194976, 41.10838350958381],
                [29.030378820195207, 41.10838350958404],
                [29.030387143292554, 41.10838432011228],
                [29.03038743390558, 41.10838262592817],
                [29.030379110808234, 41.108381815399916],
                [29.03037911080809, 41.108381815399966],
                [29.030379395348703, 41.1083801566169],
                [29.030376701881835, 41.10837989431899],
                [29.03036551015024, 41.108445138625136],
                [29.030368203617112, 41.10844540092279],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030395681954293, 41.108385151632795],
                [29.030395972567316, 41.10838345744873],
                [29.030403929553444, 41.10838423232396],
                [29.030403638940413, 41.108385926508],
                [29.030395681954293, 41.108385151632795],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03041217405962, 41.108386757683554],
                [29.030412464672654, 41.10838506349951],
                [29.030428013481366, 41.10838657768921],
                [29.030427722868342, 41.1083882718732],
                [29.03041217405962, 41.108386757683554],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03050634542721, 41.10840172163668],
                [29.030506109376958, 41.108403097736264],
                [29.030564108056733, 41.1084087458202],
                [29.030564676862056, 41.10840880121215],
                [29.030564912912286, 41.108407425112674],
                [29.03056434410699, 41.10840736972073],
                [29.03050634542721, 41.10840172163668],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030565332476023, 41.108419596384415],
                [29.030564858879185, 41.10842235730643],
                [29.030569307110042, 41.108422790488326],
                [29.030600322824654, 41.108425810889166],
                [29.03060079642126, 41.10842304996853],
                [29.030569780706884, 41.108420029566325],
                [29.030565332476023, 41.108419596384415],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03043501955973, 41.108391153693006],
                [29.03043366050592, 41.10839907655509],
                [29.030436787254427, 41.10839938104718],
                [29.030437691126718, 41.10839411175222],
                [29.030468287148313, 41.10839709128423],
                [29.030467383276015, 41.10840236057896],
                [29.03047183150687, 41.10840279376093],
                [29.030473190560677, 41.10839487089931],
                [29.03043501955973, 41.108391153693006],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03050552904934, 41.10840617840511],
                [29.030506888103154, 41.10839825554389],
                [29.03049685881002, 41.10839727886137],
                [29.030495499756206, 41.10840520172272],
                [29.030498243322405, 41.108405468899356],
                [29.03049696205785, 41.108412938272835],
                [29.030470481820966, 41.10841035954888],
                [29.030466033590105, 41.10840992636697],
                [29.030465559993267, 41.10841268728898],
                [29.030470008224125, 41.10841312047088],
                [29.03050128127028, 41.10841616593269],
                [29.03050175486712, 41.10841340501081],
                [29.03050303613168, 41.10840593563738],
                [29.03050552904934, 41.10840617840511],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03057555330268, 41.10840483928801],
                [29.030565524009546, 41.10840386260549],
                [29.03056416495573, 41.10841178546684],
                [29.0305700356693, 41.10841235717439],
                [29.030569862312053, 41.10841336779298],
                [29.030574020891613, 41.108413772767825],
                [29.030574194248864, 41.108412762149236],
                [29.03057555330268, 41.10840483928801],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030643211921745, 41.1084150500912],
                [29.030642975871494, 41.108416426190786],
                [29.03070097455127, 41.10842207427472],
                [29.030701543356592, 41.10842212966667],
                [29.030701779406822, 41.108420753567195],
                [29.030701210601524, 41.10842069817525],
                [29.030643211921745, 41.1084150500912],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03070219897056, 41.10843292483894],
                [29.03070172537372, 41.10843568576095],
                [29.030706173604578, 41.10843611894285],
                [29.030737446650733, 41.10843916440466],
                [29.030737920247574, 41.10843640348278],
                [29.03070664720142, 41.10843335802085],
                [29.03070219897056, 41.10843292483894],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030642463965155, 41.108419410445485],
                [29.03064382301897, 41.10841148758426],
                [29.030633793725837, 41.10841051090174],
                [29.03063243467202, 41.10841843376309],
                [29.030642463965155, 41.108419410445485],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030712419797215, 41.108418167742535],
                [29.030702390504082, 41.10841719106001],
                [29.030701031450267, 41.10842511392136],
                [29.030706902163836, 41.10842568562891],
                [29.03070672880659, 41.1084266962475],
                [29.03071088738615, 41.10842710122235],
                [29.0307110607434, 41.10842609060376],
                [29.030712419797215, 41.108418167742535],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030985389241224, 41.10844837229384],
                [29.030985153190972, 41.108449748393426],
                [29.03104342899185, 41.108455423464235],
                [29.031043665042077, 41.10845404736476],
                [29.030985389241224, 41.10844837229384],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030984641284633, 41.108452732648125],
                [29.030986000338448, 41.1084448097869],
                [29.030975971045315, 41.10844383310438],
                [29.0309746119915, 41.10845175596573],
                [29.030984641284633, 41.108452732648125],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030989858330887, 41.10842231888472],
                [29.030989622280636, 41.1084236949843],
                [29.031047898081514, 41.10842937005511],
                [29.03104813413174, 41.10842799395564],
                [29.030989858330887, 41.10842231888472],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030989110374296, 41.108426679239],
                [29.03099046942811, 41.10841875637778],
                [29.03098044013498, 41.10841777969526],
                [29.030979081081163, 41.10842570255661],
                [29.030989110374296, 41.108426679239],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.031048745229334, 41.1084244314465],
                [29.03104738617552, 41.10843235430785],
                [29.03105152550349, 41.10843275740792],
                [29.031051028380205, 41.10843565548447],
                [29.03105869139846, 41.10843640173232],
                [29.031067583824356, 41.108384561612446],
                [29.03105992072216, 41.108383815853344],
                [29.03105288455623, 41.10842483454652],
                [29.031048745229334, 41.1084244314465],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03104427613967, 41.108450484855624],
                [29.031042917085855, 41.108458407716974],
                [29.031047056413524, 41.10845881081701],
                [29.031038165218952, 41.10851064370393],
                [29.031045828237314, 41.10851138995025],
                [29.03105642935832, 41.10844958872708],
                [29.031056994237026, 41.10844629565912],
                [29.03104933121877, 41.10844554941138],
                [29.031048766340067, 41.108448842479376],
                [29.031048766340074, 41.108448842479376],
                [29.031048415467694, 41.10845088795575],
                [29.03104427613967, 41.108450484855624],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030848439169862, 41.10843503570688],
                [29.03084820311961, 41.108436411806466],
                [29.03090647892049, 41.108442086877275],
                [29.030906714970715, 41.1084407107778],
                [29.030848439169862, 41.10843503570688],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030633293374038, 41.10841342779734],
                [29.03063379372584, 41.10841051090174],
                [29.03059925254988, 41.108407147180124],
                [29.030598752198078, 41.10841006407587],
                [29.03059774865355, 41.108415914428676],
                [29.03060195023544, 41.108416323591065],
                [29.03060295377997, 41.108410473238294],
                [29.030633293374038, 41.10841342779734],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03081057498626, 41.10842772636493],
                [29.030810398527105, 41.10842875506699],
                [29.03081057498625, 41.10842772636492],
                [29.030736147296178, 41.1084204783808],
                [29.030735661010773, 41.108423313274116],
                [29.0307346078333, 41.10842945297217],
                [29.03073903849599, 41.10842988444291],
                [29.030740091908196, 41.10842374337734],
                [29.030810088700836, 41.108430561257926],
                [29.030809215932443, 41.10843564922661],
                [29.03081403450916, 41.10843611847349],
                [29.03081539356297, 41.108428195611864],
                [29.03081057498626, 41.10842772636493],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030849050267086, 41.10843147319994],
                [29.030839020973954, 41.10843049651742],
                [29.03083766192014, 41.10843841937877],
                [29.030837661920142, 41.10843841937877],
                [29.03083748855323, 41.108439430014066],
                [29.03084218845227, 41.10843988770394],
                [29.03084236181617, 41.108438877086186],
                [29.030842361790775, 41.10843887706615],
                [29.03084769121327, 41.108439396061165],
                [29.030849050267086, 41.10843147319994],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030913818655574, 41.10843778053259],
                [29.030907326068917, 41.10843714826511],
                [29.0309059670151, 41.10844507112646],
                [29.030915996308234, 41.108446047808854],
                [29.03091690018267, 41.1084407785019],
                [29.030947086246357, 41.10844371811099],
                [29.03094618237406, 41.108448987405716],
                [29.030950630604913, 41.10844942058769],
                [29.03095198965872, 41.10844149772607],
                [29.030913818657773, 41.108437780519765],
                [29.030913818655574, 41.10843778053259],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03056921971579, 41.108382317783345],
                [29.03056945576604, 41.10838094168376],
                [29.030511457086266, 41.10837529359983],
                [29.030510888280944, 41.10837523820788],
                [29.030510652230713, 41.10837661430735],
                [29.03051122103601, 41.1083766696993],
                [29.03056921971579, 41.108382317783345],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03056996767238, 41.10837795742906],
                [29.030568608618566, 41.108385880290285],
                [29.0305786379117, 41.10838685697281],
                [29.030579996965514, 41.10837893411146],
                [29.03056996767238, 41.10837795742906],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03050001184032, 41.10837920013201],
                [29.030510041133454, 41.108380176814535],
                [29.03051140018727, 41.108372253953185],
                [29.0305055294737, 41.10837168224564],
                [29.030505702830947, 41.10837067162705],
                [29.030501544251386, 41.1083702666522],
                [29.030501370894136, 41.10837127727079],
                [29.03050001184032, 41.10837920013201],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030477740106356, 41.10836834845389],
                [29.03047303514866, 41.10836789027086],
                [29.03047203466568, 41.10837372277839],
                [29.03044408296877, 41.10837100075762],
                [29.030444385735166, 41.108369235722776],
                [29.03043887138884, 41.10836869871903],
                [29.030438568622444, 41.1083704637539],
                [29.030438104168702, 41.10837317137615],
                [29.03047157021193, 41.108376430400504],
                [29.030476275169626, 41.10837688858348],
                [29.030477740106356, 41.10836834845389],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030842459102683, 41.10844939081982],
                [29.030842932699528, 41.10844662989838],
                [29.030808189744224, 41.10844324652933],
                [29.03080771614738, 41.108446007450915],
                [29.03083823853204, 41.108448979808514],
                [29.030838238532027, 41.10844897980852],
                [29.03083111901181, 41.108490484370854],
                [29.03083533958246, 41.108490895381884],
                [29.030842459102676, 41.10844939081982],
                [29.030842459102683, 41.10844939081982],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03094455457323, 41.10845933316944],
                [29.03094502817008, 41.10845657224842],
                [29.03098077041776, 41.10846005293078],
                [29.03098029682091, 41.10846281385166],
                [29.03094455457323, 41.10845933316944],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03105642935832, 41.10844958872708],
                [29.031056770562337, 41.10844759961375],
                [29.031072610379347, 41.10844914213947],
                [29.031072269175326, 41.108451131252764],
                [29.03105642935832, 41.10844958872708],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035206018175977, 41.10876558163226],
                [29.03520984467127, 41.10876595426713],
                [29.03521685898338, 41.108725063377086],
                [29.035247271107718, 41.10872802497102],
                [29.03524774470456, 41.10872526404859],
                [29.035213506072715, 41.108721929821506],
                [29.035206018175977, 41.10876558163226],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.031083740136143, 41.10845219501671],
                [29.031095328394056, 41.10845337682491],
                [29.031095669598077, 41.10845138771168],
                [29.031084067995398, 41.10845027363711],
                [29.031081968356958, 41.10845006916822],
                [29.031071085035887, 41.108513849528414],
                [29.031073184674334, 41.10851405399711],
                [29.031083740136015, 41.108452195016554],
                [29.031083740136143, 41.10845219501671],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03110703418983, 41.10844115734571],
                [29.03111086032614, 41.1084415409546],
                [29.031119728618542, 41.10838963866835],
                [29.031115902771376, 41.108389253863294],
                [29.03110703418983, 41.10844115734571],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.031098150762467, 41.10843692329122],
                [29.031098497797313, 41.10843490018523],
                [29.031085530476393, 41.10843363752267],
                [29.031088969918116, 41.10841358665329],
                [29.03110013307917, 41.108414673754055],
                [29.03110040818546, 41.10841306996881],
                [29.031089245009518, 41.10841198295619],
                [29.03109351548476, 41.108387087412225],
                [29.03108968750713, 41.10838671463227],
                [29.031085417046427, 41.108411610091615],
                [29.031073655732733, 41.108410464737915],
                [29.031073380626445, 41.108412068523215],
                [29.03108514191786, 41.10841321400539],
                [29.031081702513323, 41.1084332646581],
                [29.03106894826515, 41.108432022567634],
                [29.03106860123031, 41.10843404567371],
                [29.03108108656202, 41.108435261530836],
                [29.031081086562015, 41.10843526153085],
                [29.031084914539694, 41.10843563431021],
                [29.031084914539697, 41.10843563431019],
                [29.031098150762467, 41.10843692329122],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035353035754166, 41.10873832454429],
                [29.03538408808452, 41.10874134851214],
                [29.035384561685593, 41.108738587590665],
                [29.03535350935101, 41.10873556362229],
                [29.035349571260856, 41.10873518011939],
                [29.035342001163922, 41.10877882404554],
                [29.035345827659217, 41.1087791966804],
                [29.035353023495052, 41.10873832335046],
                [29.035353035754166, 41.10873832454429],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030682112201802, 41.10839693360864],
                [29.030682411904394, 41.1083951864351],
                [29.030923515759998, 41.10841866583973],
                [29.0309232160574, 41.10842041301265],
                [29.030682112201802, 41.10839693360864],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03095155887873, 41.108421396760804],
                [29.030944732273195, 41.108420731965964],
                [29.030944432570593, 41.10842247913883],
                [29.030951259175882, 41.10842314393363],
                [29.030951259175882, 41.108423143933635],
                [29.0309550744417, 41.10842351547537],
                [29.0309553741443, 41.108421768302534],
                [29.030955374124417, 41.1084217683006],
                [29.0309577222787, 41.10840807836885],
                [29.03098903164232, 41.10841108483394],
                [29.030989398149796, 41.10840891798185],
                [29.03098939821302, 41.108408917987916],
                [29.030993532891266, 41.108384814096944],
                [29.030988723007336, 41.108384345695995],
                [29.030984587806532, 41.108408456071075],
                [29.03095809388761, 41.10840591200644],
                [29.030958099843346, 41.108405877286366],
                [29.030954284755435, 41.10840550576149],
                [29.030954277925023, 41.10840554558064],
                [29.03095427282365, 41.10840554509078],
                [29.030953906319084, 41.10840771192575],
                [29.03095155887873, 41.108421396760804],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030796187208374, 41.10838833384005],
                [29.030797726536708, 41.10837936002974],
                [29.03079625102137, 41.10837921633964],
                [29.030794766636774, 41.108387869844904],
                [29.03079476663677, 41.108387869844904],
                [29.03079168619156, 41.10840582788854],
                [29.030793161706907, 41.108405971578584],
                [29.03079618720836, 41.10838833384005],
                [29.030796187208374, 41.10838833384005],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030926110128664, 41.10836141698555],
                [29.030921152510622, 41.10839015775674],
                [29.030936580440155, 41.10839166017528],
                [29.030933381895057, 41.10841030670249],
                [29.030931484608253, 41.10842136729608],
                [29.030933727917425, 41.108421585756076],
                [29.030935625204226, 41.10841052516252],
                [29.030939059832733, 41.1083905025394],
                [29.030923656565967, 41.10838900252257],
                [29.03092835343783, 41.10836163544569],
                [29.030926110128664, 41.10836141698555],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "border",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030994792077564, 41.10837747342983],
                [29.030994792077564, 41.10837747342979],
                [29.031145583100596, 41.108392157912725],
                [29.03114558310053, 41.108392157912775],
                [29.031137263286894, 41.108440659829405],
                [29.031142487317272, 41.108441168560745],
                [29.031150807130906, 41.10839266664449],
                [29.031151531548794, 41.10838844351269],
                [29.031000493991588, 41.10837373502058],
                [29.031001350056293, 41.108368744426926],
                [29.03100135005632, 41.108368744426926],
                [29.031002177243895, 41.10836392217788],
                [29.03085024715496, 41.10834912676292],
                [29.030849419967385, 41.10835394901305],
                [29.030848646976217, 41.108358455314246],
                [29.030795359378057, 41.108353266005565],
                [29.03079698231081, 41.108343804804036],
                [29.03079698231087, 41.10834380480404],
                [29.030797886662786, 41.10833853270865],
                [29.030779571094076, 41.108336749082255],
                [29.03077866674217, 41.108342021177805],
                [29.03079025885872, 41.108343150053656],
                [29.030788635925973, 41.10835261125528],
                [29.03078790232627, 41.10835688791658],
                [29.030847913376544, 41.10836273197515],
                [29.030847913376537, 41.108362731975184],
                [29.030856793061762, 41.1083635967059],
                [29.030857526661464, 41.108359320045054],
                [29.03085752666144, 41.108359320045054],
                [29.03085829965261, 41.10835481374388],
                [29.030991562676252, 41.10836779130211],
                [29.030990706611515, 41.10837278189604],
                [29.030989982193635, 41.10837700502884],
                [29.030988723007336, 41.108384345695995],
                [29.030993532891266, 41.10838481409695],
                [29.030994792077564, 41.10837747342984],
                [29.030994792077557, 41.10837747342984],
                [29.030994792077564, 41.10837747342983],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030776686655848, 41.108404367189465],
                [29.03077892996501, 41.10840458564954],
                [29.030779769687232, 41.10839969032887],
                [29.030777526378074, 41.108399471868786],
                [29.030776686655848, 41.108404367189465],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030919483960147, 41.108418273211235],
                [29.03092157591678, 41.1084184769321],
                [29.03092354193683, 41.10840701564558],
                [29.030921449980198, 41.10840681192468],
                [29.030919483960147, 41.108418273211235],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030780262987495, 41.108383518279894],
                [29.03078250629666, 41.108383736739924],
                [29.030784725371838, 41.108370800207084],
                [29.030782482062673, 41.10837058174701],
                [29.030780262987495, 41.108383518279894],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030474818029905, 41.108358600460704],
                [29.030474344433067, 41.10836136138272],
                [29.030478792663924, 41.108361794564615],
                [29.03051006571008, 41.108364840026425],
                [29.03051053930692, 41.108362079104545],
                [29.030479266260766, 41.108359033642614],
                [29.030474818029905, 41.108358600460704],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "border",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035095108541068, 41.10862466021582],
                [29.03508937110427, 41.10862410999753],
                [29.035006540140987, 41.10861604366085],
                [29.034997444721093, 41.10866906718364],
                [29.03499677393365, 41.10867300800325],
                [29.034984892362154, 41.10874227386012],
                [29.034984241482896, 41.10874606828128],
                [29.035769589567476, 41.108822547715505],
                [29.035770315089813, 41.108818318191766],
                [29.03579181628164, 41.10869297416465],
                [29.035095108541068, 41.10862466021582],
              ],
              [
                [29.035728330355006, 41.10869062409355],
                [29.035785893754426, 41.108696263356585],
                [29.03576504482105, 41.10881780495821],
                [29.035681226905233, 41.10880964253436],
                [29.035680852977215, 41.1088118223951],
                [29.035073549407535, 41.10875268144118],
                [29.035073923335577, 41.10875050157835],
                [29.03499086946543, 41.10874241355114],
                [29.035002676408517, 41.108673582804045],
                [29.035003347195953, 41.10866964198456],
                [29.035011720038785, 41.108620830865966],
                [29.035094416706098, 41.108628757693545],
                [29.035094535158745, 41.10862805614317],
                [29.035094805342688, 41.10862645594556],
                [29.035704186837243, 41.108686154720516],
                [29.035703829776196, 41.10868822386675],
                [29.035717948322546, 41.108689607006085],
                [29.035728330355006, 41.10869062409355],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035076398300134, 41.10869065405728],
                [29.03506626031732, 41.10874975533141],
                [29.035073923335577, 41.10875050157835],
                [29.035084061318386, 41.108691400304984],
                [29.035084626197094, 41.10868810723702],
                [29.035076963178838, 41.10868736098928],
                [29.035076398300134, 41.10869065405728],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03501735764416, 41.10874499304706],
                [29.035027495627006, 41.10868589176811],
                [29.035035818724353, 41.10868670229635],
                [29.03503610933738, 41.108685008112246],
                [29.03502778623989, 41.10868419758404],
                [29.035028070780502, 41.10868253880098],
                [29.035025377313634, 41.10868227650307],
                [29.035014664177286, 41.10874473074942],
                [29.03501735764416, 41.10874499304706],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03504435738609, 41.10868753381687],
                [29.035044647999115, 41.108685839632805],
                [29.035052604985243, 41.10868661450803],
                [29.035052314372212, 41.108688308692074],
                [29.03504435738609, 41.10868753381687],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03506084949142, 41.10868913986763],
                [29.035061140104453, 41.108687445683586],
                [29.035076688913165, 41.108688959873284],
                [29.03507639830014, 41.10869065405728],
                [29.03506084949142, 41.10868913986763],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035154498246516, 41.108704052927266],
                [29.03515426219626, 41.10870542902684],
                [29.035211862375796, 41.10871103830365],
                [29.035211970624758, 41.10871104884526],
                [29.03521220667499, 41.10870967274578],
                [29.03521209842605, 41.108709662204184],
                [29.035154498246516, 41.108704052927266],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03500004961514, 41.10868889618558],
                [29.03500047294455, 41.108686428308104],
                [29.03502426801814, 41.108688745524425],
                [29.03502384468873, 41.10869121340181],
                [29.03500004961514, 41.10868889618558],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035083694991528, 41.10869353587708],
                [29.03508233593772, 41.10870145873916],
                [29.035085462686226, 41.10870176323125],
                [29.035086366558517, 41.10869649393629],
                [29.03511644038647, 41.108699420132574],
                [29.03511553609998, 41.10870469184294],
                [29.035120506938668, 41.108705175945005],
                [29.035121865992476, 41.108697253083385],
                [29.035083694991528, 41.10869353587708],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035153750289933, 41.10870841328151],
                [29.035155109343748, 41.10870049042029],
                [29.035145080050615, 41.10869951373777],
                [29.0351437209968, 41.10870743659912],
                [29.035146464563, 41.10870770377576],
                [29.035145183298443, 41.108715173149236],
                [29.03511870306156, 41.10871259442528],
                [29.0351142548307, 41.10871216124337],
                [29.03511378123386, 41.10871492216538],
                [29.035118229464718, 41.10871535534728],
                [29.035149502510873, 41.10871840080909],
                [29.035149976107714, 41.10871563988721],
                [29.035151257372274, 41.10870817051378],
                [29.035153750289933, 41.10870841328151],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035222847065377, 41.10870708692115],
                [29.035212817772244, 41.10870611023863],
                [29.03521145871843, 41.10871403309998],
                [29.035217329431998, 41.108714604807524],
                [29.03521715607475, 41.108715615426114],
                [29.03522131465431, 41.10871602040096],
                [29.03522148801156, 41.10871500978237],
                [29.035222847065377, 41.10870708692115],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035290287793103, 41.10871727650557],
                [29.035290051742848, 41.108718652605155],
                [29.035347868423848, 41.108724282965504],
                [29.035348131118177, 41.10872430854746],
                [29.035348367168414, 41.10872293244798],
                [29.035348104474103, 41.108722906866035],
                [29.035290287793103, 41.10871727650557],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035074790653756, 41.10867462464296],
                [29.035075062904774, 41.10867303750353],
                [29.03507908513375, 41.10867342920029],
                [29.035078817085314, 41.108675016748954],
                [29.035074790653756, 41.10867462464296],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035289539836523, 41.108721636859805],
                [29.035290898890338, 41.10871371399858],
                [29.035280869597205, 41.10871273731606],
                [29.03527951054339, 41.10872066017741],
                [29.035289539836523, 41.108721636859805],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035359007558807, 41.10872034662328],
                [29.035348978265674, 41.10871936994076],
                [29.03534761921186, 41.10872729280211],
                [29.035353489925427, 41.10872786450965],
                [29.03535331656818, 41.10872887512824],
                [29.03535747514774, 41.10872928010309],
                [29.03535764850499, 41.1087282694845],
                [29.035359007558807, 41.10872034662328],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035280369245406, 41.10871565421166],
                [29.03528086959721, 41.10871273731606],
                [29.03524632842125, 41.10870937359444],
                [29.035245828069446, 41.10871229049019],
                [29.035244824524916, 41.108718140842996],
                [29.035249026106808, 41.108718550005385],
                [29.035250029651337, 41.108712699652614],
                [29.035280369245406, 41.10871565421166],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035456923980128, 41.1087298819938],
                [29.035382496290048, 41.10872263400967],
                [29.035382010004643, 41.10872546890299],
                [29.03538095682717, 41.10873160860104],
                [29.035385679953546, 41.10873206855273],
                [29.035386733130984, 41.108725928854696],
                [29.035456437694705, 41.1087327168868],
                [29.035455564926313, 41.10873780485548],
                [29.03546038350303, 41.108738274102365],
                [29.03546174255684, 41.10873035124074],
                [29.035456923980128, 41.1087298819938],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03506884457413, 41.10863515393496],
                [29.035070647078154, 41.108635329468356],
                [29.035072140643198, 41.10862662243818],
                [29.035070337674117, 41.10862644961593],
                [29.03506884457413, 41.10863515393496],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "second_floor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.04040279232638, 41.10898131922109],
                [29.040402258670884, 41.108981267252865],
                [29.04040071920241, 41.1089902418437],
                [29.0404012528579, 41.108990293811914],
                [29.04040125286717, 41.10899029375788],
                [29.0404012528635, 41.10899029381246],
                [29.040405442316768, 41.108990701793516],
                [29.04040649551182, 41.10898456209789],
                [29.040476733731037, 41.10899140209822],
                [29.040475860962644, 41.1089964900669],
                [29.04048067953936, 41.108996959313785],
                [29.040482038593172, 41.10898903645216],
                [29.04047722001646, 41.10898856720522],
                [29.04040279232638, 41.108981319221094],
                [29.040402792326375, 41.1089813192211],
                [29.04040279232638, 41.10898131922109],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "second_floor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.04061584328479, 41.108982955825496],
                [29.04061625323668, 41.108980483461366],
                [29.0404855204007, 41.10896817720552],
                [29.0404860075052, 41.108965239543735],
                [29.040484260518394, 41.10896507509484],
                [29.040483773413893, 41.10896801275662],
                [29.04048336346201, 41.108970485121226],
                [29.04061584328479, 41.108982955825496],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "second_floor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040669029731276, 41.10898545146379],
                [29.040668619779353, 41.10898792382773],
                [29.040718030034093, 41.1089925749562],
                [29.040718453915492, 41.108990103903636],
                [29.040669029731276, 41.10898545146379],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "second_floor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040660211811094, 41.108987132361655],
                [29.040660621763017, 41.10898465999768],
                [29.04065316260782, 41.108983957846064],
                [29.040661358765796, 41.108936994429534],
                [29.040656906021436, 41.108936566948096],
                [29.040649111199556, 41.10898357647541],
                [29.040624777446236, 41.108981285869596],
                [29.040624367494342, 41.1089837582337],
                [29.040660211811094, 41.108987132361655],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "second_floor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.04075589617972, 41.10894820253303],
                [29.040755896179707, 41.10894820253303],
                [29.04075178147255, 41.10897142745375],
                [29.04075149748671, 41.108973030374315],
                [29.040747947057426, 41.108993070303434],
                [29.040735450524654, 41.108991853355484],
                [29.040735103489812, 41.10899387646156],
                [29.04074758882152, 41.108995092318686],
                [29.0407514167992, 41.10899546509804],
                [29.04076465302197, 41.10899675407907],
                [29.040765000056815, 41.10899473097308],
                [29.040751775035105, 41.1089934430828],
                [29.040755325464392, 41.1089734031538],
                [29.040755609450233, 41.10897180023325],
                [29.04075972376861, 41.10894857750708],
                [29.040759723768602, 41.10894857750708],
                [29.040759723768613, 41.10894857750707],
                [29.040760089639573, 41.10894645732456],
                [29.04075626205067, 41.10894608235049],
                [29.04075589617971, 41.10894820253302],
                [29.04075589617972, 41.10894820253303],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "second_floor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.04078248217486, 41.108948592659566],
                [29.040782106397835, 41.1089507702465],
                [29.04077335264037, 41.109000969646],
                [29.04077704098011, 41.10900134062021],
                [29.040785933986726, 41.108951145220544],
                [29.040786309763753, 41.10894896763363],
                [29.04078248217486, 41.108948592659566],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03563003325005, 41.1087503618865],
                [29.0356297971998, 41.10875173798608],
                [29.035687600480067, 41.108757367041434],
                [29.0356878365303, 41.10875599094195],
                [29.03563003325005, 41.1087503618865],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03562928529346, 41.10875472224078],
                [29.035630644347275, 41.10874679937956],
                [29.035620615054142, 41.10874582269704],
                [29.035619256000327, 41.10875374555839],
                [29.03562928529346, 41.10875472224078],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035688447627884, 41.10875242843287],
                [29.03568708857407, 41.10876035129422],
                [29.035689639587297, 41.10876059971947],
                [29.035681226905233, 41.10880964253436],
                [29.035690478321577, 41.10881054346329],
                [29.035700600846532, 41.10875153230433],
                [29.03570116572524, 41.10874823923637],
                [29.035693502706984, 41.10874749298863],
                [29.035692937828287, 41.108750786056625],
                [29.035692586955907, 41.108752831532996],
                [29.035688447627884, 41.10875242843287],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035494167921303, 41.108737130934884],
                [29.035493931871052, 41.10873850703447],
                [29.03555220767193, 41.108744182105276],
                [29.035552443722157, 41.1087428060058],
                [29.035494167921303, 41.108737130934884],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035494779018528, 41.108733568427944],
                [29.035484749725395, 41.10873259174542],
                [29.03548339067158, 41.10874051460677],
                [29.035483390671583, 41.10874051460677],
                [29.035483217304673, 41.10874152524207],
                [29.03548791720371, 41.10874198293194],
                [29.03548809056761, 41.10874097231419],
                [29.035488090542216, 41.10874097229415],
                [29.035493419964713, 41.108741491289166],
                [29.035494779018528, 41.108733568427944],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035559547407015, 41.10873987576059],
                [29.035553054820358, 41.10873924349311],
                [29.035551695766543, 41.10874716635446],
                [29.035561725059676, 41.108748143036856],
                [29.035562628934112, 41.1087428737299],
                [29.0355928149978, 41.10874581333899],
                [29.0355919111255, 41.10875108263372],
                [29.035596359356354, 41.10875151581569],
                [29.035597718410163, 41.10874359295407],
                [29.035559547409214, 41.108739875747766],
                [29.035559547407015, 41.10873987576059],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035589858878335, 41.108763046468304],
                [29.035590332479988, 41.10876028554774],
                [29.035621359013582, 41.10876330700038],
                [29.035620885416883, 41.108766067921295],
                [29.035589858878335, 41.108763046468304],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03570051451597, 41.108752035584786],
                [29.035700855719988, 41.10875004647145],
                [29.035716695536998, 41.10875158899717],
                [29.035716354332976, 41.108753578110466],
                [29.03570051451597, 41.108752035584786],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035727816791937, 41.10875469434698],
                [29.03573939735841, 41.108755822084994],
                [29.03573975475919, 41.10875383454904],
                [29.03572605659967, 41.10875250059565],
                [29.035715732654882, 41.10881300280122],
                [29.035717832293322, 41.108813207269904],
                [29.035727816791937, 41.10875469434698],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035033293601288, 41.10865374339592],
                [29.035035793551973, 41.10863916945311],
                [29.03505450850542, 41.108640991973004],
                [29.035054329453505, 41.10864203579097],
                [29.03505613195753, 41.108642211324366],
                [29.035056676720842, 41.10863903552073],
                [29.03505487421682, 41.10863885998734],
                [29.03505470077437, 41.1086398711038],
                [29.03503598582091, 41.10863804858388],
                [29.035038498961466, 41.1086233977398],
                [29.035035804799662, 41.10862313949292],
                [29.035027230923536, 41.10867312256641],
                [29.0350299243904, 41.108673384864375],
                [29.03503045238005, 41.10867030684745],
                [29.035037522384776, 41.10867099534596],
                [29.035037794635787, 41.108669408206445],
                [29.035030724631056, 41.10866871970792],
                [29.03503302040636, 41.10865533603875],
                [29.035051735360284, 41.10865715855881],
                [29.035049439584498, 41.108670542226896],
                [29.035046200783814, 41.10867022682266],
                [29.035045928532806, 41.10867181396215],
                [29.03505096983749, 41.10867230489968],
                [29.03505108050318, 41.108671659752794],
                [29.03505529004678, 41.10864711940625],
                [29.035053487542758, 41.10864694387287],
                [29.035052008555443, 41.108655565914894],
                [29.035033293601288, 41.10865374339592],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035478080608552, 41.10879207584936],
                [29.03548530312543, 41.108749970847704],
                [29.03545400090176, 41.10874692254783],
                [29.035453527300685, 41.108749683469],
                [29.035480608957943, 41.10875232075784],
                [29.03547386003793, 41.10879166483821],
                [29.035478080608552, 41.10879207584936],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035084059934462, 41.10864371529263],
                [29.035084059916976, 41.10864371539457],
                [29.03508410145431, 41.1086437194392],
                [29.03508429075531, 41.10864259828099],
                [29.035084252169256, 41.10864259452372],
                [29.03506964487286, 41.10864117202014],
                [29.035069792267734, 41.10864031275307],
                [29.03506798976371, 41.10864013721967],
                [29.035067445000397, 41.10864331302331],
                [29.03506924750442, 41.1086434885567],
                [29.035069452603917, 41.10864229288936],
                [29.035084059900132, 41.10864371539294],
                [29.035084059934462, 41.10864371529263],
              ],
            ],
            [
              [
                [29.035067089066246, 41.10865607158984],
                [29.035081774401156, 41.10865750169267],
                [29.03508207344701, 41.10865573055768],
                [29.035067392800777, 41.10865430091158],
                [29.035068405593865, 41.10864839663801],
                [29.03506660308984, 41.10864822110463],
                [29.035062437253078, 41.10867250665414],
                [29.0350622828806, 41.10867340659811],
                [29.035066725428578, 41.10867383922689],
                [29.035066997679586, 41.10867225208745],
                [29.035064357635598, 41.10867199499196],
                [29.035067089066246, 41.10865607158984],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035055728338328, 41.108633880719445],
                [29.03505753084235, 41.10863405625284],
                [29.035059021712538, 41.10862536493286],
                [29.035057218743457, 41.10862519211061],
                [29.035055728338328, 41.108633880719445],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035086751710832, 41.10862802297115],
                [29.035094416706098, 41.108628757693545],
                [29.03508727190753, 41.10867114157768],
                [29.035086043492175, 41.108678349062615],
                [29.035078378496912, 41.108677614340785],
                [29.035079761889204, 41.108669421035614],
                [29.035086751710832, 41.10862802297115],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "0",
          poly_type: null,
          label: null,
          floor: "groundfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.030644243185314, 41.108374439153025],
                [29.03064197288623, 41.10837421806448],
                [29.03064150237694, 41.108376960988984],
                [29.030619592985236, 41.10837482738678],
                [29.030619592984568, 41.10837482738665],
                [29.03062174690416, 41.108362270695366],
                [29.030621746904902, 41.10836227069555],
                [29.0306426556886, 41.108364306855954],
                [29.030643656296434, 41.108364404298165],
                [29.030643182994236, 41.10836716350491],
                [29.030645453118904, 41.10836738457649],
                [29.0306459264211, 41.108364625369745],
                [29.03064623025337, 41.108362854120685],
                [29.03064295952086, 41.10836253560688],
                [29.03062205073717, 41.10836049944643],
                [29.03062205073716, 41.10836049944643],
                [29.030622050736397, 41.108360499446505],
                [29.030625377979558, 41.108341102638306],
                [29.030621176397666, 41.10834069347544],
                [29.030613112920364, 41.10838770107784],
                [29.030589774332228, 41.10838542829542],
                [29.03058935031582, 41.10838790017915],
                [29.030612688904313, 41.10839017296152],
                [29.030616890486172, 41.10839058212427],
                [29.030638799878083, 41.108392715726026],
                [29.0306410700018, 41.1083929367974],
                [29.030645933282884, 41.10839341039816],
                [29.030646232985482, 41.10839166322453],
                [29.03064136970438, 41.10839118962376],
                [29.03064174499221, 41.1083890018114],
                [29.030639474868487, 41.108388780740015],
                [29.03063909958066, 41.108390968552364],
                [29.030617190188778, 41.10838883495057],
                [29.030617190188657, 41.1083888349506],
                [29.030619350157462, 41.108376242994275],
                [29.030619350158084, 41.10837624299446],
                [29.030641259549803, 41.10837837659663],
                [29.03064085288358, 41.10838074733569],
                [29.030643123182653, 41.10838096842419],
                [29.030643529848877, 41.10837859768515],
                [29.030643772676026, 41.10837718207751],
                [29.030644243185314, 41.108374439153025],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03529167249933, 41.108676787432856],
                [29.035289402200245, 41.108676566344315],
                [29.035288931690957, 41.108679309268815],
                [29.03526702229925, 41.108677175666614],
                [29.035269176218918, 41.10866461897538],
                [29.035290085002615, 41.108666655135785],
                [29.03529108561045, 41.108666752577996],
                [29.035290612308252, 41.10866951178474],
                [29.03529288243292, 41.10866973285632],
                [29.035293355735117, 41.10866697364958],
                [29.035293659567387, 41.108665202400516],
                [29.035290388834877, 41.10866488388671],
                [29.035269480051184, 41.10866284772626],
                [29.035272732524877, 41.10864388679687],
                [29.03526853135632, 41.108643475224376],
                [29.03526054223438, 41.10869004935767],
                [29.035237203646243, 41.10868777657525],
                [29.035236779629837, 41.108690248458984],
                [29.03526011821833, 41.10869252124135],
                [29.035264319800188, 41.1086929304041],
                [29.0352862291921, 41.10869506400586],
                [29.035288499315815, 41.10869528507723],
                [29.0352933625969, 41.10869575867799],
                [29.035293662299498, 41.10869401150436],
                [29.035288799018396, 41.10869353790359],
                [29.035289174306225, 41.108691350091235],
                [29.035286904182502, 41.108691129019846],
                [29.035286528894677, 41.108693316832195],
                [29.035264619502794, 41.1086911832304],
                [29.035266779471478, 41.108678591274106],
                [29.03528868886382, 41.108680724876464],
                [29.035288282197595, 41.10868309561552],
                [29.03529055249667, 41.108683316704024],
                [29.035290959162893, 41.108680945964984],
                [29.03529120199004, 41.10867953035734],
                [29.03529167249933, 41.108676787432856],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035216609512283, 41.108684662214856],
                [29.03521684556253, 41.10868328611528],
                [29.035159857604956, 41.108677736458475],
                [29.035159288799633, 41.108677681066524],
                [29.035159052749403, 41.108679057166],
                [29.0351596215547, 41.108679112557944],
                [29.035216609512283, 41.108684662214856],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035217357468838, 41.10868030186055],
                [29.035215998415023, 41.108688224721774],
                [29.035226027708156, 41.108689201404296],
                [29.03522738676197, 41.108681278542946],
                [29.035217357468838, 41.10868030186055],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03514841235901, 41.10868164299066],
                [29.035158441652143, 41.10868261967318],
                [29.03515980070596, 41.10867469681183],
                [29.03515392999239, 41.10867412510429],
                [29.035154103349637, 41.1086731144857],
                [29.035149944770076, 41.10867270951085],
                [29.035149771412826, 41.10867372012944],
                [29.03514841235901, 41.10868164299066],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035126140625046, 41.108670791312534],
                [29.03512143566735, 41.10867033312951],
                [29.03512043518437, 41.108676165637036],
                [29.03509248348746, 41.10867344361627],
                [29.035092786253855, 41.10867167858142],
                [29.03508727190753, 41.10867114157768],
                [29.035086969141133, 41.10867290661255],
                [29.035086504687392, 41.1086756142348],
                [29.03511997073062, 41.10867887325915],
                [29.035124675688316, 41.108679331442126],
                [29.035126140625046, 41.108670791312534],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035123032321728, 41.108661025210786],
                [29.035122558720968, 41.108663786132425],
                [29.035127193182614, 41.10866423742326],
                [29.03515846622877, 41.10866728288507],
                [29.03515893982561, 41.10866452196319],
                [29.035127666779456, 41.10866147650126],
                [29.035123032321728, 41.108661025210786],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035778217267524, 41.10874101447367],
                [29.035778596936336, 41.10873880114377],
                [29.03575569135596, 41.10873657053157],
                [29.03575530945122, 41.10873878364381],
                [29.035778217267524, 41.10874101447367],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035328564251156, 41.108699770267975],
                [29.035328863953747, 41.10869802309443],
                [29.035414103394473, 41.108706323886764],
                [29.03541380369188, 41.108708071060086],
                [29.035328564251156, 41.108699770267975],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035422502207588, 41.10870891813939],
                [29.035425445037415, 41.108709204718195],
                [29.035433940367746, 41.10865967955566],
                [29.03543099753791, 41.108659392976634],
                [29.035422502207588, 41.10870891813939],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035464287070436, 41.10871298723491],
                [29.035464786996766, 41.108710072819406],
                [29.035465811150328, 41.10870410232119],
                [29.035461460792373, 41.10870367867439],
                [29.035460436638814, 41.108709649172624],
                [29.035425944963738, 41.10870629030252],
                [29.035425445037415, 41.108709204718195],
                [29.035464287070436, 41.10871298723491],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035497904053592, 41.1087161442217],
                [29.035499263107408, 41.10870822136048],
                [29.035489233814275, 41.10870724467796],
                [29.03548787476046, 41.10871516753931],
                [29.035497904053592, 41.1087161442217],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035498763295813, 41.10871113495799],
                [29.03549852724556, 41.108712511057576],
                [29.035556963785087, 41.108718201781436],
                [29.035557199837605, 41.10871682568217],
                [29.035498763295813, 41.10871113495799],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035600617314074, 41.10872346886066],
                [29.035601678190996, 41.10871728433906],
                [29.035597443873943, 41.108716871988555],
                [29.035596382997028, 41.108723056510186],
                [29.035563608628358, 41.108719864844005],
                [29.035564497450522, 41.108714683338015],
                [29.035557681194174, 41.10871401955048],
                [29.035556792372013, 41.10871920105655],
                [29.035556320866135, 41.10872194976209],
                [29.035600145808203, 41.10872621756603],
                [29.035600617314074, 41.10872346886066],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03521983323061, 41.1086805429576],
                [29.035224979775926, 41.10868104414348],
                [29.035232037795595, 41.10863989841707],
                [29.035226891250286, 41.10863939723086],
                [29.03521983323061, 41.1086805429576],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035599071598103, 41.108707382955004],
                [29.03559859800364, 41.10871014383682],
                [29.035602801688537, 41.10871055324454],
                [29.03563407473469, 41.10871359870635],
                [29.035634548331533, 41.10871083778447],
                [29.03560327528538, 41.10870779232254],
                [29.035599071598103, 41.108707382955004],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035633753924557, 41.1087294904242],
                [29.035635112978373, 41.10872156756298],
                [29.03562508368524, 41.10872059088046],
                [29.035623724631424, 41.10872851374181],
                [29.035633753924557, 41.1087294904242],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035634602110928, 41.10872454576145],
                [29.035634366060677, 41.10872592186103],
                [29.0356921888456, 41.10873155281578],
                [29.035692424895828, 41.1087301767163],
                [29.035634602110928, 41.10872454576145],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035004093191635, 41.10866529305881],
                [29.03500445544937, 41.10866318120739],
                [29.035028538793572, 41.10866552649487],
                [29.03502817653584, 41.10866763834622],
                [29.035004093191635, 41.10866529305881],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.03569293576313, 41.108727198518594],
                [29.035691576709315, 41.108735121379944],
                [29.03569566817417, 41.10873551981895],
                [29.035695168118142, 41.10873841760996],
                [29.035702831140878, 41.108739163858196],
                [29.035711492040754, 41.10868897450915],
                [29.035703829776196, 41.10868822386675],
                [29.035697075090027, 41.10872760161861],
                [29.03569293576313, 41.108727198518594],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035750685440057, 41.108743901993165],
                [29.035754364017368, 41.10874426236885],
                [29.03576303340861, 41.10869402381719],
                [29.0357593548313, 41.108693663441244],
                [29.035750685440057, 41.108743901993165],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035742341296263, 41.108739690363315],
                [29.03574268833111, 41.10873766725732],
                [29.035729666424015, 41.10873639914692],
                [29.035733126129568, 41.108716350382984],
                [29.035744323612967, 41.10871744082615],
                [29.035744598719255, 41.108715837040904],
                [29.035733402858696, 41.10871474675576],
                [29.035737412042902, 41.10869151379132],
                [29.035733584454, 41.10869113881727],
                [29.03572957488867, 41.10871437399051],
                [29.03571784626653, 41.10871323181001],
                [29.03571757116024, 41.10871483559531],
                [29.035729298159534, 41.10871597761774],
                [29.0357258384537, 41.10873602638343],
                [29.035713138798947, 41.108734789639726],
                [29.035712791764105, 41.1087368127458],
                [29.035725277095814, 41.10873802860293],
                [29.035729105073493, 41.10873840138228],
                [29.035742341296263, 41.108739690363315],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "1",
          poly_type: null,
          label: null,
          floor: "firstfloor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.035466932221578, 41.10869732198255],
                [29.035498171095718, 41.10870036411659],
                [29.03550400726184, 41.10866654389891],
                [29.035498861222827, 41.108666039761225],
                [29.035493532322974, 41.108697105337],
                [29.03546740581842, 41.108694561060545],
                [29.03546309676955, 41.108694141432586],
                [29.035462623172958, 41.10869690235462],
                [29.035466932221578, 41.10869732198255],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "second_floor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.04022777780462, 41.10902411793377],
                [29.040234597738596, 41.10898346828579],
                [29.04026648385334, 41.10898659878808],
                [29.04026695925317, 41.10898384394308],
                [29.040235909896097, 41.10898084845229],
                [29.04023505232396, 41.108980758766734],
                [29.0402312245489, 41.10898039418875],
                [29.040223950029564, 41.109023753356034],
                [29.04022777780462, 41.10902411793377],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "second_floor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040372634505104, 41.10899694185298],
                [29.04040390755126, 41.10899998731479],
                [29.0404043811481, 41.10899722639291],
                [29.040373108101946, 41.10899418093098],
                [29.040369170011793, 41.10899379742808],
                [29.040361698453257, 41.109036873250346],
                [29.040365527817894, 41.10903723797941],
                [29.04037262224599, 41.108996940659146],
                [29.040372634505104, 41.10899694185298],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "second_floor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040094281715795, 41.108949104324374],
                [29.04008414373298, 41.109008205598506],
                [29.04009180675124, 41.109008951845446],
                [29.040101944734047, 41.10894985057208],
                [29.040102509612755, 41.10894655750412],
                [29.0400948465945, 41.108945811256376],
                [29.040094281715795, 41.108949104324374],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "second_floor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040035241059822, 41.10900344331416],
                [29.040045379042667, 41.10894434203521],
                [29.040053702140014, 41.10894515256345],
                [29.04005399275304, 41.10894345837934],
                [29.04004566965555, 41.10894264785114],
                [29.040045954196163, 41.108940989068074],
                [29.040043260729295, 41.108940726770165],
                [29.040032547592947, 41.10900318101652],
                [29.040035241059822, 41.10900344331416],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "second_floor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040062240801753, 41.10894598408397],
                [29.040062531414776, 41.1089442898999],
                [29.040070488400904, 41.10894506477513],
                [29.040070197787873, 41.10894675895917],
                [29.040062240801753, 41.10894598408397],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "second_floor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.04007873290708, 41.108947590134726],
                [29.040079023520114, 41.10894589595068],
                [29.040094572328826, 41.10894741014038],
                [29.040094281715803, 41.108949104324374],
                [29.04007873290708, 41.108947590134726],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "second_floor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040172381662178, 41.10896250319436],
                [29.040172145611923, 41.10896387929394],
                [29.040229745791457, 41.10896948857075],
                [29.04022985404042, 41.108969499112355],
                [29.040230090090652, 41.10896812301288],
                [29.040229981841712, 41.10896811247128],
                [29.040172381662178, 41.10896250319436],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "second_floor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.0400179330308, 41.10894734645268],
                [29.04001835636021, 41.1089448785752],
                [29.040042151433802, 41.10894719579152],
                [29.04004172810439, 41.10894966366891],
                [29.0400179330308, 41.10894734645268],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "second_floor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.04010157840719, 41.10895198614418],
                [29.04010021935338, 41.10895990900626],
                [29.040103346101887, 41.10896021349835],
                [29.040104249974178, 41.10895494420339],
                [29.040134845995773, 41.1089579237354],
                [29.040133942123475, 41.10896319303013],
                [29.04013839035433, 41.1089636262121],
                [29.040139749408137, 41.10895570335048],
                [29.04010157840719, 41.10895198614418],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "second_floor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040171633705594, 41.10896686354861],
                [29.04017299275941, 41.10895894068739],
                [29.040162963466276, 41.108957964004865],
                [29.04016160441246, 41.108965886866216],
                [29.04016434797866, 41.108966154042854],
                [29.040163066714104, 41.10897362341633],
                [29.04013658647722, 41.108971044692375],
                [29.04013213824636, 41.108970611510465],
                [29.040131664649522, 41.10897337243248],
                [29.04013611288038, 41.108973805614376],
                [29.040167385926534, 41.108976851076186],
                [29.040167859523375, 41.108974090154305],
                [29.040169140787935, 41.108966620780876],
                [29.040171633705594, 41.10896686354861],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "second_floor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040240730481038, 41.10896553718825],
                [29.040230701187905, 41.108964560505726],
                [29.04022934213409, 41.108972483367076],
                [29.04023521284766, 41.10897305507462],
                [29.040239371427223, 41.10897346004947],
                [29.040240730481038, 41.10896553718825],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "second_floor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040263908729358, 41.10897670804446],
                [29.04026811031125, 41.10897711720685],
                [29.04026911385578, 41.10897126685408],
                [29.040299453449848, 41.10897422141312],
                [29.040299746478613, 41.10897424994864],
                [29.04029888777662, 41.10897925591439],
                [29.04030891706975, 41.108980232596785],
                [29.040309428976066, 41.10897724834212],
                [29.040309494929726, 41.10897725476478],
                [29.040367311610726, 41.10898288512513],
                [29.040367542582445, 41.108982907617836],
                [29.040367778632678, 41.10898153151836],
                [29.04036754766098, 41.10898150902566],
                [29.04030973097998, 41.1089758786652],
                [29.040309665026317, 41.108975872242546],
                [29.040310276123567, 41.10897230973556],
                [29.040300246830434, 41.10897133305304],
                [29.04029995380165, 41.108971304517524],
                [29.04026541262569, 41.10896794079591],
                [29.040264912273887, 41.10897085769165],
                [29.040263908729358, 41.10897670804446],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "second_floor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040092674069417, 41.108933074910055],
                [29.040092946320435, 41.108931487770626],
                [29.04009684834302, 41.10893186776143],
                [29.04009657609178, 41.108933454900814],
                [29.040092674069417, 41.108933074910055],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "second_floor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.04031224966122, 41.10895430668171],
                [29.04031254936381, 41.10895255950808],
                [29.040306682434057, 41.10895198817069],
                [29.040307057721886, 41.10894980035833],
                [29.040304787598163, 41.10894957928694],
                [29.040304412310338, 41.10895176709929],
                [29.040282502918455, 41.108949633497495],
                [29.04028466288714, 41.1089370415412],
                [29.04030657227948, 41.10893917514356],
                [29.040306165613256, 41.108941545882615],
                [29.04030843591233, 41.10894176697112],
                [29.040308842578554, 41.10893939623208],
                [29.040309085405703, 41.10893798062444],
                [29.04030955591499, 41.10893523769995],
                [29.040307285615906, 41.10893501661141],
                [29.040306815106618, 41.10893775953591],
                [29.040284905714913, 41.10893562593371],
                [29.04028705963458, 41.10892306924248],
                [29.040307968418276, 41.10892510540288],
                [29.04030896902611, 41.10892520284509],
                [29.040308495723913, 41.10892796205184],
                [29.04031076584858, 41.10892818312342],
                [29.04031123915078, 41.10892542391667],
                [29.040311542983048, 41.10892365266761],
                [29.040308272250538, 41.10892333415381],
                [29.040287363466845, 41.108921297993355],
                [29.040283143090363, 41.10892088454495],
                [29.04027846685747, 41.108948263730284],
                [29.040211371707787, 41.10894172991458],
                [29.04021090725404, 41.108944437536394],
                [29.04027800163399, 41.10895097150845],
                [29.04028220321585, 41.1089513806712],
                [29.04030411260776, 41.108953514272955],
                [29.040306382731476, 41.10895373534433],
                [29.04031224966122, 41.10895430668171],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "second_floor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040378419023067, 41.10897894569371],
                [29.040368389729935, 41.108977969011185],
                [29.04036703067612, 41.108985891872535],
                [29.040372901389688, 41.10898646358008],
                [29.040377059969252, 41.10898686855493],
                [29.040378419023067, 41.10897894569371],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "second_floor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.04020276237151, 41.1089408915258],
                [29.04011036690312, 41.108931893883366],
                [29.040110669669517, 41.10893012884852],
                [29.04010515532319, 41.10892959184478],
                [29.040104852556794, 41.108931356879644],
                [29.040104388103053, 41.108934064501895],
                [29.04013785414628, 41.10893732352625],
                [29.04020229791776, 41.10894359914766],
                [29.04020276237151, 41.1089408915258],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "second_floor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040474424758354, 41.10900477856484],
                [29.04047395115361, 41.109007539485695],
                [29.04047466417663, 41.10900760892192],
                [29.040504567345554, 41.10901052097818],
                [29.040497822983365, 41.109049838473794],
                [29.040502045076632, 41.10905024060835],
                [29.04050926151304, 41.10900817106805],
                [29.040475137781375, 41.109004848001064],
                [29.040474424758354, 41.10900477856484],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "second_floor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.04008628900952, 41.1089068469051],
                [29.04009002405886, 41.10888507270528],
                [29.04008815870356, 41.1088849312201],
                [29.040084424119286, 41.10890670270879],
                [29.04008032066874, 41.108930956921235],
                [29.04008016629626, 41.108931856865205],
                [29.04008460884424, 41.108932289493985],
                [29.040084881095247, 41.10893070235455],
                [29.04008224105126, 41.108930445259055],
                [29.040084972481907, 41.10891452185694],
                [29.040099579776204, 41.10891594435999],
                [29.040099883510866, 41.10891417368161],
                [29.040085276216438, 41.10891275117868],
                [29.04008628900952, 41.1089068469051],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "second_floor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040652140561313, 41.10900802579946],
                [29.040651904511066, 41.109009401899044],
                [29.040709935115917, 41.10901505309154],
                [29.04071017116615, 41.109013676992056],
                [29.040652140561313, 41.10900802579946],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "second_floor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040651370210654, 41.10901251670423],
                [29.04065269856977, 41.109004772783564],
                [29.040642669276636, 41.10900379610104],
                [29.040641340914465, 41.10901154003965],
                [29.040651370210654, 41.10901251670423],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "second_floor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040710782263734, 41.10901011448298],
                [29.04070942320992, 41.10901803734433],
                [29.040713373103493, 41.10901842219165],
                [29.040704794941874, 41.109069551577825],
                [29.04071246091189, 41.109070281725245],
                [29.040722935482382, 41.10900921835444],
                [29.04072350036109, 41.109005925286475],
                [29.040715837342834, 41.10900517903873],
                [29.040715272464137, 41.10900847210673],
                [29.040714921591757, 41.1090105175831],
                [29.040710782263734, 41.10901011448298],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "second_floor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040515410794807, 41.10899479972497],
                [29.040515174744556, 41.10899617582455],
                [29.040573450545434, 41.10900185089536],
                [29.04057368659566, 41.109000474795884],
                [29.040515410794807, 41.10899479972497],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "second_floor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040515961946216, 41.108991586683516],
                [29.040505932653083, 41.108990610000994],
                [29.040504573599268, 41.108998532862344],
                [29.0405092734953, 41.10899899056976],
                [29.0405146028924, 41.10899950954474],
                [29.040515961946216, 41.108991586683516],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "second_floor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.04057425962138, 41.10899713423388],
                [29.040572902256283, 41.10900505725957],
                [29.040573115999006, 41.1090050780478],
                [29.040582929848874, 41.10900603374969],
                [29.040583833735138, 41.109000764470665],
                [29.040614234430958, 41.10900372495449],
                [29.04061333055866, 41.109008994249216],
                [29.040617778789514, 41.10900942743119],
                [29.040619137843322, 41.10900150456957],
                [29.040574474253518, 41.10899715510861],
                [29.04057425962138, 41.10899713423388],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "second_floor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.04072284915182, 41.10900972163489],
                [29.040723190355838, 41.109007732521555],
                [29.040739030172848, 41.10900927504728],
                [29.040738688968826, 41.10901126416057],
                [29.04072284915182, 41.10900972163489],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "second_floor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040737765839612, 41.109072449025234],
                [29.04073986968352, 41.10907262921389],
                [29.040750151427787, 41.10901238039708],
                [29.04076173199426, 41.1090135081351],
                [29.04076208939504, 41.10901152059915],
                [29.04074839123552, 41.109010186645754],
                [29.04073776911408, 41.109072427096194],
                [29.040737765839612, 41.109072449025234],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: null,
          poly_type: null,
          label: null,
          floor: "second_floor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.04005117701695, 41.108912193663016],
                [29.040053676967634, 41.10889761972021],
                [29.040072391921083, 41.1088994422401],
                [29.040072212869166, 41.10890048605807],
                [29.04007401537319, 41.10890066159146],
                [29.040074560136503, 41.10889748578783],
                [29.04007275763248, 41.108897310254434],
                [29.040072584190032, 41.108898321370894],
                [29.040053869236573, 41.108896498850974],
                [29.040056382377127, 41.108881848006895],
                [29.040053688215323, 41.10888158976002],
                [29.040045114339197, 41.108931572833505],
                [29.04004780780606, 41.10893183513147],
                [29.04004833579571, 41.10892875711455],
                [29.040055405800437, 41.10892944561306],
                [29.04005567805145, 41.10892785847354],
                [29.040048608046718, 41.10892716997502],
                [29.04005090382202, 41.10891378630585],
                [29.040069618775945, 41.10891560882591],
                [29.04006732300016, 41.10892899249399],
                [29.040064084199475, 41.10892867708976],
                [29.040063811948468, 41.108930264229244],
                [29.04006885325315, 41.108930755166774],
                [29.04006896391884, 41.10893011001989],
                [29.040073173462442, 41.10890556967335],
                [29.04007137095842, 41.10890539413997],
                [29.040069891971104, 41.10891401618199],
                [29.04005117701695, 41.108912193663016],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "2",
          poly_type: null,
          label: null,
          floor: "second_floor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.0406117583758, 41.10901779025213],
                [29.04061128477895, 41.10902055117315],
                [29.040612412851594, 41.10902066102621],
                [29.040612412851598, 41.109020661026214],
                [29.040642475400613, 41.1090235885531],
                [29.040635865486113, 41.109062986372535],
                [29.04063905387795, 41.10906329005183],
                [29.040646126044788, 41.10902113701689],
                [29.040612886448447, 41.109017900105194],
                [29.0406117583758, 41.10901779025213],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "2",
          poly_type: null,
          label: null,
          floor: "second_floor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.04007361175399, 41.10889233098654],
                [29.040075414258013, 41.108892506519936],
                [29.0400769051282, 41.10888381519996],
                [29.04007510215912, 41.108883642377705],
                [29.04007361175399, 41.10889233098654],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "2",
          poly_type: null,
          label: null,
          floor: "second_floor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040104072224803, 41.1088897547809],
                [29.040096223104914, 41.108936294451226],
                [29.040103888100184, 41.10893702917305],
                [29.040111735243055, 41.108890501028604],
                [29.040112300121763, 41.10888720796064],
                [29.040104637103507, 41.1088864617129],
                [29.040104072224803, 41.1088897547809],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "2",
          poly_type: null,
          label: null,
          floor: "second_floor",
          unit_type: null,
          unti_subty: null,
          wall_type: "border",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.04052109330067, 41.108900476431714],
                [29.040517555589272, 41.10892110022267],
                [29.04045573678911, 41.108915174677],
                [29.040457350179935, 41.10890561917497],
                [29.040457547055635, 41.10890445315482],
                [29.040457789992992, 41.10890301432881],
                [29.040344430470554, 41.10889214842412],
                [29.040342377266587, 41.108904308774726],
                [29.040253105237056, 41.108895751740256],
                [29.040255076997465, 41.10888407374771],
                [29.040255601768454, 41.10888096572685],
                [29.040181984641325, 41.108873909266755],
                [29.040177680898744, 41.10887349673791],
                [29.040175053256107, 41.1088890592755],
                [29.04010725451993, 41.108882560264625],
                [29.04002442355665, 41.10887449392795],
                [29.040015328136754, 41.10892751745074],
                [29.04001465734931, 41.10893145827035],
                [29.040002775777815, 41.10900072412722],
                [29.040002124898557, 41.109004518548375],
                [29.04078220272217, 41.10908048474987],
                [29.040782359235358, 41.10908049999127],
                [29.040787472983137, 41.1090809979826],
                [29.04079324513534, 41.109081560070685],
                [29.040793970649883, 41.109077330546185],
                [29.040793970649876, 41.109077330546185],
                [29.04081550330002, 41.10895176970899],
                [29.040815508708835, 41.10895177022744],
                [29.040815859326496, 41.10894969365322],
                [29.040664241306377, 41.10893516054831],
                [29.040667741205148, 41.108914757197],
                [29.040668408634605, 41.10891086628434],
                [29.040521760730126, 41.10889658551822],
                [29.04052109330067, 41.108900476431714],
              ],
              [
                [29.040356821452093, 41.10890569330003],
                [29.040356470835125, 41.10890776987153],
                [29.04035741659444, 41.10890786052584],
                [29.040517199474117, 41.10892317626666],
                [29.040523863765543, 41.108923825243885],
                [29.04052775759188, 41.10890112540919],
                [29.04052775759186, 41.10890112540913],
                [29.04066063708549, 41.10891406538852],
                [29.040656906021436, 41.108936566948096],
                [29.04066079148041, 41.10893694005329],
                [29.040661358765796, 41.108936994429534],
                [29.04081215152096, 41.10895144842953],
                [29.04079061432768, 41.10907700370428],
                [29.040792823849955, 41.1090772188697],
                [29.04078819850711, 41.109076768449334],
                [29.04071246091189, 41.109070281725245],
                [29.040704794941874, 41.109069551577825],
                [29.040702816326434, 41.10906936312405],
                [29.040091432823196, 41.10901113170828],
                [29.04009180675124, 41.109008951845446],
                [29.040008752881093, 41.10900086381824],
                [29.04002055982418, 41.10893203307114],
                [29.040021230611615, 41.10892809225166],
                [29.040029603454446, 41.10887928113306],
                [29.04011230012176, 41.10888720796064],
                [29.040112418574406, 41.108886506410265],
                [29.040112688758345, 41.10888490621266],
                [29.040112752191334, 41.10888453052297],
                [29.04017481349074, 41.10889047931544],
                [29.04017911723332, 41.1088908918442],
                [29.04017935699868, 41.108889471804275],
                [29.040181459870286, 41.10887701728794],
                [29.040181459870336, 41.10887701728795],
                [29.04025013719128, 41.10888360024997],
                [29.040250137191283, 41.10888360024999],
                [29.04024781481392, 41.108897354814474],
                [29.040252754620106, 41.1088978283121],
                [29.040342026649643, 41.108906385346266],
                [29.04034412846142, 41.108906586812225],
                [29.04034447907836, 41.1089045102407],
                [29.040346289344953, 41.10889378871632],
                [29.04045565285973, 41.10890427158956],
                [29.040453842593212, 41.10891499311178],
                [29.040357767211407, 41.108905783954356],
                [29.040356821452093, 41.10890569330003],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "2",
          poly_type: null,
          label: null,
          floor: "second_floor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040286548792356, 41.108901057474775],
                [29.040289189620413, 41.10890131060732],
                [29.040288209785807, 41.108907113796654],
                [29.04028556895775, 41.10890686066413],
                [29.040286548792356, 41.108901057474775],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "2",
          poly_type: null,
          label: null,
          floor: "second_floor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.04048356777802, 41.10896925291888],
                [29.040349393748055, 41.10895630478686],
                [29.04034918437175, 41.10895753650111],
                [29.04048336346201, 41.108970485121226],
                [29.04048356777802, 41.10896925291888],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "2",
          poly_type: null,
          label: null,
          floor: "second_floor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040021976607296, 41.10892374332591],
                [29.040022338865032, 41.10892163147449],
                [29.040046422209233, 41.108923976761965],
                [29.0400460599515, 41.108926088613316],
                [29.040021976607296, 41.10892374332591],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "2",
          poly_type: null,
          label: null,
          floor: "second_floor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.04072649519338, 41.10894323797869],
                [29.040718453915492, 41.108990103903636],
                [29.040718027762992, 41.10899258819577],
                [29.040717530639707, 41.10899548627232],
                [29.040725193657963, 41.10899623252017],
                [29.040734161334356, 41.10894396615771],
                [29.04072649519338, 41.10894323797869],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          id: "2",
          poly_type: null,
          label: null,
          floor: "second_floor",
          unit_type: null,
          unti_subty: null,
          wall_type: "ic_duvar",
          layer: "wall_orn",
          path: "C:/Users/Sevda/OneDrive - Arıkovanı Yazılım Araş. Gel. Müh. San. Ve Dış Tic. A.Ş/Arı 6/wall_orn.geojson",
          "fill-extrusion-height": 3,
          "fill-extrusion-color": "#88aeaf",
          type: "fill-extrusion",
          source: "composite",
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [29.040575349846087, 41.109005295613265],
                [29.040579635944326, 41.109005713006255],
                [29.040570873193637, 41.10905679616287],
                [29.040566585549033, 41.1090563877849],
                [29.040575349846087, 41.109005295613265],
              ],
            ],
          ],
        },
      },
    ],
  },
};
