import {
  call,
  takeEvery,
  put,
  all,
  fork,
  takeLatest,
} from "redux-saga/effects";
import * as actionTypes from "../actionTypes";

//**  services
import * as services from "../../services/customerServices";

//**  actions
import * as actions from "./actions";

import { toast } from "react-toastify";
import { openBrowserNewWindow } from "../../utility/openNewWindow";
import { loginAsCustoemerPanelUrl } from "../../assets/data/urlConfig";

//** Get user list
function* getCustomerList() {
  try {
    const response = yield call(services.getCustomerListAsync);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.getCustomersListSuccess(data));
    } else {
      toast.error(data.message);
      yield put(actions.getCustomersListFailure());
    }
  } catch (error) {
    const { data } = error.response;
    yield put(actions.getCustomersListFailure());
    toast.error(data.message);
    console.log(error);
  }
}

//** delete user  */
function* deleteCustomer({ payload }) {
  let { customerId } = payload;
  try {
    const response = yield call(services.deleteCustomerAsync, customerId);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.deleteCustomerSuccess(customerId));
      toast.success("Your customer deleted successfully");
    } else {
      yield put(actions.deleteCustomerFailure());
      toast.error(data.message);
    }
  } catch (error) {
    yield put(actions.deleteCustomerFailure());
    toast.error(error.message);
    console.log(error);
  }
}

//** Get deleted user list
function* getDeletedCustomerList() {
  try {
    const response = yield call(services.getDeletedCustomerListAsync);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.getDeletedCustomerListSuccess(data));
    } else {
      toast.error(data.message);
      yield put(actions.getDeletedCustomerListFailure([]));
    }
  } catch (error) {
    yield put(actions.getDeletedCustomerListFailure([]));
    toast.error(error.message);
    console.log(error);
  }
}

// ** restore customer
function* putRestoreCustomer({ payload }) {
  let { customerId } = payload;
  try {
    const response = yield call(services.putRestoreCustomerAsync, customerId);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.putRestoreCustomerSuccess(customerId));
      yield put(actions.openDeletedCustomerListModal(false));
      yield put(actions.getCustomersList());
      // history.push(appRoutes.CUSTOMERS);
      toast.success("Your customer restored successfully");
    } else {
      yield put(actions.putRestoreCustomerFailure());
      toast.error(data.message);
    }
  } catch (error) {
    yield put(actions.putRestoreCustomerFailure());
    toast.error(error.message);
    console.log(error);
  }
}

//** post create new customer
function* postNewCustomer({ payload }) {
  let { customerInfo } = payload;
  try {
    const response = yield call(services.postNewCustomerAsync, customerInfo);
    const { data } = response;

    console.log(data);
    if (response.status === 200) {
      yield put(actions.postNewCustomerSuccess(data));
      toast.success("Your customer created successfully");
    } else {
      yield put(actions.postNewCustomerFailure());
      toast.error(data.message);
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.postNewCustomerFailure());
    toast.error(data.message);
  }
}

//** put update  customer
function* putUpdateCustomer({ payload }) {
  let { customerInfo } = payload;
  try {
    const response = yield call(services.putUpdateCustomerAsync, customerInfo);
    const { data } = response;

    console.log(data);
    if (response.status === 200) {
      yield put(actions.putUpdateCustomerSuccess(data));
      toast.success("Your customer was updated successfully");
    } else {
      yield put(actions.putUpdateCustomerFailure());
      toast.error(data.message);
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.putUpdateCustomerFailure());
    toast.error(data.message);
  }
}

//** post create new customer super admin
function* postCreateNewCustomerSuperAdmin({ payload }) {
  let { customerInfo } = payload;
  try {
    const response = yield call(
      services.postCreateNewCustomerSuperAdmin,
      customerInfo
    );
    const { data } = response;

    console.log(data);
    if (response.status === 200) {
      yield put(actions.postCreateNewCustomerSuperAdminSuccess(data));
      toast.success("Your super admin created successfully");
    } else {
      yield put(actions.postCreateNewCustomerSuperAdminFailure());
      toast.error(data.message);
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.postCreateNewCustomerSuperAdminFailure());
    toast.error(data.message);
  }
}

//** put customer super admin
function* putCustomerSuperAdmin({ payload }) {
  let { params } = payload;
  try {
    const response = yield call(services.putCustomerSuperAdminAsync, params);
    const { data } = response;

    console.log(data);
    if (response.status === 200) {
      yield put(actions.putCustomerSuperAdminSuccess(data));
      toast.success("Your super admin was edited successfully");
    } else {
      yield put(actions.putCustomerSuperAdminFailure());
      toast.error(data.message);
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.putCustomerSuperAdminFailure());
    toast.error(data.message);
  }
}

//** get Customer id
function* getCustomerById({ payload }) {
  let { customerInfo } = payload;
  try {
    const response = yield call(services.getCustomerByIdAsync, customerInfo);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.getCustomerByIdSuccess(data));
    } else {
      yield put(actions.getCustomerByIdFailure());
      toast.error(data.message);
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.getCustomerByIdFailure());
    toast.error(data.message);
  }
}

//** get Customer id
function* postResendCustomerUserInvitation({ payload }) {
  let { invitationInfo } = payload;
  try {
    const response = yield call(
      services.postResendCustomerUserInvitationAsync,
      invitationInfo
    );
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.postResendCustomerUserInvitationSuccess(data));
      toast.success("Your invitation was sent successfully");
    } else {
      yield put(actions.postResendCustomerUserInvitationFailure());
      toast.error(data.message);
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.postResendCustomerUserInvitationFailure());
    toast.error(data.message);
  }
}

// ** post new customer language
function* postNewCustomerLanguage({ payload }) {
  let { languageInfo } = payload;
  try {
    const response = yield call(
      services.postNewCustomerLanguageAsync,
      languageInfo
    );
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.postNewCustomerLanguageSuccess(data));
      toast.success("Your  language was created successfully");
    } else {
      yield put(actions.postNewCustomerLanguageFailure());
      toast.error(data.message);
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.postNewCustomerLanguageFailure());
    toast.error(data.message);
  }
}

// ** delete  customer language
function* deleteCustomerLanguage({ payload }) {
  let { languageInfo } = payload;
  try {
    const response = yield call(
      services.deleteCustomerLanguageAsync,
      languageInfo
    );
    const { data } = response;

    if (response.status === 200) {
      console.log(languageInfo);
      yield put(actions.deleteCustomerLanguageSuccess(languageInfo));
      toast.success("Your  language was deleted successfully");
    } else {
      yield put(actions.deleteCustomerLanguageFailure());
      toast.error(data.message);
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.deleteCustomerLanguageFailure());
    toast.error(data.message);
  }
}

//** Get customern navigation info
function* getCustomerNavigationConfig({ payload }) {
  const customerInfo = payload.customerInfo;

  try {
    const response = yield call(
      services.getCustomerNavigationConfigAsync,
      customerInfo
    );

    const { data } = response;

    if (response.status === 200) {
      yield put(actions.getCustomerNavigationConfigSuccess(data));
    } else {
      toast.error(data.message);
      yield put(actions.getCustomerNavigationConfigFailure());
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.getCustomerNavigationConfigFailure());
    toast.error(data.message);
    console.log(error);
  }
}

//** Get customern navigation info
function* putCustomerNavigationConfig({ payload }) {
  const navigationInfo = payload.navigationInfo;

  try {
    const response = yield call(
      services.putCustomerNavigationConfigAsync,
      navigationInfo
    );

    const { data } = response;
    console.log(data);

    if (response.status === 200) {
      yield put(actions.putCustomerNavigationConfigSuccess(navigationInfo));
      toast.success("Your customer navigation config updated successfully");
    } else {
      toast.error(data.message);
      yield put(actions.putCustomerNavigationConfigFailure());
    }
  } catch (error) {
    console.log(error);
    const { data } = error.response;

    yield put(actions.putCustomerNavigationConfigFailure());
    toast.error(data);
  }
}

function* putCustomerConfig({ payload }) {
  const payloadData = payload.data;

  try {
    const response = yield call(services.putCustomerConfigAsync, payloadData);

    const { data } = response;

    if (response.status === 200) {
      yield put(actions.putCustomerConfigSuccess(data));
      toast.success("Your customer config updated successfully");
    } else {
      toast.error(data.message);
      yield put(actions.putCustomerConfigFailure());
    }
  } catch (error) {
    console.log(error);
    const { data } = error.response;

    yield put(actions.putCustomerConfigFailure());
    toast.error(data);
  }
}

function* getCustomerConfig({ payload }) {
  const payloadData = payload.data;

  try {
    const response = yield call(services.getCustomerConfigAsync, payloadData);

    const { data } = response;

    if (response.status === 200) {
      yield put(actions.getCustomerConfigSuccess(data));
    } else {
      toast.error(data.message);
      yield put(actions.getCustomerConfigFailure());
    }
  } catch (error) {
    console.log(error);
    const { data } = error.response;

    yield put(actions.getCustomerConfigFailure());
    toast.error(data);
  }
}

function* getLoginAsCustomer({ payload }) {
  try {
    const response = yield call(services.getLoginAsCustomerAsync, payload);

    const { data } = response;

    if (response.status === 200) {
      const { token } = data;
      yield put(actions.getLoginAsCustomerSuccess(data));
      toast.success("The url has been  open in new tab. Please check it.");
      openBrowserNewWindow(loginAsCustoemerPanelUrl(token));
    } else {
      toast.error(data.message);
      yield put(actions.getLoginAsCustomerFailure());
    }
  } catch (error) {
    yield put(actions.getLoginAsCustomerFailure());
  }
}

// *************************** watchers ******************************
export function* watchGetCustomersList() {
  yield takeEvery(actionTypes.GET_CUSTOMERS_LIST, getCustomerList);
}
export function* watchGetCustomerConfig() {
  yield takeEvery(actionTypes.GET_CUSTOMER_CONFIG, getCustomerConfig);
}
export function* watchPutCustomerConfig() {
  yield takeEvery(actionTypes.PUT_CUSTOMER_CONFIG, putCustomerConfig);
}

export function* watchDeleteCustomer() {
  yield takeEvery(actionTypes.DELETE_CUSTOMER, deleteCustomer);
}

export function* watchPutRestoreCustomer() {
  yield takeEvery(actionTypes.PUT_RESTORE_CUSTOMER, putRestoreCustomer);
}
export function* watchGetDeletedCustomerList() {
  yield takeEvery(
    actionTypes.GET_DELETED_CUSTOMER_LIST,
    getDeletedCustomerList
  );
}
export function* watchPostNewCustomer() {
  yield takeEvery(actionTypes.POST_NEW_CUSTOMER, postNewCustomer);
}
export function* watchPutUpdateCustomer() {
  yield takeEvery(actionTypes.PUT_UPDATE_CUSTOMER, putUpdateCustomer);
}
export function* watchPostCreateNewCustomerSuperAdmin() {
  yield takeEvery(
    actionTypes.POST_CREATE_NEW_CUSTOMER_SUPER_ADMIN,
    postCreateNewCustomerSuperAdmin
  );
}
export function* watchGetCustomerById() {
  yield takeEvery(actionTypes.GET_CUSTOMER_BY_ID, getCustomerById);
}
export function* watchPostNewCustomerLanguage() {
  yield takeEvery(
    actionTypes.POST_NEW_CUSTOMER_LANGUAGE,
    postNewCustomerLanguage
  );
}
export function* watchPostResendCustomerUserInvitation() {
  yield takeEvery(
    actionTypes.POST_RESEND_CUSTOMER_USER_INVITATION,
    postResendCustomerUserInvitation
  );
}
export function* watchDeleteCustomerLanguage() {
  yield takeLatest(
    actionTypes.DELETE_CUSTOMER_LANGUAGE,
    deleteCustomerLanguage
  );
}
export function* watchPutCustomerSuperAdmin() {
  yield takeLatest(actionTypes.PUT_CUSTOMER_SUPER_ADMIN, putCustomerSuperAdmin);
}
export function* watchGetCustomerNavigationConfig() {
  yield takeLatest(
    actionTypes.GET_CUSTOMER_NAVIGATION_CONFIG,
    getCustomerNavigationConfig
  );
}
export function* watchPutCustomerNavigationConfig() {
  yield takeLatest(
    actionTypes.PUT_CUSTOMER_NAVIGATION_CONFIG,
    putCustomerNavigationConfig
  );
}

export function* watchGetLoginAsCustomer() {
  yield takeLatest(actionTypes.GET_LOGIN_AS_CUSTOMER, getLoginAsCustomer);
}

//Fork
export default function* rootSaga() {
  yield all([
    fork(watchGetCustomersList),
    fork(watchDeleteCustomer),
    fork(watchGetDeletedCustomerList),
    fork(watchPutRestoreCustomer),
    fork(watchPostNewCustomer),
    fork(watchPutUpdateCustomer),
    fork(watchPostCreateNewCustomerSuperAdmin),
    fork(watchGetCustomerById),
    fork(watchPostResendCustomerUserInvitation),
    fork(watchPostNewCustomerLanguage),
    fork(watchDeleteCustomerLanguage),
    fork(watchPutCustomerSuperAdmin),
    fork(watchGetCustomerNavigationConfig),
    fork(watchPutCustomerNavigationConfig),
    fork(watchGetCustomerConfig),
    fork(watchPutCustomerConfig),
    fork(watchGetLoginAsCustomer),
  ]);
}
