export const authRoutes = {
  LOGIN_BY_USER_NAME: "/auth/username",
  REGISTER: "/auth/register",
  RESET_PASSWORD: "/auth/reset-password",
  CONFIRM_PASSWORD: "/auth/confirm-password/:token",
};

export const appRoutes = {
  DASHBOARD: "/",
  NOT_FOUND: "/not-found",

  // ** customers routes
  CUSTOMERS: "/customers",
  CUSTOMER_DETAIL: "/customers/:id",
  DELETED_CUSTOMER_LIST: "/deleted-customer-list",

  // ** users routes
  USER_LIST: "/user-list",
  DELETED_USER_LIST: "/deleted-user-list",

  // ** place types
  PLACE_TYPES: "/place-types",

  // ** menu item routes
  MENU_ITEM_HOME: "/menu-item/:id",
  MENU_ITEM_DETAIL: "/menu-item/:id/:menuitemId",

  // ** settings
  SETTINGS: "/settings",

  // ** places list
  PLACES_LIST: "/places-list",
  PLACE_MAP_STUDIO: "/place/:id",

  // ** brands
  BRANDS_LIST: "/brands-list",

  // ** categories list
  CATEGORIES_LIST: "/categories-list",
  SUBCATEGORIES_LIST: "/subcategories-list/:categoryId",

  // ** network types
  NETWORKTYPES_LIST: "/networktypes-list",

  // ** point types
  POINTTYPE_LIST: "/pointtypes-list",

  //** store ids */
  STORE_IDS: "/store-ids/:id",

  // ** beacons
  BEACONS: "/beacons/:id",
};
