import * as actionTypes from "../actionTypes";

// ** get user list actions
export const openDeletedUserListModal = (openModal) => ({
  type: actionTypes.DELETED_USER_LIST_MODAL,
  payload: { openModal },
});

export const getUserList = () => ({
  type: actionTypes.GET_USER_LIST,
});
export const getUserListSuccess = (userList) => ({
  type: actionTypes.GET_USER_LIST_SUCCESS,
  payload: { userList },
});
export const getUserListFailure = () => ({
  type: actionTypes.GET_USER_LIST_FAILURE,
});

// ** get deleted user list actions
export const getDeletedUserList = () => ({
  type: actionTypes.GET_DELETED_USER_LIST,
});
export const getDeletedUserListSuccess = (userList) => ({
  type: actionTypes.GET_DELETED_USER_LIST_SUCCESS,
  payload: { userList },
});
export const getDeletedUserListFailure = (userList) => ({
  type: actionTypes.GET_DELETED_USER_LIST_FAILURE,
  payload: { userList },
});

//** post new user actions */
export const postNewUser = (email) => ({
  type: actionTypes.POST_NEW_USER,
  payload: { email },
});
export const postNewUserSuccess = (createdUserData) => ({
  type: actionTypes.POST_NEW_USER_SUCCESS,
  payload: { createdUserData },
});
export const postNewUserFailure = () => ({
  type: actionTypes.POST_NEW_USER_FAILURE,
});

// ** delete user actions
export const deleteUser = (userId) => ({
  type: actionTypes.DELETE_USER,
  payload: { userId },
});
export const deleteUserSuccess = (userId) => ({
  type: actionTypes.DELETE_USER_SUCCESS,
  payload: { userId },
});
export const deleteUserFailure = () => ({
  type: actionTypes.DELETE_USER_FAILURE,
});

// ** restore user actions
export const restoreUser = (userId) => ({
  type: actionTypes.RESTORE_USER,
  payload: { userId },
});

export const restoreUserSuccess = (userId) => ({
  type: actionTypes.RESTORE_USER_SUCCESS,
  payload: { userId },
});

export const restoreUserFailure = () => ({
  type: actionTypes.RESTORE_USER_FAILURE,
});

// **** user resend invitation
export const putUserResendInvitation = (userId) => ({
  type: actionTypes.PUT_USER_RESEND_INVITATION,
  payload: { userId },
});

export const putUserResendInvitationSuccess = (userId) => ({
  type: actionTypes.PUT_USER_RESEND_INVITATION_SUCCESS,
  payload: { userId },
});

export const putUserResendInvitationFailure = () => ({
  type: actionTypes.PUT_USER_RESEND_INVITATION_FAILURE,
});
