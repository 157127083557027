import * as services from "../assets/data/urlConfig";

import axiosApiInstance from "./axiosInterceptor";

export const getStoreIdsListAsync = async (data) => {
  const { placeId } = data;

  return await axiosApiInstance.get(services.getStoreIdsListUrl(), {
    params: {
      placeId,
    },
  });
};

export const deleteStoreIdAsync = async (data) => {
  return await axiosApiInstance.delete(services.deleteStoreIdUrl(data));
};

export const postStoreIdAsync = async (data) => {
  return await axiosApiInstance.post(services.postStoreIdUrl(), data);
};

export const putStoreIdAsync = async (data) => {
  const { placeId, nodeId, qmsAppId, storeId } = data;
  return await axiosApiInstance.put(services.putStoreIdUrl(data), {
    placeId,
    nodeId,
    qmsAppId,
    storeId,
  });
};
