import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";

// toast
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

// Layout
import { Layout } from "./utility/context/Layouts";

// **Spinner
import GlobalSpinner from "./components/loader/GlobalSpinner";

//redux
import { Provider } from "react-redux";
import { store } from "./redux/store";

import reportWebVitals from "./reportWebVitals";

const LazyApp = lazy(() => import("./App"));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <Suspense
      fallback={() => {
        return <GlobalSpinner />;
      }}
    >
      <Layout>
        <ToastContainer />
        <LazyApp />
      </Layout>
    </Suspense>
  </Provider>
);

reportWebVitals();
