import * as servicesUrl from "../assets/data/urlConfig";
import axiosApiInctance from "../services/axiosInterceptor";

// ** get user list async
export const getNetworktypeListAsync = async () => {
  return await axiosApiInctance.get(`${servicesUrl.getNetworktypeListUrl}`);
};

// ** get user list async
export const getDeletedNetworktypeListAsync = async () => {
  return await axiosApiInctance.get(
    `${servicesUrl.getDeletedNetworktypeListUrl}`
  );
};

// ** get networktype list async
export const putRestoreNetworktypeUrlAsync = async (networkInfo) => {
  return await axiosApiInctance.put(
    `${servicesUrl.putRestoreNetworktypeUrl(networkInfo)}`
  );
};

// ** post New Networktype Async
export const postNewNetworktypeAsync = async (networkInfo) => {
  const { name } = networkInfo;

  return await axiosApiInctance.post(`${servicesUrl.postNewNetworktypeUrl}`, {
    name: name,
  });
};

// ** delete networktype
export const deleteNetworktypeAsync = async (networkInfo) => {
  return await axiosApiInctance.delete(
    `${servicesUrl.deleteNetworktypeUrl(networkInfo)}`
  );
};

// ** put networktype
export const putNetworktypeAsync = async (networkInfo) => {
  const { name } = networkInfo;
  return await axiosApiInctance.put(
    `${servicesUrl.putNetworkypeUrl(networkInfo)}`,
    {
      name: name,
    }
  );
};
