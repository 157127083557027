import * as actionTypes from "../actionTypes";

// ** get language list action
export const getSegmentList = (placeInfo) => ({
  type: actionTypes.GET_SEGMENT_LIST,
  payload: { placeInfo },
});
export const getSegmentListSuccess = (segmentList) => ({
  type: actionTypes.GET_SEGMENT_LIST_SUCCESS,
  payload: { segmentList },
});
export const getSegmentListFailure = () => ({
  type: actionTypes.GET_SEGMENT_LIST_FAILURE,
});
