import { call, takeEvery, put, all, fork } from "redux-saga/effects";
import * as actionTypes from "../actionTypes";

//**  services
import * as services from "../../services/placesServices";

//**  actions
import * as actions from "./actions";

import { toast } from "react-toastify";

//** Get places list
function* getPlacesList() {
  try {
    const response = yield call(services.getPlacesListAsync);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.getPlacesListSuccess(data));
    } else {
      toast.error(data.message);
      yield put(actions.getPlacesListFailure());
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.getPlacesListFailure());
    toast.error(data.message);
    console.log(error);
  }
}

//** get Places GeoJson
function* getPlacesGeoJson({ payload }) {
  let { placeInfo } = payload;
  try {
    const response = yield call(services.getPlacesGeoJsonAsync, placeInfo);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.getPlaceGeoJsonSuccess(data));
    } else {
      toast.error(data.message);
      yield put(actions.getPlaceGeoJsonFailure());
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.getPlaceGeoJsonFailure());
    toast.error(data.message);
    console.log(error);
  }
}

//** get Places GeoJson
function* getPoiGeoJson({ payload }) {
  let { placeInfo } = payload;
  try {
    const response = yield call(services.getPoiGeoJsonAsync, placeInfo);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.getPoiGeoJsonSuccess(data));
    } else {
      toast.error(data.message);
      yield put(actions.getPoiGeoJsonFailure());
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.getPoiGeoJsonFailure());
    toast.error(data.message);
    console.log(error);
  }
}

//** get Place Center Coordination
function* getPlaceCenterCoordination({ payload }) {
  let { placeInfo } = payload;
  try {
    const response = yield call(
      services.getPlaceCenterCoordinationAsync,
      placeInfo
    );
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.getPlaceCenterCoordinationSuccess(data));
      yield put(actions.getPlaceGeoJson(placeInfo));
    } else {
      toast.error(data.message);
      yield put(actions.getPlaceCenterCoordinationFailure());
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.getPlaceCenterCoordinationFailure());
    toast.error(data.message);
    console.log(error);
  }
}

//** Get place settings
function* getPlaceSettings({ payload }) {
  const { settingInfo } = payload;

  try {
    const response = yield call(services.getPlaceSettingsAsync, settingInfo);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.getPlaceSettingsSuccess(data.data));
    } else {
      toast.error(data.message);
      yield put(actions.getPlaceSettingsFailure());
    }
  } catch (error) {
    yield put(actions.getPlaceSettingsFailure());
    toast.error(error.message);
    console.log(error);
  }
}

//** Get place settings
function* putPlaceSettings({ payload }) {
  const { settingInfo } = payload;

  try {
    const response = yield call(services.putPlaceSettingsAsync, settingInfo);
    console.log(response);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.putPlaceSettingsSuccess(data));
      toast.success("Your place settings updated successfully");
    } else {
      toast.error(data.message);
      yield put(actions.putPlaceSettingsFailure());
    }
  } catch (error) {
    yield put(actions.putPlaceSettingsFailure());
    toast.error(error.message);
    console.log(error);
  }
}

//** Get places list
function* getPlaceLiveStatus({ payload }) {
  const { placeInfo } = payload;

  try {
    const response = yield call(services.getPlaceLiveStatusAsync, placeInfo);

    const { data } = response;

    if (response.status === 200) {
      yield put(actions.getPlaceLiveStatusSuccess(data));
    } else {
      toast.error(data.message);
      yield put(actions.getPlaceLiveStatusFailure());
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.getPlaceLiveStatusFailure());
    toast.error(data.message);
    console.log(error);
  }
}

function* postPlacePublishGis({ payload }) {
  const { placeInfo } = payload;
  try {
    const response = yield call(services.postPlacePublishGisAsync, placeInfo);
    const { data } = response;

    console.log(response);

    if (response.status === 200) {
      yield put(actions.postPlacePublishGisSuccess(data));
      toast.success("You  published successfully");
    } else {
      toast.error(data.message);
      yield put(actions.postPlacePublishGisFailure());
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.postPlacePublishGisFailure());
    toast.error(data.message);
    console.log(error);
  }
}

// ** post poi content
function* postPoiContent({ payload }) {
  const { poiInfo } = payload;

  try {
    const response = yield call(services.postPoiContentAsync, poiInfo);
    const { data } = response;

    console.log(response.status);
    if (response.status === 201) {
      yield put(actions.postPoiContentSuccess(data));

      toast.success("You created poi content successfully");
    } else {
      toast.error(data.message);
      yield put(actions.postPoiContentFailure());
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.postPoiContentFailure());
    toast.error(data.message);
    console.log(error);
  }
}

// ** delete poi content
function* deletePoiContent({ payload }) {
  const { poiInfo } = payload;
  try {
    const response = yield call(services.deletePoiContentAsync, poiInfo);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.deletePoiContentSuccess(data));
      toast.success("You deleted poi content successfully");
    } else {
      toast.error(data.message);
      yield put(actions.deletePoiContentFailure());
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.deletePoiContentFailure());
    toast.error(data.message);
    console.log(error);
  }
}

// *************************** watchers ******************************
export function* watchGetPlacesList() {
  yield takeEvery(actionTypes.GET_PLACES, getPlacesList);
}
export function* watchGetPlaceSettings() {
  yield takeEvery(actionTypes.GET_PLACES_SETTINGS, getPlaceSettings);
}
export function* watchGetPlacesGeoJson() {
  yield takeEvery(actionTypes.GET_PLACE_GEO_JSON, getPlacesGeoJson);
}
export function* watchGetPoiGeoJson() {
  yield takeEvery(actionTypes.GET_POI_GEO_JSON, getPoiGeoJson);
}
export function* watchGetPlaceCenterCoordination() {
  yield takeEvery(
    actionTypes.GET_PLACE_CENTER_COORDINATION,
    getPlaceCenterCoordination
  );
}
export function* watchPutPlaceSettings() {
  yield takeEvery(actionTypes.PUT_PLACES_SETTINGS, putPlaceSettings);
}
export function* watchGetPlaceLiveStatus() {
  yield takeEvery(actionTypes.GET_PLACE_LIVE_STATUS, getPlaceLiveStatus);
}
export function* watchPostPlacePublishGis() {
  yield takeEvery(actionTypes.POST_PLACE_PUBLISH_GIS, postPlacePublishGis);
}
export function* watchPostPoiContent() {
  yield takeEvery(actionTypes.POST_POI_CONTENT, postPoiContent);
}
export function* watchDeletePoiContent() {
  yield takeEvery(actionTypes.DELETE_POI_CONTENT, deletePoiContent);
}

//Fork
export default function* rootSaga() {
  yield all([
    fork(watchGetPlacesList),
    fork(watchGetPlacesGeoJson),
    fork(watchGetPlaceCenterCoordination),
    fork(watchGetPlaceSettings),
    fork(watchPutPlaceSettings),
    fork(watchGetPoiGeoJson),
    fork(watchGetPlaceLiveStatus),
    fork(watchPostPlacePublishGis),
    fork(watchPostPoiContent),
    fork(watchDeletePoiContent),
  ]);
}
