import * as servicesUrl from "../assets/data/urlConfig";
import axiosApiInctance from "../services/axiosInterceptor";

// ** get user list async
export const getUserListAsync = async () => {
  return await axiosApiInctance.get(`${servicesUrl.getUserListUrl}`);
};

// ** post create new user
export const postCreateNewUserAsync = async (email) => {
  return await axiosApiInctance.post(`${servicesUrl.postCreateNewUserUrl}`, {
    email,
  });
};

// ** delete user
export const deleteUserAsync = async (userId) => {
  return await axiosApiInctance.delete(
    `${servicesUrl.deleteUserUrl(userId)}`,
    null
  );
};

// ** restore user
export const restoreUserAsync = async (userId) => {
  return await axiosApiInctance.put(`${servicesUrl.restoreUserUrl(userId)}`);
};

// ** putUserResendInvitation
export const putUserResendInvitationAsync = async (userId) => {
  return await axiosApiInctance.put(
    `${servicesUrl.putUserResendInvitationUrl(userId)}`
  );
};

// ** get deleted user list
export const getDeletedUserListAsync = async () => {
  return await axiosApiInctance.get(`${servicesUrl.getDeletedUserListUrl}`);
};
