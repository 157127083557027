import { call, takeEvery, put, all, fork } from "redux-saga/effects";
import * as actionTypes from "../actionTypes";

//**  services
import * as services from "../../services/zoneServices";

//**  actions
import * as actions from "./actions";

import { toast } from "react-toastify";

//** Get user list
function* getZoneList({ payload }) {
  const { placeInfo } = payload;
  try {
    const response = yield call(services.getZoneListAsync, placeInfo);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.getZonesListSuccess(data));
    } else {
      toast.error(data.message);
      yield put(actions.getZonesListFailure());
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.getZonesListFailure());
    toast.error(data.message);
    console.log(error);
  }
}

// *************************** watchers ******************************
export function* watchGetZoneList() {
  yield takeEvery(actionTypes.GET_ZONE_LIST, getZoneList);
}

//Fork
export default function* rootSaga() {
  yield all([fork(watchGetZoneList)]);
}
