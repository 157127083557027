//******************************** Authenticationn  Action Types***************************/

//**  login by password
export const POST_LOGIN_WITH_PASSWORD = "POST_LOGIN_WITH_PASSWORD";
export const POST_LOGIN_WITH_PASSWORD_SUCCESS =
  "POST_LOGIN_WITH_PASSWORD_SUCCESS";
export const POST_LOGIN_WITH_PASSWORD_FAILURE =
  "POST_LOGIN_WITH_PASSWORD_FAILURE";

//**  register
export const POST_REGISTER = "POST_REGISTER";
export const POST_REGISTER_SUCCESS = "POST_REGISTER_SUCCESS";
export const POST_REGISTER_FAILURE = "POST_REGISTER_FAILURE";

//** for get password
export const POST_FORGET_PASSWORD = "POST_FORGET_PASSWORD";
export const POST_FORGET_PASSWORD_SUCCESS = "POST_FORGET_PASSWORD_SUCCESS";
export const POST_FORGET_PASSWORD_FAILURE = "POST_FORGET_PASSWORD_FAILURE";

//** reset password
export const POST_RESET_PASSSWORD = "POST_RESET_PASSSWORD";
export const POST_RESET_PASSSWORD_SUCCESS = "POST_RESET_PASSSWORD_SUCCESS";
export const POST_RESET_PASSSWORD_FAILURE = "POST_RESET_PASSSWORD_FAILURE";

//**********************************Users Action Types***************************/

// ** users
export const DELETED_USER_LIST_MODAL = "DELETED_USER_LIST_MODAL";

// ** get user list action
export const GET_USER_LIST = "GET_USER_LIST";
export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS";
export const GET_USER_LIST_FAILURE = "GET_USER_LIST_FAILURE";

//** deleted user list  actions */
export const GET_DELETED_USER_LIST = "GET_DELETED_USER_LIST";
export const GET_DELETED_USER_LIST_SUCCESS = "GET_DELETED_USER_LIST_SUCCESS";
export const GET_DELETED_USER_LIST_FAILURE = "GET_DELETED_USER_LIST_FAILURE";

// ** post new user action
export const POST_NEW_USER = "POST_NEW_USER";
export const POST_NEW_USER_SUCCESS = "POST_NEW_USER_SUCCESS";
export const POST_NEW_USER_FAILURE = "POST_NEW_USER_FAILURE";

// **  delete user actions
export const DELETE_USER = "DELETE_USER";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

// ** restore user actions
export const RESTORE_USER = "RESTORE_USER";
export const RESTORE_USER_SUCCESS = "RESTORE_USER_SUCCESS";
export const RESTORE_USER_FAILURE = "RESTORE_USER_FAILURE";

// ** put resend invitation actions
export const PUT_USER_RESEND_INVITATION = "PUT_USER_RESEND_INVITATION";
export const PUT_USER_RESEND_INVITATION_SUCCESS =
  "PUT_USER_RESEND_INVITATION_SUCCESS";
export const PUT_USER_RESEND_INVITATION_FAILURE =
  "PUT_USER_RESEND_INVITATION_FAILURE";

//************************************************* Customers Action Types ***************************/
export const DELETED_CUSTOMER_LIST_MODAL = "DELETED_CUSTOMER_LIST_MODAL";

// ** customers list actions
export const GET_CUSTOMERS_LIST = "GET_CUSTOMERS_LIST";
export const GET_CUSTOMERS_LIST_SUCCESS = "GET_CUSTOMERS_LIST_SUCCESS";
export const GET_CUSTOMERS_LIST_FAILURE = "GET_CUSTOMERS_LIST_FAILURE";

// ** delete customer
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS";
export const DELETE_CUSTOMER_FAILURE = "DELETE_CUSTOMER_FAILURE";

//** deleted customer list  actions */
export const GET_DELETED_CUSTOMER_LIST = "GET_DELETED_CUSTOMER_LIST";
export const GET_DELETED_CUSTOMER_LIST_SUCCESS =
  "GET_DELETED_CUSTOMER_LIST_SUCCESS";
export const GET_DELETED_CUSTOMER_LIST_FAILURE =
  "GET_DELETED_CUSTOMER_LIST_FAILURE";

// ** put restore customer
export const PUT_RESTORE_CUSTOMER = "PUT_RESTORE_CUSTOMER";
export const PUT_RESTORE_CUSTOMER_SUCCESS = "PUT_RESTORE_CUSTOMER_SUCCESS";
export const PUT_RESTORE_CUSTOMER_FAILURE = "PUT_RESTORE_CUSTOMER_FAILURE";

// ** post new customer
export const POST_NEW_CUSTOMER = "POST_NEW_CUSTOMER";
export const POST_NEW_CUSTOMER_SUCCESS = "POST_NEW_CUSTOMER_SUCCESS";
export const POST_NEW_CUSTOMER_FAILURE = "POST_NEW_CUSTOMER_FAILURE";

// ** put  update customer
export const PUT_UPDATE_CUSTOMER = "PUT_UPDATE_CUSTOMER";
export const PUT_UPDATE_CUSTOMER_SUCCESS = "PUT_UPDATE_CUSTOMER_SUCCESS";
export const PUT_UPDATE_CUSTOMER_FAILURE = "PUT_UPDATE_CUSTOMER_FAILURE";

// ** post create new customer super admin
export const POST_CREATE_NEW_CUSTOMER_SUPER_ADMIN =
  "POST_CREATE_NEW_CUSTOMER_SUPER_ADMIN";
export const POST_CREATE_NEW_CUSTOMER_SUPER_ADMIN_SUCCESS =
  "POST_CREATE_NEW_CUSTOMER_SUPER_ADMIN_SUCCESS";
export const POST_CREATE_NEW_CUSTOMER_SUPER_ADMIN_FAILURE =
  "POST_CREATE_NEW_CUSTOMER_SUPER_ADMIN_FAILURE";

// ** post create new customer super admin
export const PUT_CUSTOMER_SUPER_ADMIN = "PUT_CUSTOMER_SUPER_ADMIN";
export const PUT_CUSTOMER_SUPER_ADMIN_SUCCESS =
  "PUT_CUSTOMER_SUPER_ADMIN_SUCCESS";
export const PUT_CUSTOMER_SUPER_ADMIN_FAILURE =
  "PUT_CUSTOMER_SUPER_ADMIN_FAILURE";

// ** get single customer by id
export const GET_CUSTOMER_BY_ID = "GET_CUSTOMER_BY_ID";
export const GET_CUSTOMER_BY_ID_SUCCESS = "GET_CUSTOMER_BY_ID_SUCCESS";
export const GET_CUSTOMER_BY_ID_FAILURE = "GET_CUSTOMER_BY_ID_FAILURE";

// ** post resend customer user invitation
export const POST_RESEND_CUSTOMER_USER_INVITATION =
  "POST_RESEND_CUSTOMER_USER_INVITATION";
export const POST_RESEND_CUSTOMER_USER_INVITATION_SUCCESS =
  "POST_RESEND_CUSTOMER_USER_INVITATION_SUCCESS";
export const POST_RESEND_CUSTOMER_USER_INVITATION_FAILURE =
  "POST_RESEND_CUSTOMER_USER_INVITATION_FAILURE";

// ** post resend customer user invitation
export const POST_NEW_CUSTOMER_LANGUAGE = "POST_NEW_CUSTOMER_LANGUAGE";
export const POST_NEW_CUSTOMER_LANGUAGE_SUCCESS =
  "POST_NEW_CUSTOMER_LANGUAGE_SUCCESS";
export const POST_NEW_CUSTOMER_LANGUAGE_FAILURE =
  "POST_NEW_CUSTOMER_LANGUAGE_FAILURE";

// ** post resend customer user invitation
export const DELETE_CUSTOMER_LANGUAGE = "DELETE_CUSTOMER_LANGUAGE";
export const DELETE_CUSTOMER_LANGUAGE_SUCCESS =
  "DELETE_CUSTOMER_LANGUAGE_SUCCESS";
export const DELETE_CUSTOMER_LANGUAGE_FAILURE =
  "DELETE_CUSTOMER_LANGUAGE_FAILURE";

// ** get customer navigation config
export const GET_CUSTOMER_NAVIGATION_CONFIG = "GET_CUSTOMER_NAVIGATION_CONFIG";
export const GET_CUSTOMER_NAVIGATION_CONFIG_SUCCESS =
  "GET_CUSTOMER_NAVIGATION_CONFIG_SUCCESS";
export const GET_CUSTOMER_NAVIGATION_CONFIG_FAILURE =
  "GET_CUSTOMER_NAVIGATION_CONFIG_FAILURE";

// ** update customer navigation config
export const PUT_CUSTOMER_NAVIGATION_CONFIG = "PUT_CUSTOMER_NAVIGATION_CONFIG";
export const PUT_CUSTOMER_NAVIGATION_CONFIG_SUCCESS =
  "PUT_CUSTOMER_NAVIGATION_CONFIG_SUCCESS";
export const PUT_CUSTOMER_NAVIGATION_CONFIG_FAILURE =
  "PUT_CUSTOMER_NAVIGATION_CONFIG_FAILURE";

// ** GET_CUSTOMER_CONFIG
export const GET_CUSTOMER_CONFIG = "GET_CUSTOMER_CONFIG";
export const GET_CUSTOMER_CONFIG_SUCCESS = "GET_CUSTOMER_CONFIG_SUCCESS";
export const GET_CUSTOMER_CONFIG_FAILURE = "GET_CUSTOMER_CONFIG_FAILURE";

// ** PUT_CUSTOMER_CONFIG
export const PUT_CUSTOMER_CONFIG = "PUT_CUSTOMER_CONFIG";
export const PUT_CUSTOMER_CONFIG_SUCCESS = "PUT_CUSTOMER_CONFIG_SUCCESS";
export const PUT_CUSTOMER_CONFIG_FAILURE = "PUT_CUSTOMER_CONFIG_FAILURE";

// ** ******************************** place types actions types **************************

// **  place types  actions
export const GET_PLACE_TYPES_LIST = "GET_PLACE_TYPES_LIST";
export const GET_PLACE_TYPES_LIST_SUCCESS = "GET_PLACE_TYPES_LIST_SUCCESS";
export const GET_PLACE_TYPES_LIST_FAILURE = "GET_PLACE_TYPES_LIST_FAILURE";

// ** post new place type
export const POST_PLACE_TYPE = "POST_PLACE_TYPE";
export const POST_PLACE_TYPE_SUCCESS = "POST_PLACE_TYPE_SUCCESS";
export const POST_PLACE_TYPE_FAILURE = "POST_PLACE_TYPE_FAILURE";

// ** post new place type
export const PUT_PLACE_TYPE = "PUT_PLACE_TYPE";
export const PUT_PLACE_TYPE_SUCCESS = "PUT_PLACE_TYPE_SUCCESS";
export const PUT_PLACE_TYPE_FAILURE = "PUT_PLACE_TYPE_FAILURE";

// ** DELETE_PLACE_TYPE
export const DELETE_PLACE_TYPE = "DELETE_PLACE_TYPE";
export const DELETE_PLACE_TYPE_SUCCESS = "DELETE_PLACE_TYPE_SUCCESS";
export const DELETE_PLACE_TYPE_FAILURE = "DELETE_PLACE_TYPE_FAILURE";

// ** DELETE_PLACE_TYPE
export const PUT_RESTORE_PLACE_TYPE = "PUT_RESTORE_PLACE_TYPE";
export const PUT_RESTORE_PLACE_TYPE_SUCCESS = "PUT_RESTORE_PLACE_TYPE_SUCCESS";
export const PUT_RESTORE_PLACE_TYPE_FAILURE = "PUT_RESTORE_PLACE_TYPE_FAILURE";

//** deleted user list  actions */
export const GET_DELETED_PLACE_TYPE_LIST = "GET_DELETED_PLACE_TYPE_LIST";
export const GET_DELETED_PLACE_TYPE_LIST_SUCCESS =
  "GET_DELETED_PLACE_TYPE_LIST_SUCCESS";
export const GET_DELETED_PLACE_TYPE_LIST_FAILURE =
  "GET_DELETED_PLACE_TYPE_LIST_FAILURE";

// ** deleted  place type list
export const DELETED_PLACE_TYPE_LIST_MODAL = "DELETED_PLACE_TYPE_LIST_MODAL";

// ** current floor
export const CHANGE_CURRENT_FLOOR = "CHANGE_CURRENT_FLOOR";

// *********************************** menu items **************************
// ** customers list actions
export const GET_MENU_ITEM_LIST = "GET_MENU_ITEM_LIST";
export const GET_MENU_ITEM_LIST_SUCCESS = "GET_MENU_ITEM_LIST_SUCCESS";
export const GET_MENU_ITEM_LIST_FAILURE = "GET_MENU_ITEM_LIST_FAILURE";

export const POST_MENU_ITEM = "POST_MENU_ITEM";
export const POST_MENU_ITEM_SUCCESS = "POST_MENU_ITEM_SUCCESS";
export const POST_MENU_ITEM_FAILURE = "POST_MENU_ITEM_FAILURE";

export const DELETE_MENU_ITEM = "DELETE_MENU_ITEM";
export const DELETE_MENU_ITEM_SUCCESS = "DELETE_MENU_ITEM_SUCCESS";
export const DELETE_MENU_ITEM_FAILURE = "DELETE_MENU_ITEM_FAILURE";

export const PUT_MENU_ITEM = "PUT_MENU_ITEM";
export const PUT_MENU_ITEM_SUCCESS = "PUT_MENU_ITEM_SUCCESS";
export const PUT_MENU_ITEM_FAILURE = "PUT_MENU_ITEM_FAILURE";

export const POST_MENU_ITEM_TRANSLATION = "POST_MENU_ITEM_TRANSLATION";
export const POST_MENU_ITEM_TRANSLATION_SUCCESS =
  "POST_MENU_ITEM_TRANSLATION_SUCCESS";
export const POST_MENU_ITEM_TRANSLATION_FAILURE =
  "POST_MENU_ITEM_TRANSLATION_FAILURE";

export const DELETE_MENU_ITEM_TRANSLATION = "DELETE_MENU_ITEM_TRANSLATION";
export const DELETE_MENU_ITEM_TRANSLATION_SUCCESS =
  "DELETE_MENU_ITEM_TRANSLATION_SUCCESS";
export const DELETE_MENU_ITEM_TRANSLATION_FAILURE =
  "DELETE_MENU_ITEM_TRANSLATION_FAILURE";

export const PUT_MENU_ITEM_TRANSLATION = "PUT_MENU_ITEM_TRANSLATION";
export const PUT_MENU_ITEM_TRANSLATION_SUCCESS =
  "PUT_MENU_ITEM_TRANSLATION_SUCCESS";
export const PUT_MENU_ITEM_TRANSLATION_FAILURE =
  "PUT_MENU_ITEM_TRANSLATION_FAILURE";

export const POST_EXCLUDE_MENU_ITEM = "POST_EXCLUDE_MENU_ITEM";
export const POST_EXCLUDE_MENU_ITEM_SUCCESS = "POST_EXCLUDE_MENU_ITEM_SUCCESS";
export const POST_EXCLUDE_MENU_ITEM_FAILURE = "POST_EXCLUDE_MENU_ITEM_FAILURE";

export const DELETE_EXCLUDE_MENU_ITEM = "DELETE_EXCLUDE_MENU_ITEM";
export const DELETE_EXCLUDE_MENU_ITEM_SUCCESS =
  "DELETE_EXCLUDE_MENU_ITEM_SUCCESS";
export const DELETE_EXCLUDE_MENU_ITEM_FAILURE =
  "DELETE_EXCLUDE_MENU_ITEM_FAILURE";

export const POST_ATTACH_MENU_ITEM = "POST_ATTACH_MENU_ITEM";
export const POST_ATTACH_MENU_ITEM_SUCCESS = "POST_ATTACH_MENU_ITEM_SUCCESS";
export const POST_ATTACH_MENU_ITEM_FAILURE = "POST_ATTACH_MENU_ITEM_FAILURE";

export const DELETE_ATTACH_MENU_ITEM = "DELETE_ATTACH_MENU_ITEM";
export const DELETE_ATTACH_MENU_ITEM_SUCCESS =
  "DELETE_ATTACH_MENU_ITEM_SUCCESS";
export const DELETE_ATTACH_MENU_ITEM_FAILURE =
  "DELETE_ATTACH_MENU_ITEM_FAILURE";

// *********************************** settings **************************

export const GET_LANGUAGE_LIST = "GET_LANGUAGE_LIST";
export const GET_LANGUAGE_LIST_SUCCESS = "GET_LANGUAGE_LIST_SUCCESS";
export const GET_LANGUAGE_LIST_FAILURE = "GET_LANGUAGE_LIST_FAILURE";

export const POST_NEW_LANGUAGE = "POST_NEW_LANGUAGE";
export const POST_NEW_LANGUAGE_SUCCESS = "POST_NEW_LANGUAGE_SUCCESS";
export const POST_NEW_LANGUAGE_FAILURE = "POST_NEW_LANGUAGE_FAILURE";

export const DELETE_LANGUAGE = "DELETE_LANGUAGE";
export const DELETE_LANGUAGE_SUCCESS = "DELETE_LANGUAGE_SUCCESS";
export const DELETE_LANGUAGE_FAILURE = "DELETE_LANGUAGE_FAILURE";

// *********************************** Places **************************
export const GET_PLACES = "GET_PLACES";
export const GET_PLACES_SUCCESS = "GET_PLACES_SUCCESS";
export const GET_PLACES_FAILURE = "GET_PLACES_FAILURE";

export const GET_PLACE_GEO_JSON = "GET_PLACE_GEO_JSON";
export const GET_PLACE_GEO_JSON_SUCCESS = "GET_PLACE_GEO_JSON_SUCCESS";
export const GET_PLACE_GEO_JSON_FAILURE = "GET_PLACE_GEO_JSON_FAILURE";

export const GET_POI_GEO_JSON = "GET_POI_GEO_JSON";
export const GET_POI_GEO_JSON_SUCCESS = "GET_POI_GEO_JSON_SUCCESS";
export const GET_POI_GEO_JSON_FAILURE = "GET_POI_GEO_JSON_FAILURE";

export const GET_PLACE_CENTER_COORDINATION = "GET_PLACE_CENTER_COORDINATION";
export const GET_PLACE_CENTER_COORDINATION_SUCCESS =
  "GET_PLACE_CENTER_COORDINATION_SUCCESS";
export const GET_PLACE_CENTER_COORDINATION_FAILURE =
  "GET_PLACE_CENTER_COORDINATION_FAILURE";

export const GET_PLACES_SETTINGS = "GET_PLACES_SETTINGS";
export const GET_PLACES_SETTINGS_SUCCESS = "GET_PLACES_SETTINGS_SUCCESS";
export const GET_PLACES_SETTINGS_FAILURE = "GET_PLACES_SETTINGS_FAILURE";

export const PUT_PLACES_SETTINGS = "PUT_PLACES_SETTINGS";
export const PUT_PLACES_SETTINGS_SUCCESS = "PUT_PLACES_SETTINGS_SUCCESS";
export const PUT_PLACES_SETTINGS_FAILURE = "PUT_PLACES_SETTINGS_FAILURE";

export const DELETE_PLACES_SETTINGS = "DELETE_PLACES_SETTINGS";
export const DELETE_PLACES_SETTINGS_SUCCESS = "DELETE_PLACES_SETTINGS_SUCCESS";
export const DELETE_PLACES_SETTINGS_FAILURE = "DELETE_PLACES_SETTINGS_FAILURE";

export const GET_PLACE_LIVE_STATUS = "GET_PLACE_LIVE_STATUS";
export const GET_PLACE_LIVE_STATUS_SUCCESS = "GET_PLACE_LIVE_STATUS_SUCCESS";
export const GET_PLACE_LIVE_STATUS_FAILURE = "GET_PLACE_LIVE_STATUS_FAILURE";

export const POST_PLACE_PUBLISH_GIS = "POST_PLACE_PUBLISH_GIS";
export const POST_PLACE_PUBLISH_GIS_SUCCESS = "POST_PLACE_PUBLISH_GIS_SUCCESS";
export const POST_PLACE_PUBLISH_GIS_FAILURE = "POST_PLACE_PUBLISH_GIS_FAILURE";

// *********************************** brands **************************
export const GET_BRANDS_LIST = "GET_BRANDS_LIST";
export const GET_BRANDS_LIST_SUCCESS = "GET_BRANDS_LIST_SUCCESS";
export const GET_BRANDS_LIST_FAILURE = "GET_BRANDS_LIST_FAILURE";

export const POST_NEW_BRAND = "POST_NEW_BRAND";
export const POST_NEW_BRAND_SUCCESS = "POST_NEW_BRAND_SUCCESS";
export const POST_NEW_BRAND_FAILURE = "POST_NEW_BRAND_FAILURE";

export const GET_DELETED_BRANDS_LIST = "GET_DELETED_BRANDS_LIST";
export const GET_DELETED_BRANDS_LIST_SUCCESS =
  "GET_DELETED_BRANDS_LIST_SUCCESS";
export const GET_DELETED_BRANDS_LIST_FAILURE =
  "GET_DELETED_BRANDS_LIST_FAILURE";

export const PUT_BRAND = "PUT_BRAND";
export const PUT_BRAND_SUCCESS = "PUT_BRAND_SUCCESS";
export const PUT_BRAND_FAILURE = "PUT_BRAND_FAILURE";

export const DELETE_BRAND = "DELETE_BRAND";
export const DELETE_BRAND_SUCCESS = "DELETE_BRAND_SUCCESS";
export const DELETE_BRAND_FAILURE = "DELETE_BRAND_FAILURE";

export const PUT_RESTORE_BRAND = "PUT_RESTORE_BRAND";
export const PUT_RESTORE_BRAND_SUCCESS = "PUT_RESTORE_BRAND_SUCCESS";
export const PUT_RESTORE_BRAND_FAILURE = "PUT_RESTORE_BRAND_FAILURE";
export const DELETED_BRANDS_LIST_MODAL = "DELETED_BRANDS_LIST_MODAL";

// ********************** get categories action types ************

export const GET_CATEGORIES_LIST = "GET_CATEGORIES_LIST";
export const GET_CATEGORIES_LIST_SUCCESS = "GET_CATEGORIES_LIST_SUCCESS";
export const GET_CATEGORIES_LIST_FAILURE = "GET_CATEGORIES_LIST_FAILURE";

export const GET_DELETED_CATEGORIES_LIST = "GET_DELETED_CATEGORIES_LIST";
export const GET_DELETED_CATEGORIES_LIST_SUCCESS =
  "GET_DELETED_CATEGORIES_LIST_SUCCESS";
export const GET_DELETED_CATEGORIES_LIST_FAILURE =
  "GET_DELETED_CATEGORIES_LIST_FAILURE";
export const DELETED_CATEGORIES_LIST_MODAL = "DELETED_CATEGORIES_LIST_MODAL";

export const GET_CATEGORY_BY_ID = "GET_CATEGORY_BY_ID";
export const GET_CATEGORY_BY_ID_SUCCESS = "GET_CATEGORY_BY_ID_SUCCESS";
export const GET_CATEGORY_BY_ID_FAILURE = "GET_CATEGORY_BY_ID_FAILURE";

export const POST_CATEGORY = "POST_CATEGORY";
export const POST_CATEGORY_SUCCESS = "POST_CATEGORY_SUCCESS";
export const POST_CATEGORY_FAILURE = "POST_CATEGORY_FAILURE";

export const PUT_CATEGORY = "PUT_CATEGORY";
export const PUT_CATEGORY_SUCCESS = "PUT_CATEGORY_SUCCESS";
export const PUT_CATEGORY_FAILURE = "PUT_CATEGORY_FAILURE";

export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAILURE = "DELETE_CATEGORY_FAILURE";

export const PUT_RESTORE_CATEGORY = "PUT_RESTORE_CATEGORY";
export const PUT_RESTORE_CATEGORY_SUCCESS = "PUT_RESTORE_CATEGORY_SUCCESS";
export const PUT_RESTORE_CATEGORY_FAILURE = "PUT_RESTORE_CATEGORY_FAILURE";

export const POST_CATEGORY_TRANSLATION = "POST_CATEGORY_TRANSLATION";
export const POST_CATEGORY_TRANSLATION_SUCCESS =
  "POST_CATEGORY_TRANSLATION_SUCCESS";
export const POST_CATEGORY_TRANSLATION_FAILURE =
  "POST_CATEGORY_TRANSLATION_FAILURE";

export const PUT_CATEGORY_TRANSLATION = "PUT_CATEGORY_TRANSLATION";
export const PUT_CATEGORY_TRANSLATION_SUCCESS =
  "PUT_CATEGORY_TRANSLATION_SUCCESS";
export const PUT_CATEGORY_TRANSLATION_FAILURE =
  "PUT_CATEGORY_TRANSLATION_FAILURE";

export const DELETE_CATEGORY_TRANSLATION = "DELETE_CATEGORY_TRANSLATION";
export const DELETE_CATEGORY_TRANSLATION_SUCCESS =
  "DELETE_CATEGORY_TRANSLATION_SUCCESS";
export const DELETE_CATEGORY_TRANSLATION_FAILURE =
  "DELETE_CATEGORY_TRANSLATION_FAILURE";

// *******************sub categories

export const GET_DELETED_SUBCATEGORIES_LIST = "GET_DELETED_SUBCATEGORIES_LIST";
export const GET_DELETED_SUBCATEGORIES_LIST_SUCCESS =
  "GET_DELETED_SUBCATEGORIES_LIST_SUCCESS";
export const GET_DELETED_SUBCATEGORIES_LIST_FAILURE =
  "GET_DELETED_SUBCATEGORIES_LIST_FAILURE";
export const DELETED_SUBCATEGORIES_LIST_MODAL =
  "DELETED_SUBCATEGORIES_LIST_MODAL";

export const POST_SUBCATEGORY = "POST_SUBCATEGORY";
export const POST_SUBCATEGORY_SUCCESS = "POST_SUBCATEGORY_SUCCESS";
export const POST_SUBCATEGORY_FAILURE = "POST_SUBCATEGORY_FAILURE";

export const PUT_SUBCATEGORY = "PUT_SUBCATEGORY";
export const PUT_SUBCATEGORY_SUCCESS = "PUT_SUBCATEGORY_SUCCESS";
export const PUT_SUBCATEGORY_FAILURE = "PUT_SUBCATEGORY_FAILURE";

export const DELETE_SUBCATEGORY = "DELETE_SUBCATEGORY";
export const DELETE_SUBCATEGORY_SUCCESS = "DELETE_SUBCATEGORY_SUCCESS";
export const DELETE_SUBCATEGORY_FAILURE = "DELETE_SUBCATEGORY_FAILURE";

export const PUT_RESTORE_SUBCATEGORY = "PUT_RESTORE_SUBCATEGORY";
export const PUT_RESTORE_SUBCATEGORY_SUCCESS =
  "PUT_RESTORE_SUBCATEGORY_SUCCESS";
export const PUT_RESTORE_SUBCATEGORY_FAILURE =
  "PUT_RESTORE_SUBCATEGORY_FAILURE";

export const POST_SUBCATEGORY_TRANSLATION = "POST_SUBCATEGORY_TRANSLATION";
export const POST_SUBCATEGORY_TRANSLATION_SUCCESS =
  "POST_SUBCATEGORY_TRANSLATION_SUCCESS";
export const POST_SUBCATEGORY_TRANSLATION_FAILURE =
  "POST_SUBCATEGORY_TRANSLATION_FAILURE";

export const PUT_SUBCATEGORY_TRANSLATION = "PUT_SUBCATEGORY_TRANSLATION";
export const PUT_SUBCATEGORY_TRANSLATION_SUCCESS =
  "PUT_SUBCATEGORY_TRANSLATION_SUCCESS";
export const PUT_SUBCATEGORY_TRANSLATION_FAILURE =
  "PUT_SUBCATEGORY_TRANSLATION_FAILURE";

export const DELETE_SUBCATEGORY_TRANSLATION = "DELETE_SUBCATEGORY_TRANSLATION";
export const DELETE_SUBCATEGORY_TRANSLATION_SUCCESS =
  "DELETE_SUBCATEGORY_TRANSLATION_SUCCESS";
export const DELETE_SUBCATEGORY_TRANSLATION_FAILURE =
  "DELETE_CATEGORY_TRANSLATION_FAILURE";

// *********************  netwroktypes action *************************

export const GET_NETWORKTYPE_LIST = "GET_NETWORKTYPE_LIST";
export const GET_NETWORKTYPE_LIST_SUCCESS = "GET_NETWORKTYPE_LIST_SUCCESS";
export const GET_NETWORKTYPE_LIST_FAILURE = "GET_NETWORKTYPE_LIST_FAILURE";

// ** POST_NETWORKTYPE
export const POST_NETWORKTYPE = "POST_NETWORKTYPE";
export const POST_NETWORKTYPE_SUCCESS = "POST_NETWORKTYPE_SUCCESS";
export const POST_NETWORKTYPE_FAILURE = "POST_NETWORKTYPE_FAILURE";

// ** PUT_NETWROKTYPE
export const PUT_NETWROKTYPE = "PUT_NETWROKTYPE";
export const PUT_NETWROKTYPE_SUCCESS = "PUT_NETWROKTYPE_SUCCESS";
export const PUT_NETWROKTYPE_FAILURE = "PUT_NETWROKTYPE_FAILURE";

// ** DELETE_NETWORKTYPE
export const DELETE_NETWORKTYPE = "DELETE_NETWORKTYPE";
export const DELETE_NETWORKTYPE_SUCCESS = "DELETE_NETWORKTYPE_SUCCESS";
export const DELETE_NETWORKTYPE_FAILURE = "DELETE_NETWORKTYPE_FAILURE";

// ** PUT_RESTORE_NETWORKTYPE
export const PUT_RESTORE_NETWORKTYPE = "PUT_RESTORE_NETWORKTYPE";
export const PUT_RESTORE_NETWORKTYPE_SUCCESS =
  "PUT_RESTORE_NETWORKTYPE_SUCCESS";
export const PUT_RESTORE_NETWORKTYPE_FAILURE =
  "PUT_RESTORE_NETWORKTYPE_FAILURE";

//** GET_DELETED_NETWORKTYPE_LIST */
export const GET_DELETED_NETWORKTYPE_LIST = "GET_DELETED_NETWORKTYPE_LIST";
export const GET_DELETED_NETWORKTYPE_LIST_SUCCESS =
  "GET_DELETED_NETWORKTYPE_LIST_SUCCESS";
export const GET_DELETED_NETWORKTYPE_LIST_FAILURE =
  "GET_DELETED_NETWORKTYPE_LIST_FAILURE";

// ** DELETED_NETWORKTYPE_LIST_MODAL
export const DELETED_NETWORKTYPE_LIST_MODAL = "DELETED_NETWORKTYPE_LIST_MODAL";

// ******************* Pointtype *******************

export const GET_POINTTYPE_LIST = "GET_POINTTYPE_LIST";
export const GET_POINTTYPE_LIST_SUCCESS = "GET_POINTTYPE_LIST_SUCCESS";
export const GET_POINTTYPE_LIST_FAILURE = "GET_POINTTYPE_LIST_FAILURE";

// ** POST_POINTTYPE
export const POST_POINTTYPE = "POST_POINTTYPE";
export const POST_POINTTYPE_SUCCESS = "POST_POINTTYPE_SUCCESS";
export const POST_POINTTYPE_FAILURE = "POST_POINTTYPE_FAILURE";

// ** PUT_POINTTYPE
export const PUT_POINTTYPE = "PUT_POINTTYPE";
export const PUT_POINTTYPE_SUCCESS = "PUT_POINTTYPE_SUCCESS";
export const PUT_POINTTYPE_FAILURE = "PUT_POINTTYPE_FAILURE";

// ** DELETE_POINTTYPE
export const DELETE_POINTTYPE = "DELETE_POINTTYPE";
export const DELETE_POINTTYPE_SUCCESS = "DELETE_POINTTYPE_SUCCESS";
export const DELETE_POINTTYPE_FAILURE = "DELETE_POINTTYPE_FAILURE";

// ** PUT_RESTORE_POINTTYPE
export const PUT_RESTORE_POINTTYPE = "PUT_RESTORE_POINTTYPE";
export const PUT_RESTORE_POINTTYPE_SUCCESS = "PUT_RESTORE_POINTTYPE_SUCCESS";
export const PUT_RESTORE_POINTTYPE_FAILURE = "PUT_RESTORE_POINTTYPE_FAILURE";

//** GET_DELETED_POINTTYPE_LIST */
export const GET_DELETED_POINTTYPE_LIST = "GET_DELETED_POINTTYPE_LIST";
export const GET_DELETED_POINTTYPE_LIST_SUCCESS =
  "GET_DELETED_POINTTYPE_LIST_SUCCESS";
export const GET_DELETED_POINTTYPE_LIST_FAILURE =
  "GET_DELETED_POINTTYPE_LIST_FAILURE";

// ** DELETED_POINTTYPE_LIST_MODAL
export const DELETED_POINTTYPE_LIST_MODAL = "DELETED_POINTTYPE_LIST_MODAL";

// *********************************** Zones **************************
export const GET_ZONE_LIST = "GET_ZONE_LIST";
export const GET_ZONE_LIST_SUCCESS = "GET_ZONE_LIST_SUCCESS";
export const GET_ZONE_LIST_FAILURE = "GET_ZONE_LIST_FAILURE";

// *********************************** segment **************************
export const GET_SEGMENT_LIST = "GET_SEGMENT_LIST";
export const GET_SEGMENT_LIST_SUCCESS = "GET_SEGMENT_LIST_SUCCESS";
export const GET_SEGMENT_LIST_FAILURE = "GET_SEGMENT_LIST_FAILURE";

// ***************************** Walk ways ***********************
export const GET_WALKWAYS_LIST = "GET_WALKWAYS_LIST";
export const GET_WALKWAYS_LIST_SUCCESS = "GET_WALKWAYS_LIST_SUCCESS";
export const GET_WALKWAYS_LIST_FAILURE = "GET_WALKWAYS_LIST_FAILURE";

// ***************************** beacons  ***********************
export const GET_BEACONS_LIST = "GET_BEACONS_LIST";
export const GET_BEACONS_LIST_SUCCESS = "GET_BEACONS_LIST_SUCCESS";
export const GET_BEACONS_LIST_FAILURE = "GET_BEACONS_LIST_FAILURE";

export const GET_BEACONS = "GET_BEACONS";
export const GET_BEACONS_SUCCESS = "GET_BEACONS_SUCCESS";
export const GET_BEACONS_FAILURE = "GET_BEACONS_FAILURE";

export const DELETE_BEACON = "DELETE_BEACON";
export const DELETE_BEACON_SUCCESS = "DELETE_BEACON_SUCCESS";
export const DELETE_BEACON_FAILURE = "DELETE_BEACON_FAILURE";

export const POST_BEACON = "POST_BEACON";
export const POST_BEACON_SUCCESS = "POST_BEACON_SUCCESS";
export const POST_BEACON_FAILURE = "POST_BEACON_FAILURE";

export const PUT_BEACON = "PUT_BEACON";
export const PUT_BEACON_SUCCESS = "PUT_BEACON_SUCCESS";
export const PUT_BEACON_FAILURE = "PUT_BEACON_FAILURE";

// ***************************** contents  ***********************
export const POST_POI_CONTENT = "POST_POI_CONTENT";
export const POST_POI_CONTENT_SUCCESS = "POST_POI_CONTENT_SUCCESS";
export const POST_POI_CONTENT_FAILURE = "POST_POI_CONTENT_FAILURE ";

export const DELETE_POI_CONTENT = "DELETE_POI_CONTENT";
export const DELETE_POI_CONTENT_SUCCESS = "DELETE_POI_CONTENT_SUCCESS";
export const DELETE_POI_CONTENT_FAILURE = "DELETE_POI_CONTENT";

// ***************************** store ids  ***********************

export const GET_STORE_IDS_LIST = "GET_STORE_IDS_LIST";
export const GET_STORE_IDS_LIST_SUCCESS = "GET_STORE_IDS_LIST_SUCCESS";
export const GET_STORE_IDS_LIST_FAILURE = "GET_STORE_IDS_LIST_FAILURE";

export const DELETE_STORE_ID = "DELETE_STORE_ID";
export const DELETE_STORE_ID_SUCCESS = "DELETE_STORE_ID_SUCCESS";
export const DELETE_STORE_ID_FAILURE = "DELETE_STORE_ID_FAILURE";

export const POST_STORE_ID = "POST_STORE_ID";
export const POST_STORE_ID_SUCCESS = "POST_STORE_ID_SUCCESS";
export const POST_STORE_ID_FAILURE = "POST_STORE_ID_FAILURE";

export const PUT_STORE_ID = "PUT_STORE_ID";
export const PUT_STORE_ID_SUCCESS = "PUT_STORE_ID_SUCCESS";
export const PUT_STORE_ID_FAILURE = "PUT_STORE_ID_FAILURE";

// ** pois

export const GET_POIS_LIST = "GET_POIS_LIST";
export const GET_POIS_LIST_SUCCESS = "GET_POIS_LIST_SUCCESS";
export const GET_POIS_LIST_FAILURE = "GET_POIS_LIST_FAILURE";

export const GET_LOGIN_AS_CUSTOMER = "GET_LOGIN_AS_CUSTOMER";
export const GET_LOGIN_AS_CUSTOMER_SUCCESS = "GET_LOGIN_AS_CUSTOMER_SUCCESS";
export const GET_LOGIN_AS_CUSTOMER_FAILURE = "GET_LOGIN_AS_CUSTOMER_FAILURE";
