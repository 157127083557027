import * as actionTypes from "../actionTypes";

const intialState = {
  placeTypesList: [],
  deletedPlaceTypeList: [],
  customerSuperAdminData: {},
  customerDetailInfo: {},
  selectedPlaceTypeId: null,

  postPlaceTypeLoader: false,
  getPlaceTypesListLoader: false,
  deletePlaceTypeLoader: false,
  putRestorePlaceTypeLoader: false,
  putPlaceTypeLoader: false,

  getDeletedPlaceTypeListLoader: false,

  openDeletedPlaceTypeListModal: false,
};

const palceTypes = (state = intialState, action) => {
  switch (action.type) {
    //**GET_PLACE_TYPES_LIST */
    case actionTypes.GET_PLACE_TYPES_LIST: {
      return {
        ...state,
        getPlaceTypesListLoader: true,
      };
    }
    case actionTypes.GET_PLACE_TYPES_LIST_SUCCESS: {
      return {
        ...state,
        placeTypesList: action.payload.placeTypesList,
        getPlaceTypesListLoader: false,
      };
    }
    case actionTypes.GET_PLACE_TYPES_LIST_FAILURE: {
      return {
        ...state,
        getPlaceTypesListLoader: false,
      };
    }

    // ** post new place type
    case actionTypes.POST_PLACE_TYPE: {
      return {
        ...state,
        postPlaceTypeLoader: true,
      };
    }
    case actionTypes.POST_PLACE_TYPE_SUCCESS: {
      const { id, name, createdAt } = action.payload.placeTypeInfo;

      return {
        ...state,
        placeTypesList: [
          ...state.placeTypesList,
          {
            id,
            name,
            createdAt,
          },
        ],
        postPlaceTypeLoader: false,
      };
    }
    case actionTypes.POST_PLACE_TYPE_FAILURE: {
      return {
        ...state,
        postPlaceTypeLoader: false,
      };
    }

    // ** delete customer
    case actionTypes.DELETE_PLACE_TYPE: {
      return {
        ...state,
        deletePlaceTypeLoader: true,
      };
    }
    case actionTypes.DELETE_PLACE_TYPE_SUCCESS: {
      const placeTypeId = action.payload.placeTypeId;

      // ** remove deleted item from array
      const updatePlaceTypeList = state.placeTypesList.filter(
        (item) => item.id !== placeTypeId
      );
      return {
        ...state,
        placeTypesList: updatePlaceTypeList,
        deletePlaceTypeLoader: false,
      };
    }
    case actionTypes.DELETE_PLACE_TYPE_FAILURE: {
      return {
        ...state,
        deletePlaceTypeLoader: false,
      };
    }

    //** DELETED_CUSTOMER_LIST_MODAL
    case actionTypes.DELETED_CUSTOMER_LIST_MODAL: {
      const { openModal } = action.payload;
      return {
        ...state,
        openDeletedCustomerListModal: openModal,
      };
    }

    //**GET_DELETED_CUSTOMER_LIST */
    case actionTypes.GET_DELETED_PLACE_TYPE_LIST: {
      return {
        ...state,
        getDeletedPlaceTypeListLoader: true,
      };
    }
    case actionTypes.GET_DELETED_PLACE_TYPE_LIST_SUCCESS: {
      return {
        ...state,
        deletedPlaceTypeList: action.payload.deletedPlaceTypeList,
        getDeletedPlaceTypeListLoader: false,
      };
    }
    case actionTypes.GET_DELETED_PLACE_TYPE_LIST_FAILURE: {
      return {
        ...state,
        getDeletedPlaceTypeListLoader: false,
      };
    }

    // ** delete customer
    case actionTypes.PUT_RESTORE_PLACE_TYPE: {
      const placeTypeId = action.payload.placeTypeId;
      return {
        ...state,
        selectedPlaceTypeId: placeTypeId,
        putRestorePlaceTypeLoader: true,
      };
    }
    case actionTypes.PUT_RESTORE_PLACE_TYPE_SUCCESS: {
      const placeTypeId = action.payload.placeTypeId;

      // ** remove deleted item from array
      const updateDeletedPlaceTypeList = state.deletedPlaceTypeList.filter(
        (item) => item.id !== placeTypeId
      );
      return {
        ...state,
        deletedPlaceTypeList: updateDeletedPlaceTypeList,
        putRestorePlaceTypeLoader: false,
      };
    }
    case actionTypes.PUT_RESTORE_PLACE_TYPE_FAILURE: {
      return {
        ...state,
        putRestorePlaceTypeLoader: false,
      };
    }

    // ** PUT_PLACE_TYPE
    case actionTypes.PUT_PLACE_TYPE: {
      return {
        ...state,
        putPlaceTypeLoader: true,
      };
    }
    case actionTypes.PUT_PLACE_TYPE_SUCCESS: {
      const { id, name, createdAt, updatedAt } = action.payload.placeTypeInfo;

      const placeTypeIndex = state.placeTypesList.findIndex(
        (placeType) => placeType.id === id
      );

      const placeTypeListUpdate = [...state.placeTypesList];

      placeTypeListUpdate[placeTypeIndex] = {
        id,
        name,
        createdAt,
        updatedAt,
      };

      return {
        ...state,
        placeTypesList: placeTypeListUpdate,
        putPlaceTypeLoader: false,
      };
    }
    case actionTypes.PUT_PLACE_TYPE_FAILURE: {
      return {
        ...state,
        putPlaceTypeLoader: false,
      };
    }

    // ** DELETED_PLACE_TYPE_LIST_MODAL
    case actionTypes.DELETED_PLACE_TYPE_LIST_MODAL: {
      const { openModal } = action.payload;
      return {
        ...state,
        openDeletedPlaceTypeListModal: openModal,
      };
    }

    default: {
      return state;
    }
  }
};

export default palceTypes;
