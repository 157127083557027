import { call, takeEvery, put, all, fork } from "redux-saga/effects";
import * as actionTypes from "../actionTypes";

// services
import * as services from "../../services/authServices";

// actions
import * as actions from "./actions";

// ** routes
import { appRoutes, authRoutes } from "../../router/routesList";

import { history } from "../../history";
import { toast } from "react-toastify";

//** Login With Username & Password
function* postLoginPassword({ payload }) {
  const email = payload.email;
  const password = payload.password;
  try {
    const response = yield call(services.loginPasswordAsync, email, password);
    const { data } = response;

    if (response.status === 200) {
      const token = data.token;
      localStorage.setItem("token", token);
      localStorage.setItem("userData", JSON.stringify(data));
      yield put(actions.postLoginByPasswordSuccess(data));

      history.push(appRoutes.DASHBOARD);
    } else {
      toast.error(data.message);
      yield put(actions.postLoginByPasswordFailure());
    }
  } catch (error) {
    const { data } = error.response;
    yield put(actions.postLoginByPasswordFailure());
    toast.error(data.message);
    console.log(error);
  }
}

// ** post forget password
function* postForgetPassword({ payload }) {
  const email = payload.email;
  try {
    const response = yield call(services.forgetPasswordAsync, email);
    const { data } = response;
    if (response.status === 200) {
      yield put(actions.postForGetPasswordSuccess());
      toast.info("Please check your email...");
    } else {
      toast.error(data.message);
      yield put(actions.postForGetPasswordFailure());
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.postForGetPasswordFailure());
    toast.error(data.message);
    console.log(error);
  }
}

// ** post reset password
function* postResetPassword({ payload }) {
  const token = payload.token;
  const password = payload.password;
  try {
    const response = yield call(services.resetPasswordAsync, token, password);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.postResetPasswordSuccess());
      history.push(authRoutes.LOGIN_BY_USER_NAME);
      toast.success("You have successfully changed your password");
    } else {
      toast.error(data.message);
      yield put(actions.postResetPasswordFailure());
    }
  } catch (error) {
    const { data } = error.response;
    yield put(actions.postResetPasswordFailure());
    toast.error(data.error);
    console.log(error);
  }
}

export function* watchPostLoginPassword() {
  yield takeEvery(actionTypes.POST_LOGIN_WITH_PASSWORD, postLoginPassword);
}
export function* watchPostForGetPassword() {
  yield takeEvery(actionTypes.POST_FORGET_PASSWORD, postForgetPassword);
}
export function* watchPostResetPassword() {
  yield takeEvery(actionTypes.POST_RESET_PASSSWORD, postResetPassword);
}

//Fork
export default function* rootSaga() {
  yield all([
    fork(watchPostLoginPassword),
    fork(watchPostForGetPassword),
    fork(watchPostResetPassword),
  ]);
}
