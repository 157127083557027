import * as actionTypes from "../actionTypes";

// ** get language list action
export const getPlacesList = () => ({
  type: actionTypes.GET_PLACES,
});
export const getPlacesListSuccess = (placesList) => ({
  type: actionTypes.GET_PLACES_SUCCESS,
  payload: { placesList },
});
export const getPlacesListFailure = () => ({
  type: actionTypes.GET_PLACES_FAILURE,
});

// ** get language list action
export const getPlaceGeoJson = (placeInfo) => ({
  type: actionTypes.GET_PLACE_GEO_JSON,
  payload: { placeInfo },
});
export const getPlaceGeoJsonSuccess = (data) => ({
  type: actionTypes.GET_PLACE_GEO_JSON_SUCCESS,
  payload: { data },
});
export const getPlaceGeoJsonFailure = () => ({
  type: actionTypes.GET_PLACE_GEO_JSON_FAILURE,
});

// ** get poi list action
export const getPoiGeoJson = (placeInfo) => ({
  type: actionTypes.GET_POI_GEO_JSON,
  payload: { placeInfo },
});
export const getPoiGeoJsonSuccess = (data) => ({
  type: actionTypes.GET_POI_GEO_JSON_SUCCESS,
  payload: { data },
});
export const getPoiGeoJsonFailure = () => ({
  type: actionTypes.GET_POI_GEO_JSON_FAILURE,
});

// ** get Place Center Coordination
export const getPlaceCenterCoordination = (placeInfo) => ({
  type: actionTypes.GET_PLACE_CENTER_COORDINATION,
  payload: { placeInfo },
});
export const getPlaceCenterCoordinationSuccess = (data) => ({
  type: actionTypes.GET_PLACE_CENTER_COORDINATION_SUCCESS,
  payload: { data },
});
export const getPlaceCenterCoordinationFailure = () => ({
  type: actionTypes.GET_PLACE_CENTER_COORDINATION_FAILURE,
});

// ** put plces settings
export const putPlaceSettings = (settingInfo) => ({
  type: actionTypes.PUT_PLACES_SETTINGS,
  payload: { settingInfo },
});
export const putPlaceSettingsSuccess = (settingInfo) => ({
  type: actionTypes.PUT_PLACES_SETTINGS_SUCCESS,
  payload: { settingInfo },
});
export const putPlaceSettingsFailure = () => ({
  type: actionTypes.PUT_PLACES_SETTINGS_FAILURE,
});

// ** delete plces settings
export const deletePlaceSettings = (settingInfo) => ({
  type: actionTypes.DELETE_PLACES_SETTINGS,
  payload: { settingInfo },
});
export const deletePlaceSettingsSuccess = (settingInfo) => ({
  type: actionTypes.DELETE_PLACES_SETTINGS_SUCCESS,
  payload: { settingInfo },
});
export const deletePlaceSettingsFailure = () => ({
  type: actionTypes.DELETE_PLACES_SETTINGS_FAILURE,
});

// ** delete plces settings
export const getPlaceSettings = (settingInfo) => ({
  type: actionTypes.GET_PLACES_SETTINGS,
  payload: { settingInfo },
});
export const getPlaceSettingsSuccess = (settingInfo) => ({
  type: actionTypes.GET_PLACES_SETTINGS_SUCCESS,
  payload: { settingInfo },
});
export const getPlaceSettingsFailure = () => ({
  type: actionTypes.GET_PLACES_SETTINGS_FAILURE,
});

// ** current floor
export const changeCurrentFloor = (floorInfo) => ({
  type: actionTypes.CHANGE_CURRENT_FLOOR,
  payload: { floorInfo },
});

// ** get place live status
export const getPlaceLiveStatus = (placeInfo) => ({
  type: actionTypes.GET_PLACE_LIVE_STATUS,
  payload: { placeInfo },
});
export const getPlaceLiveStatusSuccess = (placeLiveStatus) => ({
  type: actionTypes.GET_PLACE_LIVE_STATUS_SUCCESS,
  payload: { placeLiveStatus },
});
export const getPlaceLiveStatusFailure = () => ({
  type: actionTypes.GET_PLACE_LIVE_STATUS_FAILURE,
});

// ** post publish  GIS

export const postPlacePublishGis = (placeInfo) => ({
  type: actionTypes.POST_PLACE_PUBLISH_GIS,
  payload: { placeInfo },
});
export const postPlacePublishGisSuccess = (placeInfo) => ({
  type: actionTypes.POST_PLACE_PUBLISH_GIS_SUCCESS,
  payload: { placeInfo },
});
export const postPlacePublishGisFailure = () => ({
  type: actionTypes.POST_PLACE_PUBLISH_GIS_FAILURE,
});

// ******************** content of poi *****************

// *** post content of poi
export const postPoiContent = (poiInfo) => ({
  type: actionTypes.POST_POI_CONTENT,
  payload: { poiInfo },
});
export const postPoiContentSuccess = (poiInfo) => ({
  type: actionTypes.POST_POI_CONTENT_SUCCESS,
  payload: { poiInfo },
});
export const postPoiContentFailure = () => ({
  type: actionTypes.POST_POI_CONTENT_FAILURE,
});

// *** delete content of poi
export const deletePoiContent = (poiInfo) => ({
  type: actionTypes.DELETE_POI_CONTENT,
  payload: { poiInfo },
});
export const deletePoiContentSuccess = (poiInfo) => ({
  type: actionTypes.DELETE_POI_CONTENT_SUCCESS,
  payload: { poiInfo },
});
export const deletePoiContentFailure = () => ({
  type: actionTypes.DELETE_POI_CONTENT_FAILURE,
});
