import { call, takeEvery, put, all, fork } from "redux-saga/effects";
import * as actionTypes from "../actionTypes";

//**  services
import * as services from "../../services/userServices";

//**  actions
import * as actions from "./actions";

import { toast } from "react-toastify";

//** Get user list
function* getUserList() {
  try {
    const response = yield call(services.getUserListAsync);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.getUserListSuccess(data));
    } else {
      toast.error(data.message);
      yield put(actions.getUserListFailure());
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.getUserListFailure());
    toast.error(data.message);
    console.log(error);
  }
}

//** post create new user
function* postCreateNewUser({ payload }) {
  let { email } = payload;
  try {
    const response = yield call(services.postCreateNewUserAsync, email);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.postNewUserSuccess(data));
      toast.success("Your user created successfully");
    } else {
      yield put(actions.postNewUserFailure());
      toast.error(data.message);
    }
  } catch (error) {
    const { data } = error.response;
    yield put(actions.postNewUserFailure());
    toast.error(data.message);
    console.log(error);
  }
}

//** delete user  */
function* deleteUser({ payload }) {
  let { userId } = payload;
  try {
    const response = yield call(services.deleteUserAsync, userId);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.deleteUserSuccess(userId));
      toast.success("Your user deleted successfully");
    } else {
      yield put(actions.deleteUserFailure());
      toast.error(data.message);
    }
  } catch (error) {
    yield put(actions.deleteUserFailure());
    toast.error(error.message);
    console.log(error);
  }
}

//** Get deleted user list
function* getDeletedUserList() {
  try {
    const response = yield call(services.getDeletedUserListAsync);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.getDeletedUserListSuccess(data));
    } else {
      toast.error(data.message);
      yield put(actions.getDeletedUserListFailure([]));
    }
  } catch (error) {
    yield put(actions.getDeletedUserListFailure([]));
    toast.error(error.message);
    console.log(error);
  }
}

//** restore user  */
function* putUserResendInvitation({ payload }) {
  let { userId } = payload;
  try {
    const response = yield call(services.putUserResendInvitationAsync, userId);
    const { data } = response;

    console.log(data);

    if (response.status === 200) {
      yield put(actions.putUserResendInvitationSuccess(userId));
      toast.success("Your invitation was sent successfully");
    } else {
      yield put(actions.putUserResendInvitationFailure());
      toast.error("User has own password");
    }
  } catch (error) {
    yield put(actions.putUserResendInvitationFailure());
    toast.error("User has own password");
    console.log(error);
  }
}
//** restore user  */
function* restoreUser({ payload }) {
  let { userId } = payload;
  try {
    const response = yield call(services.restoreUserAsync, userId);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.restoreUserSuccess(userId));
      yield put(actions.openDeletedUserListModal(false));
      yield put(actions.getUserList());
      toast.success("Your user restored successfully");
    } else {
      yield put(actions.restoreUserFailure());
      toast.error(data.message);
    }
  } catch (error) {
    yield put(actions.restoreUserFailure());
    toast.error(error.message);
    console.log(error);
  }
}

// *************************** watchers ******************************

export function* watchGetUserList() {
  yield takeEvery(actionTypes.GET_USER_LIST, getUserList);
}

export function* watchPostNewUser() {
  yield takeEvery(actionTypes.POST_NEW_USER, postCreateNewUser);
}

export function* watchDeleteUser() {
  yield takeEvery(actionTypes.DELETE_USER, deleteUser);
}

export function* watchGetDeletedUserList() {
  yield takeEvery(actionTypes.GET_DELETED_USER_LIST, getDeletedUserList);
}

export function* watchRestoreUser() {
  yield takeEvery(actionTypes.RESTORE_USER, restoreUser);
}
export function* watchUserResendInvitation() {
  yield takeEvery(
    actionTypes.PUT_USER_RESEND_INVITATION,
    putUserResendInvitation
  );
}

//Fork
export default function* rootSaga() {
  yield all([
    fork(watchGetUserList),
    fork(watchPostNewUser),
    fork(watchDeleteUser),
    fork(watchGetDeletedUserList),
    fork(watchRestoreUser),
    fork(watchUserResendInvitation),
  ]);
}
