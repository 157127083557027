import * as actionTypes from "../actionTypes";

// ** get language list action
export const getZonesList = (placeInfo) => ({
  type: actionTypes.GET_ZONE_LIST,
  payload: { placeInfo },
});
export const getZonesListSuccess = (zoneList) => ({
  type: actionTypes.GET_ZONE_LIST_SUCCESS,
  payload: { zoneList },
});
export const getZonesListFailure = () => ({
  type: actionTypes.GET_ZONE_LIST_FAILURE,
});
