import * as actionTypes from "../actionTypes";

const intialState = {
  userList: [],
  customerList: [],
  deletedUserList: [],
  createdUserData: {},

  getUserListLoader: false,
  postNewUserLoader: false,
  deleteUserLoader: false,
  getDeletedUserListLoader: false,
  restoreUserLoader: false,
  getCustomerListLoader: false,
  putUserResendInvitation: false,

  openDeletedUserListModal: false,
};

const users = (state = intialState, action) => {
  switch (action.type) {
    //**GET_USER_LIST */
    case actionTypes.GET_USER_LIST: {
      return {
        ...state,
        getUserListLoader: true,
      };
    }
    case actionTypes.GET_USER_LIST_SUCCESS: {
      return {
        ...state,
        userList: action.payload.userList,
        getUserListLoader: false,
      };
    }
    case actionTypes.GET_USER_LIST_FAILURE: {
      return {
        ...state,
        getUserListLoader: false,
      };
    }

    //**GET_DELETED_USER_LIST */
    case actionTypes.GET_DELETED_USER_LIST: {
      return {
        ...state,
        getDeletedUserListLoader: true,
      };
    }
    case actionTypes.GET_DELETED_USER_LIST_SUCCESS: {
      return {
        ...state,
        deletedUserList: action.payload.userList,
        getDeletedUserListLoader: false,
      };
    }
    case actionTypes.GET_DELETED_USER_LIST_FAILURE: {
      return {
        ...state,
        getDeletedUserListLoader: false,
        deletedUserList: action.payload.userList,
      };
    }

    // **POST_NEW_USER
    case actionTypes.POST_NEW_USER: {
      return {
        ...state,
        postNewUserLoader: true,
      };
    }
    case actionTypes.POST_NEW_USER_SUCCESS: {
      const { id, email, updatedAt, createdAt } =
        action.payload.createdUserData;

      return {
        ...state,
        postNewUserLoader: false,
        userList: [
          ...state.userList,
          {
            id: id,
            email: email,
            updatedAt: updatedAt,
            createdAt: createdAt,
          },
        ],
      };
    }
    case actionTypes.POST_NEW_USER_FAILURE: {
      return {
        ...state,
        postNewUserLoader: false,
      };
    }

    // ** delete user
    case actionTypes.DELETE_USER: {
      return {
        ...state,
        deleteUserLoader: true,
      };
    }
    case actionTypes.DELETE_USER_SUCCESS: {
      const userId = action.payload.userId;

      // ** remove deleted item from array
      const updateUserList = state.userList.filter(
        (item) => item.id !== userId
      );
      return {
        ...state,
        userList: updateUserList,
        deleteUserLoader: false,
      };
    }
    case actionTypes.DELETE_USER_FAILURE: {
      return {
        ...state,
        deleteUserLoader: false,
      };
    }

    // ** restore user
    case actionTypes.RESTORE_USER: {
      return {
        ...state,
        restoreUserLoader: true,
      };
    }
    case actionTypes.RESTORE_USER_SUCCESS: {
      const userId = action.payload.userId;

      console.log("deleted user id ", userId);

      // ** remove deleted item from array
      const updateUserList = state.userList.filter(
        (item) => item.id !== userId
      );

      return {
        ...state,
        userList: updateUserList,
        restoreUserLoader: false,
      };
    }
    case actionTypes.RESTORE_USER_FAILURE: {
      return {
        ...state,
        restoreUserLoader: false,
      };
    }

    // *** PUT_USER_RESEND_INVITATION
    case actionTypes.PUT_USER_RESEND_INVITATION: {
      return {
        ...state,
        putUserResendInvitation: true,
      };
    }
    case actionTypes.PUT_USER_RESEND_INVITATION_SUCCESS: {
      return {
        ...state,
        putUserResendInvitation: false,
      };
    }
    case actionTypes.PUT_USER_RESEND_INVITATION_FAILURE: {
      return {
        ...state,
        putUserResendInvitation: false,
      };
    }

    // *** DELETED_USER_LIST_MODAL
    case actionTypes.DELETED_USER_LIST_MODAL: {
      const { openModal } = action.payload;
      return {
        ...state,
        openDeletedUserListModal: openModal,
      };
    }

    default: {
      return state;
    }
  }
};

export default users;
