import * as actionTypes from "../actionTypes";

const intialState = {
  pointtypeList: [],
  deletedPointtypeList: [],

  selectedPointInfo: null,
  postPointtypeLoader: false,
  getPointtypeListLoader: false,
  deletePointtypeLoader: false,
  putRestorePointtypeLoader: false,
  putPointtypeLoader: false,

  getDeletedPointtypeListLoader: false,

  openDeletedPointtypeListModal: false,
};

const pointtypes = (state = intialState, action) => {
  switch (action.type) {
    //**GET_POINTTYPE_LIST */
    case actionTypes.GET_POINTTYPE_LIST: {
      return {
        ...state,
        getPointtypeListLoader: true,
      };
    }
    case actionTypes.GET_POINTTYPE_LIST_SUCCESS: {
      return {
        ...state,
        pointtypeList: action.payload.pointtypeList,
        getPointtypeListLoader: false,
      };
    }
    case actionTypes.GET_POINTTYPE_LIST_FAILURE: {
      return {
        ...state,
        getPointtypeListLoader: false,
      };
    }

    // ** post new place type
    case actionTypes.POST_POINTTYPE: {
      return {
        ...state,
        postPointtypeLoader: true,
      };
    }
    case actionTypes.POST_POINTTYPE_SUCCESS: {
      const { id, name, createdAt } = action.payload.pointInfo;

      return {
        ...state,
        pointtypeList: [
          ...state.pointtypeList,
          {
            id,
            name,
            createdAt,
          },
        ],
        postPointtypeLoader: false,
      };
    }
    case actionTypes.POST_POINTTYPE_FAILURE: {
      return {
        ...state,
        postPointtypeLoader: false,
      };
    }

    // ** DELETE POINTTYPE
    case actionTypes.DELETE_POINTTYPE: {
      return {
        ...state,
        deletePointtypeLoader: true,
      };
    }
    case actionTypes.DELETE_POINTTYPE_SUCCESS: {
      const pointInfo = action.payload.pointInfo;

      // ** remove deleted item from array
      const updatePointtypeList = state.pointtypeList.filter(
        (item) => item.id !== pointInfo.id
      );
      return {
        ...state,
        pointtypeList: updatePointtypeList,
        deletePointtypeLoader: false,
      };
    }
    case actionTypes.DELETE_POINTTYPE_FAILURE: {
      return {
        ...state,
        deletePointtypeLoader: false,
      };
    }

    //** DELETED_POINTTYPE_LIST_MODAL
    case actionTypes.DELETED_POINTTYPE_LIST_MODAL: {
      const { openModal } = action.payload;
      return {
        ...state,
        openDeletedPointtypeListModal: openModal,
      };
    }

    //**GET_DELETED_CUSTOMER_LIST */
    case actionTypes.GET_DELETED_POINTTYPE_LIST: {
      return {
        ...state,
        getDeletedPointtypeListLoader: true,
      };
    }
    case actionTypes.GET_DELETED_POINTTYPE_LIST_SUCCESS: {
      return {
        ...state,
        deletedPointtypeList: action.payload.deletedPointtypeList,
        getDeletedPointtypeListLoader: false,
      };
    }
    case actionTypes.GET_DELETED_POINTTYPE_LIST_FAILURE: {
      return {
        ...state,
        getDeletedPointtypeListLoader: false,
      };
    }

    // ** delete customer
    case actionTypes.PUT_RESTORE_POINTTYPE: {
      const pointInfo = action.payload.pointInfo;
      return {
        ...state,
        selectedPointInfo: pointInfo,
        putRestorePointtypeLoader: true,
      };
    }
    case actionTypes.PUT_RESTORE_POINTTYPE_SUCCESS: {
      const pointInfo = action.payload.pointInfo;

      // ** remove deleted item from array
      const updateDeletedPointtypeList = state.deletedPointtypeList.filter(
        (item) => item.id !== pointInfo.id
      );
      return {
        ...state,
        deletedPointtypeList: updateDeletedPointtypeList,
        putRestorePointtypeLoader: false,
      };
    }
    case actionTypes.PUT_RESTORE_POINTTYPE_FAILURE: {
      return {
        ...state,
        putRestorePointtypeLoader: false,
      };
    }

    // ** PUT_NETWROKTYPE
    case actionTypes.PUT_POINTTYPE: {
      return {
        ...state,
        putPointtypeLoader: true,
      };
    }
    case actionTypes.PUT_POINTTYPE_SUCCESS: {
      const { id, name, createdAt, updatedAt } = action.payload.pointInfo;
      console.log(action.payload.pointInfo);

      const pointTypeIndex = state.pointtypeList.findIndex(
        (pointtype) => pointtype.id === id
      );

      const pointtypeListUpdate = [...state.pointtypeList];

      pointtypeListUpdate[pointTypeIndex] = {
        id,
        name,
        createdAt,
        updatedAt,
      };

      return {
        ...state,
        pointtypeList: pointtypeListUpdate,
        putPointtypeLoader: false,
      };
    }
    case actionTypes.PUT_POINTTYPE_FAILURE: {
      return {
        ...state,
        putPointtypeLoader: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default pointtypes;
