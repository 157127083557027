import * as actionTypes from "../actionTypes";

const intialState = {
  brandsList: [],
  deletedBrandsList: [],

  postBrandsLoader: false,
  getBrandsListLoader: false,
  getDeletedBrandsListLoader: false,
  deleteBrandLoader: false,
  putRestoreBrandLoader: false,
  putBrandLoader: false,

  selectedBrandeId: "",

  getDeletedBrandsLoader: false,

  openDeletedBrandsListModal: false,
};

const Brands = (state = intialState, action) => {
  switch (action.type) {
    //**GET_BRANDS_LIST */
    case actionTypes.GET_BRANDS_LIST: {
      return {
        ...state,
        getBrandsListLoader: true,
      };
    }
    case actionTypes.GET_BRANDS_LIST_SUCCESS: {
      return {
        ...state,
        brandsList: action.payload.brandsList,
        getBrandsListLoader: false,
      };
    }
    case actionTypes.GET_BRANDS_LIST_FAILURE: {
      return {
        ...state,
        getBrandsListLoader: false,
      };
    }

    // ** post new place type
    case actionTypes.POST_NEW_BRAND: {
      return {
        ...state,
        postBrandsLoader: true,
      };
    }
    case actionTypes.POST_NEW_BRAND_SUCCESS: {
      const { id, name, createdAt } = action.payload.brandInfo;

      return {
        ...state,
        brandsList: [
          ...state.brandsList,
          {
            id,
            name,
            createdAt,
          },
        ],
        postBrandsLoader: false,
      };
    }
    case actionTypes.POST_NEW_BRAND_FAILURE: {
      return {
        ...state,
        postBrandsLoader: false,
      };
    }

    //**GET_DELETED_BRANDS_LIST */
    case actionTypes.GET_DELETED_BRANDS_LIST: {
      return {
        ...state,
        getDeletedBrandsListLoader: true,
      };
    }
    case actionTypes.GET_DELETED_BRANDS_LIST_SUCCESS: {
      return {
        ...state,
        deletedBrandsList: action.payload.deletedBrandsList,
        getDeletedBrandsListLoader: false,
      };
    }
    case actionTypes.GET_DELETED_BRANDS_LIST_FAILURE: {
      return {
        ...state,
        getDeletedBrandsListLoader: false,
      };
    }

    // ** DELETE_BRAND
    case actionTypes.DELETE_BRAND: {
      return {
        ...state,
        deleteBrandLoader: true,
      };
    }
    case actionTypes.DELETE_BRAND_SUCCESS: {
      const brandInfo = action.payload.brandInfo;

      // ** remove deleted item from array
      const updateBrandsList = state.brandsList.filter(
        (item) => item.id !== brandInfo.id
      );
      return {
        ...state,
        brandsList: updateBrandsList,
        deleteBrandLoader: false,
      };
    }
    case actionTypes.DELETE_BRAND_FAILURE: {
      return {
        ...state,
        deleteBrandLoader: false,
      };
    }

    // ** PUT_RESTORE_BRAND
    case actionTypes.PUT_RESTORE_BRAND: {
      const brandInfo = action.payload.brandInfo;
      return {
        ...state,
        selectedBrandeId: brandInfo.id,
        putRestoreBrandLoader: true,
      };
    }
    case actionTypes.PUT_RESTORE_BRAND_SUCCESS: {
      const brandInfo = action.payload.brandInfo;

      // ** remove deleted item from array
      const updateDeletedBrandsList = state.deletedBrandsList.filter(
        (item) => item.id !== brandInfo.id
      );
      return {
        ...state,
        deletedBrandsList: updateDeletedBrandsList,
        putRestoreBrandLoader: false,
      };
    }
    case actionTypes.PUT_RESTORE_BRAND_FAILURE: {
      return {
        ...state,
        putRestoreBrandLoader: false,
      };
    }

    // ** PUT_BRAND
    case actionTypes.PUT_BRAND: {
      return {
        ...state,
        putBrandLoader: true,
      };
    }
    case actionTypes.PUT_BRAND_SUCCESS: {
      const { id, name, createdAt, updatedAt } = action.payload.brandInfo;

      const brandIndex = state.brandsList.findIndex((brand) => brand.id === id);

      const brandsListUpdate = [...state.brandsList];

      brandsListUpdate[brandIndex] = {
        id,
        name,
        createdAt,
        updatedAt,
      };

      return {
        ...state,
        brandsList: brandsListUpdate,
        putBrandLoader: false,
      };
    }
    case actionTypes.PUT_BRAND_FAILURE: {
      return {
        ...state,
        putBrandLoader: false,
      };
    }

    case actionTypes.DELETED_BRANDS_LIST_MODAL: {
      const { openModal } = action.payload;
      return {
        ...state,
        openDeletedBrandsListModal: openModal,
      };
    }

    default: {
      return state;
    }
  }
};

export default Brands;
