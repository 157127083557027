import * as servicesUrl from "../assets/data/urlConfig";
import axiosApiInctance from "../services/axiosInterceptor";

// ** get user list async
export const getCustomerListAsync = async () => {
  return await axiosApiInctance.get(`${servicesUrl.getCustomerListUrl}`);
};

// ** get customer config async
export const getCustomerConfigAsync = async (data) => {
  return await axiosApiInctance.get(
    `${servicesUrl.getCustomerConfigUrl(data)}`
  );
};

// ** put customer config async
export const putCustomerConfigAsync = async (data) => {
  return await axiosApiInctance.put(
    `${servicesUrl.putCustomerConfigUrl(data)}`,
    {
      ...data.config,
    }
  );
};

// ** get deleted customer list async
export const getDeletedCustomerListAsync = async () => {
  return await axiosApiInctance.get(`${servicesUrl.getDeletedCustomerListUrl}`);
};

// ** delete customer
export const deleteCustomerAsync = async (customerId) => {
  return await axiosApiInctance.delete(
    `${servicesUrl.deleteCustomerUrl(customerId)}`
  );
};
// ** put restore  customer
export const putRestoreCustomerAsync = async (customerId) => {
  return await axiosApiInctance.put(
    `${servicesUrl.putRestoreCustomerUrl(customerId)}`
  );
};

// ** post new customer
export const postNewCustomerAsync = async (customerInfo) => {
  const {
    name,
    subscriptionStartDate,
    subscriptionEndDate,
    subscriptionActive,
    navigationActive,
    vdNavigationActive,
  } = customerInfo;
  return await axiosApiInctance.post(`${servicesUrl.postNewCustomerUrl}`, {
    name,
    subscriptionStartDate,
    subscriptionEndDate,
    subscriptionActive,
    navigationActive,
    vdNavigationActive,
  });
};

// ** post create new customer super admin
export const postCreateNewCustomerSuperAdmin = async (customerInfo) => {
  const { id, email } = customerInfo;

  return await axiosApiInctance.post(
    `${servicesUrl.postCreateNewCustomerSuperAdmin(id)}`,
    {
      email,
    }
  );
};
// ** put customer super admin
export const putCustomerSuperAdminAsync = async (data) => {
  const { email } = data;
  return await axiosApiInctance.put(
    `${servicesUrl.putCustomerSuperAdminUrl(data)}`,
    {
      email,
    }
  );
};

// ** post create new customer language
export const postNewCustomerLanguageAsync = async (languageInfo) => {
  const { customerId, language } = languageInfo;
  return await axiosApiInctance.post(
    `${servicesUrl.postNewCustomerLanguageUrl(customerId)}`,
    {
      language,
    }
  );
};

// ** delete customer language
export const deleteCustomerLanguageAsync = async (languageInfo) => {
  return await axiosApiInctance.delete(
    `${servicesUrl.deleteCustomerLanguageUrl(languageInfo)}`
  );
};

// ** put update customer
export const putUpdateCustomerAsync = async (customerInfo) => {
  const {
    id,
    name,
    subscriptionStartDate,
    subscriptionEndDate,
    subscriptionActive,
    navigationActive,
    vdNavigationActive,
  } = customerInfo;
  return await axiosApiInctance.put(`${servicesUrl.putUpdateCustomerUrl(id)}`, {
    name,
    subscriptionStartDate,
    subscriptionEndDate,
    subscriptionActive,
    navigationActive,
    vdNavigationActive,
  });
};

// ** get customer by id
export const getCustomerByIdAsync = async (customerInfo) => {
  const { id } = customerInfo;
  return await axiosApiInctance.get(`${servicesUrl.getCustomerByIdUrl(id)}`);
};

// ** post resend customer user invitation
export const postResendCustomerUserInvitationAsync = async (invitationInfo) => {
  return await axiosApiInctance.put(
    `${servicesUrl.postResendCustomerUserInvitationUrl(invitationInfo)}`
  );
};

// ** get customer navigation config
export const getCustomerNavigationConfigAsync = async (customerInfo) => {
  return await axiosApiInctance.get(
    `${servicesUrl.getCustomerNavigationConfigUrl(customerInfo)}`
  );
};

// ** put customer navigation config
export const putCustomerNavigationConfigAsync = async (navigationInfo) => {
  const { uuid, requestInterval, fullInit, topPoiCount, contactEmail } =
    navigationInfo;

  const data = {
    uuid: uuid,
    requestInterval: requestInterval,
    fullInit: fullInit,
    // ...(contactEmail && { contactEmail: contactEmail.toString() }),
    contactEmail: contactEmail ? contactEmail.toString() : "",
    topPoiCount: topPoiCount ? topPoiCount : 0,
  };

  return await axiosApiInctance.put(
    `${servicesUrl.putCustomerNavigationConfigUrl(navigationInfo)}`,

    data
  );
};

// ** login as a customer
export const getLoginAsCustomerAsync = async (data) => {
  return await axiosApiInctance.get(
    `${servicesUrl.getLoginAsCustomerUrl(data)}`
  );
};
