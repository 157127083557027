import * as actionTypes from "../actionTypes";

const intialState = {
  userData: {},

  postLoginLoader: false,
  postForGetPasswordLoader: false,
  postResetPassswordLoader: false,
};

const auth = (state = intialState, action) => {
  switch (action.type) {
    //**POST_LOGIN_WITH_PASSWORD */
    case actionTypes.POST_LOGIN_WITH_PASSWORD: {
      return {
        ...state,
        postLoginLoader: true,
      };
    }
    case actionTypes.POST_LOGIN_WITH_PASSWORD_SUCCESS: {
      return {
        ...state,
        userData: action.payload.userData,
        postLoginLoader: false,
      };
    }
    case actionTypes.POST_LOGIN_WITH_PASSWORD_FAILURE: {
      return {
        ...state,
        postLoginLoader: false,
      };
    }

    // **POST_FORGET_PASSWORD
    case actionTypes.POST_FORGET_PASSWORD: {
      return {
        ...state,
        postForGetPasswordLoader: true,
      };
    }
    case actionTypes.POST_FORGET_PASSWORD_SUCCESS: {
      return {
        ...state,
        postForGetPasswordLoader: false,
      };
    }
    case actionTypes.POST_FORGET_PASSWORD_FAILURE: {
      return {
        ...state,
        postForGetPasswordLoader: false,
      };
    }

    // **POST_RESET_PASSWORD
    case actionTypes.POST_RESET_PASSSWORD: {
      return {
        ...state,
        postResetPassswordLoader: true,
      };
    }
    case actionTypes.POST_RESET_PASSSWORD_SUCCESS: {
      return {
        ...state,
        postResetPassswordLoader: false,
      };
    }
    case actionTypes.POST_RESET_PASSSWORD_FAILURE: {
      return {
        ...state,
        postResetPassswordLoader: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default auth;
