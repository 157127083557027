import { call, takeEvery, put, all, fork } from "redux-saga/effects";
import * as actionTypes from "../actionTypes";

//**  services
import * as services from "../../services/pointtypeServices";

//**  actions
import * as actions from "./actions";

import { toast } from "react-toastify";

//** Get user list
function* getPointtypes() {
  try {
    const response = yield call(services.getPointtypeListAsync);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.getPointtypeListSuccess(data));
    } else {
      toast.error(data.message);
      yield put(actions.getPointtypeListFailure());
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.getPointtypeListFailure());
    toast.error(data.message);
    console.log(error);
  }
}

//** post  new pointtype
function* postNewPointtype({ payload }) {
  let { pointInfo } = payload;
  try {
    const response = yield call(services.postNewPointtypeAsync, pointInfo);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.postNewPointtypeSuccess(data));
      toast.success("Your pointtype created successfully");
    } else {
      yield put(actions.postNewPointtypeFailure());
      toast.error(data.message);
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.postNewPointtypeFailure());
    toast.error(data.message);
  }
}

// ** delete pointtype
function* deletePointtype({ payload }) {
  let { pointInfo } = payload;
  try {
    const response = yield call(services.deletePointtypeAsync, pointInfo);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.deletePointtypeSuccess(pointInfo));
      toast.success("Your pointtype deleted successfully");
    } else {
      yield put(actions.deletePointtypeFailure());
      toast.error(data.message);
    }
  } catch (error) {
    // const { data } = error.response;

    yield put(actions.deletePointtypeFailure());
    toast.error(error.message);
    console.log(error);
  }
}

//** get Deleted PointtypeList
function* getDeletedPointtypeList() {
  try {
    const response = yield call(services.getDeletedPointtypeListAsync);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.getDeletedPointtypeListSuccess(data));
    } else {
      toast.error(data.message);
      yield put(actions.getDeletedPointtypeListFailure([]));
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.getDeletedPointtypeListFailure([]));
    toast.error(data.message);
    console.log(error);
  }
}

// ** restore pointtype
function* putRestorePointtype({ payload }) {
  let { pointInfo } = payload;
  try {
    const response = yield call(
      services.putRestorePointtypeUrlAsync,
      pointInfo
    );
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.putRestorePointtypeSuccess(pointInfo));
      yield put(actions.openDeletedPointtypeListModal(false));
      yield put(actions.getPointtypeList());

      toast.success("Your pointtype restored successfully");
    } else {
      yield put(actions.putRestorePointtypeFailure());
      toast.error(data.message);
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.putRestorePointtypeFailure());
    toast.error(data.message);
    console.log(error);
  }
}

//** put update  customer
function* putPointtype({ payload }) {
  let { pointInfo } = payload;

  try {
    const response = yield call(services.putPointtypeAsync, pointInfo);
    const { data } = response;

    if (response.status === 200) {
      yield put(actions.putPointtypeSuccess(data));
      toast.success("Your pointtype was updated successfully");
    } else {
      yield put(actions.putPointtypeFailure());
      toast.error(data.message);
    }
  } catch (error) {
    const { data } = error.response;

    yield put(actions.putPointtypeFailure());
    toast.error(data.message);
  }
}

// *************************** watchers ******************************
export function* watchGetPointtypes() {
  yield takeEvery(actionTypes.GET_POINTTYPE_LIST, getPointtypes);
}
export function* watchPostNewPointtype() {
  yield takeEvery(actionTypes.POST_POINTTYPE, postNewPointtype);
}
export function* watchDeletePointtype() {
  yield takeEvery(actionTypes.DELETE_POINTTYPE, deletePointtype);
}
export function* watchGetDeletedPointtypeList() {
  yield takeEvery(
    actionTypes.GET_DELETED_POINTTYPE_LIST,
    getDeletedPointtypeList
  );
}
export function* watchPutRestorePointtype() {
  yield takeEvery(actionTypes.PUT_RESTORE_POINTTYPE, putRestorePointtype);
}
export function* watchPutPointtype() {
  yield takeEvery(actionTypes.PUT_POINTTYPE, putPointtype);
}

//Fork
export default function* rootSaga() {
  yield all([
    fork(watchGetPointtypes),
    fork(watchPostNewPointtype),
    fork(watchDeletePointtype),
    fork(watchGetDeletedPointtypeList),
    fork(watchPutRestorePointtype),
    fork(watchPutPointtype),
  ]);
}
