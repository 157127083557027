import * as actionTypes from "../actionTypes";

// ** get Pointtype List
export const getPointtypeList = () => ({
  type: actionTypes.GET_POINTTYPE_LIST,
});
export const getPointtypeListSuccess = (pointtypeList) => ({
  type: actionTypes.GET_POINTTYPE_LIST_SUCCESS,
  payload: { pointtypeList },
});
export const getPointtypeListFailure = () => ({
  type: actionTypes.GET_POINTTYPE_LIST_FAILURE,
});

//** POST_POINTTYPE  */
export const postNewPointtype = (pointInfo) => ({
  type: actionTypes.POST_POINTTYPE,
  payload: { pointInfo },
});
export const postNewPointtypeSuccess = (pointInfo) => ({
  type: actionTypes.POST_POINTTYPE_SUCCESS,
  payload: { pointInfo },
});
export const postNewPointtypeFailure = () => ({
  type: actionTypes.POST_POINTTYPE_FAILURE,
});

// ** DELETE_POINTTYPE
export const deletePointtype = (pointInfo) => ({
  type: actionTypes.DELETE_POINTTYPE,
  payload: { pointInfo },
});
export const deletePointtypeSuccess = (pointInfo) => ({
  type: actionTypes.DELETE_POINTTYPE_SUCCESS,
  payload: { pointInfo },
});
export const deletePointtypeFailure = () => ({
  type: actionTypes.DELETE_POINTTYPE_FAILURE,
});

// ** get Deleted PointtypeList
export const getDeletedPointtypeList = () => ({
  type: actionTypes.GET_DELETED_POINTTYPE_LIST,
});
export const getDeletedPointtypeListSuccess = (deletedPointtypeList) => ({
  type: actionTypes.GET_DELETED_POINTTYPE_LIST_SUCCESS,
  payload: { deletedPointtypeList },
});
export const getDeletedPointtypeListFailure = () => ({
  type: actionTypes.GET_DELETED_POINTTYPE_LIST_FAILURE,
});

//** DELETED_POINTTYPE_LIST_MODAL

export const openDeletedPointtypeListModal = (openModal) => ({
  type: actionTypes.DELETED_POINTTYPE_LIST_MODAL,
  payload: { openModal },
});

// ** put Restore Pointtype
export const putRestorePointtype = (pointInfo) => ({
  type: actionTypes.PUT_RESTORE_POINTTYPE,
  payload: { pointInfo },
});
export const putRestorePointtypeSuccess = (pointInfo) => ({
  type: actionTypes.PUT_RESTORE_POINTTYPE_SUCCESS,
  payload: { pointInfo },
});
export const putRestorePointtypeFailure = () => ({
  type: actionTypes.PUT_RESTORE_POINTTYPE_FAILURE,
});

//** put update customer  */
export const putPointtype = (pointInfo) => ({
  type: actionTypes.PUT_POINTTYPE,
  payload: { pointInfo },
});
export const putPointtypeSuccess = (pointInfo) => ({
  type: actionTypes.PUT_POINTTYPE_SUCCESS,
  payload: { pointInfo },
});
export const putPointtypeFailure = () => ({
  type: actionTypes.PUT_POINTTYPE_FAILURE,
});
